import ITableName from "../../interfaces/ITableName";

class ConfigAgent implements ITableName {
  id?: number = -1;
  agent: string = "";
  short: string = "";

  static getTableNameStatic(): string {
    return "config_agent";
  }

  getTableName(): string {
    return ConfigAgent.getTableNameStatic();
  }
}

export default ConfigAgent;
