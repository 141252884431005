import ITableName from "../../interfaces/ITableName";

class ConfigFeePaymentFreq implements ITableName {
  id?: number = -1;
  fee_payment_freq: string = "";

  static getTableNameStatic(): string {
    return "config_fee_payment_freq";
  }

  getTableName(): string {
    return ConfigFeePaymentFreq.getTableNameStatic();
  }
}

export default ConfigFeePaymentFreq;
