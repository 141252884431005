import ITableName from "../../interfaces/ITableName";
import Debtor from "./Debtor";
import RealEstate from "./RealEstate";

class Auction implements ITableName {
  id: number = -1;
  debtor_id: string = "";
  real_estate_id: string = "";
  re_collateral_id: string = "";
  third_part_id: string = "";
  ar_prog_pl: string = "";
  hmer_pl: string = "";
  anag_apetisis: string = "";
  epispeudon: string = "";
  timh_pl: string = "";
  katastash: string = "";
  timh_katak: string = "";
  eparkes_pl: boolean = false;
  yp_parat: string = "";
  suntak_pinaka_katat: string = "";
  hmer_anakophs: string = "";
  poso_ana_client: string = "";
  eisp_me_egg_lf: boolean = false;
  ekd_trapeza_ib: string = "";
  hmer_ekd: string = "";
  lg_amount: string = "";
  lg_comission: string = "";
  dikaiouxos_text: string = "";
  prothesmia_liksis: string = "";
  eksoda_dikhgorou: string = "";
  eksoda_pistop_ektim: string = "";
  hmer_prohg_pl: string = "";
  timh_prohg_pl: string = "";
  katastash_prohg_pl: string = "";
  pleiodotis: string = "";
  debtor: Debtor = new Debtor();
  real_estate: RealEstate = new RealEstate();

  approve_status: string = "";
  insert_timestamp: string = "";
  insert_user: string = "";
  approve_user: string = "";

  static getTableNameStatic(): string { return "auctions"; }

  getTableName(): string { return Auction.getTableNameStatic(); }
}

export default Auction;
