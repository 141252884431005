import ITableName from "../../interfaces/ITableName";

class ConfigInsRisk implements ITableName {
  id?: number = -1;
  ins_risk: string = "";

  static getTableNameStatic(): string {
    return "config_insurance_risk";
  }

  getTableName(): string {
    return ConfigInsRisk.getTableNameStatic();
  }
}

export default ConfigInsRisk;
