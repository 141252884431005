import ITableName from "../../interfaces/ITableName";
import LoanAgreement from "./LoanAgreement";

class RestOfCollaterals implements ITableName {
  id: number = -1;
  ar_kalimatos: string = "";
  em_ekd_etair_metox: string = "";
  em_katox_metox: string = "";
  em_sumbash_enexurou: string = "";
  em_plithos_metoxon: string = "";
  em_onomastikh_timh: string = "";
  em_dhlosh_protheshs_metabib: string = "";
  em_hmer_epidoshs: string = "";
  mem_ekdo_eteria_metox: string = "";
  mem_katoxos_metox: string = "";
  mem_sumbash_enexurou: string = "";
  mem_plithos_metoxon: string = "";
  mem_onom_aksia_metox: string = "";
  mem_sunol_onomas_aksia_metox: string = "";
  mem_epishm_metox_biblio_metox: string = "";
  mem_hmer_epidoshs: string = "";
  k_dikaiouxos_katath: string = "";
  k_sumbash_enex: string = "";
  k_sumbatika_eggrafa: string = "";
  k_trapeza: string = "";
  k_iban: string = "";
  k_ypol: string = "";
  k_hmer_ypol: string = "";
  ea_sumbash_enex: string = "";
  ea_dikaiol_enexur_apait: string = "";
  ea_gnost_sust_enex: string = "";
  ea_hmer_epidoshs: string = "";
  tp_sumbash_enex: string = "";
  tp_hmer_epidoshs: string = "";
  tp_sxetikh_praksh: string = "";
  tp_ypogr_titl_paegae: string = "";
  tp_sumbatika_eggrafa: string = "";
  tp_sumbas_enex_apait_: string = "";
  e_sumbatika_eggrafa: string = "";
  e_pinakio_epitagon: string = "";
  e_sxetika_dikaiol: string = "";
  e_log_takseos: string = "";
  e_xor_log: string = "";
  e_log_xre_eks_prom: string = "";
  e_egkekr_orio_diasp_ana_ekdoth: string = "";
  e_nomotipo_ekdos_epitaghs: string = "";
  e_sumf_poson_epitagis: string = "";
  e_diaspora_ana_ekdoth: string = "";
  e_ypar_kartel_xreost_ypol: string = "";
  e_hmer_ekd_epitaghs: string = "";
  e_end_sfragishs_epit: string = "";
  e_loipa_stoixeia_epitaghs: string = "";
  s_eborikotita: string = "";
  s_pinakio_sunal: string = "";
  s_sxetika_dikaol: string = "";
  s_ypo_ait_pa_meta_sunal: string = "";
  s_log_takseos: string = "";
  s_xor_log_sund_takseos: string = "";
  s_log_xre_eks_prom: string = "";
  s_egkek_orio_diasporas_ekd: string = "";
  s_nom_ekd_epit: string = "";
  s_sumf_poson_epit: string = "";
  s_hmer_ekd_epit: string = "";
  s_ypar_kart_xreost_ypol: string = "";
  s_diasp_ana_ekd: string = "";
  s_ypar_dusmenon_stoix: string = "";
  s_ypog_sumbash: string = "";
  kel_eidos: string = "";
  kel_idioktisia: string = "";
  kel_poso: string = "";
  loan_agreement_id: string = "";
  seira_prosim: string = "";
  poso_prosim: string = "";
  imer_axias: string = "";

  type: string = "";
  loan_agreement: LoanAgreement = new LoanAgreement();

  static getTableNameStatic(): string {
    return "rest_of_collaterals";
  }

  getTableName(): string {
    return RestOfCollaterals.getTableNameStatic();
  }
}

export default RestOfCollaterals;
