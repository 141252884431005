<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { SuccessFilled } from "@element-plus/icons-vue";
import { useMainStore } from "../store/main";
import UserType from "../enums/UserType";

const mainStore = useMainStore();
const emits = defineEmits(["accept"]);
const { t } = useI18n({ useScope: "global" });

</script>

<template>
  <div v-if="
      mainStore.loggedUser.user_type < UserType.Agent">
    <el-row>
      <el-col :span="2">
        <el-popconfirm title="Are you sure?" @confirm="emits('accept')">
          <template #reference>
            <el-icon :size="36" class="successbtn" color="green" style="float: left;">
              <SuccessFilled />
            </el-icon>
          </template>
        </el-popconfirm>
      </el-col>
      <el-col :span="12" style="display:flex; align-items:center;">
        Accept all pending changes
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>

</style>
