<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
import User from "../classes/DB_Entities/User";
import PGRESTResult from "../classes/PGRESTResult";
import Interest_Rates from "../classes/DB_Entities/InterestRates";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
const { showError } = useGenericMethodsVariables();

const emits = defineEmits(["save", "close", "fail-save"]);
const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });
const data = ref(new Interest_Rates());
const usersList = ref(new Array<User>());
const props = defineProps<{
  interestRowID: number;
  interestCategory: String;
  newEntity?: boolean;
}>();

const close = async () => {
  emits("close");
};

onMounted(async () => {
  await loadInterestRate(props.interestRowID);
});

watch(
  () => props.interestRowID,
  async (newVal, oldVal) => {
    console.log("Loading debtor with id " + props.interestRowID);
    await loadInterestRate(props.interestRowID as number);
  }
);

const loadInterestRate = async (row_id: number) => {
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get("interest_rates?id=eq." + row_id);

  if (result.error) {
    showError(result.error.message);

    return;
  }

  data.value = result.data[0];
};

const save = async () => {
  let obj = JSON.parse(JSON.stringify(data.value));
  let result = await pgrestapi.patch("interest_rates?id=eq." + props.interestRowID, obj);

  console.log(obj);

  if (result.error) {
    showError(result.error.message); // emits("fail-save");
    return;
  }

  emits("save");
};
</script>

<template>
  <div>
    <el-form :model="data">
      <el-form-item :label="$t('PlatformSettingsView.interest_rate')">
        <el-input
          v-model="data.interest_value"
          autocomplete="off"
          type="number"
          style="width: 100%; border-bottom: 3px solid #e3e3e3"
        />
      </el-form-item>
      <el-form-item :label="$t('PlatformSettingsView.interest_value_date')">
        <el-date-picker
          format="DD-MM-YYYY"
          value-format="YYYY-MM-DD"
          v-model="data.interest_value_date"
          type="date"
          placeholder="Pick a date"
          style="width: 100%; border-bottom: 3px solid #e3e3e3"
        />
      </el-form-item>
    </el-form>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form-item class="btn-wrapper-components">
          <el-button type="primary" @click="save">{{$t('generic.save')}}</el-button>
          <el-button @click="close">{{$t('generic.close')}}</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped></style>
