<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import RealEstateCollateral from "../classes/DB_Entities/RealEstateCollateral";
import { ElMessage } from "element-plus";
import Decision from "../classes/Decision";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import PGRESTResult from "../classes/PGRESTResult";
import AcceptAllPending from "./AcceptAllPending.vue";

const props = defineProps<{
  re_collateral_id: number;
  newEntity?: boolean;
  view_only: boolean;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });

const loanAgreementsList = ref<any[]>([]);
const realEstatesList = ref<any[]>([]);
const TypeofPrenotationList = ref<any>([]);
const trueFalseList = ref<any>([]);
let disabledRE = ref(false);

const {
  cleanObject,
  fullObj,
  hasPendingChanges,
  isLoading,
  table_name,
  clickAcceptAll,
  close,
  loadData,
  resetObjects,
  save,
  updateDecision,
  showError,
} = useGenericMethodsVariables();

watch(
  () => props.re_collateral_id,
  async (newVal, oldVal) => {
    console.log("Got re_collateral_id -> " + newVal);

    if (props.re_collateral_id === undefined || props.re_collateral_id == null) return;
    isLoading.value = true;
    await loadSelects();
    await loadLoanAgreements();
    await loadRealEstates();

    resetObjects();

    if (props.newEntity) return;

    await loadData(props.re_collateral_id as number);
    await fixPendingSelects();
    isLoading.value = false;
  }
);

onMounted(async () => {
  table_name.value = RealEstateCollateral.getTableNameStatic();
  cleanObject.value = new RealEstateCollateral();

  console.log("re_collateral_id", props.re_collateral_id, "newEntity", props.newEntity);

  await loadSelects();
  await loadLoanAgreements();
  await loadRealEstates();

  if (props.newEntity === true) {
    resetObjects();
    return;
  }

  isLoading.value = true;
  await loadData(props.re_collateral_id);
  await fixPendingSelects();
  isLoading.value = false;
});

const loadLoanAgreements = async () => {
  let result: PGRESTResult = new PGRESTResult();
  loanAgreementsList.value = [];

  //  result = await pgrestapi.get("loan_agreement?select=*,debtor!inner(*)&approve_status=eq.1");
  if (mainStore.loggedUser.user_type === 2) {
    result = await pgrestapi.get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1&allowed_users=cs.{${mainStore.loggedUser.id}}`);
  }
  if (mainStore.loggedUser.user_type === 1) {
    result = await pgrestapi.get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`);
  }
  if (mainStore.loggedUser.user_type === 0) {
    result = await pgrestapi.get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1`);
  }

  if (result.error) {
    showError(result.error.message);

    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    loanAgreementsList.value.push({
      value: result.data[i].id,
      label: result.data[i].ar_sumbashs + " (" + result.data[i].fullname + ")",
    });
  }
};

const loadRealEstates = async () => {
  let result: PGRESTResult = new PGRESTResult();
  realEstatesList.value = [];

  let real_estate = (await pgrestapi.get(`re_collaterals?select=real_estate_id,real_estate!inner(id,approve_status)&id=eq.${props.re_collateral_id}`)).data[0]?.real_estate;

  if (real_estate?.approve_status === 3) {
    disabledRE.value = true;
    result = await pgrestapi.get(
      "real_estate?select=*,debtor!inner(*)&id=eq." + real_estate.id
    ); // Retrieve only the linked real estate, user is unable to alter the field
  } else {
    disabledRE.value = false;
    result = await pgrestapi.get(
      "real_estate?select=*,debtor!inner(*)&approve_status=eq.1"
    ); // Only approved real estates
  }

  if (result.error) {
    showError(result.error.message);
    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    realEstatesList.value.push({
      value: result.data[i].id,
      label:
        result.data[i].arithmos_akinitou_diax +
        " (" +
        result.data[i].debtor.fullname +
        ")",
    });
  }
};

const onDecision = async (decision: Decision) => {
  isLoading.value = true;

  await updateDecision(decision);

  await loadData(props.re_collateral_id);

  isLoading.value = false;
};

const onPreSave = async () => {
  if (props.newEntity as boolean) {
    if (fullObj.value.loan_agreement_id.originalValue === 0) {
      ElMessage({
        showClose: true,
        message: t("errors.missing_loan_agreement"),
        type: "warning",
        duration: 1500,
      });
      return;
    }

    if (fullObj.value.real_estate_id.originalValue === 0) {
      ElMessage({
        showClose: true,
        message: t("errors.missing_real_estate"),
        type: "warning",
        duration: 1500,
      });
      return;
    }

    if (fullObj.value.meteggrafh_se_ypothikof_ktimat_graf.originalValue === "") {
      ElMessage({
        showClose: true,
        message: t("errors.missing_meteggrafh_se_ypothikof_ktimat_graf"),
        type: "warning",
        duration: 1500,
      });
      return;
    }

    // if (fullObj.value.ekxor_asfalist.originalValue === "") {
    //   ElMessage({
    //     showClose: true,
    //     message: t("errors.missing_ekxor_asfalist"),
    //     type: "warning",
    //     duration: 1500,
    //   });
    //   return;
    // }
  }

  // if (
  //   fullObj.value.lhksh_asfalistiriou.originalValue === "" ||
  //   fullObj.value.lhksh_asfalistiriou.originalValue === null
  // ) {
  //   ElMessage({
  //     showClose: true,
  //     message: t("errors.missing_lhksh_asfalistiriou"),
  //     type: "warning",
  //     duration: 1500,
  //   });
  //   return;
  // }

  if (
    fullObj.value.real_estate_id.originalValue === "" ||
    fullObj.value.real_estate_id.originalValue === null
  ) {
    ElMessage({
      showClose: true,
      message: t("errors.missing_real_estate_id"),
      type: "warning",
      duration: 1500,
    });
    return;
  }

  delete fullObj.value.loan_agreement;
  delete fullObj.value.real_estate;

  await save();
  resetObjects();
};

const fixPendingSelects = async () => {
  for (let field of Object.keys(fullObj.value)) {
    if (fullObj.value[field].pendingValue === undefined) continue; // Skip field if no pending value

    // Loan Agreement
    if (field === "loan_agreement_id") {
      let loan1 = await pgrestapi.get(
        `loan_agreement?select=ar_sumbashs&id=eq.` +
        fullObj.value[field].pendingValue.value
      );
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = `${loan1.data[0].cid} (${loan1.data[0].fullname})`;

      let loan2 = await pgrestapi.get(
        `loan_agreement?select=ar_sumbashs&id=eq.` + fullObj.value[field].originalValue
      );
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = `${loan2.data[0].cid} (${loan2.data[0].fullname})`;
    }

    // Real Estate
    if (field === "real_estate_id") {
      let re1 = await pgrestapi.get(
        `real_estate?select=arithmos_akinitou_diax&id=eq.` +
        fullObj.value[field].pendingValue.value
      );
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = `${re1.data[0].cid} (${re1.data[0].fullname})`;

      let re2 = await pgrestapi.get(
        `real_estate?select=arithmos_akinitou_diax&id=eq.` +
        fullObj.value[field].originalValue
      );
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = `${re2.data[0].cid} (${re2.data[0].fullname})`;
    }

    //Το error που δημιουργείτε είναι γιατί όταν το  originalValue ή pendingValue ειναι Null η empty 
    //τοτε προσπαθεί να βρει στο array με την λίστα TypeofPrenotationList null value me label ενώ δεν υπάρχει
    //Πρόσθεσα τα (?) για να μην πετάει error οταν δεν βρίσκει label στο TypeofPrenotationList
    // Πρεπει να μπει και αλλου το ερωτιματικο για να γλιτωσουμε πιθανα προβληματα στο μελλον ;;

    if (field === "eidos_prosim") {
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = TypeofPrenotationList.value.find(
        (e: any) => e.value === fullObj.value[field].originalValue
      )?.label;
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = TypeofPrenotationList.value.find(
        (e: any) => e.value === fullObj.value[field].pendingValue.value
      )?.label;
    }

    if (
      field === "cession_of_insurance_policy" ||
      field === "meteggrafh_se_ypothikof_ktimat_graf"
    ) {
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = trueFalseList.value.find(
        (e: any) => e.value === fullObj.value[field].originalValue
      )?.label;
      fullObj.value[field].pendingValue["pendingDisplayValue"] = trueFalseList.value.find(
        (e: any) => e.value === fullObj.value[field].pendingValue.value
      )?.label;
    }
  }
};

const loadSelects = async () => {
  TypeofPrenotationList.value = [
    {
      value: "sunenetikh", // proshmeiwsh
      label: t("TypeofPrenotation.sunenetikh"),
    },
    {
      value: "anagkastikh", // proshmeiwsh
      label: t("TypeofPrenotation.anagkastikh"),
    },
    {
      value: "sunenetikh_ypothiki",
      label: t("TypeofPrenotation.sunenetikh_ypothiki"),
    },
    {
      value: "anagkastikh_ypothiki",
      label: t("TypeofPrenotation.anagkastikh_ypothiki"),
    },
  ];

  trueFalseList.value = [
    {
      value: true,
      label: t("global.true"),
    },
    {
      value: false,
      label: t("global.false"),
    },
  ];
};

</script>

<template>
  <div v-loading="isLoading">
    <el-form>
      <AcceptAllPending v-show="hasPendingChanges && !props.view_only"
        @accept="clickAcceptAll(props.re_collateral_id)" />
      <p />

      <el-row :gutter="10">
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.loan_agreement_id"
            :label="$t('real_estate_collateral.loan_agreement_number')" :options="loanAgreementsList"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.real_estate_id"
            :label="$t('real_estate_collateral.real_estate_nbr')" :options="realEstatesList" @decision="onDecision"
            :is-disabled="props.view_only || disabledRE" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.collateral_number')"
            :object="fullObj.ar_kalummatos" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.original_collateral_nbr')"
            :object="fullObj.arxikos_arith_kalummatos" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.eidos_prosim"
            :label="$t('real_estate_collateral.type_of_prenotation')" :options="TypeofPrenotationList"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.lien')" :object="fullObj.seira_prosim"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="money" :label="$t('real_estate_collateral.prenotation_amount')"
            :object="fullObj.poso_prosim" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="money" :label="$t('real_estate_collateral.total_prenotation_amount')"
            :object="fullObj.sunoliko_poso_prosim" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.perc_of_participation')"
            :object="fullObj.pososto_summetoxhs" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.prenotation_summary')"
            :object="fullObj.perilhps_eggrafhs_prosim" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.meteggrafh_se_ypothikof_ktimat_graf"
            :label="$t('real_estate_collateral.transfer_of_prenot_to_cadastral')" :options="trueFalseList"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.volume')" :object="fullObj.tomos"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.page')" :object="fullObj.fullo"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.number')" :object="fullObj.number"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.insurer')" :object="fullObj.asfal_foreas"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.insurance_policy_nbr')"
            :object="fullObj.ar_asfal" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.ekxor_asfalist"
            :label="$t('real_estate_collateral.cession_of_insurance_policy')" :options="trueFalseList"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          {{ $t('real_estate_collateral.legal_status') }} : Legal Status Loan Agreement *********
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.comments')" :object="fullObj.comments"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <!-- <el-col :span="24">
          <EntityField type="datepicker" :label="$t('real_estate_collateral.lhksh_asfalistiriou')"
            :object="fullObj.lhksh_asfalistiriou" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col> -->
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button v-if="!props.view_only" type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped>

</style>
