import ITableName from "../../interfaces/ITableName";

class ConfigShortDesc implements ITableName {
  id?: number = -1;
  short_desc: string = "";

  static getTableNameStatic(): string {
    return "config_short_descriptions";
  }

  getTableName(): string {
    return ConfigShortDesc.getTableNameStatic();
  }
}

export default ConfigShortDesc;
