<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import ROB_Participating_Bank from "../classes/DB_Entities/ROB_Participating_Bank";
import Decision from "../classes/Decision";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { useRoute } from "vue-router";

const route = useRoute();
let skipApprove = ref<boolean>(false);
let isNewROB = ref<boolean>(false);

const emits = defineEmits(["save", "close", "fail-save"]);
const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });
const representativesList = ref<any[]>([]);

const props = defineProps<{
  rob_id: number;
  rob_participating_bank_id: number;
  newEntity?: boolean;
  view_only: boolean;
}>();

const {
  updateDecision,
  isLoading,
  fullObj,
  table_name,
  data,
  loadData,
  save,
  close,
  showError,
  showWarning,
  TransformFullObj,
} = useGenericMethodsVariables();

watch(
  () => props.rob_participating_bank_id,
  async (newVal, oldVal) => {
    resetObjects();
    await loadRepresentatives();

    if (props.newEntity === true) {
      await getDefaultRep();
      return;
    }

    await loadData(props.rob_participating_bank_id);
  }
);

const resetObjects = () => {
  data.value = new ROB_Participating_Bank();
  fullObj.value = new ROB_Participating_Bank();
  data.value.register_of_bond_id = props.rob_id;
  fullObj.value.register_of_bond_id = props.rob_id;
  TransformFullObj();
};

const getDefaultRep = async () => {
  let robAgent = await pgrestapi.get(`register_of_bonds?select=payment_representative&id=eq.${props.rob_id}`);
  let result = await pgrestapi.get(`config_agent?agent=eq.${robAgent.data[0].payment_representative}`);

  if (result.data.length === 0) return;

  // console.log('representative is =>', JSON.stringify(result.data));
  fullObj.value.bank_name.originalValue = result.data[0].agent;
  data.value.bank_name = result.data[0].agent;
}

onMounted(async () => {
  table_name.value = ROB_Participating_Bank.getTableNameStatic();

  await loadRepresentatives();

  if (props.newEntity === true) {
    resetObjects();
    await getDefaultRep();
    return;
  }

  isLoading.value = true;
  await loadData(props.rob_participating_bank_id);
  isLoading.value = false;
});

const onDecision = async (decision: Decision) => {
  isLoading.value = true;

  await updateDecision(decision);

  await loadData(props.rob_participating_bank_id);

  isLoading.value = false;
};

const onPreSave = async () => {
  let result: PGRESTResult = await pgrestapi.get(
    `register_of_bonds?id=eq.${props.rob_id}`
  );

  let result2: PGRESTResult = await pgrestapi.get(
    `loan_agreement?select=config_product(koinopraktiko)&id=eq.${result.data[0].loan_agreement_id}`
  );

  // Assume omologiako loan agreement has been selected (filtered at loan agreement select)

  if (result2.data[0].config_product.koinopraktiko === false) {
    let result3: PGRESTResult = await pgrestapi.get(
      `rob_participating_banks?select=id&register_of_bond_id=eq.${props.rob_id}`
    );

    console.log('result3.data')
    console.log(result3.data)
    console.log('props.newEntity')
    console.log(props.newEntity)
    console.log('fullObj')
    console.log(fullObj.value.participation_percent.originalValue)

    if (result3.data.length > 1 || (props.newEntity && result3.data.length > 0)) {
      showWarning(t("errors.aplo_omologiako_mia_trapeza"), 2000);
      return;
    }
    if (fullObj.value.participation_percent.originalValue !== '100') {
      showWarning(t("errors.aplo_omologiako_mia_trapeza_pososto"), 2000);
      return
    }
  }

  if (props.newEntity as boolean) {
    if (fullObj.value.bank_name.originalValue === "") {
      showWarning(t("errors.missing_bank_name"));
      return;
    }

    if (fullObj.value.participation_percent.originalValue === "") {
      showWarning(t("errors.missing_participation_percent"));
      return;
    }

    if (fullObj.value.contact_details.originalValue === "") {
      showWarning(t("errors.missing_contact_details"))
      return;
    }
  }

  isNewROB.value = false;
  let pendingresult: PGRESTResult = await pgrestapi.get(
    "view_mosregisterofbonds_view1?&id=eq." + props.rob_id
  );

  if (pendingresult.data[0].approve_status === 0) {
    isNewROB.value = true;
  } else {
    isNewROB.value = false;
  }

  if (isNewROB) {
    skipApprove.value = true;
  } else {
    skipApprove.value = false;
  }

  await save(skipApprove.value);
  resetObjects();
};

const loadRepresentatives = async () => {
  let result: PGRESTResult = new PGRESTResult();
  representativesList.value = [];

  result = await pgrestapi.get("config_agent");

  if (result.error) {
    showError('Error retrieving representatives: ' + result.error.message)
    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    representativesList.value.push({
      value: result.data[i].agent,
      label: result.data[i].agent,
    });
  }
};
</script>

<template>
  <div v-loading="isLoading">
    <el-form>
      <EntityField type="select" :object="fullObj.bank_name" label="Bank/Servicer Name" :options="representativesList"
        :is-disabled="props.view_only" @decision="onDecision" />

      <EntityField type="input" label="Participation Percent" :object="fullObj.participation_percent"
        @decision="onDecision" :is-disabled="props.view_only" />

      <EntityField type="switch" label="Portfolio account" :object="fullObj.portfolio_account"
        @decision="onDecision" :is-disabled="props.view_only" />

      <EntityField type="textarea" label="Contact Details" :object="fullObj.contact_details" @decision="onDecision"
        :is-disabled="props.view_only" />

      <EntityField type="input" label="IBAN" :object="fullObj.iban" @decision="onDecision"
        :is-disabled="props.view_only" />

      <EntityField type="input" label="IBAN's Bank" :object="fullObj.iban_bank" @decision="onDecision"
        :is-disabled="props.view_only" />

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button v-if="!props.view_only" type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped></style>
