<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import { Edit, View } from "@element-plus/icons-vue";
import { useMainStore } from "../store/main";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import UserType from "../enums/UserType";
import ROB_ParticipatingBanks from "../components/ROB_ParticipatingBanks.vue";
import ROB_BondTitles from "../components/ROB_BondTitles.vue";
import AddEdit_ROB from "../components/AddEdit_ROB.vue";

//
// Use history.state instead
// -------------------------
// const props = defineProps<{
//   id: number;
//   view_only: boolean;
// }>();

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const route = useRoute();
const router = useRouter();

const { t } = useI18n({ useScope: "global" });

let data = ref<any>({});
let isLoading = ref(false);
let rowid = ref<number>(-1);
let activeTab = ref<string>("info");
let showEditROB = ref<boolean>(false);
let hasPending = ref<boolean>(false);
let viewOnly = ref(false);

onMounted(async () => {
  await loadData();
});

const loadData = async () => {
  hasPending.value = false;
  isLoading.value = true;
  console.log(history.state);
  rowid.value = history.state.id;
  viewOnly.value = history.state.view_only;

  console.log(rowid.value);
  console.log(viewOnly.value)

  let result: PGRESTResult = new PGRESTResult();

  let url = `register_of_bonds?id=eq.${rowid.value}&select=*,loan_agreement!inner(ar_sumbashs)`;

  if (mainStore.loggedUser.user_type === UserType.Agent) {
    url += `&allowed_users.cs{${mainStore.loggedUser.id}}`;
  } else if (mainStore.loggedUser.user_type === UserType.Supervisor) {
    url += `&portfolio_allowed_users.cs{${mainStore.loggedUser.id}}`;
  }

  result = await pgrestapi.get(url);

  if (result.data.length === 0) {
    ElMessage({
      showClose: true,
      message: t("errors.access_denied"),
      type: "error",
      duration: 2500,
    });

    router.push("/registerofbonds");
    return;
  }

  data.value = result.data[0];

  result = await pgrestapi.get(
    "view_mosregisterofbonds_view1?select=pending_approvals&id=eq." + rowid.value
  );
  if (result.data[0].pending_approvals > 0) {
    hasPending.value = true;
  }

  isLoading.value = false;
};

const editROB = async () => {
  showEditROB.value = true;
};

const onClose = () => {
  showEditROB.value = false;
};

const onSave = async () => {
  showEditROB.value = false;
  await loadData();
};

const goBack = async () => {
  router.push('/registerofbonds');
}

</script>

<template>
  <div class="content-wrapper" v-loading="isLoading" style="height: 100%; padding: 50px">
    <el-row>
      <el-col>
        <el-button @click="goBack()" type="primary" style="float: right">Back</el-button>
      </el-col>
    </el-row>

    <el-tabs class="demo-tabs" v-model="activeTab">
      <el-tab-pane :label="$t('RegisterOfBondsView.information')" name="info">
        <el-row>
          <el-col>
            <el-button size="large" style="float: right; margin: 10px" class="btn-add" type="primary"
              @click="editROB" :icon="viewOnly ? View : Edit" />
          </el-col>
        </el-row>
        <el-row v-if="data.loan_agreement !== undefined">
          <div v-if="hasPending" class="el-tag el-tag--warning">
            {{ $t("notifications.pending_changes_register") }}
          </div>
          <el-col>
            <el-form class="demo-form-inline" v-if="data.loan_agreement !== undefined">
              <el-row>
                <el-col :span="24" style="margin: 1px">
                  <el-form-item :label="$t('RegisterOfBondsView.loan_agreement')">
                    <el-input size="large" disabled v-model="data.loan_agreement.ar_sumbashs" />
                  </el-form-item>
                </el-col>
                <el-col :span="24" style="margin: 1px">
                  <el-form-item :label="$t('RegisterOfBondsView.payment_representative')">
                    <el-input size="large" disabled v-model="data.payment_representative" />
                  </el-form-item>
                </el-col>
                <el-col :span="24" style="margin: 1px">
                  <el-form-item :label="$t('RegisterOfBondsView.replacement_mechanism')">
                    <el-input :input-style="{ height: '200px' }" type="textarea" size="large" disabled
                      v-model="data.replacement_mechanism" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane :label="$t('RegisterOfBondsView.Participating_banks_servicers')" name="PB">
        <ROB_ParticipatingBanks :rob_id="rowid" :view_only="viewOnly" />
      </el-tab-pane>
      <el-tab-pane :label="$t('RegisterOfBondsView.bonds')" name="BT">
        <ROB_BondTitles :rob_id="rowid" :view_only="viewOnly" />
      </el-tab-pane>
    </el-tabs>

    <el-dialog v-if="showEditROB" v-model="showEditROB" @update:model-value="onClose" title="Edit Register of Bonds" width="50%">
      <template #footer #default="scope">
        <AddEdit_ROB :rob_id="rowid" @save="onSave" @close="onClose" :view_only="viewOnly" />
      </template>
    </el-dialog>
  </div>
</template>

<style scoped>
#tab-PB,
#tab-info,
#tab-BT {
  font-size: 17px !important;
}
</style>
