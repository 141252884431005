import ITableName from "../../interfaces/ITableName";

class ConfigPostalcode implements ITableName {
  id?: number = -1;
  postalcode: string = "";

  static getTableNameStatic(): string {
    return "config_postalcode";
  }

  getTableName(): string {
    return ConfigPostalcode.getTableNameStatic();
  }
}

export default ConfigPostalcode;
