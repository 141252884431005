<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import RestOfCollaterals from "../classes/DB_Entities/RestOfCollaterals";
import { ElMessage } from "element-plus";
import Decision from "../classes/Decision";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import PGRESTResult from "../classes/PGRESTResult";
import AcceptAllPending from "./AcceptAllPending.vue";
import UserType from "../enums/UserType";
import { Edit, UploadFilled } from "@element-plus/icons-vue";
import { useValueLists } from "../composables/valueLists";

const props = defineProps<{
    nrecollateralid: number;
    view_only: boolean;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const { CollateralTypeOptions } = useValueLists();
const { t } = useI18n({ useScope: "global" });

const loanAgreementsList = ref(new Array<any>());
let active = ref(0);
let breadcrumbText = ref("");

const {
    showError,
    updateDecision,
    hasPendingChanges,
    isLoading,
    fullObj,
    table_name,
    clickAcceptAll,
    isNewEntity,
    loadData,
    save,
    close,
    cleanObject,
    resetObjects,
} = useGenericMethodsVariables();

onMounted(async () => {
    isLoading.value = true;
    breadcrumbText.value = t("global.select");

    table_name.value = RestOfCollaterals.getTableNameStatic();
    cleanObject.value = new RestOfCollaterals();

    console.log("new nrecollateralid", props.nrecollateralid);

    await loadLoanAgreements();

    if (props.nrecollateralid === -1) {
        resetObjects();
        isLoading.value = false;
        return;
    }

    await loadData(props.nrecollateralid);
    breadcrumbText.value = t("nre_collaterals_category." + fullObj.value?.type?.originalValue);

    active.value = 1;
    isLoading.value = false;
});

const loadLoanAgreements = async () => {
    let result: PGRESTResult = new PGRESTResult();
    loanAgreementsList.value = [];

    let query = `view_mosloanagreement_view1?approve_status=eq.1&debtor_approve_status=eq.1`;

    if (mainStore.loggedUser.user_type === UserType.Agent) {
        query += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
    } else if (mainStore.loggedUser.user_type === UserType.Supervisor) {
        query += `&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`;
    }

    result = await pgrestapi.get(query);

    if (result.error) {
        showError(result.error.message);
        return;
    }

    for (let i = 0; i < result.data.length; i++) {
        loanAgreementsList.value.push({
            value: result.data[i].id,
            label: result.data[i].ar_sumbashs + " (" + result.data[i].fullname + ")",
        });
    }
};

const onDecision = async (decision: Decision) => {
    isLoading.value = true;

    await updateDecision(decision);

    await loadData(props.nrecollateralid);

    isLoading.value = false;
};

const onPreSave = async () => {
    if (props.nrecollateralid === -1) {
        if (fullObj.value.loan_agreement_id.originalValue === 0) {
            ElMessage({
                showClose: true,
                message: t("errors.missing_loan_agreement"),
                type: "warning",
                duration: 1500,
            });
            return;
        }
    }

    delete fullObj.value.loan_agreement;

    await save();
};

const isNextDisabled = (): boolean => {
    return fullObj.value?.loan_agreement_id?.originalValue === '' || fullObj.value?.ar_kalimatos?.originalValue === '' || fullObj.value?.type?.originalValue === '';
}

const nextClicked = () => {
    breadcrumbText.value = t("nre_collaterals_category." + fullObj.value.type.originalValue);
    active.value = 1;
}

const backClicked = () => {
    breadcrumbText.value = t("global.select");
    active.value = 0;
}

</script>

<template>
    <div v-loading="isLoading">
        <el-steps :space="200" :active="active" simple finish-status="success">
            <el-step :title="breadcrumbText" :icon="Edit" />
            <el-step :title="$t('global.data_entry')" :icon="UploadFilled" />
        </el-steps>
        <el-form>
            <div v-if="active == 0" style="padding: 50px">
                <el-row :gutter="10">
                    <el-col :span="24">
                        <EntityField type="select" :object="fullObj.loan_agreement_id"
                            :label="$t('GreekStateGuarantees.loan_agreement')" :options="loanAgreementsList"
                            @decision="onDecision" />
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <EntityField type="input" :object="fullObj.ar_kalimatos"
                            :label="$t('RealEstateCollateralsView.collateral_nbr')" @decision="onDecision" />
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <EntityField type="select" :object="fullObj.type"
                            :label="$t('nre_collaterals_category.collateral_type')" :options="CollateralTypeOptions"
                            @decision="onDecision" />
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <EntityField type="money" :object="fullObj.poso_prosim"
                            :label="$t('NRECollateralsDetails.poso_prosim')" @decision="onDecision" />
                    </el-col>
                </el-row>                
                <el-row :gutter="10">
                    <el-col :span="24">
                        <EntityField type="input" :object="fullObj.seira_prosim"
                            :label="$t('NRECollateralsDetails.seira_prosim')" @decision="onDecision" />
                    </el-col>
                </el-row>                
                <el-row :gutter="10">
                    <el-col :span="24">
                        <EntityField type="datepicker" :object="fullObj.imer_axias"
                            :label="$t('NRECollateralsDetails.imer_axias')" @decision="onDecision" />
                    </el-col>
                </el-row>
            </div>

            <div v-if="active == 1">
                <AcceptAllPending v-show="hasPendingChanges && !props.view_only"
                    @accept="clickAcceptAll(props.nrecollateralid)" />
                <p />

                <div v-if="fullObj?.type?.originalValue === 'listed_shares'" style="padding: 50px">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.em_ekd_etair_metox"
                                :label="$t('NRECollateralsDetails.listed_shares.issuing_stock_company')"
                                @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.em_katox_metox"
                                :label="$t('NRECollateralsDetails.listed_shares.stockholder')" @decision="onDecision" />
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.em_sumbash_enexurou"
                                :label="$t('NRECollateralsDetails.listed_shares.pledge_agreement')"
                                @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.em_plithos_metoxon"
                                :label="$t('NRECollateralsDetails.listed_shares.nbr_of_shares')" @decision="onDecision" />
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.em_onomastikh_timh"
                                :label="$t('NRECollateralsDetails.listed_shares.nominal_value')" @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.em_dhlosh_protheshs_metabib"
                                :label="$t('NRECollateralsDetails.listed_shares.declaration_of_intent')"
                                @decision="onDecision" />
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="datepicker" :object="fullObj.em_hmer_epidoshs"
                                :label="$t('NRECollateralsDetails.listed_shares.servicing_date')" @decision="onDecision" />
                        </el-col>
                    </el-row>
                </div>

                <div v-if="fullObj?.type?.originalValue === 'unlisted_shares'" style="padding: 50px">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.mem_ekdo_eteria_metox"
                                :label="$t('NRECollateralsDetails.unlisted_shares.issuing_stock_company')"
                                @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.mem_katoxos_metox"
                                :label="$t('NRECollateralsDetails.unlisted_shares.stockholder')" @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.mem_sumbash_enexurou"
                                :label="$t('NRECollateralsDetails.unlisted_shares.pledge_agreement')"
                                @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.mem_plithos_metoxon"
                                :label="$t('NRECollateralsDetails.unlisted_shares.nbr_of_shares')" @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.mem_onom_aksia_metox"
                                :label="$t('NRECollateralsDetails.unlisted_shares.nominal_value')" @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.mem_sunol_onomas_aksia_metox"
                                :label="$t('NRECollateralsDetails.unlisted_shares.total_nominal_value')"
                                @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.mem_epishm_metox_biblio_metox" :label="
                                $t(
                                    'NRECollateralsDetails.unlisted_shares.share_and_shareholder_book_annotations'
                                )
                            " @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="datepicker" :object="fullObj.mem_hmer_epidoshs"
                                :label="$t('NRECollateralsDetails.unlisted_shares.servicing_date')"
                                @decision="onDecision" />
                        </el-col>
                    </el-row>
                </div>

                <div v-if="fullObj?.type?.originalValue === 'deposits'" style="padding: 50px">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.k_dikaiouxos_katath"
                                :label="$t('NRECollateralsDetails.deposits.beneficiary')" @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.k_sumbash_enex"
                                :label="$t('NRECollateralsDetails.deposits.pledge_agreement')" @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.k_sumbatika_eggrafa" :label="
                                $t('NRECollateralsDetails.deposits.contractual_document_agreement')
                            " @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.k_trapeza"
                                :label="$t('NRECollateralsDetails.deposits.bank')" @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.k_iban"
                                :label="$t('NRECollateralsDetails.deposits.iban')" @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.k_ypol"
                                :label="$t('NRECollateralsDetails.deposits.balance')" @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="datepicker" :object="fullObj.k_hmer_ypol"
                                :label="$t('NRECollateralsDetails.deposits.date_of_balance')" @decision="onDecision" />
                        </el-col>
                    </el-row>
                </div>

                <div v-if="fullObj?.type?.originalValue === 'pledged_claims'" style="padding: 50px">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.ea_sumbash_enex"
                                :label="$t('NRECollateralsDetails.pledged_claims.pledged_agreement')"
                                @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.ea_dikaiol_enexur_apait" :label="
                                $t('NRECollateralsDetails.pledged_claims.evidence_of_the_pledged_claim')
                            " @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.ea_gnost_sust_enex"
                                :label="$t('NRECollateralsDetails.pledged_claims.notice_of_pledge')"
                                @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="datepicker" :object="fullObj.ea_hmer_epidoshs"
                                :label="$t('NRECollateralsDetails.pledged_claims.servicing_date')" @decision="onDecision" />
                        </el-col>
                    </el-row>
                </div>

                <div v-if="fullObj?.type?.originalValue === 'warehouse_receipt'" style="padding: 50px">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.tp_sumbash_enex" :label="
                                $t('NRECollateralsDetails.Warehouse_receipt_(PAEGAE).pledge_agreement')
                            " @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="datepicker" :object="fullObj.tp_hmer_epidoshs" :label="
                                $t('NRECollateralsDetails.Warehouse_receipt_(PAEGAE).servicing_date')
                            " @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.tp_sxetikh_praksh" :label="
                                $t('NRECollateralsDetails.Warehouse_receipt_(PAEGAE).relevant_act')
                            " @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.tp_ypogr_titl_paegae" :label="
                                $t('NRECollateralsDetails.Warehouse_receipt_(PAEGAE).sign_of_titles')
                            " @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.tp_sumbatika_eggrafa" :label="
                                $t(
                                    'NRECollateralsDetails.Warehouse_receipt_(PAEGAE).contractual_document'
                                )
                            " @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.tp_sumbas_enex_apait_" :label="
                                $t(
                                    'NRECollateralsDetails.Warehouse_receipt_(PAEGAE).pledge_agreement_of_insurance_policy'
                                )
                            " @decision="onDecision" />
                        </el-col>
                    </el-row>
                </div>

                <div v-if="fullObj?.type?.originalValue === 'checks'" style="padding: 50px">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_sumbatika_eggrafa"
                                :label="$t('NRECollateralsDetails.checks.contractual_document')" @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_pinakio_epitagon"
                                :label="$t('NRECollateralsDetails.checks.checks_table')" @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_sxetika_dikaiol"
                                :label="$t('NRECollateralsDetails.checks.other_documents')" @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_log_takseos"
                                :label="$t('NRECollateralsDetails.checks.off_balance_account')" @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_xor_log"
                                :label="$t('NRECollateralsDetails.checks.loan_account')" @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_log_xre_eks_prom"
                                :label="$t('NRECollateralsDetails.checks.commission_account')" @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_egkekr_orio_diasp_ana_ekdoth" :label="
                                $t('NRECollateralsDetails.checks.approved_spread_limit_per_issuer')
                            " @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_nomotipo_ekdos_epitaghs"
                                :label="$t('NRECollateralsDetails.checks.legal_issuing_of_check')" @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_sumf_poson_epitagis"
                                :label="$t('NRECollateralsDetails.checks.amount_agreement')" @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_diaspora_ana_ekdoth"
                                :label="$t('NRECollateralsDetails.checks.amount_agreement')" @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_ypar_kartel_xreost_ypol" :label="
                                $t(
                                    'NRECollateralsDetails.checks.existence_of_account_and_debit_balance'
                                )
                            " @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="datepicker" :object="fullObj.e_hmer_ekd_epitaghs"
                                :label="$t('NRECollateralsDetails.checks.issuing_date')" @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_end_sfragishs_epit"
                                :label="$t('NRECollateralsDetails.checks.stamped_check')" @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_loipa_stoixeia_epitaghs"
                                :label="$t('NRECollateralsDetails.checks.check_details')" @decision="onDecision" />
                        </el-col>
                    </el-row>
                </div>

                <div v-if="fullObj?.type?.originalValue === 'bill_of_exchange'" style="padding: 50px">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_eborikotita"
                                :label="$t('NRECollateralsDetails.bill_of_exchange.commerciality')"
                                @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_pinakio_sunal" :label="
                                $t('NRECollateralsDetails.bill_of_exchange.bill_of_exchange_table')
                            " @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_sxetika_dikaol"
                                :label="$t('NRECollateralsDetails.bill_of_exchange.related_documents')"
                                @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_ypo_ait_pa_meta_sunal" :label="
                                $t(
                                    'NRECollateralsDetails.bill_of_exchange.signed_request_for_the_receipt'
                                )
                            " @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_log_takseos"
                                :label="$t('NRECollateralsDetails.bill_of_exchange.off_balance_account')"
                                @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_xor_log_sund_takseos"
                                :label="$t('NRECollateralsDetails.bill_of_exchange.loan_account')" @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_log_xre_eks_prom"
                                :label="$t('NRECollateralsDetails.bill_of_exchange.loan_account')" @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_egkek_orio_diasporas_ekd" :label="
                                $t('NRECollateralsDetails.bill_of_exchange.spread_limit_per_issuer')
                            " @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_nom_ekd_epit" :label="
                                $t('NRECollateralsDetails.bill_of_exchange.legal_issuing_of_check')
                            " @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_sumf_poson_epit" :label="
                                $t('NRECollateralsDetails.bill_of_exchange.legal_issuing_of_check')
                            " @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_hmer_ekd_epit"
                                :label="$t('NRECollateralsDetails.bill_of_exchange.issue_date')" @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_ypar_kart_xreost_ypol" :label="
                                $t(
                                    'NRECollateralsDetails.bill_of_exchange.existence_of_account_and_ebit_balance'
                                )
                            " @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_diasp_ana_ekd"
                                :label="$t('NRECollateralsDetails.bill_of_exchange.spread_per_issuer')"
                                @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_ypar_dusmenon_stoix" :label="
                                $t('NRECollateralsDetails.bill_of_exchange.default_credit_behavior')
                            " @decision="onDecision" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_ypog_sumbash"
                                :label="$t('NRECollateralsDetails.bill_of_exchange.signed_contract')"
                                @decision="onDecision" />
                        </el-col>
                    </el-row>
                </div>

                <div v-if="fullObj?.type?.originalValue === 'movable_collaterals'" style="padding: 50px">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.kel_eidos"
                                :label="$t('NRECollateralsDetails.movable_collaterals.type')" @decision="onDecision" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.kel_idioktisia"
                                :label="$t('NRECollateralsDetails.movable_collaterals.property')" @decision="onDecision" />
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.kel_poso"
                                :label="$t('NRECollateralsDetails.movable_collaterals.amount')" @decision="onDecision" />
                        </el-col>
                    </el-row>
                </div>
            </div>

            <el-row :gutter="10" style="padding-left: 50px; padding-right: 50px">
                <el-col :span="24">
                    <el-form-item class="btn-wrapper-components">
                        <div v-if="active == 0">
                            <el-button :disabled="isNextDisabled()" style="margin-top: 12px; margin: 5px"
                                @click="nextClicked()">
                                {{ $t("global.next_step") }}
                            </el-button>
                        </div>
                        <div v-if="active == 1">
                            <el-button style="margin-top: 12px; margin: 5px" @click="backClicked()">{{
                                $t("global.back")
                            }}</el-button>
                        </div>
                        <div v-if="active == 1">
                            <el-button type="primary" style="margin-top: 12px; margin: 5px" @click="onPreSave()">
                                {{ $t("global.save") }}
                            </el-button>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
  

<style scoped></style>
