<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { Delete, EditPen, Plus, Printer } from "@element-plus/icons-vue";
import { ElTreeV2 } from 'element-plus'
import { useI18n } from "vue-i18n";
import type { TreeNode } from 'element-plus/es/components/tree-v2/src/types'

import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import UserType from "../enums/UserType";
import DDebtor from "../classes/DB_Entities/DDebtor";
import AddEdit_DigitizedFolder from "./AddEdit_DigitizedFolder.vue";
import AddEdit_DigitizedGelatin from "./AddEdit_DigitizedGelatin.vue";
const { t, locale } = useI18n({ useScope: "global" });
import type { FormInstance } from "element-plus";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import Portfolio from "../classes/DB_Entities/Portfolio";
const { showError, showSuccess, printBarcode } = useGenericMethodsVariables();

const emits = defineEmits(["save", "close", "fail-save"]);

interface Tree {
    id: string
    label: string
    level: number
    data: any
    children?: Tree[]
}

const treeNodeProps = {
    value: 'id',
    label: 'label',
    children: 'children',
    level: 'level'
}

const treeData = ref<Tree[]>([]); // ([{ id: '0', label: '', children: [] }]);
const query = ref('');

const treeRef = ref<InstanceType<typeof ElTreeV2>>();

let isLoading = ref(false);
const activeName = ref("first");
const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const data = ref(new DDebtor());
const ruleFormRef = ref<FormInstance>();
let detailsVisible = ref(false);
let selectedRow = ref(-1);
let selectedDitigizedLA = ref(-1);
let selectedFolder = ref(-1);
let currentPortfolio = ref<Portfolio>(new Portfolio());
let DeleteSelectedRow = ref(0);
let DeleteSelectedGelatin = ref(0);
let DeleteSelectedFolder = ref (0);
let portfolios = ref<any>([]);
let showFolderDialog = ref(false);
let showGelatinDialog = ref(false);
let allowedUsers = ref<any>([]);
let allowedSupervisors = ref<any>([]);
let kivotia = ref<any>([]);
let hasRowProblem = ref(false);
const DeleteFolderVisible = ref(false);
const DeleteGelatinVisible = ref(false);
const props = defineProps<{
    row_id: number; // d_debtor_id
}>();

const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate((valid, fields) => {
        if (valid) {
            console.log("submit!");
            save();
        } else {
            console.log("error submit!", fields);
        }
    });
};

const loadFolders = async () => {
    let result = await pgrestapi.get(`digitized_folder?select=*,digitized_gelatin(*)&d_debtor_id=eq.${props.row_id}&order=folder_barcode.asc&digitized_gelatin.order=gelatin_barcode.asc`);
    kivotia.value = [];
    isLoading.value = false;

    if (result.error) {
        showError(result.error.message);
        return;
    }

    treeData.value = [];

    result.data.forEach((folder: any) => {
        let newItem: Tree = {
            id: folder.id,
            label: folder.folder_barcode,
            data: folder,
            level: 0,
            children: []
        };

        kivotia.value.push(folder.container_barcode);

        folder.digitized_gelatin.forEach((gelatin: any) => {
            newItem.children!.push({
                id: gelatin.id,
                label: gelatin.gelatin_barcode,
                data: gelatin,
                level: 1,
                children: []
            });
        });

        treeData.value.push(newItem);
    });
    let uniqueKivotia = [... new Set(kivotia.value)];
    //console.log(uniqueKivotia);
    kivotia.value = [];
    uniqueKivotia.forEach((folder: any) => {
        kivotia.value.push({ kivotio_barcode: folder });
    });


    // console.log(JSON.stringify(folderData.value));
};

const loadData = async (entity_id: number) => {
    if (entity_id === -1) return;

    let result = await pgrestapi.get(`d_debtor?id=eq.${entity_id}`);

    if (result.data[0].portfolio_id !== null) {
        let portfolio = await pgrestapi.get(`portfolio?id=eq.${result.data[0].portfolio_id}`);

        if (portfolio.data.length > 0)
            currentPortfolio.value = portfolio.data[0];
    }

    await loadFolders();

    isLoading.value = false;

    if (result.error) {
        showError(result.error.message);
        return;
    }

    data.value = result.data[0];
    hasRowProblem.value=data.value.row_problem.length>0;
};

onMounted(async () => {
    hasRowProblem.value=false;
    isLoading.value = true;
    await GetPortfolios();

    //console.log('loading digitized_la id', props.row_id);

    if (props.row_id === -1) {
        isLoading.value = false;
        await GetUsers();
        await GetSupervisors();
        return;
    }

    await loadData(props.row_id);
    await GetUsers();
    await GetSupervisors();
});

const close = async () => {
    hasRowProblem.value=false;
    emits("close");
};

const onFailSave = async () => {
    selectedRow.value = -1;
    showError("Digitised LA failed to update", 2000);
    detailsVisible.value = false;
    hasRowProblem.value=false;
};

const onInsert = async () => {
    selectedRow.value = -1;
    showSuccess("Digitised LA saved Successfully", 1500);
    detailsVisible.value = false;
};

const save = async () => {
    let result: PGRESTResult = new PGRESTResult();
    if (data.value.stoixeia_xeiristi) {
        data.value.stoixeia_xeiristi = parseInt(data.value.stoixeia_xeiristi.toString());
    }

    // @ts-ignore
    if (data.value.stoixeia_xeiristi == '') {
        // @ts-ignore
        data.value.stoixeia_xeiristi = null;
    }

    if (data.value.elegxos_so1) {
        data.value.elegxos_so1 = parseInt(data.value.elegxos_so1.toString());
    }

    // @ts-ignore
    if (data.value.elegxos_so1 == '') {
        // @ts-ignore
        data.value.elegxos_so1 = null;
    }

    if (data.value.stoixeia_pshfiop) {
        data.value.stoixeia_pshfiop = parseInt(data.value.stoixeia_pshfiop.toString());
    }

    // @ts-ignore
    if (data.value.stoixeia_pshfiop == '') {
        // @ts-ignore
        data.value.stoixeia_pshfiop = null;
    }

    if (data.value.id === -1) {
        data.value.allowed_users.push((mainStore.loggedUser as any).id);
        //console.log("data.value.allowed_users: " + data.value.allowed_users);
        let obj = JSON.parse(JSON.stringify(data.value));
        delete obj.id;
        result = await pgrestapi.post_data("d_debtor", obj);
    } else {
        result = await pgrestapi.patch("d_debtor?id=eq." + props.row_id, data.value);
    }

    //console.log(result);

    if (result.error) {
        onFailSave();
        return;
    }

    onInsert();

    emits("save");
};
const GetUsers = async () => {
    //let url = `users?&digitization=eq.true&id=in.(${data.value.allowed_users.join(",")})`;
    let url = `users?&digitization=eq.true`;
    //console.log(url);

    let result = await pgrestapi.get(url);
    //console.log(result);

    allowedUsers.value = [];

    for (let user of result.data) {
        allowedUsers.value.push({
            value: user.id,
            label: `${user.first_name} ${user.last_name}`,
        })
    }
}
const GetSupervisors = async () => {
    //let url = `users?&digitization=eq.true&id=in.(${data.value.allowed_users.join(",")})`;
    let url = `users?&id=in.(${currentPortfolio.value.allowed_users.join(",")}))`;
    //console.log(url);
    //console.log('allowedsupervisors');
    let result = await pgrestapi.get(url);
   // console.log(result);

    allowedSupervisors.value = [];

    for (let user of result.data) {

        allowedSupervisors.value.push({
            value: user.id,
            label: `${user.first_name} ${user.last_name}`,
        })
    }
    //console.log(allowedSupervisors.value);
}
const GetPortfolios = async () => {
    let url = `portfolio?select=*,mosclients!inner(clientname)&digitization=is.true&order=portf_name.asc`;

    if (mainStore.loggedUser.user_type === UserType.Supervisor)
        url += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;

    let result = await pgrestapi.get(url);

    portfolios.value = [];

    for (let portfolio of result.data) {
        portfolios.value.push({
            value: portfolio.id,
            label: `${portfolio.portf_name} (${portfolio.mosclients.clientname})`,
        });
    }
};

const onQueryChanged = (query: string) => {
    treeRef.value!.filter(query)
}

const filterMethod = (query: string, node: TreeNode) => {
    return node.label!.includes(query);
}

const openFolder = (folder_id: number, digitized_la_id: number) => {
    showFolderDialog.value = true;
    selectedRow.value = folder_id;
    selectedDitigizedLA.value = digitized_la_id;
}

const openGelatin = (gelatin_id: number, digitized_folder_id: number) => {
    showGelatinDialog.value = true;
    selectedRow.value = gelatin_id;
    selectedFolder.value = digitized_folder_id;
}

const onAddFolderGelatin = async () => {
    showFolderDialog.value = false;
    showGelatinDialog.value = false;

    isLoading.value = true;
    await loadFolders();
    isLoading.value = false;
}

const onCloseFolderGelatin = () => {
    showGelatinDialog.value = false;
    showFolderDialog.value = false;
}

const generateBarcodes = async () => {
    let error_symv_arithmos_zelatinon = false;
    let error_nomim_arithmos_zelatinon = false;
    let error_axiogr_arithmos_zelatinon = false;
    let error_exasf_arithmos_zelatinon = false;
    let error_nomikes_arithmos_zelatinon = false;
    let error_loipa_zelat = false;
    if (data.value.symv_arithmos_zelatinon === null) { error_symv_arithmos_zelatinon = true; }
    if (data.value.nomim_arithmos_zelatinon === null) { error_nomim_arithmos_zelatinon = true; }
    if (data.value.axiogr_arithmos_zelatinon === null) { error_axiogr_arithmos_zelatinon = true; }
    if (data.value.exasf_arithmos_zelatinon === null) { error_exasf_arithmos_zelatinon = true; }
    if (data.value.nomikes_arithmos_zelatinon === null) { error_nomikes_arithmos_zelatinon = true; }
    if (data.value.loipa_zelat === null) { error_loipa_zelat = true; }

    if (error_symv_arithmos_zelatinon || error_nomim_arithmos_zelatinon || error_axiogr_arithmos_zelatinon || error_exasf_arithmos_zelatinon || error_nomikes_arithmos_zelatinon || error_loipa_zelat) {
        if (error_symv_arithmos_zelatinon) { showError("Πρέπει να συμπληρωθεί ο αριθμός ζελατινών Συμβατικών εγγράφων"); }
        if (error_nomim_arithmos_zelatinon) { showError("Πρέπει να συμπληρωθεί ο αριθμός ζελατινών Νομιμοποιητικών εγγράφων"); }
        if (error_axiogr_arithmos_zelatinon) { showError("Πρέπει να συμπληρωθεί ο αριθμός ζελατινών Αξιόγραφων εγγράφων"); }
        if (error_exasf_arithmos_zelatinon) { showError("Πρέπει να συμπληρωθεί ο αριθμός ζελατινών Εξασφαλιστικών εγγράφων"); }
        if (error_nomikes_arithmos_zelatinon) { showError("Πρέπει να συμπληρωθεί ο αριθμός ζελατινών Νομικών Ενεργειών"); }
        if (error_loipa_zelat) { showError("Πρέπει να συμπληρωθεί ο αριθμός ζελατινών Λοιπών εγγράφων"); }
        error_symv_arithmos_zelatinon = false;
        error_nomim_arithmos_zelatinon = false;
        error_axiogr_arithmos_zelatinon = false;
        error_exasf_arithmos_zelatinon = false;
        error_nomikes_arithmos_zelatinon = false;
        error_loipa_zelat = false;
        return;
    }


    if (data.value.symv_arithmos_zelatinon === null) {
        showError("Πρέπει να συμπληρωθεί ο αριθμός ζελατινών Συμβατικών εγγράφων");
        return;
    }

    if (data.value.nomim_arithmos_zelatinon === null) {
        showError("Πρέπει να συμπληρωθεί ο αριθμός ζελατινών Νομιμοποιητικών εγγράφων");
        return;
    }

    if (data.value.axiogr_arithmos_zelatinon === null) {
        showError("Πρέπει να συμπληρωθεί ο αριθμός ζελατινών Αξιόγραφων εγγράφων");
        return;
    }

    if (data.value.exasf_arithmos_zelatinon === null) {
        showError("Πρέπει να συμπληρωθεί ο αριθμός ζελατινών Εξασφαλιστικών εγγράφων");
        return;
    }

    if (data.value.nomikes_arithmos_zelatinon === null) {
        showError("Πρέπει να συμπληρωθεί ο αριθμός ζελατινών Νομικών Ενεργειών");
        return;
    }

    if (data.value.loipa_zelat === null) {
        showError("Πρέπει να συμπληρωθεί ο αριθμός ζελατινών Λοιπών εγγράφων");
        return;
    }

    isLoading.value = true;

    // @ts-ignore
    let gelatinList = [parseInt(data.value.symv_arithmos_zelatinon), parseInt(data.value.nomim_arithmos_zelatinon), parseInt(data.value.axiogr_arithmos_zelatinon), parseInt(data.value.exasf_arithmos_zelatinon), parseInt(data.value.nomikes_arithmos_zelatinon), parseInt(data.value.loipa_zelat)];

    // console.log(JSON.stringify(gelatinList));

    let folders: { [key: string]: string[] } = {};
    let currentGelatin = 0;
    let currentBarcodeBase = currentPortfolio.value.digital_code.padStart(2, '0');
    currentBarcodeBase += data.value.cid.padStart(8, '0');
    currentBarcodeBase += data.value.physical_contract_id_copy.padStart(10, '0');

    let folderCount = 1;
    let currentFolderBarcodeBase = currentBarcodeBase + "00";
    let currentFolderBarcode = currentFolderBarcodeBase + folderCount.toString().padStart(2, '0');

    let gelatinCount = 1;

    while (currentGelatin < 6) {
        // console.log('currentGelatin', currentGelatin);

        if (gelatinList[currentGelatin] === 0) {
            gelatinCount = 1;
            currentGelatin++;
            continue;
        }

        if (!(currentFolderBarcode in folders)) {
            folders[currentFolderBarcode] = [];
        }

        gelatinList[currentGelatin] = gelatinList[currentGelatin] - 1;

        // let addGelatin = currentBarcodeBase + (currentGelatin + 1).toString().padStart(2, '0') + gelatinCount.toString().padStart(2, '0');
        // console.log('Adding', addGelatin);
        folders[currentFolderBarcode].push(currentBarcodeBase + (currentGelatin + 1).toString().padStart(2, '0') + gelatinCount.toString().padStart(2, '0'));
        ++gelatinCount;

        if (folders[currentFolderBarcode].length === 3) {
            ++folderCount;
            currentFolderBarcode = currentFolderBarcodeBase + folderCount.toString().padStart(2, '0');
        }
    }

    for (let key of Object.keys(folders)) {
        let resultFolder = await pgrestapi.post_data("digitized_folder", {
            folder_barcode: key,
            d_debtor_id: props.row_id,
        });

        folders[key].forEach(async (gelatinItem: string) => {
            let resultGelatin = await pgrestapi.post_data("digitized_gelatin", {
                gelatin_barcode: gelatinItem,
                digitized_folder_id: resultFolder.data[0].id,
                document_type: parseInt(gelatinItem.substring(21, 22))
            });
        });
    }
    // console.log(JSON.stringify(folders));

    await loadFolders();

    isLoading.value = false;
}
const deleteHandleClickFolder = (row_id: number) => {
  //DeleteSelectedFolder.value = row_id;
  //deleteFolderVisible.value = true;
  deleteFolder(row_id);
};
const deleteHandleClickGelatin = (row_id: number) => {
  //DeleteSelectedGelatin.value = row_id;
  //deleteGelatinVisible.value = true;
  deleteGelatin(row_id);
};

const deleteFolder = async (folder_id: number) => {
    isLoading.value = true;
    let result = await pgrestapi.delete_data("digitized_folder?id=eq." + folder_id);

    isLoading.value = false;

    if (result.error) {
        showError(result.error.message);
        DeleteFolderVisible.value = false;
        return;
    }
    DeleteFolderVisible.value = false;
    await loadFolders();
}

const deleteGelatin = async (gelatin_id: number) => {
    isLoading.value = true;
    let result = await pgrestapi.delete_data("digitized_gelatin?id=eq." + gelatin_id);

    isLoading.value = false;

    if (result.error) {
        showError(result.error.message);
        DeleteGelatinVisible.value = false;
        return;
    }
    DeleteGelatinVisible.value = false;
    await loadFolders();
}

const rowProblemLabel = (row_problem: string) => {
    if(row_problem){
        return t('global.import_errors') + '*';
    } else {
        return t('global.import_errors');
    }
}

const sleep = (delay: any) => new Promise((resolve) => setTimeout(resolve, delay))

const printAllBarcodes = async() => {
    let allBarcodes =[];

    for (let i = 0; i < treeData.value.length; ++i) {
        allBarcodes.push(treeData.value[i].data.folder_barcode);
        //await printBarcode (treeData.value[i].data.folder_barcode);
        for (let j = 0; j < treeData.value[i].data.digitized_gelatin.length; ++j) {
            allBarcodes.push(treeData.value[i].data.digitized_gelatin[j].gelatin_barcode);
            //await printBarcode(treeData.value[i].data.digitized_gelatin[j].gelatin_barcode);
    }
    }
    for (let barcode of allBarcodes) {
        await printBarcode(barcode);
        await sleep(50);
    }
}

</script>

<template>
    <div v-loading="isLoading">
        <el-form :model="data" ref="ruleFormRef" v-loading="isLoading">
            <el-tabs v-model="activeName" type="card" class="demo-tabs">
                <el-tab-pane :label="$t('DDebtorDetailsView.details')" name="first">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <el-form-item :label="$t('DDebtorDetailsView.portfolio')">
                                <el-select v-model="data.portfolio_id" placeholder="Select" filterable :disabled="(data.id > 0 && mainStore.loggedUser.user_type!==UserType.Administrator)">
                                    <el-option v-for="item in portfolios" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        </el-row>
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.cor_center')">
                                <el-input v-model="data.cor_center" autocomplete="off" :disabled="(data.id > 0 && mainStore.loggedUser.user_type!==UserType.Administrator)"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">                            
                            <el-form-item :label="$t('DDebtorDetailsView.cid')">
                                <el-input v-model="data.cid" autocomplete="off" :disabled="(data.id > 0 && mainStore.loggedUser.user_type!==UserType.Administrator)"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.pelatis')">
                                <el-input v-model="data.pelatis" autocomplete="off"  :disabled="(data.id > 0 && mainStore.loggedUser.user_type!==UserType.Administrator)"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.cor_contractcode')">
                                <el-input v-model="data.cor_contractcode" autocomplete="off"  :disabled="(data.id > 0 && mainStore.loggedUser.user_type!==UserType.Administrator)"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.cor_sdocontractid')">
                                <el-input v-model="data.cor_sdocontractid" autocomplete="off"  :disabled="(data.id > 0 && mainStore.loggedUser.user_type!==UserType.Administrator)"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.physical_contract_id')">
                                <el-input v-model="data.physical_contract_id" autocomplete="off" :disabled="(data.id > 0 && mainStore.loggedUser.user_type!==UserType.Administrator)"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.cor_importdate')" :disabled="(data.id > 0 && mainStore.loggedUser.user_type!==UserType.Administrator)">
                                <el-date-picker value-format="YYYY-MM-DD" v-model="data.cor_importdate" type="date"
                                    placeholder="Pick a day" size="default" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.physical_contract_id_copy')" :disabled="(data.id > 0 && mainStore.loggedUser.user_type!==UserType.Administrator)">
                                <el-input v-model="data.physical_contract_id_copy" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.securitized_perimeter')" :disabled="(data.id > 0 && mainStore.loggedUser.user_type!==UserType.Administrator)">
                                <el-switch v-model="data.securitized_perimeter" class="ml-2"
                                    :active-text="$t('global.true')" :inactive-text="$t('global.false')" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.analyt_thesi_fakel_ana_cid')" :disabled="(data.id > 0 && mainStore.loggedUser.user_type!==UserType.Administrator)">
                                <el-switch v-model="data.analyt_thesi_fakel_ana_cid" class="ml-2"
                                    :active-text="$t('global.true')" :inactive-text="$t('global.false')" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.checked_not_included_incairo')" :disabled="(data.id > 0 && mainStore.loggedUser.user_type!==UserType.Administrator)">
                                <el-switch v-model="data.checked_not_included_incairo" class="ml-2"
                                    :active-text="$t('global.true')" :inactive-text="$t('global.false')" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.fak_pistoux_cairo')" :disabled="(data.id > 0 && mainStore.loggedUser.user_type!==UserType.Administrator)">
                                <el-switch v-model="data.fak_pistoux_cairo" class="ml-2" :active-text="$t('global.true')"
                                    :inactive-text="$t('global.false')" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.denounced_flag')" :disabled="(data.id > 0 && mainStore.loggedUser.user_type!==UserType.Administrator)">
                                <el-switch v-model="data.denounced_flag" class="ml-2" :active-text="$t('global.true')"
                                    :inactive-text="$t('global.false')" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.ccy')">
                                <el-input v-model="data.ccy" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-tab-pane>

                <el-tab-pane :label="$t('DDebtorDetailsView.extra_fields')" name="second">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <el-form-item :label="$t('DDebtorDetailsView.stoixeia_xeiristi')">
                                <el-select clearable v-model="data.stoixeia_xeiristi" autocomplete="off" class="m-2 header-filter"
                                    placeholder="" style="width: 100%">
                                    <el-option v-for="item in allowedUsers" :key="item.value" :label="item.label" :value="item.value" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item :label="$t('DDebtorDetailsView.anoigma')">
                                <el-date-picker value-format="YYYY-MM-DD" v-model="data.anoigma" type="date"
                                    placeholder="Pick a day" size="default" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item :label="$t('DDebtorDetailsView.elegxos')">
                                <el-date-picker value-format="YYYY-MM-DD" v-model="data.elegxos" type="date"
                                    placeholder="Pick a day" size="default" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item :label="$t('DDebtorDetailsView.apografh')">
                                <el-date-picker value-format="YYYY-MM-DD" v-model="data.apografh" type="date"
                                    placeholder="Pick a day" size="default" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item :label="$t('DDebtorDetailsView.elegxos_so1')">
                                <el-select clearable v-model="data.elegxos_so1" autocomplete="off" class="m-2 header-filter"
                                    placeholder="" style="width: 100%">
                                    <el-option v-for="item in allowedSupervisors" :key="item.value" :label="item.label" :value="item.value" />
                                </el-select>
                            </el-form-item>
                        </el-col>                        
                            <el-table v-loading="isLoading" :data="kivotia" stripe border table-layout="fixed">
                                <el-table-column fixed prop="kivotio_barcode" :label="$t('DDebtorDetailsView.suskeuasia')" />
                            </el-table>
                        
                        <el-col :span="24">
                            <el-form-item :label="$t('DDebtorDetailsView.end_stand_by_apograf')">
                                <el-switch v-model="data.end_stand_by_apograf" class="ml-2"
                                    :active-text="$t('global.true')" :inactive-text="$t('global.false')" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item :label="$t('DDebtorDetailsView.sxolio')">
                                <el-input v-model="data.sxolio" autocomplete="off" />
                            </el-form-item>
                        </el-col>

                        
                        
                        
                    </el-row>
                </el-tab-pane>
               

                <el-tab-pane :label="$t('DDebtorDetailsView.apografi')" name="apografi">
                    <!--ΣΥΜΒΑΤΙΚΑ ΕΓΓΡΑΦΑ-->
                    <el-row>
                        <el-col :span="24">
                        <h3>{{ $t('DDebtorDetailsView.sumvatika_eggrafa') }}</h3>
                        
                        <el-form-item :label="$t('DDebtorDetailsView.symv_arithmos_zelatinon')">
                                    <el-input v-model="data.symv_arithmos_zelatinon" autocomplete="off" />
                            </el-form-item>
                            <h5>{{ $t('DDebtorDetailsView.sumvasi') }}</h5>
                        </el-col>
                    </el-row>
                        <el-row>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.symv_symv_prot')">
                                    <el-input v-model="data.symv_symv_prot" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.symv_symv_antig')">
                                    <el-input v-model="data.symv_symv_antig" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                        <h5>{{ $t('DDebtorDetailsView.prosthetes_praxeis') }}</h5>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.symv_prosth_prot')">
                                    <el-input v-model="data.symv_prosth_prot" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.symv_prosth_antigr')">
                                    <el-input v-model="data.symv_prosth_antigr" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item :label="$t('DDebtorDetailsView.symv_prosth_sxolio')">
                                    <el-input v-model="data.symv_prosth_sxolio" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!--ΝΟΜΙΜΟΠΟΙΗΤΙΚΑ-->
                    <el-divider />
                    <el-row>
                        <el-col :span="24">
                        <h3>{{ $t('DDebtorDetailsView.nomimopoihtika') }}</h3>
                        
                        <el-form-item :label="$t('DDebtorDetailsView.symv_arithmos_zelatinon')">
                                    <el-input v-model="data.nomim_arithmos_zelatinon" autocomplete="off" />
                            </el-form-item>
                            <h5>{{ $t('DDebtorDetailsView.praktikaGS_DS') }}</h5>
                        </el-col>
                    </el-row>
                        <el-row>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.nomim_prot')">
                                    <el-input v-model="data.nomim_prot" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.nomim_antig')">
                                    <el-input v-model="data.nomim_antig" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                     <!--ΑΞΙΟΓΡΑΦΑ-->
                     <el-divider />
                    <el-row>
                        <el-col :span="24">
                            <h3>{{ $t('DDebtorDetailsView.axiografa') }}</h3>         
                            <el-form-item :label="$t('DDebtorDetailsView.axiogr_arithmos_zelatinon')">
                                    <el-input v-model="data.axiogr_arithmos_zelatinon" autocomplete="off" />
                            </el-form-item>              
                        </el-col>                       
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.axiogr_prot')">
                                    <el-input v-model="data.axiogr_prot" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.axiogr_antig')">
                                    <el-input v-model="data.axiogr_antig" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!--ΕΞΑΣΦΑΛΙΣΤΙΚΑ-->
                    <el-divider />
                    <el-row>
                        <el-col :span="24">
                            <h3>{{ $t('DDebtorDetailsView.exasfalistika') }}</h3>         
                            <el-form-item :label="$t('DDebtorDetailsView.arithmos_zelatinon')">
                                    <el-input v-model="data.exasf_arithmos_zelatinon" autocomplete="off" />
                            </el-form-item>              
                        </el-col>                       
                    </el-row>
                    <h5>{{ $t('DDebtorDetailsView.prosim_upoth') }}</h5>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.prosim_plithos')">
                                    <el-input v-model="data.prosim_plithos" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.plithos_eggr')">
                                    <el-input v-model="data.prosim_plithos_eggr" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <h5>{{ $t('DDebtorDetailsView.enexiriasis_ekx') }}</h5>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.enex_plithos_prot')">
                                    <el-input v-model="data.enex_plithos_prot" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.enex_plithos_antig')">
                                    <el-input v-model="data.enex_plithos_antig" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <h5>{{ $t('DDebtorDetailsView.epid_enexiriasis_ekx') }}</h5>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.epid_enex_plithos_prot')">
                                    <el-input v-model="data.epid_enex_plithos_prot" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.epid_enex_plithos_antig')">
                                    <el-input v-model="data.epid_enex_plithos_antig" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!--ΝΟΜΙΚΕΣ ΕΝΕΡΓΕΙΕΣ-->
                    <el-divider />
                    <el-row>
                        <el-col :span="24">
                            <h3>{{ $t('DDebtorDetailsView.nomikes_energeies') }}</h3>         
                            <el-form-item :label="$t('DDebtorDetailsView.arithmos_zelatinon')">
                                    <el-input v-model="data.nomikes_arithmos_zelatinon" autocomplete="off" />
                            </el-form-item>              
                        </el-col>                       
                    </el-row>

                    <h5>{{ $t('DDebtorDetailsView.nom_epist_kataggelias') }}</h5>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.nom_epist_kataggelias_prot')">
                                    <el-input v-model="data.nom_epist_kataggelias_prot" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.nom_epist_kataggelias_antig')">
                                    <el-input v-model="data.nom_epist_kataggelias_antig" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <h5>{{ $t('DDebtorDetailsView.nom_ekth_epid') }}</h5>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.nom_ekthes_epid_prot')">
                                    <el-input v-model="data.nom_ekthes_epid_prot" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.nom_ekthes_epid_antig')">
                                    <el-input v-model="data.nom_ekthes_epid_antig" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <h5>{{ $t('DDebtorDetailsView.nom_diat_plir') }}</h5>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.nom_diat_plir_prot')">
                                    <el-input v-model="data.nom_diat_plir_prot" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.nom_diat_plir_antig')">
                                    <el-input v-model="data.nom_diat_plir_antig" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <h5>{{ $t('DDebtorDetailsView.nom_epid_dp') }}</h5>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.nom_epid_dp_prot')">
                                    <el-input v-model="data.nom_epid_dp_prot" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.nom_epid_dp_antig')">
                                    <el-input v-model="data.nom_epid_dp_antig" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <h5>{{ $t('DDebtorDetailsView.nom_apogr') }}</h5>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.nom_apogr_prot')">
                                    <el-input v-model="data.nom_apogr_prot" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.nom_apogr_antig')">
                                    <el-input v-model="data.nom_apogr_antig" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <!--ΛΟΙΠΑ-->
                    <el-divider />
                    <h3>{{ $t('DDebtorDetailsView.loipa') }}</h3>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.arithmos_zelatinon')">
                                    <el-input v-model="data.loipa_zelat" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-divider />
                     <el-row>
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.eggrafa_ektos_DT')">
                                    <el-input v-model="data.eggrafa_ektos_dt" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                   
                        <el-col :span="12">
                            <el-form-item :label="$t('DDebtorDetailsView.sxolio_apogr')">
                                    <el-input v-model="data.sxolio_apogr" autocomplete="off" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    </el-tab-pane><el-tab-pane :label="$t('DDebtorDetailsView.folders')" name="folders">
                        <el-row>
                            <el-col :span="8">
                                <el-form-item :label="$t('DDebtorDetailsView.pshfiopoihsh')">
                                    <el-date-picker value-format="YYYY-MM-DD" v-model="data.pshfiopoihsh" type="date"
                                        placeholder="Pick a day" size="default" />
                                </el-form-item>
                             </el-col>
                            <el-col :span="8">
                                <el-form-item :label="$t('DDebtorDetailsView.apostolh')">
                                    <el-switch v-model="data.apostolh" class="ml-2"
                                    :active-text="$t('global.true')" :inactive-text="$t('global.false')" />                                    
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item :label="$t('DDebtorDetailsView.stby_apostolhs')">
                                    <el-switch v-model="data.stby_apostolhs" class="ml-2"
                                    :active-text="$t('global.true')" :inactive-text="$t('global.false')" />                                  
                                </el-form-item>
                            </el-col>
                        </el-row>                    
                        <el-row>
                            <el-col :span="8">
                                <el-form-item :label="$t('DDebtorDetailsView.elegxos_pmo')">
                                    <el-switch v-model="data.elegxos_pmo" class="ml-2"
                                    :active-text="$t('global.true')" :inactive-text="$t('global.false')" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item :label="$t('DDebtorDetailsView.elegxos_so2_1')">
                                    <el-select clearable v-model="data.elegxos_so2_1" autocomplete="off" class="m-2 header-filter"
                                        placeholder="" style="width: 100%">
                                        <el-option v-for="item in allowedSupervisors" :key="item.value" :label="item.label" :value="item.value" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item :label="$t('DDebtorDetailsView.stoixeia_pshfiop')">
                                    <el-select clearable v-model="data.stoixeia_pshfiop" autocomplete="off" class="m-2 header-filter"
                                        placeholder="" style="width: 100%">
                                        <el-option v-for="item in allowedUsers" :key="item.value" :label="item.label" :value="item.value" />
                                    </el-select>
                                </el-form-item>
                            </el-col>                 
                        </el-row>
                    <el-input v-model="query" placeholder="Search folder/gelatin barcode" @input="onQueryChanged" />
                    <el-button @click="openFolder(-1, props.row_id)" style="margin-top: 20px; borderRadius: var(--el-border-radius-round)">Add Folder</el-button>
                    <!-- @vue-ignore -->
                    <span v-if="(data.elegxos_so1 > 0)">
                    <el-button v-if="(treeData.length === 0)" @click="generateBarcodes" style="margin-left:10px; margin-top: 20px; borderRadius: var(--el-border-radius-round);display:inline-block;">Generate Barcodes</el-button>
                    <el-button v-if="(treeData.length >0)" @click="printAllBarcodes" style="margin-left:10px;margin-top: 20px; borderRadius: var(--el-border-radius-round);display:inline-block;">Print All Barcodes</el-button>
                </span>
                    <p />
                    Σύνολο φακέλων: {{ treeData.length }}
                    <p />
                    <!-- @vue-ignore -->
                    <el-tree-v2 ref="treeRef" :data="treeData" :props="treeNodeProps" :filter-method="filterMethod" :expand-on-click-node="false"
                        :height="408">
                        <template #default="{ node, data }">
                            <span>{{ node.label }}</span>
                            <span v-if="data.level === 0">({{ data.children.length }})</span>
                            <div>
                                <el-icon v-if="data.level === 0" style="padding-left: 10px; padding-right: 12px;" color="green"
                                    @click="openGelatin(-1, data.id)"
                                    :size="16">
                                    <Plus />
                                </el-icon>
                                <span v-if="data.level === 0">/</span>
                                <el-icon style="padding-left: 10px; padding-right: 12px;" @click="data.level === 0 ? openFolder(data.id, props.row_id) : openGelatin(data.id, data.data.digitized_folder_id)">
                                    <EditPen />
                                </el-icon>
                                <span>/</span>
                                <el-icon style="padding-left: 10px; padding-right: 12px;" color="blue" :size="16" @click="data.level === 0 ? printBarcode(data.data.folder_barcode) : printBarcode(data.data.gelatin_barcode)">
                                    <Printer />
                                </el-icon>
                                <span v-if="mainStore.loggedUser.user_type < 2">/</span>
                                <el-icon v-if="mainStore.loggedUser.user_type < 2 && data.level === 0" style="padding-left: 10px;" color="red" :size="16" @click="DeleteFolderVisible = true; DeleteSelectedFolder = data.id;">
                                    <Delete />
                                </el-icon>
                                <el-icon v-if="mainStore.loggedUser.user_type < 2 && data.level != 0" style="padding-left: 10px;" color="red" :size="16" @click="DeleteGelatinVisible = true; DeleteSelectedGelatin = data.id;">
                                    <Delete />
                                </el-icon>
                            </div>
                        </template>
                    </el-tree-v2>
                </el-tab-pane>
                <el-tab-pane :label="rowProblemLabel(data.row_problem)" name="importerrors" v-if="hasRowProblem">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item :label="$t('global.import_error_field')">
                                    <el-input v-model="data.row_problem" autocomplete="off" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea"/>
                            </el-form-item>
                            <span v-if="data.row_problem" class="import_error_info">{{ $t('global.import_error_info') }}</span>
                        </el-col>
                    </el-row>
                </el-tab-pane>
            </el-tabs>

            <el-row :gutter="10">
                <el-col :span="24">
                    <el-form-item class="btn-wrapper-components">
                        <el-button type="primary" @click="submitForm(ruleFormRef)">{{ $t('generic.save') }}</el-button>
                        <el-button @click="close">{{ $t('generic.close') }}</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <el-dialog v-if="showFolderDialog" v-model="showFolderDialog" @update:model-value="onCloseFolderGelatin" :title="(selectedRow !== -1 ? 'Edit' : 'Add') + ' Folder'">
            <AddEdit_DigitizedFolder :row_id="selectedRow" :digitized_la_id="props.row_id" :portfolio_code="currentPortfolio.digital_code" @close="onCloseFolderGelatin" @save="onAddFolderGelatin"
                @fail-save="onFailSave" />
        </el-dialog>

        <el-dialog v-if="showGelatinDialog" v-model="showGelatinDialog" @update:model-value="onCloseFolderGelatin" :title="(selectedRow !== -1 ? 'Edit' : 'Add') + ' Gelatin'">
            <AddEdit_DigitizedGelatin :row_id="selectedRow" :digitized_folder_id="selectedFolder" :portfolio_code="currentPortfolio.digital_code" @close="onCloseFolderGelatin" @save="onAddFolderGelatin"
                @fail-save="onFailSave" />
        </el-dialog>
        <el-dialog v-model="DeleteFolderVisible" title="Fodler Delete" width="50%">
      <span>Are you sure you want to delete this Folder?</span>
      <template #footer #default="scope">
        <span class="dialog-footer">
          <el-button @click="DeleteFolderVisible = false">{{ $t('generic.cancel') }}</el-button>
          <el-button type="primary" @click="deleteHandleClickFolder(DeleteSelectedFolder)">{{ $t('generic.delete') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="DeleteGelatinVisible" title="Gelatin Delete" width="50%">
      <span>Are you sure you want to delete this Gelatin?</span>
      <template #footer #default="scope">
        <span class="dialog-footer">
          <el-button @click="DeleteGelatinVisible = false">{{ $t('generic.cancel') }}</el-button>
          <el-button type="primary" @click="deleteHandleClickGelatin(DeleteSelectedGelatin)">{{ $t('generic.delete') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
    </div>
</template>

<style scoped>
#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
}</style>
