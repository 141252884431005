import ITableName from "../../interfaces/ITableName";

class ConfigLoanAccountProduct implements ITableName {
  id?: number = -1;
  product: string = "";
  greek_trans: string = "";
  english_trans: string = "";
  calculate_argies:boolean = false;
  omologiako:boolean = false;
  allhloxreo:boolean = false;

  static getTableNameStatic(): string {
    return "config_loan_account_product";
  }

  getTableName(): string {
    return ConfigLoanAccountProduct.getTableNameStatic();
  }
}

export default ConfigLoanAccountProduct;
