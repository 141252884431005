import ITableName from "../../interfaces/ITableName";

class DigitizedFolder implements ITableName {
    id: number = -1;
    d_debtor_id: number = 0;
    folder_barcode: string = "";
    container_barcode: string = "";

    static getTableNameStatic(): string {
        return "digitized_folder";
    }

    getTableName(): string {
        return DigitizedFolder.getTableNameStatic();
    }
}

export default DigitizedFolder;
