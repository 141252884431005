<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import Privilege from "../classes/DB_Entities/Privilege";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
const { showError } = useGenericMethodsVariables();

const props = defineProps({
  privilegeid: Number,
});

const emits = defineEmits(["save", "close", "fail-save"]);

const pgrestapi = usePGRESTAPIStore();

const data = ref(new Privilege());

let isLoading = ref(false);

onMounted(async () => {
  if (props.privilegeid === 0) return;  // Add new privilege
  await loadPrivilege(props.privilegeid as number);
});

const close = async () => {
  emits("close");
};

const save = async () => {
  let result: PGRESTResult = new PGRESTResult();

  let id = JSON.stringify(data.value.id);
  delete data.value.id;

  result = await pgrestapi.patch("privileges?id=eq." + id, data.value);

  if (result.error) {
    showError(result.error.message);
    return;
  }

  emits("save");
};

const loadPrivilege = async (privilegeid: number) => {
  isLoading.value = true;

  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get("privileges?id=eq." + privilegeid);

  if (result.error) {
    showError(result.error.message);

    return;
  }

  data.value = result.data[0];

  isLoading.value = false;
};
</script>

<template>
  <div v-loading="isLoading">
    <el-form :model="data">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="Name">
            <el-input v-model="data.name" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button type="primary" @click="save">{{$t('generic.save')}}</el-button>
            <el-button @click="close">{{$t('generic.close')}}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped></style>
