<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import PGRESTResult from "../classes/PGRESTResult";
import { Edit, Plus, SuccessFilled, CircleCloseFilled, Delete, UploadFilled } from "@element-plus/icons-vue";

import { genFileId } from 'element-plus'
import type { UploadInstance, UploadProps, UploadRawFile } from 'element-plus'

import papa from 'papaparse';

import DebitCreditType from "../enums/DebitCreditType";
import ChangeApprovalStatus from "../enums/ChangeApprovalStatus";

import AddEdit_DebitCredit from "./AddEdit_DebitCredit.vue";
import AcceptAllPending from "./AcceptAllPending.vue";

import { DateTime } from "luxon";
import { useMainStore } from "../store/main";
import UserType from "../enums/UserType";

const mainStore = useMainStore();
const props = defineProps<{
	loan_account_id: number;
	type: DebitCreditType;
	view_only: boolean;
}>();

const tableRowClassName = ({
	row,
	rowIndex,
}: {
	row: any
	rowIndex: number
}) => {
	if (row.status === 2) {
		return 'warning-row';
	}

	return '';
}

const pgrestapi = usePGRESTAPIStore();
const { showError, showSuccess } = useGenericMethodsVariables();

let isLoading = ref(false);
let page = ref(0);
let dataList = ref<any[]>([]);
let showAddEdit = ref(false);
let selectedRow = ref(0);
let euroFormat = new Intl.NumberFormat('el-GR', { style: 'currency', currency: 'EUR' });
let hasPendingNumberChanges = ref(false);
let hasPendingStatusChanges = ref(false);
let hasPendingDateChanges = ref(false);
let hasPendingPerigrafiChanges = ref(false);
let showRejected = ref(false);

const upload = ref<UploadInstance>()
let fileList = ref<any[]>([]);
let showUpload = ref(false);

onMounted(async () => {
	await loadData();
});

const loadData = async () => {
	isLoading.value = true;

	let url = `view_debits_credits_list_internal?loan_account_id=eq.${props.loan_account_id}&type=eq.${props.type}&status=in.(0, 1${showRejected.value ? ', 2' : ''})&order=date.desc`;

	let result = await pgrestapi.get(url);

	dataList.value = result.data;

	hasPendingNumberChanges.value = false;
	hasPendingStatusChanges.value = false;
	hasPendingDateChanges.value = false;
	hasPendingPerigrafiChanges.value = false;

	for (let i = 0; i < dataList.value.length; ++i) {
		if (!dataList.value[i].entity_changes) {
			continue;
		}

		for (let j = 0; j < dataList.value[i].entity_changes.length; ++j) {
			if (dataList.value[i].entity_changes[j].approval_status === 0) {
				switch (dataList.value[i].entity_changes[j].field) {
					case "amount":
						hasPendingNumberChanges.value = true;
						break;
					case "status":
						hasPendingStatusChanges.value = true;
						break;
					case "date":
						hasPendingDateChanges.value = true;
						break;
					case "perigrafi":
						hasPendingPerigrafiChanges.value = true;
						break;
				}
				// console.log("Pending change found", dataList.value[i].entity_changes[j].field);
			}
		}
	}

	// console.log("hasPendingNumberChanges", hasPendingNumberChanges.value);
	// console.log("hasPendingStatusChanges", hasPendingStatusChanges.value);
	// console.log("hasPendingDateChanges", hasPendingDateChanges.value);
	// console.log("hasPendingPerigrafiChanges", hasPendingPerigrafiChanges.value);

	isLoading.value = false;
}

const openAddEdit = (row_id: number) => {
	selectedRow.value = row_id;
	showAddEdit.value = true;
}

const deleteRow = async (row_id: number) => {

	let result = await pgrestapi.delete_data("debits_credits?id=eq." + row_id);
	await loadData();
	if (result.error) {
		showError(result.error.message);
		return;
	}
}

// Should only arrive only from Admin/Supervisor changes
const onSaveAndApprove = async (entity_change_ids: number[]) => {
	console.log('onSaveAndApprove', JSON.stringify(entity_change_ids));

	isLoading.value = true;

	for (let row of entity_change_ids) {
		await AcceptChange(row, false, false);
	}

	showAddEdit.value = false;
	await loadData();
}

const onCloseAddEdit = async () => {
	showAddEdit.value = false;
	await loadData();
}

const getDebitCreditTypeName = (type: DebitCreditType) => {
	switch (type) {
		case DebitCreditType.Debit:
			return "Debit";
		case DebitCreditType.Credit:
			return "Credit";
		case DebitCreditType.Installment:
			return "Installment";
		case DebitCreditType.InterestTypeChanges:
			return "Interest Type Change";
		case DebitCreditType.Exoda:
			return "Expense";
		case DebitCreditType.TokoiTokwn:
			return "Initial Interest";
    case DebitCreditType.HmerTokou:
    return " Interest Payment Date";
	}
}

const formatEuroCurrency = (value: number) => {
	return euroFormat.format(value);
}

const AcceptChange = async (entity_debit_credit_changes_row: any, reload: boolean = true, changeLoading: boolean = true) => {
	// console.log(JSON.stringify(entity_debit_credit_changes_row))
	console.log("AcceptChange", entity_debit_credit_changes_row.id);

	if (changeLoading) {
		isLoading.value = true;
	}

	let obj_entity_change: any = {};
	// obj_entity_change

	let result = await pgrestapi.patch("entity_debits_credits_changes?id=eq." + entity_debit_credit_changes_row.id, { approval_status: ChangeApprovalStatus.Approved });

	if (changeLoading) {
		isLoading.value = false;
	}

	if (result.error) {
		showError(result.error.code + ' - ' + result.error.message);
		return;
	}

	let obj: any = {};
	let field = entity_debit_credit_changes_row.field;
	obj[field] = entity_debit_credit_changes_row.value;
	result = await pgrestapi.patch("debits_credits?id=eq." + entity_debit_credit_changes_row.entity_id, obj);

	if (reload) {
		await loadData();
	}
}

const RejectChange = async (entity_debit_credit_changes_id: any) => {
	console.log("RejectChange", entity_debit_credit_changes_id);

	isLoading.value = true;
	let get_id = await pgrestapi.get("entity_debits_credits_changes?select=entity_id&id=eq." + entity_debit_credit_changes_id);
	let result = await pgrestapi.patch("entity_debits_credits_changes?id=eq." + entity_debit_credit_changes_id, { approval_status: ChangeApprovalStatus.Rejected });
	let result2 = await pgrestapi.patch("debits_credits?id=eq." + get_id.data[0].entity_id, { status: ChangeApprovalStatus.Rejected });
	isLoading.value = false;

	if (result.error) {
		showError(result.error.code + ' - ' + result.error.message);
		return;
	}

	await loadData();
}

const GetPendingEntryForRow = (row: any, field: string) => {
	if (!row.entity_changes) {
		return null;
	}

	let change = row.entity_changes.find((change: any) => {
		if (change.approval_status === 0 && change.field === field) {
			return true;
		}
	});

	if (change) {
		return change;
	}

	return null;
}

const AcceptAll = async () => {
	isLoading.value = true;

	for (let i = 0; i < dataList.value.length; ++i) {
		if (!dataList.value[i].entity_changes)
			continue;

		for (let j = 0; j < dataList.value[i].entity_changes.length; ++j) {
			if (dataList.value[i].entity_changes[j].approval_status === 0) {
				await AcceptChange(dataList.value[i].entity_changes[j], false, false);
			}
		}
	}

	isLoading.value = false;

	await loadData();
}

let lastFile: any = undefined;

const onUpload = async () => {
	console.log('do upload');
	const file = lastFile;

	if (file === undefined)
		return;

	isLoading.value = true;

	let postEntries: any[] = [];
	papa.parse(file, {
		worker: true, // Don't bog down the main thread if its a big file
		step: function (result: any) {
			console.log(result);
			postEntries.push({
				loan_account_id: props.loan_account_id,
				type: props.type,
				status: 1,
				date: result.data[0],
				amount: result.data[1],
				perigrafi: result.data[2]
			});
		},
		complete: async function (results, file) {
			console.log('parsing complete read', postEntries.length, 'records.');
			console.log(JSON.stringify(postEntries));
			let result = await pgrestapi.post_data("debits_credits", postEntries);

			if (result.error) {
				showError("Error uploading file: " + result.error.message);
				isLoading.value = false;
				return;
			} else {
				showSuccess("File uploaded successfully");
				await loadData();
			}
		}
	});
}

const onAddedFile = (e: any) => {
	console.log('added file');
	lastFile = e.target.files[0];
	showUpload.value = true;
}

</script>

<template>
	<div class="content-wrapper">
		<AcceptAllPending
			v-show="hasPendingNumberChanges || hasPendingDateChanges || hasPendingStatusChanges || hasPendingPerigrafiChanges"
			@accept="AcceptAll" />
		<p />

		<el-checkbox v-model="showRejected" @change="loadData">Show Rejected Entries</el-checkbox>

		<p />

		<el-tooltip class="box-item" effect="dark" content="New entry" placement="top">
			<el-button type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px" :icon="Plus"
				@click="openAddEdit(0)" />
		</el-tooltip>

		<el-button v-if = "props.type!==DebitCreditType.TokoiTokwn" :disabled="!showUpload" type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
			@click="onUpload">
			Upload
		</el-button>
		<input v-if = "props.type!==DebitCreditType.TokoiTokwn" type="file" style="float: right; margin: 2.5px" @change="onAddedFile">


		<!-- <div style="float: right; width: 110px;">
			<el-upload
				ref="upload"
				v-model:file-list="fileList"
				
				class="upload-demo"
				:auto-upload="false"
				:on-change="onAddedFile"
				:on-remove="onRemoveFile"
				:on-exceed="handleExceed"
				:limit="1"
			>
				<template #trigger>
					<el-tooltip class="box-item" effect="dark" content="CSV upload" placement="top">
						<el-button type="primary" size="small" class="btn-add" style="margin: 2.5px;" :icon="UploadFilled" />
					</el-tooltip>
				</template>

			</el-upload>
		</div> -->

		<el-table :data="dataList" stripe border table-layout="fixed" size="small" v-loading="isLoading"
			:row-class-name="tableRowClassName">

			<el-table-column prop="date" :label="$t('DebitCreditList.date')" width="110" />
			<el-table-column v-if="props.type === DebitCreditType.InterestTypeChanges" prop="interest" :label="$t('DebitCreditList.base_rate')" width="110" />
			<el-table-column v-if="props.type === DebitCreditType.InterestTypeChanges" prop="perithorio" :label="$t('DebitCreditList.perithorio')" width="110" />
			<el-table-column v-if="props.type === DebitCreditType.InterestTypeChanges" prop="eisfora" :label="$t('DebitCreditList.eisfora')" width="110" />
			<el-table-column v-if="props.type === DebitCreditType.InterestTypeChanges" prop="pososto_tokwn_yperhmerias" :label="$t('DebitCreditList.pososto_tokwn_yperhmerias')" width="110" />

			<el-table-column v-if="props.type !== DebitCreditType.InterestTypeChanges" :label="$t('DebitCreditList.newdate')" width="110">
				<template #default="scope">
					<div v-if="GetPendingEntryForRow(scope.row, 'date')" style="background-color: lightgrey;">
						{{ GetPendingEntryForRow(scope.row, 'date').value }}
						<br />

						<el-tooltip v-if="GetPendingEntryForRow(scope.row, 'date')" class="box-item" effect="dark" :content="'Accept proposed change by ' +
							GetPendingEntryForRow(scope.row, 'date').editor_username + ', done on ' + DateTime.fromISO(GetPendingEntryForRow(scope.row, 'date').insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
							" placement="bottom">
							<el-button style="background-color: green; color: white" size="small" :icon="SuccessFilled"
								@click="AcceptChange(GetPendingEntryForRow(scope.row, 'date'))" />
						</el-tooltip>

						<el-tooltip v-if="GetPendingEntryForRow(scope.row, 'date')" class="box-item" effect="dark" :content="'Reject proposed change by ' +
							GetPendingEntryForRow(scope.row, 'date').editor_username + ', done on ' + DateTime.fromISO(GetPendingEntryForRow(scope.row, 'date').insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
							" placement="bottom">
							<el-button style="background-color: lightcoral; color: white" size="small"
								:icon="CircleCloseFilled"
								@click="RejectChange(GetPendingEntryForRow(scope.row, 'date').id)" />
						</el-tooltip>
					</div>
				</template>
			</el-table-column>

			<el-table-column v-if="props.type !== DebitCreditType.InterestTypeChanges" :label="$t('DebitCreditList.amount')"
				width="110">
				<template #default="scope">
					{{ formatEuroCurrency(scope.row.amount) }}
				</template>
			</el-table-column>

			<el-table-column v-if="props.type !== DebitCreditType.InterestTypeChanges" :label="$t('DebitCreditList.newamount')"
				width="110">
				<template #default="scope">
					<div v-if="GetPendingEntryForRow(scope.row, 'amount')" style="background-color: lightgrey;">
						{{ formatEuroCurrency(GetPendingEntryForRow(scope.row, 'amount').value) }}
						<br />
						<el-tooltip v-if="GetPendingEntryForRow(scope.row, 'amount')" class="box-item" effect="dark"
							:content="'Accept proposed change by ' +
								GetPendingEntryForRow(scope.row, 'amount').editor_username + ', done on ' + DateTime.fromISO(GetPendingEntryForRow(scope.row, 'amount').insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
								" placement="bottom">
							<el-button style="background-color: green; color: white" size="small" :icon="SuccessFilled"
								@click="AcceptChange(GetPendingEntryForRow(scope.row, 'amount'))" />
						</el-tooltip>

						<el-tooltip v-if="GetPendingEntryForRow(scope.row, 'amount')" class="box-item" effect="dark"
							:content="'Reject proposed change by ' +
								GetPendingEntryForRow(scope.row, 'amount').editor_username + ', done on ' + DateTime.fromISO(GetPendingEntryForRow(scope.row, 'amount').insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
								" placement="bottom">
							<el-button style="background-color: lightcoral; color: white" size="small"
								:icon="CircleCloseFilled"
								@click="RejectChange(GetPendingEntryForRow(scope.row, 'amount').id)" />
						</el-tooltip>

					</div>
				</template>
			</el-table-column>
			<el-table-column v-if="props.type !== DebitCreditType.InterestTypeChanges" :label="$t('DebitCreditList.perigrafi')"
				width="110">
				<template #default="scope">
					{{ scope.row.perigrafi }}
				</template>
			</el-table-column>
			<el-table-column v-if="props.type !== DebitCreditType.InterestTypeChanges" :label="$t('DebitCreditList.newperigrafi')"
				width="110">
				<template #default="scope">
					<div v-if="GetPendingEntryForRow(scope.row, 'perigrafi')" style="background-color: lightgrey;">
						{{ GetPendingEntryForRow(scope.row, 'perigrafi').value }}
						<br />
						<el-tooltip v-if="GetPendingEntryForRow(scope.row, 'perigrafi')" class="box-item" effect="dark"
							:content="'Accept proposed change by ' +
								GetPendingEntryForRow(scope.row, 'perigrafi').editor_username + ', done on ' + DateTime.fromISO(GetPendingEntryForRow(scope.row, 'perigrafi').insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
								" placement="bottom">
							<el-button style="background-color: green; color: white" size="small" :icon="SuccessFilled"
								@click="AcceptChange(GetPendingEntryForRow(scope.row, 'perigrafi'))" />
						</el-tooltip>

						<el-tooltip v-if="GetPendingEntryForRow(scope.row, 'perigrafi')" class="box-item" effect="dark"
							:content="'Reject proposed change by ' +
								GetPendingEntryForRow(scope.row, 'perigrafi').editor_username + ', done on ' + DateTime.fromISO(GetPendingEntryForRow(scope.row, 'perigrafi').insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
								" placement="bottom">
							<el-button style="background-color: lightcoral; color: white" size="small"
								:icon="CircleCloseFilled"
								@click="RejectChange(GetPendingEntryForRow(scope.row, 'perigrafi').id)" />
						</el-tooltip>

					</div>
				</template>
			</el-table-column>

			<el-table-column :label="$t('DebitCreditList.newstatus')" width="110">
				<template #default="scope">
					<div v-if="GetPendingEntryForRow(scope.row, 'status')" style="background-color: lightgrey;">
						<el-tag v-if="GetPendingEntryForRow(scope.row, 'status').value === '1'" type="success">{{
							$t('DebitCreditList.addition') }}</el-tag>
						<el-tag v-else-if="GetPendingEntryForRow(scope.row, 'status').value === '2'" type="danger">{{
							$t('DebitCreditList.removal') }}</el-tag>
						<br />

						<el-tooltip v-if="GetPendingEntryForRow(scope.row, 'status')" class="box-item" effect="dark"
							:content="'Accept proposed change by ' +
								GetPendingEntryForRow(scope.row, 'status').editor_username + ', done on ' + DateTime.fromISO(GetPendingEntryForRow(scope.row, 'status').insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
								" placement="bottom">
							<el-button style="background-color: green; color: white" size="small" :icon="SuccessFilled"
								@click="AcceptChange(GetPendingEntryForRow(scope.row, 'status'))" />
						</el-tooltip>

						<el-tooltip v-if="GetPendingEntryForRow(scope.row, 'status')" class="box-item" effect="dark"
							:content="'Reject proposed change by ' +
								GetPendingEntryForRow(scope.row, 'status').editor_username + ', done on ' + DateTime.fromISO(GetPendingEntryForRow(scope.row, 'status').insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
								" placement="bottom">
							<el-button style="background-color: lightcoral; color: white" size="small"
								:icon="CircleCloseFilled"
								@click="RejectChange(GetPendingEntryForRow(scope.row, 'status').id)" />
						</el-tooltip>

					</div>
				</template>
			</el-table-column>

			<el-table-column label="Actions">
				<template #default="scope">
					<el-button type="primary" size="small" :icon="Edit" @click="openAddEdit(scope.row.debit_credit_id)" />
					<el-tooltip v-if="scope.row.entity_change_id" class="box-item" effect="dark" :content="'Accept proposed change by ' +
						scope.row.username + ', done on ' + DateTime.fromISO(scope.row.insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
						" placement="bottom">
						<el-button style="background-color: green; color: white" size="small" :icon="SuccessFilled"
							@click="AcceptChange(scope.row.entity_change_id)" />
					</el-tooltip>
					<el-tooltip v-if="scope.row.entity_change_id" class="box-item" effect="dark" :content="'Reject proposed change by ' +
						scope.row.username + ', done on ' + DateTime.fromISO(scope.row.insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
						" placement="bottom">
						<el-button style="background-color: red; color: white" size="small" :icon="CircleCloseFilled"
							@click="RejectChange(scope.row.entity_change_id)" />
					</el-tooltip>
					<el-button type="danger"
						v-if="(mainStore.loggedUser.user_type < UserType.Agent) && scope.row.status > 0" size="small"
						:icon="Delete" @click="deleteRow(scope.row.debit_credit_id)" />
				</template>
			</el-table-column>

		</el-table>

		<el-dialog v-if="showAddEdit" v-model="showAddEdit" @update:model-value="onCloseAddEdit"
			:title="(selectedRow === 0 ? 'New ' : '') + getDebitCreditTypeName(props.type)" width="30%">
			<AddEdit_DebitCredit :row_id="selectedRow" :view_only="false" :loan_account_id="props.loan_account_id"
				:type="props.type" @close="onCloseAddEdit" @save="onCloseAddEdit" @save_and_approve="onSaveAndApprove" />
		</el-dialog>

	</div>
</template>

<style>
.el-table .warning-row {
	--el-table-tr-bg-color: var(--el-color-error-light-9);
}

.el-table .success-row {
	--el-table-tr-bg-color: var(--el-color-success-light-9);
}</style>
