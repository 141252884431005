import ITableName from "../../interfaces/ITableName";

class ConfigPrefecture implements ITableName {
  id?: number = -1;
  prefecture: string = "";
  min_tk: number = 0;
  max_tk: number = 0;

  static getTableNameStatic(): string {
    return "config_prefecture";
  }

  getTableName(): string {
    return ConfigPrefecture.getTableNameStatic();
  }
}

export default ConfigPrefecture;
