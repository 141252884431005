<script lang="ts" setup>
import { ref, watch, onMounted, reactive } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import type { FormInstance } from "element-plus";
import ConfigLoanAccountProduct from "../classes/DB_Entities/ConfigLoanAccountProduct";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
const { showError } = useGenericMethodsVariables();

const props = defineProps({
  larowid: Number,
});
const klados = ref<any>([]);
const emits = defineEmits(["save", "close", "fail-save"]);
const pgrestapi = usePGRESTAPIStore();
const data = ref(new ConfigLoanAccountProduct());
const activeName = ref("first");
const ruleFormRef = ref<FormInstance>();
let isLoading = ref(false);

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!", fields);
    }
  });
};

watch(
  () => props.larowid,
  async (newVal, oldVal) => {
    // console.log("Loading debtor with id " + props.debtorid);
    await loadKAD(props.larowid as number);
  }
);

onMounted(async () => {
  await loadKAD(props.larowid as number);
});

const close = async () => {
  emits("close");
};

const save = async () => {
  let result: PGRESTResult = new PGRESTResult();

  let id = JSON.stringify(data.value.id);
  delete data.value.id;

  result = await pgrestapi.patch("config_loan_account_product?id=eq." + id, data.value);

  if (result.error) {
    showError(result.error.message); // emits("fail-save");
    return;
  }

  emits("save");
};

const loadKAD = async (kad_id: number) => {
  isLoading.value = true;
  if (kad_id === -1) return;

  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get("config_loan_account_product?id=eq." + kad_id);

  if (result.error) {
    showError(result.error.message);

    return;
  }

  data.value = result.data[0];
  isLoading.value = false;
};
</script>

<template>
  <div>
    <el-form :model="data" ref="ruleFormRef" v-loading="isLoading">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item :label="$t('PlatformSettingsView.loan_account_product_details')">
            <el-input v-model="data.product" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('PlatformSettingsView.la_greek_trans')">
            <el-input v-model="data.greek_trans" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('PlatformSettingsView.la_english_trans')">
            <el-input v-model="data.english_trans" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('PlatformSettingsView.calculateArgies')">
            <el-switch v-model="data.calculate_argies" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('PlatformSettingsView.omologiako')">
            <el-switch v-model="data.omologiako" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('PlatformSettingsView.allhloxreo')">
            <el-switch v-model="data.allhloxreo" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>

      <div style="display: flex; justify-content: flex-end; width: 100%; padding: 0">
        <el-form-item>
          <el-button type="primary" @click="submitForm(ruleFormRef)">{{ $t('generic.save') }}</el-button>
          <el-button @click="close">{{ $t('generic.close') }}</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<style scoped></style>
