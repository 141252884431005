<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import Payment from "../classes/DB_Entities/Pliromi";
import { ElMessage } from "element-plus";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import User from "../classes/DB_Entities/User";
import PGRESTResult from "../classes/PGRESTResult";
import { useValidation } from "../composables/validation";

const props = defineProps<{
  loan_account_id: string;
  paymentid: number;
  newEntity?: boolean;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);
const loanaccoundlist = ref(new Array<any>());
const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });

const usersList = ref(new Array<User>());

const data = ref<any>({
  loan_account_id: null,
  hmer: null,
  poso: null,
  kefalaio: null,
  tokoi: null,
  exoda: null,
  proskomisi_vevaiosis: false,
  hmer_proskomisis: null,
});
const resetData = async () => {
  data.value.loan_account_id = props.loan_account_id;
  data.value.hmer = null;
  data.value.poso = null;
  data.value.kefalaio = null;
  data.value.tokoi = null;
  data.value.exoda = null;
  data.value.proskomisi_vevaiosis = false;
  data.value.hmer_proskomisis = false;
};

const {
  isLoading,
  fullObj,
  table_name,
  loadData,
  close,
  cleanObject,
  resetObjects,
  showError,
} = useGenericMethodsVariables();

const {
  validationErrors,
  onValidationChange,
  checkValidationsForError,
} = useValidation();

const save = async () => {
  let obj = JSON.parse(JSON.stringify(data.value));
  let result: PGRESTResult = new PGRESTResult();

  console.log('obj');

  if (obj.hmer === null || obj.poso === null) { return }

  if (props.paymentid < 0) {
    if (obj.hmer_proskomisis === null) { delete obj.hmer_proskomisis }
    console.log(obj);
    result = await pgrestapi.post_data("sunallages", obj);
  }
  else {
    result = await pgrestapi.patch("sunallages?id=eq." + props.paymentid,
      {
        hmer: data.value.hmer,
        poso: data.value.poso,
        kefalaio: data.value.kefalaio,
        tokoi: data.value.tokoi,
        exoda: data.value.exoda,
        proskomisi_vevaiosis: data.value.proskomisi_vevaiosis,
        hmer_proskomisis: data.value.hmer_proskomisis,
      }

    );
  }

  if (result.error) {
    showError(result.error.message); // emits("fail-save");
    return;
  }
  resetData();
  emits("save");
};

watch(

  () => props.paymentid,
  async (newVal, oldVal) => {
    console.log('mpike me payment id' + props.paymentid)
    if (props.paymentid === undefined || props.paymentid == null) return;


    resetData();
    console.log(data.value.loan_account_id)
    if (props.paymentid > 0) {
      await loadPayment();
    }
    console.log('data.value.hmer' + data.value.hmer)
    if (props.newEntity) return;
    await loadData(props.paymentid as number);
  }
);

const loadPayment = async () => {
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get("sunallages?select=*&id=eq." + props.paymentid);

  if (result.error) {
    showError(result.error.message);

    return;
  }

  data.value.hmer = result.data[0].hmer;
  data.value.poso = result.data[0].poso;
  data.value.kefalaio = result.data[0].kefalaio;
  data.value.tokoi = result.data[0].tokoi;
  data.value.exoda = result.data[0].exoda;
  data.value.proskomisi_vevaiosis = result.data[0].proskomisi_vevaiosis;
  data.value.hmer_proskomisis = result.data[0].hmer_proskomisis;

};

// const loadLoanAccount = async () => {
//   let result: PGRESTResult = new PGRESTResult();
//   loanaccoundlist.value = [];

//   result = await pgrestapi.get("loan_accounts?select=id,ar_trexontos_logariasmou");

//   if (result.error) {
//     ElMessage({
//       showClose: true,
//       message: result.error.message,
//       type: "error",
//     });

//     return;
//   }

//   for (let i = 0; i < result.data.length; i++) {
//     loanaccoundlist.value.push({
//       value: result.data[i].id,
//       label: result.data[i].ar_trexontos_logariasmou,
//     });
//   }
// };

onMounted(async () => {
  //await loadLoanAccount();

  data.value.loan_account_id = props.loan_account_id;
  console.log(data.value.loan_account_id)
  if (props.paymentid > 0) { await loadPayment(); }
  table_name.value = Payment.getTableNameStatic();
  cleanObject.value = new Payment();

  if (props.newEntity === true) {
    resetObjects();
    return;
  }

  isLoading.value = true;
  await loadData(props.paymentid);
  isLoading.value = false;
});

const onPreSave = async () => {
  if (checkValidationsForError()) {
    showError("Please correct shown errors.");
    return;
  }

  await save();
  resetObjects();
};

</script>

<template>
  <!-- An to kaneis me entity fields prepei na baleis condition sto save kai na apothikeuete kateuthian sthn bash (mesa apo ta generic) allios o pio 
  eukolos tropos na ta kaneis me apla inputs kai na kaneis mia sunarthsh save pou tha pachari sth bash -->
  <div v-loading="isLoading">

    <el-form :model="data" ref="ruleFormRef">
      <!-- {{ $t('payments.loan_account') }}: {{ data.loan_account_id }} -->
      <el-row :gutter="10">

        <el-col :span="24">
          <span v-if="(data.hmer === null)">{{ $t('errors.missing_field_hmer') }}</span>

          <el-form-item :label="$t('payments.hmer')">
            <el-date-picker value-format="YYYY-MM-DD" v-model="data.hmer" autocomplete="off" format="DD-MM-YYYY" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <span v-if="(data.poso === null || data.poso === '')">{{ $t('errors.missing_field_poso') }}</span>
          <el-form-item :label="$t('payments.poso')">
            <el-input v-model="data.poso" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('payments.kefalaio')">
            <el-input v-model="data.kefalaio" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('payments.tokoi')">
            <el-input v-model="data.tokoi" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('payments.exoda')">
            <el-input v-model="data.exoda" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('payments.proskomisi')">
            <el-switch v-model="data.proskomisi_vevaiosis" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('payments.hmer_prosk')">
            <el-date-picker v-model="data.hmer_proskomisis" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form-item class="btn-wrapper-components">
          <el-button type="primary" @click="onPreSave">{{$t('generic.save')}}</el-button>
          <el-button @click="close">{{$t('generic.close')}}</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>

</style>
