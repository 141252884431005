<script lang="ts" setup>
import { ref, onMounted, watch } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import {
  Bell,
  Edit,
  Delete,
  Document,
  User,
  Plus,
  RefreshLeft,
  CircleCheck,
  CircleClose,
  Watch,
  Files,
  Collection,
  WarnTriangleFilled
} from "@element-plus/icons-vue";
import { useMOSAPIStore } from "../store/mos_api";
import { ElUpload, UploadFile, UploadFiles } from "element-plus";
import type { UploadInstance } from "element-plus";
import { useMainStore } from "../store/main";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { storeToRefs } from "pinia";
import UserType from "../enums/UserType";
import Option from "../interfaces/Option";
import AddEdit_DDebtor from "../components/AddEdit_DDebtor.vue";
import { useSort } from "../composables/sort";
import DDebtor from "../classes/DB_Entities/DDebtor";
import DocumentsList from "../components/DocumentsList.vue";
import EntityType from "../enums/EntityType";
import DocumentFolders from "../enums/DocumentFolders";
const genericVM = useGenericMethodsVariables();
const mosapi = useMOSAPIStore();
const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const uploadRef = ref<UploadInstance>();
const { showError, showSuccess, isLoading, entityApprove } = useGenericMethodsVariables();
let dataList = ref([]);
let dataCount = ref(0);
let lastPage = ref(0);
let selectedRow = ref(-1);
let availableDigitizers = ref<Option[]>([]);
let assignedDigitizers = ref([]);
let filterPelatis = ref<string>("");
let filterCID = ref<string>("");
let filterCor_contractcode = ref<string>("");
let filterPhysical = ref<string>("");
let timer = ref<any>(null);
let addEditVisible = ref(false);
let showDocumentsList = ref(false);
let selectedDocumentEntity = ref<any>(undefined);
let detailsDigitizersVisible = ref(false);
let selectPortfolioVisible = ref(false);
let selectPortfolioProtVisible = ref(false);
let selectedRowForDigitizers = ref(0);
let fileSelected = ref(false);
let portfolios = ref<any>([]);
let selected_portfolioID=ref(-1);
let selected_portfolioUploadID=ref();
let firstPortfolioID=ref(-1);
let deleteDialogVisible = ref(false);
let entityName = ref("");
let DeleteSelectedRow = ref(0);
interface Folder {
    id: number;
    d_debtor_id: number;
}
const users = ref<any>([]);
const { onSortChange, sortColumn, sortOrder } = useSort();
const { globalPortfolioID } = storeToRefs(mainStore);
const { globalDebtorID } = storeToRefs(mainStore);
const { globalLoanAgreementID } = storeToRefs(mainStore);
const loadPortfolios = async () => {
  let result: PGRESTResult = new PGRESTResult();

  let addedFilter = "";

  if (mainStore.loggedUser.user_type === UserType.Supervisor) {
    addedFilter += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
  }
  result = await pgrestapi.get("portfolio?digitization=eq.true" + addedFilter);
  portfolios.value = result.data;
  if(result.data.length>0){ 
    selected_portfolioID.value=portfolios.value[0].id;
    firstPortfolioID.value=portfolios.value[0].id;
  }
  // console.log(portfolios.value);
};
watch(globalPortfolioID, async () => {
  if (mainStore.loggedUser.username !== "")
    // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalDebtorID, async () => {
  if (mainStore.loggedUser.username !== "")
    // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== "")
    // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

const handleClickAdd = () => {
  selectedRow.value = -1;
  addEditVisible.value = true;
};

const handleClickEdit = (row_id: number) => {
  selectedRow.value = row_id;
  addEditVisible.value = true;
};

onMounted(async () => {
  await loadPortfolios();
  loadPage(1);  
});

const loadDigitizers = async (row_id: number) => {
  let result = await pgrestapi.get(
    "users?select=id,username&active=eq.true&deleted=eq.false&user_type=eq." +
      UserType.Digitizer
  ); // active = true, deleted = false, user_type = 2 (Agent)

  availableDigitizers.value = [];

  for (let digitizer of result.data) {
    availableDigitizers.value.push({
      key: digitizer.id,
      label: digitizer.username,
      disabled: false,
    });
  }

  result = await pgrestapi.get("d_debtor?id=eq." + row_id);
  //console.log(result.data)
  assignedDigitizers.value = result.data[0].allowed_users;
};

const loadPage = async (page: number) => {
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;

  let filterUrl = "";

  if (filterCID.value !== "") {
    filterUrl += `&cid=ilike.*${filterCID.value}*`;
  }
  if (filterPhysical.value !== "") {
    filterUrl += `&physical_contract_id=ilike.*${filterPhysical.value}*`;
  }
  
  if (filterPelatis.value !== "") {
    filterUrl += `&pelatis=ilike.*${filterPelatis.value}*`;
  }

  if (filterCor_contractcode.value !== "") {
    filterUrl += `&cor_contractcode=ilike.*${filterCor_contractcode.value}*`;
  }
  let result: PGRESTResult = new PGRESTResult();
  if (mainStore.loggedUser.user_type == 3) {
    filterUrl += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
    result = await pgrestapi.getPaginated("d_debtor?select=*" + filterUrl, page);
  }
  else if (mainStore.loggedUser.user_type == 1 && mainStore.loggedUser.digitization) {
    result = await pgrestapi.getPaginated(`d_debtor?select=*,portfolio!inner(*)&portfolio.allowed_users=cs.{${mainStore.loggedUser.id}}` + filterUrl, page);
  }
  else if (mainStore.loggedUser.user_type == 0) {
    //ADMIN
    result = await pgrestapi.getPaginated("d_debtor?select=*" + filterUrl, page);
  }

  

  

  
  

  if (result.error) {
    showError(result.error.message);
    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;

  isLoading.value = false;
};

const onSave = async () => {
  selectedRow.value = -1;
  //showSuccess("Digitized LA updated");
  addEditVisible.value = false;

  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  showError("Digitized LA failed to update");
  addEditVisible.value = false;
};
const closeDigitizersPicking = async () => {
  detailsDigitizersVisible.value = false;
  let assigned_digitizer_id=-1;
  
  if (assignedDigitizers.value.length==1) {
    assigned_digitizer_id = assignedDigitizers.value[0];
    let result = pgrestapi.patch("d_debtor?id=eq." + selectedRowForDigitizers.value, {
    allowed_users: assignedDigitizers.value,
    stoixeia_xeiristi:assigned_digitizer_id,
  });  

  } else {
  let result2 = pgrestapi.patch("d_debtor?id=eq." + selectedRowForDigitizers.value, {
    allowed_users: assignedDigitizers.value,
  });
}
  await loadUsers();
  loadPage(lastPage.value);
  selectedRow.value = -1;
};
const loadUsers = async () => {
  let result = await pgrestapi.get("users?select=id,username&order=username");

  if (result.error) {
    showError(result.error.message);
    return;
  }

  users.value = result.data;
};
const handleDigitizers = (row_id: number) => {
  isLoading.value = true;
  selectedRowForDigitizers.value = row_id;
  detailsDigitizersVisible.value = true;
  loadDigitizers(row_id);
  isLoading.value = false;
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const onCloseAddEdit = () => {
  addEditVisible.value = false;
  selectedRow.value = -1;
};

const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 500);
};


const uploadComplete = async (
  response: any,
  uploadFile: UploadFile,
  uploadFiles: UploadFiles
) => {
  console.log(response);
  loadPage(lastPage.value);
};

const addedFile = async (uploadFile: UploadFile, uploadFiles: UploadFiles) => {
  for (let file of uploadFiles) {
    console.log(file.status);
    if (file.status === "ready") {
      fileSelected.value = true;
    } else if (file.status === "success") {
      fileSelected.value = false;
      uploadRef.value!.clearFiles();
    }
  }
};

const removedFile = async () => {
  fileSelected.value = false;
  console.log("Removed file for upload.");
};

const onError = async () => {
  fileSelected.value = false;
  console.log("There was an error while uploading the file.");
};

const submitUpload = async () => {
  uploadRef.value!.submit();
};

const DownloadExportProt = async (portfolio_id:any) => {
  console.log(portfolios.value[0].id);
  try {
       let res = await mosapi.getexportProt(portfolio_id);
       if (res !== "") {
         showError(res);
       }

     } catch (ex) {
       console.log(ex);
     }
     selected_portfolioID.value=firstPortfolioID.value;
     selectPortfolioProtVisible.value=false;
     return;
};
const DownloadExportDT = async (portfolio_id:any) => {
  console.log(portfolios.value[0].id);
  
  //(product_type: string, loan_agreement_id: number) => {
  // if (row.product_type === "summary_check_KOD") {
     try {
       let res = await mosapi.getexportDT(portfolio_id);
       if (res !== "") {
         showError(res);
       }

     } catch (ex) {
       console.log(ex);
     }
     selected_portfolioID.value=firstPortfolioID.value;
     selectPortfolioVisible.value=false;
     return;
};


const onDelete = async (messageDuration: number = 2000) => {
        genericVM.showSuccess(`${entityName.value} deleted successfully`, messageDuration);
        deleteDialogVisible.value = false;
        loadPage(lastPage.value);
};

async function deleteDDebtorAndAssociatedRecords(debtorId: number): Promise<void> {
    try {

        // Delete records from digitized_gelatin table
        await pgrestapi.delete_data(`digitized_gelatin?digitized_folder_id.in=(${getFolderIdsQuery(debtorId)})`);

        // Delete records from digitized_folder table
        await pgrestapi.delete_data(`digitized_folder?d_debtor_id=eq.${debtorId}`);
        
        // Delete the parent record from d_debtor
        await pgrestapi.delete_data(`d_debtor?id=eq.${debtorId}`);
        
        console.log(`Successfully deleted debtor and associated records with ID: ${debtorId}`);
        
        onDelete();
    } catch (error) {
        console.error(`Error deleting records: ${error}`);
    }
}


async function getFolderIdsQuery(debtorId: number): Promise<string> {
    try {
        const response = await pgrestapi.get(`digitized_folder?id,d_debtor_id&d_debtor_id=eq.${debtorId}`);
        const folderIds = response.data.map((folder: Folder) => folder.id);
        return folderIds.join(',');
    } catch (error) {
        console.error(`Error fetching folder IDs: ${error}`);
        return '';
    }
}

const deleteHandleClick = async (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  const debtorIdToDelete  = row_id;
  await deleteDDebtorAndAssociatedRecords(debtorIdToDelete);
};




</script>

<template>
  <div class="content-wrapper" v-loading="isLoading" v-if="(
    (mainStore.loggedUser.user_type === UserType.Administrator) ||
    (mainStore.loggedUser.user_type === UserType.Supervisor && mainStore.loggedUser.digitization===true) ||
    (mainStore.loggedUser.user_type === UserType.Digitizer)
    )">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          {{ $t("DigitizedView.loan_agreements") }} ({{ dataCount }})
        </h3>
      </el-col>
    </el-row>

    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input
            v-model="filterCID"
            class="client-input"
            :placeholder="$t('DDebtorDetailsView.cid')"
            @input="filterChanged"
          />
        </el-col>
        <el-col :span="6">
          <el-input
            v-model="filterPhysical"
            class="client-input"
            :placeholder="$t('DDebtorDetailsView.physical_contract_id')"
            @input="filterChanged"
          />
        </el-col>
        <el-col :span="6">
          <el-input
            v-model="filterPelatis"
            class="client-input"
            :placeholder="$t('DDebtorDetailsView.pelatis')"
            @input="filterChanged"
          />
        </el-col>
        <el-col :span="6">
          <el-input
            v-model="filterCor_contractcode"
            class="client-input"
            :placeholder="$t('DDebtorDetailsView.cor_contractcode')"
            @input="filterChanged"
          />
        </el-col>
      </el-row>
    </div>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn"> </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button
            link
            type="primary"
            size="small"
            class="btn-add"
            @click="loadPage(lastPage)"
            style="float: inline-end; margin: 2.5px"
            :icon="RefreshLeft"
          />
          <el-button
            link
            type="primary"
            size="small"
            class="btn-add"
            style="float: inline-end; margin: 2.5px"
            @click="handleClickAdd()"
            :icon="Plus"
          />
          <el-button 
          v-if="mainStore.loggedUser.user_type < 2"
          link 
          type="primary" 
          class="btn-add"
          style="float: inline-end; margin: 2.5px" 
          size="small" 
          @click="selectPortfolioVisible=true"
          :icon ="Files"
          >{{$t('DDebtorDetailsView.downloadexportdt')}}</el-button>
          <el-button 
          v-if="mainStore.loggedUser.user_type < 2"
          link 
          type="primary" 
          class="btn-add" 
          size="small" 
          style="float: inline-end; margin: 2.5px" 
          @click="selectPortfolioProtVisible=true"
          :icon="Collection"
          >{{$t('DDebtorDetailsView.downloadexportprot')}}</el-button>
              
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="10">
      <el-table
        :data="dataList"
        stripe
        border
        table-layout="fixed"
        sortable="custom"
        @sort-change="localSortChange"
        size="small"
      >
        <el-table-column
          fixed
          sortable
          prop="cid"
          :label="$t('DDebtorDetailsView.cid')"
        />
        <el-table-column
          fixed
          sortable
          prop="pelatis"
          :label="$t('DDebtorDetailsView.pelatis')"
        />
        <el-table-column
          fixed
          sortable
          prop="cor_contractcode"
          :label="$t('DDebtorDetailsView.cor_contractcode')"
        />
        <el-table-column
          fixed
          sortable
          prop="cor_sdocontractid"
          :label="$t('DDebtorDetailsView.cor_sdocontractid')"
        />
        <el-table-column
          fixed
          sortable
          prop="physical_contract_id"
          :label="$t('DDebtorDetailsView.physical_contract_id')"
        />
        <el-table-column fixed="right" :label="$t('global.operations')" width="280">
          <template #default="scope">
            <el-button
              v-if="mainStore.loggedUser.user_type < 2"
              link
              type="primary"
              size="small"
              class="btn-portfolios"
              @click="handleDigitizers(scope.row.id)"
              :icon="User"
            />
            <el-button
              link
              type="primary"
              size="small"
              class="btn-detail"
              @click="handleClickEdit(scope.row.id)"
              :icon="Edit"
            />
            <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
            class="btn-delete" @click="deleteDialogVisible = true; DeleteSelectedRow = scope.row.id;" :icon="Delete" />
            <el-tooltip :content="$t('global.ImportError')" v-if="scope.row.row_problem">
             <el-button type="danger" :icon="WarnTriangleFilled" circle />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- :action="`http://localhost:3000/digitized`" -->
<!-- action="https://mos-api.web-idea.gr/digitized" -->
    <el-row :gutter="10">
      <el-col :span="12"> </el-col>
      
      <el-col :span="12" v-if="(
    (mainStore.loggedUser.user_type === UserType.Administrator) ||
    (mainStore.loggedUser.user_type === UserType.Supervisor && mainStore.loggedUser.digitization===true))">
    Select portfolio for upload:
        <el-select v-model="selected_portfolioUploadID" class="m-2 header-filter" size="small" clearable filterable placeholder=" ">
                  <el-option v-for="item in portfolios" :key="item.id" :label="item.portf_name"
                    :value="item.id" />
                </el-select>
        <el-upload
          :disabled="!(selected_portfolioUploadID !== undefined && selected_portfolioUploadID !== null && selected_portfolioUploadID !== '')"
          style="float: inline-end; margin: 15.5px"
          ref="uploadRef"
          class="upload-demo-d"
          :action="`${mosapi.apiURL()}digitized/${selected_portfolioUploadID}`"
          :limit="1"
          :auto-upload="false"
          :on-success="uploadComplete"
          :headers="{ Authorization: 'Bearer ' + mosapi.token }"
          @change="addedFile"
          @remove="removedFile"
          @error="onError"
        >
          <template #trigger>
            <el-button type="primary">Select file</el-button>
          </template>
          <el-button
            v-if="fileSelected"
            class="ml-3"
            type="success"
            @click="submitUpload"
          >
            Upload to server
          </el-button>
          <template #tip>
            <div class="el-upload__tip text-red">
              Limit 1 file, new file will overwrite the old file
            </div>
          </template>
        </el-upload>
      </el-col>
    </el-row>

    <el-dialog
      v-if="addEditVisible"
      v-model="addEditVisible"
      @update:model-value="onCloseAddEdit"
      :title="selectedRow === -1 ? 'Add Digitized LA' : 'Digitized LA'"
      width="50%"
    >
      <AddEdit_DDebtor
        @close="onCloseAddEdit"
        @save="onSave"
        @fail-save="onFailSave"
        :row_id="selectedRow"
      />
    </el-dialog>

    <el-dialog
      v-if="showDocumentsList"
      v-model="showDocumentsList"
      :title="`Debtor / ${selectedDocumentEntity.cid} - Documents List`"
      width="700"
    >
      <DocumentsList
        v-if="showDocumentsList"
        :entity_type="EntityType.Debtor"
        :entity_id="selectedDocumentEntity.id"
        :row_id="selectedRow"
        :allowed_folders="[
          DocumentFolders.LegalizationDocuments,
          DocumentFolders.MiscDocuments,
        ]"
      />
    </el-dialog>

    <el-dialog v-model="detailsDigitizersVisible" title="Agent assignment" width="60%">
      <el-transfer
        v-model="assignedDigitizers"
        :data="availableDigitizers"
        :titles="['Available', 'Assigned']"
      />

      <template #footer #default="scope">
        <span class="dialog-footer">
          <el-button @click="closeDigitizersPicking">{{ $t("generic.close") }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-if="selectPortfolioVisible" v-model="selectPortfolioVisible" title="Select Portfolio" width="40%">
    <p>  {{ $t("DDebtorDetailsView.Please_select") }}</p>
    <el-form :model="portfolios" ref="ruleFormRef">
      <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item :label="$t('DDebtorDetailsView.portfolio')" prop="portfolio_id">
                <el-select v-model="selected_portfolioID" class="m-2 header-filter" size="small" clearable filterable placeholder=" ">
                  <el-option v-for="item in portfolios" :key="item.id" :label="item.portf_name"
                    :value="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
            </el-row>
    </el-form>
      <p />
      <el-button @click="selectPortfolioVisible = false;selected_portfolioID=firstPortfolioID">Back</el-button>
      <el-button @click="DownloadExportDT(selected_portfolioID)">Export</el-button>
      
    </el-dialog>
    <el-dialog v-if="selectPortfolioProtVisible" v-model="selectPortfolioProtVisible" title="Select Portfolio" width="40%">
    <p>  {{ $t("DDebtorDetailsView.Please_select") }}</p>
    <el-form :model="portfolios" ref="ruleFormRef">
      <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item :label="$t('DDebtorDetailsView.portfolio')" prop="portfolio_id">
                <el-select v-model="selected_portfolioID" class="m-2 header-filter" size="small" clearable filterable placeholder=" ">
                  <el-option v-for="item in portfolios" :key="item.id" :label="item.portf_name"
                    :value="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
            </el-row>
    </el-form>
      <p />
      <el-button @click="selectPortfolioProtVisible = false;selected_portfolioID=firstPortfolioID">Back</el-button>
      <el-button @click="DownloadExportProt(selected_portfolioID)">Export</el-button>
      
    </el-dialog>

    <el-dialog v-if="deleteDialogVisible" v-model="deleteDialogVisible" width="40%">
        <span>Are you sure you want to delete this entry?</span>
        <template #footer #default="scope">
            <span class="dialog-footer">
                <el-button @click="deleteDialogVisible = false;">{{ $t('generic.cancel') }}</el-button>
                <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{ $t('generic.delete') }}
                </el-button>
            </span>
        </template>
    </el-dialog>

    <div>
      <el-pagination
        small
        layout=" prev, pager, next"
        :page-size="10"
        :total="dataCount"
        @current-change="loadPage"
      />
    </div>
  </div>
  <div v-else>
    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="6">
          {{ $t("DDebtorDetailsView.permissions") }}
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<style scoped></style>
