<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import Client from "../classes/DB_Entities/Client";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import User from "../classes/DB_Entities/User";
import UserType from "../enums/UserType";
import { useValidation } from "../composables/validation";

const props = defineProps<{
  row_id: number;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });

const {
  isLoading,
  fullObj,
  table_name,
  isNewEntity,
  loadData,
  save,
  saveDirect,
  close,
  cleanObject,
  resetObjects,
  showError,
} = useGenericMethodsVariables();

const {
  validationErrors,
  onValidationChange,
  checkValidationsForError,
} = useValidation();

onMounted(async () => {
  table_name.value = Client.getTableNameStatic();
  cleanObject.value = new Client();

  console.log("client_id", props.row_id);

  if (props.row_id === -1) {
    resetObjects();
    return;
  }

  isLoading.value = true;
  await loadData(props.row_id);
  isLoading.value = false;
});

const onPreSave = async () => {
  if (checkValidationsForError()) {
    showError("Please correct shown errors.");
    return;
  }

  // Check if client name already exists (if new entity)
  if (isNewEntity()) {
    let result = await pgrestapi.get('mosclients?select=id&clientname=eq.' + fullObj.value.clientname);

    if (result.data.length > 0) {
      showError(t("validation.client_name_already_exists"));
      return;
    }
  } else { // Check if client name already exists (when updating existing entity)
    let result = await pgrestapi.get(`mosclients?select=id&id=neq.${fullObj.value.id.originalValue}&clientname=eq.${fullObj.value.clientname.originalValue}`);

    if (result.data.length > 0) {
      showError(t("validation.client_name_already_exists"));
      return;
    }
  }

  await save();
};
</script>

<template>
  <div v-loading="isLoading">
    <el-form>
      <EntityField
        type="input"
        :label="$t('ClientDetails.clientname')"
        :object="fullObj.clientname"
        :is-disabled="mainStore.loggedUser.user_type !== UserType.Administrator"
        :validation-rules="[['.+', 'validation.insert_client_name']]"
        @validation-change="onValidationChange"
      />
      <!-- </el-row> -->
      <!-- <el-row>
        <el-col :span="24">
          <el-form-item>
            <label class="select-label">Users with access to client</label>
            <el-select v-model="data.allowed_users" multiple placeholder="Select">
              <el-option v-for="item in usersList" :key="item.id" :label="item.name + ' (' + item.username + ')'"
                :value="(item.id as number)" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row> -->
    </el-form>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form-item class="btn-wrapper-components">
          <el-button type="primary" @click="onPreSave">{{$t('generic.save')}}</el-button>
          <el-button @click="close">{{$t('generic.close')}}</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped></style>
