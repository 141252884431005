import ITableName from "../../interfaces/ITableName";

class RegisterOfBond implements ITableName {
  id: number = -1;
  loan_agreement_id: string = "";
  payment_representative: string = "";
  replacement_mechanism: string = "";

  static getTableNameStatic(): string {
    return "register_of_bonds";
  }

  getTableName(): string {
    return RegisterOfBond.getTableNameStatic();
  }
}

export default RegisterOfBond;
