import ITableName from "../../interfaces/ITableName";

class DDebtor implements ITableName {
  id: number = -1;
  cid: string = "";
  cor_center: string = "";
  portfolio_id?: string;
  pelatis: string = "";
  cor_contractcode: string = "";
  cor_sdocontractid: string = "";
  physical_contract_id: string = "";
  physical_contract_id_copy: string = "";
  cor_importdate?: string;
  securitized_perimeter: boolean = false;
  analyt_thesi_fakel_ana_cid: boolean = false;
  checked_not_included_incairo: boolean = false;
  fak_pistoux_cairo: boolean = false;
  denounced_flag: boolean = false;
  barcode_fakelou: string = "";
  arith_psifiop_selid: string = "";
  barcode_kibotiou: string = "";
  stoixeia_xeiristi?:number;
  anoigma?: string;
  elegxos?: string;
  apografh?: string;
  elegxos_so1?: number;
  stoixeia_pshfiop?:number;
  shmansh_barcode: string = "";
  elegxos_so2_1?: string;
  pshfiopoihsh?: string;
  elegxos_so2_2?: string;
  suskeuasia?: string ;
  elegxos_pmo:  boolean = false;
  stby_apostolhs: boolean = false;
  apostolh: boolean = false;
  end_stand_by_apograf:  boolean = false;
  sxolio: string = "";
  allowed_users: Array<number> = [];
  /***************** */
  symv_arithmos_zelatinon?:number;
  symv_symv_prot?:number;
  symv_symv_antig?:number;
  symv_prosth_prot?:number;
  symv_prosth_antigr?:number;
  symv_prosth_sxolio: string = "";
  nomim_arithmos_zelatinon?:number;
  nomim_prot?:number;
  nomim_antig?:number;
  axiogr_arithmos_zelatinon?:number;
  axiogr_prot?:number;
  axiogr_antig?:number;
  exasf_arithmos_zelatinon?:number;
  prosim_plithos?:number;
  prosim_plithos_eggr?:number;
  enex_plithos_prot?:number;
  enex_plithos_antig?:number;
  epid_enex_plithos_prot?:number;
  epid_enex_plithos_antig?:number;
  nomikes_arithmos_zelatinon?:number;
  nom_epist_kataggelias_prot?:number;
  nom_epist_kataggelias_antig?:number;
  nom_ekthes_epid_prot?:number;
  nom_ekthes_epid_antig?:number;
  nom_diat_plir_prot?:number;
  nom_diat_plir_antig?:number;
  nom_epid_dp_prot?:number;
  nom_epid_dp_antig?:number;
  nom_apogr_prot?:number;
  nom_apogr_antig?:number;
  loipa_zelat?:number;
  eggrafa_ektos_dt: string="";
  sxolio_apogr: string="";
  ccy: string="";
  row_problem: string="";

  static getTableNameStatic(): string {
    return "ddebtor";
  }

  getTableName(): string {
    return DDebtor.getTableNameStatic();
  }
}

export default DDebtor;
