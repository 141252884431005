<script lang="ts" setup>
import AgentView from "../views/ConfigAgentView.vue";
import CurrencyView from "../views/ConfigCurrencyView.vue";
import { ref, watch, onMounted, nextTick, reactive } from "vue";
import ProductView from "../views/ConfigProductView.vue";
import InterestView from "../views/ConfigInterestView.vue";
import KADView from "./configKADView.vue";
import KladosView from "./ConfigKladosView.vue";
import LoanAccountProductView from "./ConfigLoanAccountProductView.vue";
import PrefecturesView from "../views/ConfigPrefecturesView.vue";
import PostalCodesView from "../views/ConfigPostalCodesView.vue";
import CountriesView from "../views/ConfigCountriesView.vue";
import ArgiesView from "../views/ConfigArgiesView.vue";
import ShortDescriptionsView from "../views/ConfigShortDescriptionsView.vue";
import InsuranceCompanyView from "../views/ConfigInsuranceCompanyView.vue";
import ObjectOfInsuranceView from "../views/ConfigObjectOfInsuranceView.vue";
import InsuranceRiskView from "../views/InsuranceRiskView.vue";
import TypeOfAgencyFeeView from "../views/ConfigTypeOfAgencyFeeView.vue";
import FeePaymentFreqView from "../views/ConfigFeePaymentFreqView.vue";
import GroupsView from "../views/ConfigGroupsView.vue";
import PrintersView from "../views/ConfigPrintersView.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n({ useScope: "global" });
</script>
<template>
  <div class="content-wrapper" style="height: 100%">
    <el-row>
      <div style="margin-bottom: 10px">
        <h3 class="heading-page">Platform Settings</h3>
      </div>
    </el-row>
    <el-tabs :tab-position="'left'">
      <el-tab-pane :label="$t('PlatformSettingsView.agents')">
        <AgentView />
      </el-tab-pane>
      <el-tab-pane :label="$t('PlatformSettingsView.currencies')">
        <CurrencyView />
      </el-tab-pane>
      <el-tab-pane :label="$t('PlatformSettingsView.loan_agreement_product')">
        <ProductView />
      </el-tab-pane>
      <el-tab-pane :label="$t('PlatformSettingsView.interests')">
        <InterestView />
      </el-tab-pane>
      <el-tab-pane :label="$t('PlatformSettingsView.kad')">
        <KADView />
      </el-tab-pane>
      <el-tab-pane :label="$t('PlatformSettingsView.klados')">
        <KladosView />
      </el-tab-pane>
      <el-tab-pane :label="$t('PlatformSettingsView.loan_account_product')">
        <LoanAccountProductView />
      </el-tab-pane>
      <el-tab-pane :label="$t('PlatformSettingsView.prefectures')">
        <PrefecturesView />
      </el-tab-pane>
      <el-tab-pane :label="$t('PlatformSettingsView.postal_codes')">
        <PostalCodesView />
      </el-tab-pane>
      <el-tab-pane :label="$t('PlatformSettingsView.countries')">
        <CountriesView />
      </el-tab-pane>
      <el-tab-pane :label="$t('PlatformSettingsView.argies')">
        <ArgiesView />
      </el-tab-pane>
      <el-tab-pane :label="$t('PlatformSettingsView.short_descriptions')">
        <ShortDescriptionsView />
      </el-tab-pane>
      <el-tab-pane :label="$t('PlatformSettingsView.ins_comps')">
        <InsuranceCompanyView />
      </el-tab-pane>
      <el-tab-pane :label="$t('PlatformSettingsView.obj_of_ins')">
        <ObjectOfInsuranceView />
      </el-tab-pane>
      <el-tab-pane :label="$t('PlatformSettingsView.insurance_risk')">
        <InsuranceRiskView />
      </el-tab-pane>
      <el-tab-pane :label="$t('PlatformSettingsView.type_of_agency_fee')">
        <TypeOfAgencyFeeView />
      </el-tab-pane>
      <el-tab-pane :label="$t('PlatformSettingsView.fee_payment_freq')">
        <FeePaymentFreqView />
      </el-tab-pane>
      <el-tab-pane :label="$t('PlatformSettingsView.groups')">
        <GroupsView />
      </el-tab-pane>
      <el-tab-pane label="Printers">
        <PrintersView />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.info {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}

.demo-tabs>.el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

.el-tabs--right .el-tabs__content,
.el-tabs--left .el-tabs__content {
  height: 100%;
}
</style>
