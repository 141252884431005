enum LoanAccountEventType {
    Dosh = 0,
    Pistwsh = 1,
    Tokos = 2,
    InterestChange = 3,
    TokoiKathysterhmenwnTokwn = 4,
    KathysterhmenoKefalaio = 5,
    Exodo = 6,
    Xrewsh = 7
}

export default LoanAccountEventType;