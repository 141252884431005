<script lang="ts" setup>
import {
  Location,
  Menu as IconMenu,
  Minus,
  More,
  Document,
  Setting,
  MessageBox,
  Histogram
} from "@element-plus/icons-vue";
import MenuEntry from "../classes/DB_Entities/MenuEntry";
import { ref, onMounted, onUnmounted } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import UserType from "../enums/UserType";

interface Props {
  menu: { [key: string]: MenuEntry[] };
  menuOrder: string[];
  menuIcons: string[];
}

let retrievingPending = false;
let pendingData = ref<any[]>([]);
let pendingNewData = ref<any[]>([]);
const props = defineProps<Props>();
const mainStore = useMainStore();
const pgStore = usePGRESTAPIStore();
let refreshPendingTimer = ref<any>(0);

const togglemenu = async () => {
  mainStore.iscollapsed = !mainStore.iscollapsed;
};

const handleOpen = (key: string, keyPath: string[]) => {
  // console.log(key, keyPath);
};

const handleClose = (key: string, keyPath: string[]) => {
  // console.log(key, keyPath);
};

const getPendingChanges = async () => {

  if (mainStore.loggedUser.user_type === UserType.Agent) return; // Menu doesn't need pending changes for agents

  retrievingPending = true;

  let result = new PGRESTResult();
  let result_new = new PGRESTResult();
  let queryChanges = `view_pending_changes`;
  let queryNew = `view_pending_new_entities`;

  if (mainStore.loggedUser.user_type === UserType.Supervisor) {
    queryChanges += `?allowed_users=cs.{${mainStore.loggedUser.id}}`;
    queryNew += `?allowed_users=cs.{${mainStore.loggedUser.id}}`;
  }

  result = await pgStore.get(queryChanges);
  result_new = await pgStore.get(queryNew);

  retrievingPending = false;

  if (!result.data) return;
  if (!result_new.data) return;

  pendingData.value = result.data;
  pendingNewData.value = result_new.data;
};

onUnmounted(async () => {
  retrievingPending = false;
  clearInterval(refreshPendingTimer.value);
});

onMounted(async () => {
  getPendingChanges();

  refreshPendingTimer.value = setInterval(async () => {
    if (retrievingPending) return;

    await getPendingChanges();
  }, 60000);
});

const hasEntityChanges = (entity: string): boolean => {
  for (let item of pendingData.value) {
    if (item.entity == entity) {
      return true;
    }
  }

  for (let item of pendingNewData.value) {
    if (item.entity == entity) {
      return true;
    }
  }
  return false;
};
</script>

<template>
  <!-- <el-button class="togglebtn" :icon="IconMenu" @click="togglemenu" /> -->

  <el-menu background-color="transparent" :router="true" :default-active="$route.path" :default-openeds="['1']"
    :collapse="mainStore.iscollapsed" @open="handleOpen" @close="handleClose" :collapse-transition="true">
    <el-sub-menu :index="index + 1 + ''" v-for="(menuEntry, index) in props.menuOrder" :key="menuEntry">
      <template #title>
        <el-icon v-if="props.menuIcons[index] == 'icon-menu'">
          <icon-menu />
        </el-icon>
        <el-icon v-if="props.menuIcons[index] == 'document'">
          <Document />
        </el-icon>
        <el-icon v-if="props.menuIcons[index] == 'setting'">
          <Setting />
        </el-icon>
        <el-icon v-if="props.menuIcons[index] == 'messagebox'">
          <MessageBox />
        </el-icon>
        <el-icon v-if="props.menuIcons[index] == 'histogram'">
          <Histogram />
        </el-icon>
        <el-icon v-if="props.menuIcons[index] == null || props.menuIcons[index] == ''">
          <More />
        </el-icon>
        <span>{{ $t("menu." + menuEntry) }}</span>
      </template>
      <el-menu-item :class="{ 'has-pending2': hasEntityChanges(menuItem.title) }" :index="menuItem.path"
        v-for="(menuItem, index) in menu[menuEntry]">
        <el-icon>
          <Minus />
        </el-icon>{{ $t("menu." + menuItem.title) }}
        <span class="has-pending" v-if="hasEntityChanges(menuItem.title)">(*)</span>
      </el-menu-item>
    </el-sub-menu>
  </el-menu>
</template>

<style scoped></style>
