import ITableName from "../../interfaces/ITableName";

class ConfigPrinter implements ITableName {
  id?: number = -1;
  printer_name: string = "";
  printer_ip: string = "";
  port: string = "";

  static getTableNameStatic(): string {
    return "printers";
  }

  getTableName(): string {
    return ConfigPrinter.getTableNameStatic();
  }
}

export default ConfigPrinter;
