<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import { Edit, Delete } from "@element-plus/icons-vue";
import PrivilegeDetails from "../components/AddEdit_Privilege.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
const { showError } = useGenericMethodsVariables();

const pgrestapi = usePGRESTAPIStore();

let dataList = ref([]);
let dataCount = ref(0);
let isLoading = ref(false);
let lastPage = ref(0);
let detailsVisible = ref(false);
let selectedRow = ref(0);

const handleClick = (clientid: number) => {
  selectedRow.value = clientid;
  detailsVisible.value = true;
};

onMounted(async () => {
  loadPage(1);
});

const loadPage = async (page: number) => {
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;

  let result: PGRESTResult = await pgrestapi.getPaginated("privileges?order=name.asc", page);

  if (result.error) {
    showError(result.error.message);

    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;

  isLoading.value = false;
};

const onSave = async () => {
  ElMessage({
    showClose: true,
    message: "Client updated",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;

  loadPage(lastPage.value);
};

const onFailSave = async () => {
  ElMessage({
    showClose: true,
    message: "Client failed to update",
    type: "error",
    duration: 2000,
  });

  detailsVisible.value = false;
};
</script>

<template>
  <div class="content-wrapper">
    <div>
      <h3 class="heading-page">Privileges</h3>
    </div>

    <el-table v-loading="isLoading" :data="dataList" stripe border table-layout="fixed">
      <el-table-column fixed prop="name" label="Name" />
      <el-table-column fixed="right" label="Operations">
        <template #default="scope">
          <el-button
            link
            type="primary"
            size="small"
            class="btn-detail"
            @click="handleClick(scope.row.id)"
            :icon="Edit"
          />
          <!-- <el-button link type="primary" size="small" class="btn-delete" :icon="Delete" /> -->
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination
        small
        layout="prev, pager, next"
        :page-size="10"
        :total="dataCount"
        @current-change="loadPage"
      />
    </div>

    <el-dialog v-if="detailsVisible" v-model="detailsVisible" title="Privilege Details">
      <PrivilegeDetails
        :privilegeid="selectedRow"
        @save="onSave"
        @close="detailsVisible = false"
        @fail-save="onFailSave"
      />
    </el-dialog>
  </div>
</template>

<style scoped></style>
