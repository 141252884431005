import ITableName from "../../interfaces/ITableName";

class Project implements ITableName {
  id: number = -1;
  project_template_id: number|null = -1;
  arithmos_aitisis: string = "";
  customer: string = "";
  customer_code: string = "";
  hmeromhnia_anathesis:  string = "";

  static getTableNameStatic(): string {
    return "projects";
  }

  getTableName(): string {
    return Project.getTableNameStatic();
  }
}

export default Project;
