import DebitCreditType from "../../enums/DebitCreditType";
import CreditType from "../../enums/CreditType";
import ITableName from "../../interfaces/ITableName";

class DebitCredit implements ITableName {
  id: number = -1;
  loan_account_id: string = "";
  date: string = "";
  amount?: number;
  status: number = 0;
  type: DebitCreditType = DebitCreditType.Credit;
  perigrafi?: string = "";
  credit_type: CreditType = CreditType.DEFAULT;
  config_interest_id?: number;
  perithorio?: number;
  eisfora?: number;
  pososto_tokwn_yperhmerias?: number;

  static getTableNameStatic(): string {
    return "debits_credits";
  }

  getTableName(): string {
    return DebitCredit.getTableNameStatic();
  }
}

export default DebitCredit;
