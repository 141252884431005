<script lang="ts" setup>
import { ref, onMounted, watch, Ref } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useMOSAPIStore } from "../store/mos_api";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import UserType from "../enums/UserType";
import UpdateUserReply from "../classes/UpdateUserReply";
import User from "../classes/DB_Entities/User";
import Printer from "../classes/DB_Entities/Printer";

const props = defineProps<{
	userid: number;
	allowTypeChange: boolean;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);

let isLoading = ref(false);
let dbObj = ref(new User());
const data = ref(new User());
let printersList = ref<any>([]);
let availablePrivileges: Ref<any[]> = ref([]);

const datapass = ref<any>({
	passwordconfirm: "",
	errors: [],
});

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const mosapiStore = useMOSAPIStore();
const mainstore = useMainStore();

const {
	showError,
} = useGenericMethodsVariables();

watch(
	() => props.userid,
	async (newVal, oldVal) => {
		// console.log("Reloading userid -> " + newVal);
		loadUser(newVal as number);
	}
);

onMounted(async () => {
	isLoading.value = true;
	await loadPrivileges();
	await loadUser(props.userid as number);
	isLoading.value = false;
});

const close = async () => {
	emits("close");
};

const save = async () => {
	
	datapass.value.errors = [];
	if (data.value.password !== datapass.value.passwordconfirm) {
		datapass.value.errors.push("Passwords do not match.");
	}
	if (data.value.first_name == "") {
		datapass.value.errors.push("First name can't be empty.");
	}
	if (data.value.last_name == "") {
		datapass.value.errors.push("Last name can't be empty.");
	}

	if (datapass.value.errors.length > 0) {

		for (let error of datapass.value.errors) {
			showError(error);
		}
		return;
	}

	console.log('hello')
	if (data.value.user_type == 3 || data.value.user_type == 0) {
		data.value.digitization = true;
	} else if (data.value.user_type == 2) {
		data.value.digitization = false;
	}
	let result: PGRESTResult = new PGRESTResult();
	let result2 = new UpdateUserReply();

	let obj = JSON.parse(JSON.stringify(data.value));

	if (obj.password === "") {
		let id = JSON.stringify(data.value.id);

		delete data.value.id;
		delete obj.password;

		if (JSON.stringify(obj) !== JSON.stringify(dbObj.value))
			result = await pgrestapi.patch("users?id=eq." + id, obj);
	} else {
		result2 = await mosapiStore.updateUser(obj);
	}

	if (result.error || result2.error) {
		if (result.error)
			showError(result.error.message); // emits("fail-save");
		else if (result2.error)
			showError(result2.error.message);
		return;
	}

	emits("save");
};

const loadUser = async (userid: number) => {
	if (userid === -1) return;

	let result: PGRESTResult = new PGRESTResult();

	result = await pgrestapi.get(
		"users?select=username,first_name,last_name,company,user_type,id,email,reset_password_on_login,active,digitization,assigned_printer,privileges&id=eq." +
		userid
	);

	if (result.error) {
		showError(result.error.message);

		emits("close");
		return;
	}

	dbObj.value = JSON.parse(JSON.stringify(result.data[0]))
	data.value = result.data[0];
	data.value.password = "";
	datapass.value.passwordconfirm = "";

	await loadPrinters();
};

const loadPrinters = async () => {
	let result: PGRESTResult = new PGRESTResult();

	result = await pgrestapi.get("printers?order=printer_name.asc");

	if (result.error) {
		showError(result.error.message);
		return;
	}

	printersList.value = [];
	result.data.forEach((element: Printer) => {
		printersList.value.push({
			value: element.printer_name,
			label: `${element.printer_name} (${element.printer_ip})`,
		});
	});
};

const loadPrivileges = async () => {
	let result: PGRESTResult = new PGRESTResult();

	result = await pgrestapi.get("privileges?order=name.asc");

	if (result.error) {
		showError(result.error.message);
		return;
	}

	availablePrivileges.value = [];

	result.data.forEach((element: any) => {
		availablePrivileges.value.push({
			key: element.name,
			label: element.name,
			disabled: false
		});
	});
};

</script>

<template>
	<div v-loading="isLoading">
		<el-form :model="data">

			<el-row :gutter="10" v-if="mainStore.loggedUser.user_type === UserType.Administrator">
				<el-col :span="12">
					<el-form-item label="Active">
						<el-switch v-model="data.active" :active-text="$t('components.switch.yes')"
							:inactive-text="$t('components.switch.no')" />
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="10">
				<el-col :span="12">
					<el-form-item label="Username">
						<el-input v-model="data.username" autocomplete="off"
							:disabled="mainstore.loggedUser.user_type !== 0" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Name">
						<el-input v-model="data.first_name" autocomplete="off"
							:disabled="mainstore.loggedUser.user_type !== 0" />
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="10">
				<el-col :span="12">
					<el-form-item label="Surname">
						<el-input v-model="data.last_name" autocomplete="off"
							:disabled="mainstore.loggedUser.user_type !== 0" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Company">
						<el-input v-model="data.company" autocomplete="off"
							:disabled="mainstore.loggedUser.user_type !== 0" />
					</el-form-item>
				</el-col>
			</el-row>
			<el-row v-if="mainStore.loggedUser.user_type === UserType.Administrator">
				<el-col>
					<el-form-item label="Change password on next login">
						<el-switch v-model="data.reset_password_on_login" :active-text="$t('components.switch.yes')"
							:inactive-text="$t('components.switch.no')" />
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="New Password">
						<el-input v-model="data.password" type="password" placeholder="Please input password"
							autocomplete="off" show-password />
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col :span="24">
					<el-form-item label="Confirm Password">
						<el-input v-model="datapass.passwordconfirm" type="password"
							placeholder="Please confirm your password" autocomplete="off" show-password />
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item label="E-mail">
						<el-input v-model="data.email" autocomplete="off"
							:disabled="mainstore.loggedUser.user_type !== 0" />
					</el-form-item>
				</el-col>
			</el-row>

			<el-form-item label="User Type">
				<el-select v-model="data.user_type" placeholder="Please Select User Type"
					:disabled="!props.allowTypeChange">
					<el-option label="Administrator" :value="UserType.Administrator" />
					<el-option label="Supervisor" :value="UserType.Supervisor" />
					<el-option label="Agent" :value="UserType.Agent" />
					<el-option label="Digitizer" :value="UserType.Digitizer" />
				</el-select>
			</el-form-item>
			<el-row :gutter="10" v-if="data.user_type === UserType.Supervisor">
				<el-col :span="12">
					<el-form-item label="Has digitization rights">
						<el-switch v-model="data.digitization" :active-text="$t('components.switch.yes')"
							:inactive-text="$t('components.switch.no')" />
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="10" v-if="data.digitization || data.user_type === UserType.Administrator">
				<el-col :span="12">
					<el-form-item label="Barcode Printer">
						<el-select v-model="data.assigned_printer" placeholder="Select printer">
							<el-option v-for="printer in printersList" :label="printer.label" :value="printer.value" />
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="10" v-if="mainStore.loggedUser.user_type === UserType.Administrator">
				<el-col :span="24">
					<el-form-item label="Privileges">
						<el-transfer v-model="data.privileges" :data="availablePrivileges" :titles="['Available', 'Assigned']" />
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item class="btn-wrapper-components">
						<el-button type="primary" @click="save">{{ $t('generic.save') }}</el-button>
						<el-button @click="close">{{ $t('generic.close') }}</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<style scoped></style>
