import User from "./DB_Entities/User";
import MenuEntry from "./DB_Entities/MenuEntry";
import APIError from "./APIError";
class LoginReply {
  user: User = new User();
  token: string = "";
  screens: Array<MenuEntry> = [];
  error?: APIError;
}

export default LoginReply;
