<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import RealEstate from "../classes/DB_Entities/RealEstate";
import Decision from "../classes/Decision";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import PGRESTResult from "../classes/PGRESTResult";
import AcceptAllPending from "./AcceptAllPending.vue";
import UserType from "../enums/UserType";
import { useValidation } from "../composables/validation";
import { useValueLists } from "../composables/valueLists";
import { useDataHelpers } from "../composables/dataHelpers";
import ConfigCountry from "../classes/DB_Entities/ConfigCountry";
import ConfigPrefecture from "../classes/DB_Entities/ConfigPrefecture";
import ISelectEntry from "../interfaces/ISelectEntry";
import ConfigPostalCode from "../classes/DB_Entities/ConfigPostalCode";
import Debtor from "../classes/DB_Entities/Debtor";

const props = defineProps<{
  row_id: number;
  view_only: boolean;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);
const {
  validationErrors,
  onValidationChange,
  checkValidationsForError,
} = useValidation();

const { OwnerShipTypeList, TypeofPropertyList } = useValueLists();
const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const dataHelpers = useDataHelpers();
const { t } = useI18n({ useScope: "global" });
const debtorsList = ref(new Array<any>());
const TypeofValuationList = ref<any>([]);
const UrbanPlanningViolationsList = ref<any>([]);
let countries = ref<ISelectEntry[]>([]);
let prefectures = ref<any>([]);
let postCodes = ref<any>([]);
let filteredPostCodes = ref<ISelectEntry[]>([]);

const {
  data,
  cleanObject,
  fullObj,
  hasPendingChanges,
  isLoading,
  table_name,
  clickAcceptAll,
  close,
  isNewEntity,
  loadData,
  resetObjects,
  save,
  setDisplayValues,
  updateDecision,
  showError,
  showSuccess,
  showWarning
} = useGenericMethodsVariables();

const loadRequiredData = async () => {
  await loadSelects();
  await loadDebtors();

  // Countries
  countries.value = await dataHelpers.getAllObjectsFromTableForSelect<ConfigCountry>(ConfigCountry, (dbObj: any) => dbObj.id, (dbObj: any) => dbObj.country);

  // Prefectures
  (await dataHelpers.getAllObjectsFromTable<ConfigPrefecture>(ConfigPrefecture)).forEach((dbObj: any) => {
    prefectures.value.push({
      value: dbObj.id,
      label: dbObj.prefecture,
      min_tk: dbObj.min_tk,
      max_tk: dbObj.max_tk,
    });
  })

  // Post Codes
  postCodes.value = await dataHelpers.getAllObjectsFromTableForSelect<ConfigPostalCode>(ConfigPostalCode, (dbObj: any) => dbObj.id, (dbObj: any) => dbObj.postalcode);
  filteredPostCodes.value = JSON.parse(JSON.stringify(postCodes.value));
};

onMounted(async () => {
  table_name.value = RealEstate.getTableNameStatic();
  cleanObject.value = new RealEstate();
  isLoading.value = true;
  console.log("real_estate_id", props.row_id);

  await loadRequiredData();

  if (props.row_id === -1) {
    resetObjects();
    isLoading.value = false;
    return;
  }

  await loadData(props.row_id);
  await fixPendingSelects();

  if(fullObj.value.district.originalValue){

    await filterPostCodes(fullObj.value.district.originalValue);

  }

  isLoading.value = false;
});

const loadDebtors = async () => {
  let result: PGRESTResult = new PGRESTResult();
  debtorsList.value = [];

  let query = `view_mosdebtors_view1?select=id,cid,fullname&approve_status=eq.1`;

  if (mainStore.loggedUser.user_type === UserType.Agent) {
    query += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
  } else if (mainStore.loggedUser.user_type === UserType.Supervisor) {
    query += `&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`;
  }

  result = await pgrestapi.get(query);

  if (result.error) {
    showError(result.error.message);
    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    debtorsList.value.push({
      value: result.data[i].id,
      label: result.data[i].cid + " (" + result.data[i].fullname + ")",
    });
  }
};

const onDecision = async (decision: Decision) => {
  isLoading.value = true;

  await updateDecision(decision);

  await loadData(props.row_id);

  isLoading.value = false;
};

const onPreSave = async () => {
  if (checkValidationsForError()) {
    showError("Please correct shown errors.");
    return;
  }

  if (isNewEntity() && fullObj.value.debtor_id.originalValue === 0) {
    showWarning(t("errors.missing_debtor"), 1500);
    return;
  }

  delete fullObj.value.debtor;

  await save();
};

const fixPendingSelects = async () => {
  for (let field of Object.keys(fullObj.value)) {
    if (fullObj.value[field].pendingValue === undefined) continue; // Skip field if no pending value

    if (field === "debtor_id") {
        setDisplayValues(Debtor, field, (dbObj: any) => { return `${dbObj.cid} (${dbObj.fullname})`; });
    }

    if (field === "eidos") {
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = TypeofPropertyList.value.find(
        (e: any) => e.value === fullObj.value[field].originalValue
      ).label;
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = TypeofPropertyList.value.find(
        (e: any) => e.value === fullObj.value[field].pendingValue.value
      ).label;
    }

    if (field === "eidos_idioktisias") {
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = OwnerShipTypeList.value.find(
        (e: any) => e.value === fullObj.value[field].originalValue
      )?.label;
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = OwnerShipTypeList.value.find(
        (e: any) => e.value === fullObj.value[field].pendingValue.value
      )?.label;
    }

    if (field === "poleod_parabaseis") {
      fullObj.value[field].pendingValue["originalDisplayValue"] =
        fullObj.value[field].originalValue === "true"
          ? t("global.true")
          : t("global.false");
      fullObj.value[field].pendingValue["pendingDisplayValue"] =
        fullObj.value[field].pendingValue.value === "true"
          ? t("global.true")
          : t("global.false");
    }
  }
};

const filterPostCodes = (val: any) => {
  let selectedPrefecture = prefectures.value.find((x: any) => x.value === val);

  if (selectedPrefecture === undefined || selectedPrefecture === '') {
    filteredPostCodes.value = JSON.parse(JSON.stringify(postCodes.value));
  } else {
    filteredPostCodes.value = postCodes.value.filter((x: any) => { return parseInt(x.label) >= selectedPrefecture?.min_tk && parseInt(x.label) <= selectedPrefecture?.max_tk });
    //fullObj.value.postalcode.originalValue = "";
  }
}

const setPrefecture = (val: any) => {
  let selectedPostCode = postCodes.value.find((x: any) => x.value === val);

  if (!selectedPostCode) return;

  let matchingPrefecture = prefectures.value.find((x: any) => x.min_tk <= parseInt(selectedPostCode.label) && x.max_tk >= parseInt(selectedPostCode.label));

  if (matchingPrefecture && fullObj.value.district.originalValue !== matchingPrefecture.value) {
    fullObj.value.district.originalValue = matchingPrefecture.value;
  }
}

const loadSelects = async () => {
  UrbanPlanningViolationsList.value = [
    {
      value: true,
      label: t("global.true"),
    },
    {
      value: false,
      label: t("global.false"),
    },
  ];

  TypeofValuationList.value = [
    {
      value: "full_inspection",
      label: "Full Inspection",
    },
    {
      value: "site_visit",
      label: "Site Visit",
    },
    {
      value: "drive_by",
      label: "Drive By",
    },
    {
      value: "desktop",
      label: "Desktop",
    },
    {
      value: "certified",
      label: "Certified",
    },
  ];
};

</script>

<template>
  <div v-loading="isLoading">
    <el-form>
      <AcceptAllPending v-show="hasPendingChanges && !props.view_only" @accept="clickAcceptAll(props.row_id)" />
      <p />

      <el-row>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.debtor_id" label="CID" :options="debtorsList" @decision="onDecision"
            :is-disabled="props.view_only" />
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.arithmos_akinitou_diax')"
            :object="fullObj.arithmos_akinitou_diax" @decision="onDecision"
            :validation-rules="[['^[0-9]+$', 'validation.realestate_nbr']]" @validation-change="onValidationChange"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.eidos" :label="$t('RealEstateDetails.eidos')"
            :options="TypeofPropertyList" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.address')" :object="fullObj.address"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.address_number')" :object="fullObj.address_number"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.region')" :object="fullObj.region"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.city')" :object="fullObj.city" @decision="onDecision"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <EntityField type="select" :label="$t('RealEstateDetails.district')" :object="fullObj.district"
              :options="prefectures" @decision="onDecision" :is-disabled="props.view_only"
              @select-change="filterPostCodes" @clear="filterPostCodes" />
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <EntityField type="select" :label="$t('RealEstateDetails.postalcode')" :object="fullObj.postalcode"
              :options="filteredPostCodes" @decision="onDecision" :is-disabled="props.view_only"
              @select-change="setPrefecture" />
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <EntityField type="select" :label="$t('RealEstateDetails.country')" :object="fullObj.country"
              :options="countries" @decision="onDecision" :is-disabled="props.view_only" />
          </el-col>
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.google_map_link')" :object="fullObj.google_map_link"
            @decision="onDecision" :is-disabled="props.view_only" :is-link="true" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.description')" :object="fullObj.description"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="datepicker" :label="$t('RealEstateDetails.constr_date')" :object="fullObj.constr_date"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.construction_status')"
            :object="fullObj.construction_status" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.eidos_idioktisias"
            :label="$t('RealEstateDetails.eidos_idioktisias')" :options="OwnerShipTypeList" @decision="onDecision"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.percentage_property')"
            :object="fullObj.percentage_property" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
      </el-row>
      <!-- </el-tab-pane>
                      <el-tab-pane label="Valuation" name="valuation"> -->
      <el-row :gutter="10">
        <el-col :span="24">
          <EntityField type="money" :label="$t('RealEstateDetails.antikeim_aksia_akin_rev')"
            :object="fullObj.antikeim_aksia_akin_rev" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="money" :label="$t('RealEstateDetails.liquidation_value')" :object="fullObj.liquidation_value"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="money" :label="$t('RealEstateDetails.ektim_aksias_akin')" :object="fullObj.ektim_aksias_akin"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.eidos_ektim" :label="$t('RealEstateDetails.eidos_ektim')"
            :options="TypeofValuationList" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="datepicker" :label="$t('RealEstateDetails.hmer_ektim')" :object="fullObj.hmer_ektim"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.ektimiths')" :object="fullObj.ektimiths"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.dikaiouxos')" :object="fullObj.dikaiouxos"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.kthmatolog_graf')" :object="fullObj.kthmatolog_graf"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.kaek')" :object="fullObj.kaek" @decision="onDecision"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.ypothikof')" :object="fullObj.ypothikof"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.tomos')" :object="fullObj.tomos" @decision="onDecision"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.fullo')" :object="fullObj.fullo" @decision="onDecision"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.number')" :object="fullObj.number"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.poleod_parabaseis"
            :label="$t('RealEstateDetails.poleod_parabaseis')" :options="UrbanPlanningViolationsList"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.ektim_kostos_taktop')"
            :object="fullObj.ektim_kostos_taktop" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.tm_oikopedou')" :object="fullObj.tm_oikopedou"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.tm_ktismatos')" :object="fullObj.tm_ktismatos"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.tm_apothikhs')" :object="fullObj.tm_apothikhs"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.tm_thesis_stathmeushs')"
            :object="fullObj.tm_thesis_stathmeushs" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.orofoi')" :object="fullObj.orofoi"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button v-if="!props.view_only" type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped></style>
