import ITableName from "../../interfaces/ITableName";

class ConfigCountry implements ITableName {
  id?: number = -1;
  country: string = "";

  static getTableNameStatic(): string {
    return "config_country";
  }

  getTableName(): string {
    return ConfigCountry.getTableNameStatic();
  }
}

export default ConfigCountry;
