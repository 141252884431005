import ITableName from "../../interfaces/ITableName";
import LoanAgreement from "./LoanAgreement";

class LoanAccount implements ITableName {
  id: number = -1;
  loan_agreement_id?: number;
  ar_trexontos_logariasmou?: string;
  ar_arxikou_logariasmou?: string;
  palaios_logariasmos?: string;
  currency?: string;
  product?: number;
  hmer_enarkshs?: string;
  arxikh_hmer_lukshs?: string;
  nea_hmer_lukshs?: string;
  hmer_ektam?: string;
  poso_ektam?: number;
  trexon_upol?: number;
  enhm_kefal: number = 0;
  kathister_kefal: number = 0;
  logistikop_tokoi?: number;
  sumbatikoi_log_tokoi?: number;
  yperhmerias_log_tokoi?: number;
  n2601_1998_log_tokoi?: number;
  mh_logistikop_tokoi?: number;
  sumbatikoi_mh_log_tokoi?: number;
  yperhmerias_mh_log_tokoi?: number;
  n2601_1998_mh_log_tokoi?: number;  
  eksoda?: number;
  diegram_poso?: number;
  dedoul_tokoi?: number;
  dedoul_tokoi_kath_kef?: number;
  dedoul_tokoi_kath_tok?: number;
  hmer_prwths_kathister?: string;
  hmeres_kathister?: number;
  kathgor_epitok_bashs?: number;
  epitok_bashs?: number;
  perithorio?: number;
  epitok_uperhm?: number;
  eisfora_n128_1975?: number;
  parakra_forou?: number;
  proskom_bebeos?: boolean;
  hmer_proskom?: string;
  hmerom_plhrom?: string;
  poso_plhrom?: number;
  kefal_pou_eksofl?: number;
  tokoi_pou_eksofl?: number;
  eksoda_pou_eksofl?: number;
  components_ofeilhs?: string;
  hmer_epomen_doshs?: string;
  kefal_epom_doshs?: number;
  tokos_epomen_doshs?: number;
  date_of_current_balance?: string;
  status?: string;
  synoliko_poso?: number;
  loan_capital_repayment_type?: number;
  loan_capital_repayment_frequency?: number;
  loan_capital_repayment_epeteiako?: boolean;
  loan_interest_repayment_frequency?: number;
  loan_interest_repayment_epeteiako?: boolean;
  loan_interest_change_frequency?: number;
  loan_interest_change_epeteiako?: boolean;
  hmer_enarxis_xaritos?: string;
  hmer_lixis_xaritos?: string;
  hmer_1is_dosis_tokon?: string;
  kathisterimeno?:boolean;
  kathisterimenokef?:number;
  kathisterimenotok?:number;
  kathisterimenodate?:string;
  kathisterimeno_enim_arx?:number;
  arnitika_epitokia?:boolean = false;
  loan_agreement: LoanAgreement = new LoanAgreement();

  static getTableNameStatic(): string {
    return "loan_accounts";
  }

  getTableName(): string {
    return LoanAccount.getTableNameStatic();
  }
}

export default LoanAccount;
