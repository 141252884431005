import ITableName from "../../interfaces/ITableName";

class ROB_Bond implements ITableName {
  id: number = -1;
  rob_title_id: string = "";
  start_bond_number: string = "";
  end_bond_number: string = "";
  nominal_value: string = "";
  issue_date: string = "";
  end_date: string = "";
  status: string = "";

  static getTableNameStatic(): string {
    return "rob_bonds";
  }

  getTableName(): string {
    return ROB_Bond.getTableNameStatic();
  }
}

export default ROB_Bond;
