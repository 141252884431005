<script lang="ts" setup>
import { ref, onMounted, watch } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import {
  Edit,
  Delete,
  Plus,
  RefreshLeft,
  Download,
  WarnTriangleFilled
} from "@element-plus/icons-vue";
import { useMOSAPIStore } from "../store/mos_api";
import { ElUpload, UploadFile, UploadFiles } from "element-plus";
import type { UploadInstance } from "element-plus";
import { useMainStore } from "../store/main";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { storeToRefs } from "pinia";
import UserType from "../enums/UserType";
import AddEdit_Apografi_Frontier from "../components/AddEdit_Apografi_Frontier.vue";
import { useSort } from "../composables/sort";

const genericVM = useGenericMethodsVariables();
const mosapi = useMOSAPIStore();
const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const uploadRef = ref<UploadInstance>();
const uploadUpdateRef = ref<UploadInstance>();
const { showError, showSuccess, isLoading, entityApprove } = useGenericMethodsVariables();
let dataList = ref([]);
let dataCount = ref(0);
let lastPage = ref(0);
let selectedRow = ref(-1);
let filter_borrower_id = ref<string>("");
let filter_borrower_name = ref<string>("");
let filter_asset_class = ref<string>("");
let filter_contract_id = ref<string>("");
let filter_loan_id_31_12_2021 = ref<string>("");
let filter_priority = ref<string>("");
let timer = ref<any>(null);
let addEditVisible = ref(false);
let selectPortfolioProtVisible = ref(false);
let fileSelected = ref(false);
let fileSelectedUpdate = ref(false);
let portfolios = ref<any>([]);
let selected_portfolioID=ref(-1);
let selected_portfolioUploadID=ref();
let firstPortfolioID=ref(-1);
let deleteDialogVisible = ref(false);
let entityName = ref("");
let DeleteSelectedRow = ref(0);

const users = ref<any>([]);
const { onSortChange, sortColumn, sortOrder } = useSort();
const { globalPortfolioID } = storeToRefs(mainStore);
const { globalDebtorID } = storeToRefs(mainStore);
const { globalLoanAgreementID } = storeToRefs(mainStore);
const currentDate = new Date();
const afterDate = new Date(2024,5,25,9,12,0,0);

function isInt(value) {
  return !isNaN(value) && 
         parseInt(Number(value)) == value && 
         !isNaN(parseInt(value, 10));
}

let showthem = ref(false);
if (afterDate <= currentDate) {
  showthem.value = true;
}
const loadPortfolios = async () => {
  let result: PGRESTResult = new PGRESTResult();

  let addedFilter = "";

  if (mainStore.loggedUser.user_type === UserType.Supervisor) {
    addedFilter += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
  }
  result = await pgrestapi.get("portfolio?digitization=eq.true" + addedFilter);
  portfolios.value = result.data;
  if(result.data.length>0){ 
    selected_portfolioID.value=portfolios.value[0].id;
    firstPortfolioID.value=portfolios.value[0].id;
  }
  // console.log(portfolios.value);
};
watch(globalPortfolioID, async () => {
  if (mainStore.loggedUser.username !== "")
    // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalDebtorID, async () => {
  if (mainStore.loggedUser.username !== "")
    // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== "")
    // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

const handleClickAdd = () => {
  selectedRow.value = -1;
  addEditVisible.value = true;
};

const handleClickEdit = (row_id: number) => {
  selectedRow.value = row_id;
  addEditVisible.value = true;
};

onMounted(async () => {
  await loadPortfolios();
  loadPage(1);  
  console.log("date: "+ currentDate)
  console.log("afterDate: "+ afterDate)
  console.log(showthem.value)
});

const loadPage = async (page: number) => {
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;

  let filterUrl = "";

  if (filter_borrower_id.value !== "") {
    filterUrl += `&borrower_id=ilike.*${filter_borrower_id.value}*`;
  }

  if (filter_borrower_name.value !== "") {
    filterUrl += `&borrower_name=ilike.*${filter_borrower_name.value}*`;
  }

  if (filter_asset_class.value !== "") {
    filterUrl += `&asset_class=ilike.*${filter_asset_class.value}*`;
  }


  if (filter_contract_id.value !== "") {
    filterUrl += `&contract_id=ilike.*${filter_contract_id.value}*`;
  }

  if (filter_loan_id_31_12_2021.value !== "") {
    filterUrl += `&loan_id_31_12_2021=ilike.*${filter_loan_id_31_12_2021.value}*`;
  }

  if (filter_priority.value !== "") {
    if (isInt(filter_priority.value)) {
    filterUrl += `&priority=eq.${filter_priority.value}`;
    }
    else {
    showError('Priority is not a number');    
    }
  }
  let result: PGRESTResult = new PGRESTResult();
  if (mainStore.loggedUser.user_type == 3) {    
    //filterUrl += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
    filterUrl += `&anathesi_mo_officer=eq.${mainStore.loggedUser.id}`;
    if (mainStore.globalPortfolioID !== "" && mainStore.globalPortfolioID !== undefined) {
      filterUrl = ",portfolio!inner(*)&portfolio.id=eq." + mainStore.globalPortfolioID + filterUrl;
    }
    result = await pgrestapi.getPaginated("apografi_frontier?select=*" + filterUrl, page);
  }
  else if (mainStore.loggedUser.user_type == 1 && mainStore.loggedUser.digitization) {
    if (mainStore.globalPortfolioID !== "" && mainStore.globalPortfolioID !==undefined) {
      filterUrl = "&portfolio.id=eq." + mainStore.globalPortfolioID + filterUrl;
    }
    result = await pgrestapi.getPaginated(`apografi_frontier?select=*,portfolio!inner(*)&portfolio.allowed_users=cs.{${mainStore.loggedUser.id}}` + filterUrl, page);
  }
  else if (mainStore.loggedUser.user_type == 0) {
    //ADMIN
    if (mainStore.globalPortfolioID !== "" && mainStore.globalPortfolioID !== undefined) {
      filterUrl = ",portfolio!inner(*)&portfolio.id=eq." + mainStore.globalPortfolioID + filterUrl;
    }
    result = await pgrestapi.getPaginated("apografi_frontier?select=*" + filterUrl, page);
  }
  

  if (result.error) {
    showError(result.error.message);
    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;

  isLoading.value = false;
};

const onSave = async () => {
  selectedRow.value = -1;
  //showSuccess("Digitized LA updated");
  addEditVisible.value = false;

  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  showError("Digitized LA failed to update");
  addEditVisible.value = false;
};

const loadUsers = async () => {
  let result = await pgrestapi.get("users?select=id,username&order=username");

  if (result.error) {
    showError(result.error.message);
    return;
  }

  users.value = result.data;
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const onCloseAddEdit = () => {
  addEditVisible.value = false;
  selectedRow.value = -1;
};

const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 500);
};


const uploadComplete = async (
  response: any,
  uploadFile: UploadFile,
  uploadFiles: UploadFiles
) => {
  console.log(response);
  loadPage(lastPage.value);
};

const addedFile = async (uploadFile: UploadFile, uploadFiles: UploadFiles) => {
  for (let file of uploadFiles) {
    console.log(file.status);
    if (file.status === "ready") {
      fileSelected.value = true;
    } else if (file.status === "success") {
      fileSelected.value = false;
      uploadRef.value!.clearFiles();
    }
  }
};

const addedFileUpdate = async (uploadFile: UploadFile, uploadFiles: UploadFiles) => {
  for (let file of uploadFiles) {
    console.log(file.status);
    if (file.status === "ready") {
      fileSelectedUpdate.value = true;
    } else if (file.status === "success") {
      fileSelectedUpdate.value = false;
      uploadUpdateRef.value!.clearFiles();
    }
  }
};

const removedFile = async () => {
  fileSelected.value = false;
  console.log("Removed file for upload.");
};

const removedFileUpdate = async () => {
  fileSelectedUpdate.value = false;
  console.log("Removed update file for upload.");
};

const onError = async () => {
  fileSelected.value = false;
  console.log("There was an error while uploading the file.");
};

const onErrorUpdate = async () => {
  fileSelectedUpdate.value = false;
  console.log("There was an error while uploading the update file.");
};

const submitUpload = async () => {
  uploadRef.value!.submit();
};


const submitUploadUpdate = async () => {
  uploadUpdateRef.value!.submit();
};

const DownloadExportProt = async (portfolio_id:any) => {
  console.log(portfolios.value[0].id);
  try {
       let res = await mosapi.getexportProtFrontier(portfolio_id);
       if (res !== "") {
         showError(res);
       }

     } catch (ex) {
       console.log(ex);
     }
     selected_portfolioID.value=firstPortfolioID.value;
     selectPortfolioProtVisible.value=false;
     return;
};

const onDelete = async (messageDuration: number = 2000) => {
        genericVM.showSuccess(`${entityName.value} deleted successfully`, messageDuration);
        deleteDialogVisible.value = false;
        loadPage(lastPage.value);
};

async function deleteApografiRow(rowId: number): Promise<void> {

    try {

        // Delete the parent record from d_debtor
        await pgrestapi.delete_data(`apografi_frontier?id=eq.${rowId}`);
        
        console.log(`Successfully deleted row`);
        
        onDelete();
    } catch (error) {
        console.error(`Error deleting records: ${error}`);
    }
}

const deleteHandleClick = async (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  const rowIdToDelete  = row_id;
  await deleteApografiRow(rowIdToDelete);
};


</script>

<template>
  <div class="content-wrapper" v-loading="isLoading" v-if="(
    (mainStore.loggedUser.user_type === UserType.Administrator) ||
    (mainStore.loggedUser.user_type === UserType.Supervisor && mainStore.loggedUser.digitization===true) ||
    (mainStore.loggedUser.user_type === UserType.Digitizer)
    )">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          {{ $t("Apografi_Frontier.project_name") }} ({{ dataCount }})
        </h3>
      </el-col>
    </el-row>

    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input
            v-model="filter_borrower_id"
            class="client-input"
            :placeholder="$t('Apografi_Frontier.borrower_id')"
            @input="filterChanged"
          />
        </el-col>
        <el-col :span="4">
          <el-input
            v-model="filter_borrower_name"
            class="client-input"
            :placeholder="$t('Apografi_Frontier.borrower_name')"
            @input="filterChanged"
          />
        </el-col>
        <el-col :span="4">
          <el-input
            v-model="filter_asset_class"
            class="client-input"
            :placeholder="$t('Apografi_Frontier.asset_class')"
            @input="filterChanged"
          />
        </el-col>
        <el-col :span="4">
          <el-input
            v-model="filter_contract_id"
            class="client-input"
            :placeholder="$t('Apografi_Frontier.contract_id')"
            @input="filterChanged"
          />
        </el-col>
        <el-col :span="4">
          <el-input
            v-model="filter_loan_id_31_12_2021"
            class="client-input"
            :placeholder="$t('Apografi_Frontier.loan_id_31_12_2021')"
            @input="filterChanged"
          />
        </el-col>
        <el-col :span="4">
          <el-input
            v-model="filter_priority"
            class="client-input"
            :placeholder="$t('Apografi_Frontier.priority')"
            @input="filterChanged"
          />
        </el-col>
      </el-row>
    </div>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn"> </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button
            link
            type="primary"
            size="small"
            class="btn-add"
            @click="loadPage(lastPage)"
            style="float: inline-end; margin: 2.5px"
            :icon="RefreshLeft"
          />
          <el-button
            link
            type="primary"
            size="small"
            class="btn-add"
            style="float: inline-end; margin: 2.5px"
            @click="handleClickAdd()"
            :icon="Plus"
          />
          <el-button 
          v-if="mainStore.loggedUser.user_type < 2 && showthem"
          link 
          type="primary" 
          class="btn-add" 
          size="small" 
          style="float: inline-end; margin: 2.5px" 
          @click="selectPortfolioProtVisible=true"
          :icon="Download"
          >{{$t('Apografi_Frontier.downloadexportprot')}}</el-button>
              
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="10">
      <el-table
        :data="dataList"
        stripe
        border
        table-layout="fixed"
        sortable="custom"
        @sort-change="localSortChange"
        size="small"
      >
        <el-table-column
          fixed
          sortable
          prop="borrower_id"
          :label="$t('Apografi_Frontier.borrower_id')"
        />
        <el-table-column
          fixed
          sortable
          prop="borrower_name"
          :label="$t('Apografi_Frontier.borrower_name')"
        />
        <el-table-column
          fixed
          sortable
          prop="asset_class"
          :label="$t('Apografi_Frontier.asset_class')"
        />
        <el-table-column
          fixed
          sortable
          prop="contract_id"
          :label="$t('Apografi_Frontier.contract_id')"
        />
        <el-table-column
          fixed
          sortable
          prop="loan_id_31_12_2021"
          :label="$t('Apografi_Frontier.loan_id_31_12_2021')"
        />
        <el-table-column fixed="right" :label="$t('global.operations')" width="280">
          <template #default="scope">
            <el-button
              link
              type="primary"
              size="small"
              class="btn-detail"
              @click="handleClickEdit(scope.row.id)"
              :icon="Edit"
            />
            <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator && showthem" link type="primary" size="small"
            class="btn-delete" @click="deleteDialogVisible = true; DeleteSelectedRow = scope.row.id;" :icon="Delete" />
            <el-tooltip :content="$t('global.ImportError')" v-if="scope.row.row_problem">
             <el-button type="danger" :icon="WarnTriangleFilled" circle />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- :action="`http://localhost:3000/digitized`" -->
<!-- action="https://mos-api.web-idea.gr/digitized" -->
    <el-row :gutter="10" style="margin-top: 15px;">

      <el-col style="border-width: 1px !important; border-color: black !important;" :span="8" v-if="((mainStore.loggedUser.user_type === UserType.Administrator) || (mainStore.loggedUser.user_type === UserType.Supervisor && mainStore.loggedUser.digitization===true)) && showthem">
        <span style="float: right;">Upload protocol updates</span>
        <el-upload
          style="float: inline-end; margin: 15.5px"
          ref="uploadUpdateRef"
          class="upload-demo-d"
          :action="`${mosapi.apiURL()}frontierUpdates`"
          :limit="1"
          :auto-upload="false"
          :on-success="uploadComplete"
          :headers="{ Authorization: 'Bearer ' + mosapi.token }"
          @change="addedFileUpdate"
          @remove="removedFileUpdate"
          @error="onErrorUpdate"
        >
          <template #trigger>
            <el-button type="primary">Select file</el-button>
          </template>
          <el-button
            v-if="fileSelectedUpdate"
            class="ml-3"
            type="success"
            @click="submitUploadUpdate"
          >
            Upload to server
          </el-button>
          <template #tip>
            <div class="el-upload__tip text-red">
              Limit 1 file, new file will overwrite the old file
            </div>
          </template>
        </el-upload>
      </el-col>

      <el-col :span="8"> </el-col>
      
      <el-col :span="8" v-if="(
    (mainStore.loggedUser.user_type === UserType.Administrator) ||
    (mainStore.loggedUser.user_type === UserType.Supervisor && mainStore.loggedUser.digitization===true)) && showthem">
    Select portfolio for upload:
        <el-select v-model="selected_portfolioUploadID" class="m-2 header-filter" size="small" clearable filterable placeholder=" ">
                  <el-option v-for="item in portfolios" :key="item.id" :label="item.portf_name"
                    :value="item.id" />
                </el-select>
        <el-upload
          :disabled="!(selected_portfolioUploadID !== undefined && selected_portfolioUploadID !== null && selected_portfolioUploadID !== '')"
          style="float: inline-end; margin: 15.5px"
          ref="uploadRef"
          class="upload-demo-d"
          :action="`${mosapi.apiURL()}digitizedFrontier/${selected_portfolioUploadID}`"
          :limit="1"
          :auto-upload="false"
          :on-success="uploadComplete"
          :headers="{ Authorization: 'Bearer ' + mosapi.token }"
          @change="addedFile"
          @remove="removedFile"
          @error="onError"
        >
          <template #trigger>
            <el-button type="primary">Select file</el-button>
          </template>
          <el-button
            v-if="fileSelected"
            class="ml-3"
            type="success"
            @click="submitUpload"
          >
            Upload to server
          </el-button>
          <template #tip>
            <div class="el-upload__tip text-red">
              Limit 1 file, new file will overwrite the old file
            </div>
          </template>
        </el-upload>
      </el-col>
    </el-row>

    <el-dialog
      v-if="addEditVisible"
      v-model="addEditVisible"
      @update:model-value="onCloseAddEdit"
      :title="selectedRow === -1 ? 'Add Frontier Indexing Row' : 'Frontier Indexing Row Edit'"
      width="50%"
    >
      <AddEdit_Apografi_Frontier
        @close="onCloseAddEdit"
        @save="onSave"
        @fail-save="onFailSave"
        :row_id="selectedRow"
      />
    </el-dialog>

    <el-dialog v-if="selectPortfolioProtVisible" v-model="selectPortfolioProtVisible" title="Select Portfolio" width="40%">
    <p>  {{ $t("Apografi_Frontier.Please_select") }}</p>
    <el-form :model="portfolios" ref="ruleFormRef">
      <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item :label="$t('Apografi_Frontier.portfolio')" prop="portfolio_id">
                <el-select v-model="selected_portfolioID" class="m-2 header-filter" size="small" clearable filterable placeholder=" ">
                  <el-option v-for="item in portfolios" :key="item.id" :label="item.portf_name"
                    :value="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
            </el-row>
    </el-form>
      <p />
      <el-button @click="selectPortfolioProtVisible = false;selected_portfolioID=firstPortfolioID">Back</el-button>
      <el-button @click="DownloadExportProt(selected_portfolioID)">Export</el-button>
      
    </el-dialog>

    <el-dialog v-if="deleteDialogVisible" v-model="deleteDialogVisible" title="Delete entry" width="40%">
        <span>Are you sure you want to delete this entry?</span>
        <template #footer #default="scope">
            <span class="dialog-footer">
                <el-button @click="deleteDialogVisible = false;">{{ $t('generic.cancel') }}</el-button>
                <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{ $t('generic.delete') }}
                </el-button>
            </span>
        </template>
    </el-dialog>

    <div>      

      <el-pagination
        size="small"
        layout=" prev, pager, next"
        :page-size="10"
        :total="dataCount"
        @current-change="loadPage"
      />
    </div>
  </div>
  <div v-else>
    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="6">
          {{ $t("Apografi_Frontier.permissions") }}
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<style scoped></style>
