<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import Project from "../classes/DB_Entities/Projects";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import PGRESTResult from "../classes/PGRESTResult";
import ProjectTask from "../classes/DB_Entities/ProjectTask";

const props = defineProps<{
	projectid: number;
	newEntity?: boolean;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);

const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });
let data = ref<Project>(new Project());
let projectsTemplatesList = ref<any>([]);

const {
	showError,
	isLoading,
	close,
} = useGenericMethodsVariables();

onMounted(async () => {
	isLoading.value = true;
	console.log("project", props.projectid, "newEntity", props.newEntity);

	// await getUsers();
	// await getTasks();
	await getProjectTemplates();

	if (props.newEntity === true) {
		data.value.project_template_id = null;
		isLoading.value = false;
		return;
	}

	await loadData();
	isLoading.value = false;
});

const onPreSave = async () => {
	//   console.log(JSON.stringify(fullObj.value));
	//VALIDATION CHECKS
	await save();
};

const getProjectTemplates = async () => {
	let result: PGRESTResult = new PGRESTResult();

	result = await pgrestapi.get("project_templates?status=eq.1");
	if (result.error) {
		showError(result.error.message);

		return;
	}

	projectsTemplatesList.value = result.data;
	//storeProjectTemplates.value = result.data;
	// console.log(
	//   "projectsTemplatesList: ",
	//   JSON.stringify(projectsTemplatesList.value)
	// )
};

const loadData = async () => {
	isLoading.value = true;

	let result: PGRESTResult = new PGRESTResult();

	result = await pgrestapi.get("projects?id=eq." + props.projectid);

	if (result.error) {
		showError(result.error.message);
		return;
	}

	data.value = result.data[0];

	isLoading.value = false;
};

const save = async () => {

	if (props.newEntity) {
		if (data.value.project_template_id == null) {
			showError("Please select a project template");
			return;
		}
		console.log('posting', JSON.stringify(data.value));
		let obj = JSON.parse(JSON.stringify(data.value));
		delete obj.id;
		if (obj.hmeromhnia_anathesis == null || obj.hmeromhnia_anathesis == "") {
			delete obj.hmeromhnia_anathesis;
		}
		console.log("obj.date" + obj.hmer_anatheshs)
		let result = await pgrestapi.post_data("projects", obj);
		console.log("result:" + result.data[0].id);

		//data.value.project_template_id
		//let objTask = new ProjectTask
		let objTasks = projectsTemplatesList.value.find((item: any) => { return item.id === data.value.project_template_id }).task_templates_ids;
		for (let i = 0; i < objTasks.length; i++) {
			let projectTask = await pgrestapi.post_data("project_tasks", {
				project_id: result.data[0].id,
				task_template_id: objTasks[i]
			});

			let taskTemplate = await pgrestapi.get("task_templates?id=eq." + objTasks[i])

			for (let field_id of taskTemplate.data[0].fields_ids) {
				let field = await pgrestapi.get("custom_fields?id=eq." + field_id);
				let projectTaskValue = await pgrestapi.post_data("project_task_values", {
					project_task_id: projectTask.data[0].id,
					task_variable: field.data[0].id,
					task_variable_value: ""
				})
			}
		}
	} else {
		console.log('patching', JSON.stringify(data.value));
		let result = await pgrestapi.patch("projects?id=eq." + props.projectid, {			
			customer: data.value.customer,
			customer_code: data.value.customer_code,
			hmeromhnia_anathesis: data.value.hmeromhnia_anathesis
		});
	}
	emits("save");
}

const filterMethod = (query: string, item: any) => {
	return item.label.toLowerCase().includes(query.toLowerCase())
}

</script>

<template>
	<div v-loading="isLoading">
		<el-form>
			<el-row :gutter="10">
				<el-col :span="12">
					<el-form-item :label="$t('ProjectsList.projectTemplate')">
						<el-select v-model="data.project_template_id as number" class="m-2 header-filter" size="small"
							clearable filterable :disabled=!props.newEntity placeholder=" ">
							<el-option v-for="item in projectsTemplatesList" :key="item.id" :label="item.name_el"
								:value="item.id" />
						</el-select>
					</el-form-item>					
					<el-form-item :label="$t('ProjectsList.customer')">
						<el-input v-model="data.customer" autocomplete="off" />
					</el-form-item>
					<el-form-item :label="$t('ProjectsList.customer_code')">
						<el-input v-model="data.customer_code" autocomplete="off" />
					</el-form-item>
					<el-form-item :label="$t('ProjectsList.hmer_anatheshs')">
						<el-date-picker v-model="data.hmeromhnia_anathesis" autocomplete="off" format="DD-MM-YYYY"
							value-format="YYYY-MM-DD" />
					</el-form-item>

				</el-col>



				<p></p><br><br><br><br><br>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item class="btn-wrapper-components">
						<el-button type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
						<el-button @click="close">{{ $t('generic.close') }}</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<style scoped>
.myCLASS .el-transfer-panel__filter {
	margin: 0px !important;
}

.el-transfer {
	--el-transfer-panel-width: 400px;
}
</style>
