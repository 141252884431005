<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import ProjectTemplate from "../classes/DB_Entities/ProjectTemplates";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import PGRESTResult from "../classes/PGRESTResult";

const props = defineProps<{
  projecttemplateid: number;
  newEntity?: boolean;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);

const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });
let data = ref<ProjectTemplate>(new ProjectTemplate());
let tasksList = ref<any>([]);
let usersList = ref<any>([]);

const {
  showError,
  isLoading,
  close,
} = useGenericMethodsVariables();

onMounted(async () => {
  isLoading.value = true;
  console.log("projecttemplateid", props.projecttemplateid, "newEntity", props.newEntity);

  await getUsers();
  await getTasks();

  if (props.newEntity === true) {
    isLoading.value = false;
    return;
  }

  await loadData();
  isLoading.value = false;
});

const onPreSave = async () => {
  //   console.log(JSON.stringify(fullObj.value));
  //VALIDATION CHECKS
  await save();
};

const getTasks = async () => {
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get("task_templates?status=eq.1");
  if (result.error) {
    showError(result.error.message);

    return;
  }

  tasksList.value = result.data;
  tasksList.value = tasksList.value.map((item: any) => {
    return {
      key: item.id,
      label: item.name_en,
      disabled: false
    }
  });
};

const getUsers = async () => {
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get("users?select=id,username,first_name,last_name&active=eq.true&user_type=gt.1&order=username.asc");
  if (result.error) {
    showError(result.error.message);

    return;
  }

  usersList.value = result.data;
  usersList.value = usersList.value.map((item: any) => {
    return {
      key: item.id,
      label: `${item.username} (${item.first_name} ${item.last_name})`,
      disabled: false
    }
  });
}

const loadData = async () => {
  isLoading.value = true;

  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get("project_templates?id=eq." + props.projecttemplateid);

  if (result.error) {
    showError(result.error.message);
    return;
  }

  data.value = result.data[0];

  isLoading.value = false;
};

const save = async () => {
  if (props.newEntity) {
    console.log('posting', JSON.stringify(data.value));
    let obj = JSON.parse(JSON.stringify(data.value));
    delete obj.id;
    let result = await pgrestapi.post_data("project_templates", obj);
  } else {
    console.log('patching', JSON.stringify(data.value));
    let result = await pgrestapi.patch("project_templates?id=eq." + props.projecttemplateid, {
      name_el: data.value.name_el,
      name_en: data.value.name_en,
      task_templates_ids: data.value.task_templates_ids,
      user_ids: data.value.user_ids
    });
  }
  emits("save");
}

const filterMethod = (query: string, item: any) => {
  return item.label.toLowerCase().includes(query.toLowerCase())
}

</script>

<template>
  <div v-loading="isLoading">
    <el-form>      
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item :label="$t('ProjectsTemplatesList.name_el')">
            <el-input v-model="data.name_el" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('ProjectsTemplatesList.name_en')">
            <el-input v-model="data.name_en" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item style="margin-top: 0px">
            <el-transfer 
              v-model="data.task_templates_ids"
              filterable
              :filter-method="filterMethod"
              filter-placeholder="Tasks"
              :data="tasksList"
              target-order="push"
              :titles="['Available Tasks', 'Selected Tasks']"
              class="myCLASS"
            />
          </el-form-item>
        </el-col>
        
        <el-col :span="24">
          <el-form-item style="margin-top: 0px">
            <el-transfer 
              v-model="data.user_ids"
              filterable
              :filter-method="filterMethod"
              filter-placeholder="Users"
              :data="usersList"
              target-order="push"
              :titles="['Available Users', 'Selected Users']"
              class="myCLASS"
            />
          </el-form-item>
        </el-col>

        <p></p><br><br><br><br><br>
      </el-row>
      <el-row :gutter="10">
      <el-col :span="24">
        <el-form-item class="btn-wrapper-components">
          <el-button type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
          <el-button @click="close">{{ $t('generic.close') }}</el-button>
        </el-form-item>
      </el-col>
    </el-row>
    </el-form>
  </div>
</template>

<style scoped>
.myCLASS .el-transfer-panel__filter {margin:0px !important;}
.el-transfer {
    --el-transfer-panel-width: 400px;
}
</style>
