<script lang="ts" setup>
import { ref, watch, onMounted, onBeforeMount } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import GreekStateGuarantees from "../classes/DB_Entities/GreekStateGuarantees";
import { ElMessage } from "element-plus";
import Decision from "../classes/Decision";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import PGRESTResult from "../classes/PGRESTResult";
import AcceptAllPending from "./AcceptAllPending.vue";

const props = defineProps<{
  greek_state_guarantee_id: number;
  newEntity?: boolean;
  view_only: boolean;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });

const loanAgreementsList = ref(new Array<any>());

const {
  updateDecision,
  hasPendingChanges,
  isLoading,
  fullObj,
  table_name,
  clickAcceptAll,
  loadData,
  save,
  close,
  cleanObject,
  resetObjects,
  showError
} = useGenericMethodsVariables();

onBeforeMount(() => {
  isLoading.value = true;
})

onMounted(async () => {
  isLoading.value = true;
  table_name.value = GreekStateGuarantees.getTableNameStatic();
  cleanObject.value = new GreekStateGuarantees();

  console.log(
    "greek_state_guarantee_id",
    props.greek_state_guarantee_id,
    "newEntity",
    props.newEntity
  );

  await loadLoanAgreements();

  if (props.newEntity === true) {
    resetObjects();
    isLoading.value = false;
    return;
  }

  await loadData(props.greek_state_guarantee_id);
  await fixPendingSelects();
  isLoading.value = false;
});

const loadLoanAgreements = async () => {
  let result: PGRESTResult = new PGRESTResult();
  loanAgreementsList.value = [];

  //result = await pgrestapi.get("loan_agreement?approve_status=eq.1");
  if (mainStore.loggedUser.user_type === 2) {
    result = await pgrestapi.get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1&allowed_users=cs.{${mainStore.loggedUser.id}}`);
  }
  if (mainStore.loggedUser.user_type === 1) {
    result = await pgrestapi.get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`);
  }
  if (mainStore.loggedUser.user_type === 0) {
    result = await pgrestapi.get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1`);
  }

  if (result.error) {
    showError(result.error.message);

    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    loanAgreementsList.value.push({
      value: result.data[i].id,
      label: result.data[i].ar_sumbashs,
    });
  }
};

const onDecision = async (decision: Decision) => {
  isLoading.value = true;

  await updateDecision(decision);

  await loadData(props.greek_state_guarantee_id);

  isLoading.value = false;
};

const onPreSave = async () => {
  if (props.newEntity as boolean) {
    if (fullObj.value.loan_agreement_id.originalValue === 0) {
      ElMessage({
        showClose: true,
        message: t("errors.missing_loan_agreement"),
        type: "warning",
        duration: 1500,
      });
      return;
    }
  }

  delete fullObj.value.loan_agreement;

  await save();
  resetObjects();
};

const ContractualDocumentList = [
  {
    value: true,
    label: t("global.true"),
  },
  {
    value: false,
    label: t("global.false"),
  },
];

const ContractServicingList = [
  {
    value: true,
    label: t("global.true"),
  },
  {
    value: false,
    label: t("global.false"),
  },
];

const fixPendingSelects = async () => {
  for (let field of Object.keys(fullObj.value)) {
    if (fullObj.value[field].pendingValue === undefined) continue;

    //LoanAgreement
    if (field === "loan_agreement_id") {
      if (fullObj.value[field].pendingValue.value) {
        let LoanAgreement = await pgrestapi.get(
          `loan_agreement?select=ar_sumbashs&id=eq.` +
          fullObj.value[field].pendingValue.value
        );
        fullObj.value[field].pendingValue["pendingDisplayValue"] =
          LoanAgreement.data[0].ar_sumbashs;

        let LoanAgreement2 = await pgrestapi.get(
          `loan_agreement?select=ar_sumbashs&id=eq.` + fullObj.value[field].originalValue
        );
        fullObj.value[field].pendingValue["originalDisplayValue"] =
          LoanAgreement2.data[0].ar_sumbashs;
      }
    }

    //Contractual Document || Contract Servicing
    if (field === "sumbatiko_eggrafo" || field === "epidosh_sumbas_se_dhmos_foreis") {
      if (fullObj.value[field].pendingValue.value) {
        fullObj.value[field].pendingValue["pendingDisplayValue"] =
          fullObj.value[field].pendingValue.value === "true"
            ? t("global.true")
            : t("global.false");
        fullObj.value[field].pendingValue["originalDisplayValue"] =
          fullObj.value[field].originalValue === "true"
            ? t("global.true")
            : t("global.false");
      }
    }
  }
};
</script>

<template>
  <div v-loading="isLoading">
    <el-form>
      <AcceptAllPending v-show="hasPendingChanges && !props.view_only"
        @accept="clickAcceptAll(props.greek_state_guarantee_id)" />
      <p />
      <el-row :gutter="10">
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.loan_agreement_id"
            :label="$t('GreekStateGuarantees.loan_agreement')" :options="loanAgreementsList"
            :is-disabled="props.view_only" @decision="onDecision" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('GreekStateGuarantees.kya_of_greek_state')"
            :object="fullObj.kua_paroxhs_eed" :is-disabled="props.view_only" @decision="onDecision" />
        </el-col>
        <el-col :span="24">
          <EntityField type="money" :label="$t('GreekStateGuarantees.guaranteed_amount')" :object="fullObj.poso_eggyhseos"
            :is-disabled="props.view_only" @decision="onDecision" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.sumbatiko_eggrafo"
            :label="$t('GreekStateGuarantees.contractual_document')" :options="ContractualDocumentList"
            :is-disabled="props.view_only" @decision="onDecision" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.epidosh_sumbas_se_dhmos_foreis"
            :label="$t('GreekStateGuarantees.contract_servicing')" :options="ContractServicingList"
            :is-disabled="props.view_only" @decision="onDecision" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('GreekStateGuarantees.monitoring_of_debt_25th')"
            :object="fullObj.parakolouth_ofilon" :is-disabled="props.view_only" @decision="onDecision" />
        </el-col>

        <el-col :span="24">
          <EntityField type="input" :label="$t('GreekStateGuarantees.delivery_of_comm_25th')"
            :object="fullObj.apodosh_prom" :is-disabled="props.view_only" @decision="onDecision" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('GreekStateGuarantees.claim_limitation_period')"
            :object="fullObj.prothesmia_paragraf_apet" :is-disabled="props.view_only" @decision="onDecision" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('GreekStateGuarantees.forfeiture_actions')"
            :object="fullObj.energies_katapt" :is-disabled="props.view_only" @decision="onDecision" />
        </el-col>
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button v-if="!props.view_only" type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped></style>
