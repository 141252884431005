<script lang="ts" setup>
import { ref, watch, onMounted, nextTick, reactive } from "vue";
import type { FormInstance } from "element-plus";
import { useRouter } from "vue-router";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import { Edit, Delete, Notebook } from "@element-plus/icons-vue";
import InsCompDetails from "../components/ConfigInsCompDetails.vue";
import UserType from "../enums/UserType";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
const { showError } = useGenericMethodsVariables();

const router = useRouter();
const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const formRef = ref<FormInstance>();
const tabPosition = ref("left");
const DeleteDialogVisible = ref(false);
let detailsVisible = ref(false);
let deleteVisible = ref(false);
let selectedRow = ref(0);
let DeleteSelectedRow = ref(0);
let dataList = ref([]);
let isLoading = ref(false);
let lastPage = ref(0);
let dataCount = ref(0);

const emits = defineEmits(["save", "close", "fail-save"]);

onMounted(async () => {
  loadPage(1);
});

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeleteInsuranceCompany(row_id);
};

const handleClick = (row_id: number) => {
  selectedRow.value = row_id;
  detailsVisible.value = true;
};

const DeleteInsuranceCompany = async (row_id: number) => {
  let result: PGRESTResult = new PGRESTResult();

  let id = JSON.stringify(row_id);

  result = await pgrestapi.delete_data("config_insurance_company?id=eq." + id);

  if (result.error) {
    showError(result.error.message);

    DeleteDialogVisible.value = false;
    return;
  }

  onDelete();
};

const onSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Insurance Company updated Successfully",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onInsert = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Insurance Company saved Successfully",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onDelete = async () => {
  ElMessage({
    showClose: true,
    message: "Insurance Company Deleted Successfully",
    type: "success",
    duration: 1000,
  });

  DeleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Insurance Company failed to update",
    type: "error",
    duration: 2000,
  });

  detailsVisible.value = false;
};

const dynamicValidateForm = reactive<{
  ins_comps: InsuranceCompanyItem[];
}>({
    ins_comps: [],
});

interface InsuranceCompanyItem {
  ins_comp: string;
}

const removeInsuranceCompany = (item: InsuranceCompanyItem) => {
  const index = dynamicValidateForm.ins_comps.indexOf(item);
  if (index !== -1) {
    dynamicValidateForm.ins_comps.splice(index, 1);
  }
};

const addInsuranceCompany = () => {
  dynamicValidateForm.ins_comps.push({
    ins_comp: "",
  });
};

const submitForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!");
    }
  });
};

const save = async () => {
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.post_data("config_insurance_company", dynamicValidateForm.ins_comps);

  if (result.error) {
    onFailSave();
    return;
  }
  dynamicValidateForm.ins_comps = [];
  onInsert();
  loadPage(1);
};

const loadPage = async (page: number) => {
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;

  let result: PGRESTResult = await pgrestapi.getPaginated(
    "config_insurance_company?order=ins_comp",
    page
  );

  if (result.error) {
    showError(result.error.message);

    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;

  isLoading.value = false;
};
</script>

<template>
  <div style="height: 100%" v-loading="isLoading">
    <el-row :gutter="10" style="margin: 20px">
      <el-col :span="24">
        <el-table :data="dataList" stripe border table-layout="fixed" size="small">
          <el-table-column fixed sortable prop="ins_comp" :label="$t('PlatformSettingsView.ins_comps')" type="text" />
          <el-table-column fixed="right" :label="$t('global.operations')">
            <template #default="scope">
              <el-button link type="primary" size="small" class="btn-detail" @click="handleClick(scope.row.id)">
                <el-icon>
                  <Edit />
                </el-icon>
              </el-button>
              <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary"
                size="small" class="btn-delete" @click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;">
                <el-icon>
                  <Delete />
                </el-icon>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div>
          <el-pagination size="small" layout=" prev, pager, next" :page-size="10" :total="dataCount"
            @current-change="loadPage" />
        </div>

        <el-dialog v-model="DeleteDialogVisible" title="Insurance Company Delete" width="50%">
          <span>{{ $t("PlatformSettingsView.inscomp_delete_message") }}</span>
          <template #footer #default="scope">
            <span class="dialog-footer">
              <el-button @click="DeleteDialogVisible = false">{{$t('generic.cancel')}}</el-button>
              <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{$t('generic.delete')}}
              </el-button>
            </span>
          </template>
        </el-dialog>

        <el-dialog v-model="detailsVisible" title="Insurance Company Details" width="50%">
          <InsCompDetails :inscompid="selectedRow" @save="onSave" @close="detailsVisible = false; selectedRow = -1;"
            @fail-save="onFailSave" />
        </el-dialog>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form ref="formRef" :model="dynamicValidateForm" label-width="220px">
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-form-item v-for="(inscomp, index) in dynamicValidateForm.ins_comps"
                    :label="$t('PlatformSettingsView.ins_comp') + ' ' + index" :prop="'ins_comp.' + index + '.value'"
                    style="border-bottom: 0px; margin-top: 30px">
                    <el-col :span="11">
                      <el-input :placeholder="$t('PlatformSettingsView.ins_comp')" v-model="inscomp.ins_comp"
                        autocomplete="off" style="border-bottom: 3px solid #e3e3e3" />
                    </el-col>
                    <el-col :span="2">
                      <el-button style="float: right" @click.prevent="removeInsuranceCompany(inscomp)">{{$t('generic.delete')}}
                      </el-button>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-form-item style="border-bottom: 0px; float: right; margin-left: 0px; margin-top: 30px;">
                    <el-button v-if="dynamicValidateForm.ins_comps.length != 0" type="primary"
                      @click="submitForm(formRef)">
                      {{ $t("generic.save") }}
                    </el-button>
                    <el-button @click="addInsuranceCompany">{{
                        $t("PlatformSettingsView.new_ins_comp")
                    }}</el-button>
                    <!-- <el-button @click="resetForm(formRef)">Reset</el-button> -->
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.info {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}
</style>
