<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { CircleCheck, Edit, Plus, HomeFilled, Delete, View } from "@element-plus/icons-vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import ROB_Participating_Bank from "../classes/DB_Entities/ROB_Participating_Bank";
import AddEdit_ROB_Bank from "./AddEdit_ROB_Bank.vue";
import { useI18n } from "vue-i18n";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import UserType from "../enums/UserType";

const { t } = useI18n({ useScope: "global" });

const props = defineProps({
  rob_id: Number,
  view_only: Boolean
});

let isLoading = ref(false);

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
let banksArray = ref<Array<ROB_Participating_Bank>>([]);
let showAddEditBank = ref<boolean>(false);
let editROBBankID = ref<number>(-1);
let newEntity = ref<boolean>(false);
let percentage_issue = ref<boolean>(false);
let percentagecount = ref<number>(0);
let showPortfolioError = ref(false);

const { showError, showSuccess } = useGenericMethodsVariables();

watch(
  () => props.rob_id,
  async (oldVal, newVal) => {
    await loadData();
  }
);

onMounted(async () => {
  await loadData();
});

const close = async () => {
  showAddEditBank.value = false;
  newEntity.value = false;
  editROBBankID.value = -1;
};

const onSave = async () => {
  showAddEditBank.value = false;
  newEntity.value = false;
  editROBBankID.value = -1;

  showSuccess("Entry updated");

  isLoading.value = true;
  await loadData();
  isLoading.value = false;
};

const loadData = async () => {
  console.log("rob_id: " + props.rob_id);

  if ((props.rob_id as any) > 0) {
    let pendingresult: PGRESTResult = await pgrestapi.get(
      "view_mosregisterofbonds_view1?select=pending_approvals_banks&id=eq." + props.rob_id
    );
  }

  if (props.rob_id === -1) return;

  isLoading.value = true;

  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get(
    "view_mosrob_participating_banks_view1?register_of_bond_id=eq." + props.rob_id
  );

  percentagecount.value = 0;
  let portfolio_account_count = 0;
  for (let bank of result.data) {
    percentagecount.value = percentagecount.value + bank.participation_percent;

    if (bank.portfolio_account === true)
      portfolio_account_count++;
  }
  //if (percentagecount.value > 100 || percentagecount.value < 0) {
  if (percentagecount.value !== 100) {
    percentage_issue.value = true;
  }
  else {
    percentage_issue.value = false;
  }

  showPortfolioError.value = portfolio_account_count > 1;

  if (result.error) {
    showError(result.error.message);

    isLoading.value = false;

    return;
  }

  banksArray.value = result.data;
  isLoading.value = false;
};

const addEditBank = async (row_id: number, isNewEntity: boolean) => {
  showAddEditBank.value = true;
  newEntity.value = isNewEntity;
  editROBBankID.value = row_id;
};

const deleteBank = async (bankId: number) => {
  if (!Number.isInteger(bankId)) {
    return;
  }

  try {
    await pgrestapi.delete_data("rob_participating_banks?id=eq." + bankId);
    await loadData();
  } catch (ex: any) {
    showError(ex);
    console.log(ex);
  }
}
</script>

<template>
  <el-row v-if="percentage_issue" class="el-tag el-tag--danger">{{ $t("errors.percentage_issue") }}: ({{
    percentagecount
  }}) </el-row>
  <el-row v-if="showPortfolioError" class="el-tag el-tag--danger">{{ $t("errors.portfolio_account_count_error") }}
  </el-row>
  <div v-loading="isLoading">
    <el-row>
      <el-col>
        <el-tooltip class="box-item" effect="dark" content="Add Bank/Servicer" placement="left">
          <el-button v-if="!props.view_only" style="float: right; margin: 10px" class="btn-add" size="large" :icon="Plus"
            @click="addEditBank(-1, true)">
            <el-icon :size="12">
              <HomeFilled />
            </el-icon>
          </el-button>
        </el-tooltip>
      </el-col>
      <el-col>
        <el-table :data="banksArray" stripe border table-layout="fixed">
          <el-table-column fixed prop="bank_name" :label="$t('RegisterOfBondsView.banks')" />
          <el-table-column fixed prop="participation_percent" :label="$t('RegisterOfBondsView.participating_percent')" />
          <el-table-column fixed :label="$t('RegisterOfBondsView.portfolio_account')">
            <template #default="scope">
              <el-icon v-if="scope.row.portfolio_account === true" color="green" :size="24">
                <CircleCheck />
              </el-icon>
            </template>
          </el-table-column>
          <el-table-column fixed prop="contact_details" :label="$t('RegisterOfBondsView.contact_details')" />
          <el-table-column fixed prop="iban" label="IBAN" />
          <el-table-column fixed prop="iban_bank" label="IBAN's Bank" />
          <el-table-column fixed prop="pending_approvals_banks" :label="$t('global.pending_approvals')" />
          <el-table-column fixed="right" :label="$t('global.operations')">
            <template #default="scope">
              <el-button link type="primary" size="small" class="btn-detail" @click="addEditBank(scope.row.id, false)"
                :icon="props.view_only ? View : Edit" />
              <el-popconfirm v-if="scope.row.id !== null && mainStore.loggedUser.user_type === UserType.Administrator"
                :title="`Are you sure you want to delete this Bank/Servicer?`" @confirm="deleteBank(scope.row.id)"
                width="350">
                <template #reference>
                  <el-button link type="danger" size="small" class="btn-detail btn-delete">
                    <el-icon>
                      <Delete />
                    </el-icon>
                  </el-button>
                  <!-- <el-icon color="red" :size="12" :class="{ 'red': scope.row.pending_approvals_titles > 0 }">
                      <Delete />
                    </el-icon> -->
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog v-model="showAddEditBank" :title="(newEntity ? 'Add' : 'Edit') + ' Bank/Servicer'" width="45%">
      <AddEdit_ROB_Bank :rob_id="props.rob_id as number" :rob_participating_bank_id="editROBBankID"
        :new-entity="newEntity" @save="onSave" @close="close" :view_only="props.view_only" />
    </el-dialog>
  </div>
</template>

<style scoped></style>
