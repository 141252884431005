import ITableName from "../../interfaces/ITableName";

class ProjectTemplates implements ITableName {
  id: number = -1;
  name_el: string = "";
  name_en: string = "";
  task_templates_ids: number[] = [];
  user_ids: number[] = [];
  status: number = 1;

  static getTableNameStatic(): string {
    return "project_templates";
  }

  getTableName(): string {
    return ProjectTemplates.getTableNameStatic();
  }
}

export default ProjectTemplates;
