import { ref, watch, onMounted } from 'vue';
import { useI18n } from "vue-i18n";

export function useValueLists() {
  const { t, locale } = useI18n({ useScope: "global" });
  const OwnerShipTypeList = ref<any>([]);
  const TypeofPropertyList = ref<any>([]);
  const CollateralTypeOptions = ref<any>([]);
  const LegalEntityList = ref<any>([]);
  const IDCardList = ref<any>([]);
  const contractStatusList = ref<any>([]);
  const TrueFalseList = ref<any>([]);
  const paymentTypeList= ref<any>([]);
 const TypeofPrenotationList= ref<any>([]);
 const NREcollateralTypeList = ref<any>([]);
  watch(
    () => locale.value,
    async (newVal, oldVal) => {
      reload();
    }
  );

  onMounted(async () => {
    reload();
  });

  const reload = () => {
    loadOwnerShipTypeList();
    loadTypeOfPropertyTypeList();
    loadCollateralTypeOptions();
    loadLegalEntityList();
    LoadIDCardList();
    LoadcontractStatusList();
    LoadTrueFalseList();
    LoadPaymentTypeList();
    LoadTypeofPrenotationList();
    LoadNREcollateralTypeList();
  }

  const loadOwnerShipTypeList = async () => {
    OwnerShipTypeList.value = [
      {
        value: "full_ownership",
        label: t("re_ownershiptype.full_ownership"),
      },
      {
        value: "bare_ownership",
        label: t("re_ownershiptype.bare_ownership"),
      },
      {
        value: "usufruct",
        label: t("re_ownershiptype.usufruct"),
      },
      {
        value: "right_of_residence_for_life",
        label: t("re_ownershiptype.right_of_residence_for_life"),
      },
      {
        value: "air_right",
        label: t("re_ownershiptype.air_right"),
      },
    ];
  };

  const loadTypeOfPropertyTypeList = async () => {
    TypeofPropertyList.value = [
      {
        value: "field",
        label: t("re_typeofproperty.field"),
      },
      {
        value: "commercial",
        label: t("re_typeofproperty.commercial"),
      },
      {
        value: "residential",
        label: t("re_typeofproperty.residential"),
      },
      {
        value: "office",
        label: t("re_typeofproperty.office"),
      },
      {
        value: "landplot",
        label: t("re_typeofproperty.landplot"),
      },
      {
        value: "industrial",
        label: t("re_typeofproperty.industrial"),
      },
      {
        value: "hotel",
        label: t("re_typeofproperty.hotel"),
      },
    ];
  }

  const loadCollateralTypeOptions = async () => {
    CollateralTypeOptions.value = [
      {
        value: "listed_shares",
        label: t("CollateralTypes.listed_shares"),
      },
      {
        value: "unlisted_shares",
        label: t("CollateralTypes.unlisted_shares"),
      },
      {
        value: "deposits",
        label: t("CollateralTypes.deposits"),
      },
      {
        value: "pledged_claims",
        label: t("CollateralTypes.pledged_claims"),
      },
      {
        value: "warehouse_receipt",
        label: t("CollateralTypes.warehouse_receipt"),
      },
      {
        value: "checks",
        label: t("CollateralTypes.checks"),
      },
      {
        value: "bill_of_exchange",
        label: t("CollateralTypes.bill_of_exchange"),
      },
      {
        value: "movable_collaterals",
        label: t("CollateralTypes.movable_collaterals"),
      },
    ];
  }

  const loadLegalEntityList = async () => {
    LegalEntityList.value = [
        {
            value: "legal_entity",
            label: t("DebtorDetailsView.legal_entity"),
        },
        {
            value: "individual",
            label: t("DebtorDetailsView.individual"),
        }
    ];
  };

  const LoadIDCardList = async () => {
    IDCardList.value = [
        {
            value: "id_card",
            label: t("DebtorDetailsView.id_card"),
        },
        {
          value: "passport",
          label: "Passport",
      },
    ];
  };

  const LoadcontractStatusList = async () => {
  contractStatusList.value = [
    {
      value: "performing",
      label: t("loan_agreement.performing"),
    },
    {
      value: "non_performing",
      label: t("loan_agreement.non_performing"),
    },
    {
      value: "denounced",
      label: t("loan_agreement.denounced"),
    },
  ];
  };

  const LoadTrueFalseList = async () => {
    TrueFalseList.value = [
      {
        value: true,
        label: "YES",
      },
      {
        value: false,
        label: "NO",
      },
    ];
    };
    const LoadPaymentTypeList = async () => {
    paymentTypeList.value = [
      {
        value: "xreolitiko",
        label: t("payment_type_list.xreolitiko"),
      },
      {
        value: "tokoxreolitiko",
        label: t("payment_type_list.tokoxreolitiko"),
      },
      {
        value: "balloon",
        label: t("payment_type_list.balloon"),
      },
    ];
  }
  const LoadTypeofPrenotationList = async () => {
  TypeofPrenotationList.value = [
    {
      value: "sunenetikh", // proshmeiwsh
      label: t("TypeofPrenotation.sunenetikh"),
    },
    {
      value: "anagkastikh", // proshmeiwsh
      label: t("TypeofPrenotation.anagkastikh"),
    },
    {
      value: "sunenetikh_ypothiki",
      label: t("TypeofPrenotation.sunenetikh_ypothiki"),
    },
    {
      value: "anagkastikh_ypothiki",
      label: t("TypeofPrenotation.anagkastikh_ypothiki"),
    },
  ];
}

const LoadNREcollateralTypeList = async () => {
  NREcollateralTypeList.value = [
    {
      value: "listed_shares",
      label: t("nre_collaterals_category.listed_shares"),
    },
    {
      value: "unlisted_shares",
      label: t("nre_collaterals_category.unlisted_shares"),
    },
    {
      value: "deposits",
      label: t("nre_collaterals_category.deposits"),
    },
    {
      value: "pledged_claims",
      label: t("nre_collaterals_category.pledged_claims"),
    },
    {
      value: "warehouse_receipt",
      label: t("nre_collaterals_category.warehouse_receipt"),
    },
    {
      value: "checks",
      label: t("nre_collaterals_category.checks"),
    },
    {
      value: "bill_of_exchange",
      label: t("nre_collaterals_category.bill_of_exchange"),
    },
    {
      value: "movable_collaterals",
      label: t("nre_collaterals_category.movable_collaterals"),
    },
  ];
}
  return { OwnerShipTypeList, TypeofPropertyList, CollateralTypeOptions, LegalEntityList, IDCardList, contractStatusList, TrueFalseList, paymentTypeList, TypeofPrenotationList, NREcollateralTypeList };
}