import { usePGRESTAPIStore } from "../store/pgrest_api";
import { Newable } from "../classes/Newable";
import ITableName from "../interfaces/ITableName";
import ConfigCountry from "../classes/DB_Entities/ConfigCountry";
import ConfigPrefecture from "../classes/DB_Entities/ConfigPrefecture";
import ISelectEntry from "../interfaces/ISelectEntry";

export function useDataHelpers() {

  const pgrestapi = usePGRESTAPIStore();

  const getObjectFromTableWithID = async <T extends ITableName>(table: Newable<T>, id: number): Promise<T> => {
    let obj = new table();
    let res = await pgrestapi.get(`${obj.getTableName()}?id=eq.${id}`);

    if (res.data.length > 0) {
      return res.data[0];
    }

    return obj;
  }

  const getAllObjectsFromTable = async <T extends ITableName>(table: Newable<T>): Promise<T[]> => {
    let obj = new table();
    let res = await pgrestapi.get(`${obj.getTableName()}`);

    return res.data;
  }

  const getAllObjectsFromTableForSelect = async <T extends ITableName>(table: Newable<T>, valuePicker: (dbObj: any) => string, labelPicker: (dbObj: any) => string): Promise<ISelectEntry[]> => {
    let obj = new table();
    let res = await pgrestapi.get(`${obj.getTableName()}`);
    let fv: ISelectEntry[] = [];

    for (let i = 0; i < res.data.length; i++) {
      fv.push({
        value: valuePicker(res.data[i]),
        label: labelPicker(res.data[i])
      });
    }

    return fv;
  }

  const deleteEntityFromTableWithID = async (table: string, id: number): Promise<boolean> => {
    let res = await pgrestapi.delete_data(`${table}?id=eq.${id}`);

    if (res.error) {
      return false;
    }

    return true;
  }

  return {
    deleteEntityFromTableWithID,
    getAllObjectsFromTable,
    getAllObjectsFromTableForSelect,
    getObjectFromTableWithID,
  };
}
