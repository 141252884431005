import ITableName from "../../interfaces/ITableName";

class ConfigInsComp implements ITableName {
  id?: number = -1;
  ins_comp: string = "";

  static getTableNameStatic(): string {
    return "config_insurance_company";
  }

  getTableName(): string {
    return ConfigInsComp.getTableNameStatic();
  }
}

export default ConfigInsComp;
