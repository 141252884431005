import ITableName from "../../interfaces/ITableName";

class Pliromi implements ITableName {
  id: number = -1;
  loan_account_id: string = "";
  hmer: string = "";
  poso: string = "";
  kefalaio: string = "";
  tokoi: string = "";
  exoda: string = "";
  proskomisi_vevaiosis: boolean = false;
  hmer_proskomisis: string = "";


  static getTableNameStatic(): string {
    return "sunallages";
  }

  getTableName(): string {
    return Pliromi.getTableNameStatic();
  }
}

export default Pliromi;
