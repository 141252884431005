enum DebitCreditType {
    Credit = 1,
    Debit = 2,
    Installment = 3,
    InterestTypeChanges = 4,
    Exoda = 5,
    TokoiTokwn= 6,
    HmerTokou = 7,
}

export default DebitCreditType;