<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import LoanAgreementInterest from "../classes/DB_Entities/EktokismosLoanAgreement";
import { ElMessage } from "element-plus";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import PGRESTResult from "../classes/PGRESTResult";
import { useValidation } from "../composables/validation";
import { computed } from "vue";
import { DateTime } from "luxon";

const props = defineProps<{
  loan_agreement_id: string;
  interestid: number;
  newEntity?: boolean;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);
const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });
const data = ref<any>({
  loan_agreement_id: null,
  base_rate_value: null,
  margin: null,
  levy: null,
  total_due_rate: null,
  overdue_rate: null,
  total_rate: null,
  participating_bank_id: null,
  due_principal: null,
  interest_on_due_principal: null,
  overdue_principal: null,
  overdue_interest: null,
  interest_on_overdue_amount: null,
  start_date: null,
  end_date: null,
  year_base: null,
});
let isKOD = ref<boolean>(false);
let originalStartDate = ref<string>("");

const resetData = async () => {
  data.value.loan_agreement_id = props.loan_agreement_id;
  data.value.base_rate_value = null;
  data.value.margin = null;
  data.value.levy = null;
  data.value.total_due_rate = null;
  data.value.overdue_rate = null;
  data.value.total_rate = null;
  data.value.participating_bank_id = null;
  data.value.due_principal = null;
  data.value.interest_on_due_principal = null;
  data.value.overdue_principal = null;
  data.value.overdue_interest = null;
  data.value.interest_on_overdue_amount = null;
  data.value.start_date = null;
  data.value.end_date = null;
  data.value.year_base = null;
  data.value.year_base = 0.0;
};

const {
  isLoading,
  fullObj,
  table_name,
  loadData,
  close,
  cleanObject,
  resetObjects,
  showError,
} = useGenericMethodsVariables();

const {
  validationErrors,
  onValidationChange,
  checkValidationsForError,
} = useValidation();

const imeres = computed(() => {
  if (data.value.start_date !== null && data.value.end_date !== null) {
    let start_date = DateTime.fromFormat(data.value.start_date as any, "yyyy-MM-dd");
    let end_date = DateTime.fromFormat(data.value.end_date as any, "yyyy-MM-dd");
    return end_date.diff(start_date, "days").toObject().days as number;
  } else return "";
});
/*const epitokio = computed(() => {
  // console.log('loan agreement margin:' + data.value.margin)
  data.value.base_rate_value =
    parseFloat(data.value.base_rate_value) ;
  // data.value.base_rate_value =
  //   parseFloat(data.value.total_due_rate) - parseFloat(data.value.margin);
  // console.log(Math.round(data.value.base_rate_value * 100) / 100);
  //data.value.base_rate_value = parseFloat(data.value.base_rate_value).toPrecision(15) / 1;
  data.value.base_rate_value =
    Math.round(data.value.base_rate_value * 10000000) / 10000000;
  return data.value.base_rate_value;
});*/
const total_rate = computed(() => {
  data.value.total_rate =
    parseFloat(data.value.base_rate_value) + parseFloat(data.value.overdue_rate) + parseFloat(data.value.margin);
  // console.log(Math.round(data.value.base_rate_value * 100) / 100);
  data.value.total_rate = Math.round(data.value.total_rate * 10000) / 10000;
  return data.value.total_rate;
});

const tokoi_enimerou = computed(() => {
  let apotelesma: number;
  let kefalaio: number;
  let epitokio: number;
  let paronomastis: number;
  if (data.value.year_base) {
    paronomastis = data.value.year_base;
  } else {
    paronomastis = 360;
  }

  kefalaio = parseFloat(data.value.due_principal);
  epitokio = (parseFloat(data.value.base_rate_value)+parseFloat(data.value.margin)+ parseFloat(data.value.levy))/100;
  epitokio = Math.round(epitokio * 1000000) / 1000000;
  // console.log("kefalaio: " + kefalaio);
  // console.log("epitokio: " + epitokio);
  // console.log("imeres: " + imeres.value);
  // console.log("paronomastis: " + paronomastis);

  // console.log('kefalaio' + enimerokef.text);
  // console.log('epitokio' + epitokio);
  apotelesma = (kefalaio * epitokio * (imeres.value as any)) / paronomastis;
  apotelesma = Math.round(apotelesma * 100) / 100;
   console.log('apotelesma' + apotelesma);
  data.value.total_due_rate = parseFloat(data.value.base_rate_value)+parseFloat(data.value.margin);
  data.value.total_due_rate = Math.round( data.value.total_due_rate * 100000) / 100000;
  if (isNaN(apotelesma)) {
    return "";
  } else {
    if (apotelesma >= 0) {
      data.value.interest_on_due_principal = apotelesma;
      // console.log(data.value.interest_on_due_principal)
      return apotelesma.toFixed(2);
    } else return "";
  }
});

const tokoi_kathisterimenis = computed(() => {
  let apotelesma: number;
  let kkefalaio: number;
  let ktokoi: number;
  let epitokio: number;
  let paronomastis: number;
  if (data.value.year_base) {
    paronomastis = data.value.year_base;
  } else {
    paronomastis = 360;
  }

  kkefalaio = parseFloat(data.value.overdue_principal);

  ktokoi = parseFloat(data.value.overdue_interest);
  // console.log("kkefalaio: " + kkefalaio);
  // console.log("ktokoi: " + ktokoi);
  if (isNaN(kkefalaio)) {
    kkefalaio = 0;
  }
  if (isNaN(ktokoi)) {
    ktokoi = 0;
  }
  // console.log("data.value.base_rate_value: " + data.value.base_rate_value);
  // console.log("data.value.overdue_rate: " + data.value.overdue_rate);
  epitokio = (parseFloat(data.value.base_rate_value) + parseFloat(data.value.overdue_rate)+ parseFloat(data.value.margin)+ parseFloat(data.value.levy))/100;
  //console.log("epitokio: " + epitokio);
  epitokio = Math.round(epitokio * 10000000) / 10000000;
  // console.log("data.value.total_due_rate: " + data.value.total_due_rate);
  // console.log("data.value.margin: " + data.value.margin);
  // console.log("epitokio: " + epitokio);
  // console.log("imeres: " + imeres.value);
  // console.log("paronomastis: " + paronomastis);
  apotelesma = ((kkefalaio + ktokoi) * epitokio * (imeres.value as any)) / paronomastis;
  apotelesma = Math.round(apotelesma * 100) / 100;
  // console.log("apotelesma: " + apotelesma);
  // console.log("apotelesma kath");
  // console.log(apotelesma);
  if (isNaN(apotelesma)) {
    return "";
  } else {
    if (apotelesma >= 0) {
      data.value.interest_on_overdue_amount = apotelesma;
      return apotelesma.toFixed(2);
    } else return "";
  }
});

const save = async () => {
  let obj = JSON.parse(JSON.stringify(data.value));
  let result: PGRESTResult = new PGRESTResult();

  // console.log("obj");
  //  console.log('props.interestid');
  //  console.log(props.interestid);
  if (obj.loan_agreement_id === null) {
    return;
  }
  delete obj.total_rate;

  if (props.interestid < 0) {
    if (!isKOD.value) {
      // Single entry if not KOD
      // console.log('obj !iskod')
      // console.log(JSON.stringify(obj))
      result = await pgrestapi.post_data("ektokismoi_loan_agreement", obj);
    } else {
      console.log('obj else')
      // KOD entries
      let banks = await pgrestapi.get(
        "register_of_bonds?select=rob_participating_banks!inner(id,bank_name,participation_percent)&loan_agreement_id=eq." +
          props.loan_agreement_id
      );

      if (banks.data.length === 0) {
        showError("No banks in Register of Bonds for this Loan Agreement", 1500);
        return;
      }
      console.log('banksdata')
      console.log(JSON.stringify(banks.data));
      for (let i = 0; i < banks.data[0].rob_participating_banks.length; ++i) {
        let bank = banks.data[0].rob_participating_banks[i];
        let objCopy = JSON.parse(JSON.stringify(obj));

        objCopy.participating_bank_id = bank.id;
        objCopy.bank_percent = bank.participation_percent;
        objCopy.interest_on_due_principal =
          Math.round(
            objCopy.interest_on_due_principal * (bank.participation_percent / 100) * 10000
          ) / 10000;
        objCopy.interest_on_overdue_amount =
          Math.round(
            objCopy.interest_on_overdue_amount *
              (bank.participation_percent / 100) *
              10000
          ) / 10000;
        // console.log(JSON.stringify(objCopy));

        result = await pgrestapi.post_data("ektokismoi_loan_agreement", objCopy);
        console.log('result');
        console.log(JSON.stringify(result));
      }
    }
  } else {
    if (!isKOD.value) {
      console.log('!iskod')
      console.log(data.value.interest_on_due_principal)
      console.log('HERE*****************22222')
      result = await pgrestapi.patch(
        "ektokismoi_loan_agreement?id=eq." + props.interestid,
        {
          base_rate_value: data.value.base_rate_value,
          total_due_rate: data.value.total_due_rate,
          participating_bank_id: data.value.participating_bank_id,
          due_principal: data.value.due_principal,
          interest_on_due_principal: data.value.interest_on_due_principal,
          overdue_principal: data.value.overdue_principal,
          overdue_interest: data.value.overdue_interest,
          interest_on_overdue_amount: data.value.interest_on_overdue_amount,
          start_date: data.value.start_date,
          end_date: data.value.end_date,
          levy: data.value.levy,
        }
      );
    } else {
      if (originalStartDate.value === undefined) return;

      // console.log('originalStartDate.value');
      // console.log(originalStartDate.value);
      // // result = await pgrestapi.get(
      // //   `ektokismoi_loan_agreement?loan_agreement_id=eq.${props.loan_agreement_id}&start_date=eq.${originalStartDate.value}`
      // // );
      // console.log('HERE*****************')
      // console.log(JSON.stringify(result.data));
      // console.log(result.data.length);
      // for (let i = 0; i < result.data.length; ++i) {
      //   let entry = result.data[i];

      //   console.log("updating", entry.id, `(${i})`);
      //   console.log('else')
      // console.log(data.value.interest_on_due_principal)
      //   let resultInner = await pgrestapi.patch(
      //     "ektokismoi_loan_agreement?id=eq." + entry.id,
      //     {
      //       base_rate_value: data.value.base_rate_value,
      //       total_due_rate: data.value.total_due_rate,
      //       participating_bank_id: entry.participating_bank_id,
      //       due_principal: data.value.due_principal,
      //       interest_on_due_principal:
      //         Math.round(
      //           data.value.interest_on_due_principal * (entry.bank_percent / 100) * 10000
      //         ) / 10000,
      //       overdue_principal: data.value.overdue_principal,
      //       overdue_interest: data.value.overdue_interest,
      //       interest_on_overdue_amount:
      //         Math.round(
      //           data.value.interest_on_overdue_amount * (entry.bank_percent / 100) * 10000
      //         ) / 10000,
      //       start_date: data.value.start_date,
      //       end_date: data.value.end_date,
      //       levy: data.value.levy,
      //     }
      //   );
      //   console.log(JSON.stringify(resultInner));

      /*Αυτο patchαρει ολες τις εγγραφες για ολες τις τραπεζες*/
      // }
      result = await pgrestapi.get(
        `ektokismoi_loan_agreement?id=eq.${props.interestid}`
      );
      for (let i = 0; i < 1; ++i) {
        let entry = result.data[i];
        
        let resultInner = await pgrestapi.patch(
          "ektokismoi_loan_agreement?id=eq." + entry.id,
          {
            base_rate_value: data.value.base_rate_value,
            total_due_rate: data.value.total_due_rate,
            participating_bank_id: entry.participating_bank_id,
            due_principal: data.value.due_principal,
            interest_on_due_principal:
              Math.round(
                data.value.interest_on_due_principal * (entry.bank_percent / 100) * 10000
              ) / 10000,
            overdue_principal: data.value.overdue_principal,
            overdue_interest: data.value.overdue_interest,
            interest_on_overdue_amount:
              Math.round(
                data.value.interest_on_overdue_amount * (entry.bank_percent / 100) * 10000
              ) / 10000,
            start_date: data.value.start_date,
            end_date: data.value.end_date,
            levy: data.value.levy,
          }
        );
        console.log(JSON.stringify(resultInner));
      }

      console.log("hello");
    }
  }

  if (result.error) {
    showError(result.error.message); // emits("fail-save");
    return;
  }
  resetData();
  emits("save");
};

const checkKOD = async () => {
  let kod_ids = await pgrestapi.get("config_product?type=eq.summary_check_KOD&select=id");

  let kods = [];
  for (let i = 0; i < kod_ids.data.length; ++i) {
    kods.push(kod_ids.data[i].id);
  }

  if (kods.length === 0) {
    isKOD.value = false;
    return;
  }

  // if we have at least one result from the query, it's a KOD entry
  let res = await pgrestapi.get(
    `loan_agreement?id=eq.${props.loan_agreement_id}&product=in.(${kods.join(",")})`
  );

  isKOD.value = res.data.length > 0;
};

const loadloanAgreementFields = async () => {
  // console.log('props.interestid')
  // console.log(props.interestid)
  let result2: PGRESTResult = new PGRESTResult();
  result2 = await pgrestapi.get(
    "loan_agreement?select=*&id=eq." + props.loan_agreement_id
  );

  if (props.newEntity) {
    //console.log("NEW***");
    data.value.margin = result2.data[0].perithorio;
    data.value.overdue_rate = result2.data[0].epitok_uperhm;
    data.value.year_base = result2.data[0].vasi_ektokismou;
    data.value.levy = result2.data[0].eisfora_n128_1975;
    console.log('result2.data[0]');
    console.log(result2.data[0]);
  }
};
const loadInterest = async () => {
  let result: PGRESTResult = new PGRESTResult();
  result = await pgrestapi.get(
    "ektokismoi_loan_agreement?select=*&id=eq." + props.interestid
  );
  if (result.error) {
    showError(result.error.message);

    return;
  }
  if (!props.newEntity) {
    data.value.margin = result.data[0].margin;
    data.value.overdue_rate = result.data[0].overdue_rate;
    data.value.year_base = result.data[0].year_base;
    data.value.levy = result.data[0].levy;
    console.log('result.data[0]')
    console.log(result.data[0])
  }
  data.value.base_rate_value = result.data[0].base_rate_value;
  data.value.total_due_rate = result.data[0].total_due_rate;
  // data.value.total_rate = result.data[0].total_rate;
  data.value.participating_bank_id = result.data[0].participating_bank_id;
  data.value.due_principal = result.data[0].due_principal;
  data.value.interest_on_due_principal = result.data[0].interest_on_due_principal;
  data.value.overdue_principal = result.data[0].overdue_principal;
  data.value.overdue_interest = result.data[0].overdue_interest;
  data.value.interest_on_overdue_amount = result.data[0].interest_on_overdue_amount;
  data.value.start_date = result.data[0].start_date;
  data.value.end_date = result.data[0].end_date;
};

onMounted(async () => {
  await loadloanAgreementFields();

  data.value.loan_agreement_id = props.loan_agreement_id;
  if (props.interestid > 0) {
    await loadInterest();
  }
  table_name.value = LoanAgreementInterest.getTableNameStatic();
  cleanObject.value = new LoanAgreementInterest();

  await checkKOD();

  if (props.newEntity === true) {
    resetObjects();
    return;
  }

  isLoading.value = true;
  await loadData(props.interestid);
  originalStartDate.value = fullObj.value?.start_date?.originalValue;
  isLoading.value = false;
});

const onPreSave = async () => {
  if (checkValidationsForError()) {
    showError("Please correct shown errors.");
    return;
  }

  await save();
  resetObjects();
};
</script>

<template>
  <span style="display: none"
    > {{ total_rate }}, {{ tokoi_enimerou }}, {{ tokoi_kathisterimenis }}
    <p />
    {{ isKOD }}
  </span>
  <div v-loading="isLoading">
    <el-form :model="data" ref="ruleFormRef">
      <!-- {{ $t('payments.loan_account') }}: {{ data.loan_account_id }} -->
      <el-row :gutter="10">
        <el-col :span="8">
          <span v-if="data.poso === null || data.poso === ''">{{
            $t("errors.missing_field_poso")
          }}</span>

          <el-form-item :label="$t('LoanAgreementEktokismosView.start_date')">
            <el-date-picker
              v-model="data.start_date"
              autocomplete="off"
              format="DD-MM-YYYY"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
          {{ $t("LoanAgreementDetails.vasi_ektokismou") }} : {{ data.year_base }}
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('LoanAgreementEktokismosView.end_date')">
            <el-date-picker
              v-model="data.end_date"
              autocomplete="off"
              format="DD-MM-YYYY"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
          {{ t("LoanAgreementEktokismosView.imeres") }}: {{ imeres }}
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('LoanAgreementEktokismosView.base_rate_value')">
            <el-input v-model="data.base_rate_value" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('LoanAgreementEktokismosView.due_principal')">
            <el-input v-model="data.due_principal" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('LoanAgreementEktokismosView.overdue_principal')">
            <el-input v-model="data.overdue_principal" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('LoanAgreementEktokismosView.overdue_interest')">
            <el-input v-model="data.overdue_interest" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('LoanAgreementEktokismosView.total_due_rate')">
            <el-input v-model="data.total_due_rate" autocomplete="off" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('LoanAgreementEktokismosView.margin')">
            <el-input v-model="data.margin" autocomplete="off" disabled />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="$t('LoanAgreementEktokismosView.overdue_rate')">
            <el-input v-model="data.overdue_rate" autocomplete="off" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('LoanAgreementEktokismosView.total_rate')">
            <el-input v-model="data.total_rate" autocomplete="off" disabled />
          </el-form-item>
        </el-col>
        
        <!-- <el-col :span="8">
          <el-form-item :label="$t('LoanAgreementEktokismosView.participating_bank_id')">
            <el-input v-model="data.participating_bank_id" autocomplete="off" />
          </el-form-item>
        </el-col> -->

        <el-col :span="8">
          <el-form-item
            :label="$t('LoanAgreementEktokismosView.interest_on_due_principal')"
          >
            <el-input
              v-model="data.interest_on_due_principal"
              autocomplete="off"
              disabled
            />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item
            :label="$t('LoanAgreementEktokismosView.interest_on_overdue_amount')"
          >
            <el-input
              v-model="data.interest_on_overdue_amount"
              autocomplete="off"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('LoanAccountDetails.levy_N128/75')">
            <el-input v-model="data.levy" autocomplete="off" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <!-- <el-select clearable v-model="data.year_base" class="m-2 client-input" placeholder="Year Base Rate"
            value-key="id" style="width: 100%" disabled>
            <el-option v-for="item in year_base_rates" :key="item.value" :label="item.label" :value="item.value" />
          </el-select> -->
        </el-col>
      </el-row>
    </el-form>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form-item class="btn-wrapper-components">
          <el-button type="primary" @click="onPreSave">{{$t('generic.save')}}</el-button>
          <el-button @click="close">{{$t('generic.close')}}</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped></style>
