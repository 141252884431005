import UserType from "../../enums/UserType";
class User {
  id?: number = 0;
  username: string = "";
  first_name: string = "";
  last_name: string = "";
  password: string = "";
  name?: string;
  active: boolean = true;
  deleted: boolean = true;
  company: string = "";
  user_type: UserType = UserType.Agent;
  auth_id: Number | null = null;
  email: string = "";
  privileges: string[] = [];
  language: string = "en";
  reset_password_on_login: boolean = false;
  last_password_change: string = "1970-01-01";
  digitization: boolean = false;
  assigned_printer: string = "";
}

export default User;
