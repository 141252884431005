import ITableName from "../../interfaces/ITableName";

class ROB_Title implements ITableName {
  id: number = -1;
  rob_participating_bank_id: number = 0;
  title_name: string = "";

  static getTableNameStatic(): string {
    return "rob_titles";
  }

  getTableName(): string {
    return ROB_Title.getTableNameStatic();
  }
}

export default ROB_Title;
