<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { Avatar, Bell, Edit, Delete, Document, Plus, RefreshLeft, CircleCheck, CircleClose, View, Watch } from "@element-plus/icons-vue";
import { storeToRefs } from "pinia";
import { useMainStore } from "../store/main";
import AddEdit_Approval from "../components/AddEdit_Approval.vue";
import UserType from "../enums/UserType";
import { useSort } from "../composables/sort";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useViewVariablesMethods } from "../composables/viewVariablesMethods";
import Approval from "../classes/DB_Entities/Approval";
import DeleteEntityDialog from "../components/DeleteEntityDialog.vue";

const { onSortChange, sortColumn, sortOrder } = useSort();
const pgrestapi = usePGRESTAPIStore();
const mainStore = useMainStore();

let filterPending = ref(false);
let filterCidNumber = ref<string>("");
let filterLoanAgreementNumber = ref<string>("");
let filterMosClientNumber = ref<string>("");
let filterMiddleOfficer = ref<string>("");
let filterFullname = ref<string>("");
let filterPendingNew = ref(false);
let filterHideRejected = ref(true);

const { isLoading, showError } = useGenericMethodsVariables();
const { addEditVisible, dataCount, dataList, deleteDialogVisible, entityName, lastPage, loadPageHook, reloadOnGlobalFilterChange, selectedRow, viewOnly,
        filterChanged, handleClickAdd, handleClickDelete, handleClickEdit, onAdd, onApprove, onCloseAddEdit, onCloseDelete, onDelete, onFailSave } = useViewVariablesMethods();

onMounted(async () => {
  entityName.value = "Approval";
  loadPageHook.value = loadPage;
  reloadOnGlobalFilterChange.value = true;
  await loadPage(1);
});

const loadPage = async (page: number) => {
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;

  let filterUrl = "";

  if (filterPending.value) {
    filterUrl += "&pending_approvals=gt.0";
  }

  if (filterCidNumber.value !== "") {
    filterUrl += `&cid=in.(${filterCidNumber.value})`;
  }

  if (filterLoanAgreementNumber.value !== "") {
    filterUrl += `&loan_agreement_ar_sumbashs=eq.${filterLoanAgreementNumber.value}`;
  }

  if (filterMosClientNumber.value !== "") {
    filterUrl += `&clientname=ilike.*${filterMosClientNumber.value}*`;
  }

  if (filterMiddleOfficer.value !== "") {
    filterUrl += `&middle_officer=ilike.*${filterMiddleOfficer.value}*`;
  }

  if (sortOrder.value !== "") {
    filterUrl += `&order=${sortColumn.value}.${sortOrder.value}`;
  }

  if (filterFullname.value !== "") {
    filterUrl += `&fullname=ilike.*${filterFullname.value}*`;
  }

  if (filterPendingNew.value) {
    filterUrl += "&approve_status=eq.0";
  }

  if (filterHideRejected.value) {
    filterUrl += "&approve_status=neq.2";
  }

  let selectedPortfolio = "";
  let selectedDebtor = "";
  let selectedLoanAgreement = "";
  let addedFilter = "";

  if (mainStore.globalPortfolioID !== "") {
    selectedPortfolio = mainStore.globalPortfolioID;
    addedFilter += "&portfolio=eq." + selectedPortfolio;
  }

  if (mainStore.globalDebtorID !== "") {
    selectedDebtor = mainStore.globalDebtorID;
    addedFilter += "&debtor_id=eq." + selectedDebtor;
  }

  if (mainStore.globalLoanAgreementID !== "") {
    selectedLoanAgreement = mainStore.globalLoanAgreementID;
    addedFilter += "&loan_agreement_id=eq." + selectedLoanAgreement;
  }

  if (mainStore.loggedUser.user_type == UserType.Agent) {
    addedFilter += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
  } else if (mainStore.loggedUser.user_type == UserType.Supervisor) {
    addedFilter += `&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`;
  }
  let result: PGRESTResult = await pgrestapi.getPaginated(
    "view_approval_view1?select=*" + addedFilter + filterUrl,
    page
  );

  if (result.error) {
    showError(result.error.message);
    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;
  isLoading.value = false;
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const pendingFilterChange = async () => {
  await loadPage(1);
};

const isEditable = (row: any) => {
  return (row.debtor_approve_status === 1 && row.loan_agreement_approve_status === 1);
}
</script>

<template>
  <div class="content-wrapper" v-loading="isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">{{ $t("ApprovalView.approvals") }} ({{ dataCount }})</h3>
      </el-col>
    </el-row>

    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input v-model="filterCidNumber" class="client-input" placeholder="CID" @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filterFullname" class="client-input" :placeholder="$t('DebtorView.company_name')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filterMosClientNumber" class="client-input" :placeholder="$t('ApprovalView.mos_client')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filterMiddleOfficer" class="client-input" :placeholder="$t('ApprovalView.middle_officer')"
            @input="filterChanged" />
        </el-col>
      </el-row>
    </div>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn">
          <el-switch v-model="filterPending" size="small" :active-text="$t('global.pending_changes')" inactive-text=""
            @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filterPendingNew" size="small" :active-text="$t('global.pending_changes_new_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filterHideRejected" size="small" :active-text="$t('global.hide_rejected_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
        </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
            @click="handleClickAdd()" :icon="Plus">
          </el-button>
        </el-col>
      </el-row>
    </div>

    <el-table v-loading="isLoading" :data="dataList" stripe border table-layout="fixed" sortable="custom"
      @sort-change="localSortChange" size="small">
      <el-table-column sortable fixed prop="loan_agreement_ar_sumbashs" :label="$t('ApprovalView.loan_agreement')" />
      <el-table-column fixed prop="fullname" :label="$t('DebtorView.company_name')" sortable />

      <el-table-column sortable fixed prop="cid" label="CID" />

      <el-table-column sortable fixed prop="clientname" :label="$t('ApprovalView.mos_client')" />
      <el-table-column sortab le fixed prop="middle_officer" :label="$t('ApprovalView.middle_officer')" />
      <el-table-column fixed prop="portf_name" :label="$t('DebtorDetailsView.portfolio')" sortable />

      <el-table-column sortable fixed prop="pending_approvals" :label="$t('global.pending_approvals')" />
      <el-table-column fixed="right" :label="$t('global.operations')" width="280">
        <template #default="scope">
          <el-button link type="primary" size="small" class="btn-detail"
            @click="handleClickEdit(scope.row, isEditable(scope.row))" :icon="isEditable(scope.row) ? Edit : View" />
          <el-tooltip v-if="scope.row.approve_status == 0 && mainStore.loggedUser.user_type === UserType.Agent"
            effect="dark" content="Pending Supervisor approval" placement="top">
            <el-button size="small" type="primary" class="btn-warn" :icon="Watch" circle />
          </el-tooltip>
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
            class="btn-delete" @click="handleClickDelete(scope.row.id)" :icon="Delete" />
          <span v-if="mainStore.loggedUser.user_type < UserType.Agent && scope.row.approve_status === 0">
            <el-button class="btn-accept_square" @click="onApprove(Approval, scope.row.id, true)" :icon="CircleCheck" />
            <el-button class="btn-reject_square" @click="onApprove(Approval, scope.row.id, false)" :icon="CircleClose" />
          </span>
          <el-popover v-if="scope.row.pending_approvals > 0" placement="top-start" title="" :width="155" trigger="hover"
            :content="$t('global.pending_changes')">
            <template #reference>
              <el-button :icon="Bell" circle size="small" style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.debtor_approve_status !== 1" placement="top-start" title="" :width="205"
            trigger="hover" :content="$t('global.debtor_not_approved')">
            <template #reference>
              <el-button :icon="Avatar" circle size="small"
                style="background-color: blueviolet; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.loan_agreement_approve_status !== 1" placement="top-start" title="" :width="265"
            trigger="hover" :content="$t('global.loan_agreement_not_approved')">
            <template #reference>
              <el-button :icon="Document" circle size="small"
                style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination small layout="prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
    </div>

    <el-dialog v-if="addEditVisible" v-model="addEditVisible" @update:model-value="onCloseAddEdit"
      :title="selectedRow === -1 ? 'Add Approval' : 'Approval Details'" width="50%">
      <AddEdit_Approval @close="onCloseAddEdit" @save="onAdd" @fail-save="onFailSave" :row_id="selectedRow"
        :view_only="viewOnly" />
    </el-dialog>

    <DeleteEntityDialog v-if="deleteDialogVisible" :row_id="selectedRow" entity_name="Approval" table="approvals"
        title="Delete Approval" width="50%" @close="onCloseDelete" @delete="onDelete" />
  </div>
</template>

<style scoped></style>
