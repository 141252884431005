<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import PGRESTResult from "../classes/PGRESTResult";
import DebitCredit from "../classes/DB_Entities/DebitCredit";
import CreditType from "../enums/CreditType";
import UserType from "../enums/UserType";
import DebitCreditType from "../enums/DebitCreditType";

const props = defineProps<{
    loan_account_id: number;
    row_id: number;
    view_only: boolean;
    type: DebitCreditType;
}>();

const emits = defineEmits(["save", "close", "save_and_approve"]);

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });

const { showError, showSuccess } = useGenericMethodsVariables();

let dbEntry = ref(new DebitCredit());
let originalAmount = -1;
let originalDate = "";
let originalPerigrafi = "";
let originalCreditType = CreditType.DEFAULT;
let originalConfigInterestId: number | null = null;
let originalPerithorio: number | null = null;
let originalEisfora: number | null = null;
let originalPosostoTokwnYperhmerias: number | null = null;

let isLoading = ref(false);

const interest_rate_categories_list = ref<any[]>([]);

onMounted(async () => {
    isLoading.value = true;

    console.log("debit_credit id", props.row_id);

    if (props.type === DebitCreditType.InterestTypeChanges) {
        interest_rate_categories_list.value = (await pgrestapi.get("config_interest?order=interest")).data;
    }

    if (props.row_id === 0) {
        isLoading.value = false;

        return;
    }

    let result = await pgrestapi.get("debits_credits?id=eq." + props.row_id);

    if (result.error) {
        showError(result.error.code + ' - ' + result.error.message);
        isLoading.value = false;

        return;
    }

    originalAmount = result.data[0].amount;
    originalDate = result.data[0].date;
    originalPerigrafi = result.data[0].perigrafi
    originalCreditType = result.data[0].credit_type;
    originalConfigInterestId = result.data[0].config_interest_id;
    originalPerithorio = result.data[0].perithorio === '' ? null : result.data[0].perithorio ;
    originalEisfora = result.data[0].eisfora === '' ? null : result.data[0].eisfora;
    originalPosostoTokwnYperhmerias = result.data[0].pososto_tokwn_yperhmerias === '' ? null : result.data[0].pososto_tokwn_yperhmerias;


    dbEntry.value = result.data[0];


    isLoading.value = false;
});

// TODO add user type checks
const onSave = async () => {

    if ((dbEntry.value as any).perithorio === '' || (dbEntry.value as any).perithorio === undefined)
        (dbEntry.value as any).perithorio = null;

    if ((dbEntry.value as any).eisfora === '' || (dbEntry.value as any).eisfora === undefined)
        (dbEntry.value as any).eisfora = null;

    if ((dbEntry.value as any).pososto_tokwn_yperhmerias === '' || (dbEntry.value as any).pososto_tokwn_yperhmerias === undefined)
        (dbEntry.value as any).pososto_tokwn_yperhmerias = null;

    if (props.type === DebitCreditType.InterestTypeChanges) {
        if (dbEntry.value.perithorio === null) {
            showError("Περιθώριο δεν μπορεί να είναι κενό.");
            return;
        }

        if (dbEntry.value.eisfora === null) {
            showError("Εισφορά δεν μπορεί να είναι κενή.");
            return;
        }

        if (dbEntry.value.pososto_tokwn_yperhmerias === null) {
            showError("Επιτόκιο υπερημερίας δεν μπορεί να είναι κενό.");
            return;
        }
    }

    // New entry
    if (props.row_id === 0) {
        let newDebitCredit = new DebitCredit();
        newDebitCredit.loan_account_id = props.loan_account_id.toString();
        newDebitCredit.type = props.type;
        newDebitCredit.date = dbEntry.value.date;
        newDebitCredit.amount = dbEntry.value.amount;
        newDebitCredit.perigrafi = dbEntry.value.perigrafi;
        newDebitCredit.credit_type = dbEntry.value.credit_type;
        newDebitCredit.config_interest_id = dbEntry.value.config_interest_id;
        newDebitCredit.perithorio = dbEntry.value.perithorio;
        newDebitCredit.eisfora = dbEntry.value.eisfora;
        newDebitCredit.pososto_tokwn_yperhmerias = dbEntry.value.pososto_tokwn_yperhmerias;

        // Auto-approved for Admin/Supervisor
        if (mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor) {
            newDebitCredit.status = 1;
        } else {
            newDebitCredit.status = 0;
        }

        let obj: any = JSON.parse(JSON.stringify(newDebitCredit));

        delete obj.id;

        let result = await pgrestapi.post_data("debits_credits", obj);

        // Add a new approval request for the new item
        let entityObj: any = {};
        entityObj.entity_id = result.data[0].id;
        entityObj.editor_user_id = mainStore.loggedUser.id;
        entityObj.field = 'status';
        entityObj.value = '1';

        // Auto-approved for Admin/Supervisor
        // if (mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor) {
        //     entityObj.status = 1;
        // }

        if (result.error) {
            showError(result.error.code + ' - ' + result.error.message);
            return;
        }

        let result2 = await pgrestapi.post_data("entity_debits_credits_changes", entityObj);

        // In case the entity change request fails, delete the new debit_credit item
        if (result2.error) {
            showError(result2.error.code + ' - ' + result2.error.message);
            await pgrestapi.delete_data("debits_credits?id=eq." + result.data[0].id);
            return;
        }

        showSuccess("Entry added successfully");

        if (mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor) {
            emits("save_and_approve", [{ id:result2.data[0].id, entity_id: result2.data[0].id, field: 'status', value: '1' }]);
        } else {
            emits("save");
        }

        return;
    }

    // Edit existing entry

    if (originalAmount === dbEntry.value.amount && originalDate === dbEntry.value.date && originalPerigrafi === dbEntry.value.perigrafi && originalCreditType === dbEntry.value.credit_type
        && originalConfigInterestId === dbEntry.value.config_interest_id && originalPerithorio === dbEntry.value.perithorio && originalEisfora === dbEntry.value.eisfora
        && originalPosostoTokwnYperhmerias === dbEntry.value.pososto_tokwn_yperhmerias
    ) {
        emits('close');
        return;
    }

    let ids_to_approve: any[] = [];

    if (originalAmount !== dbEntry.value.amount) {
        let entityObj: any = {};
        entityObj.entity_id = dbEntry.value.id;
        entityObj.editor_user_id = mainStore.loggedUser.id;
        entityObj.field = 'amount';
        entityObj.value = dbEntry.value.amount;

        console.log('POSTing entity_debits_credits_changes', entityObj)
        let result = await pgrestapi.post_data("entity_debits_credits_changes", entityObj);

        if (result.error) {
            showError(result.error.code + ' - ' + result.error.message);
            return;
        }

        ids_to_approve.push({ id: result.data[0].id, entity_id: dbEntry.value.id, field: 'amount', value: dbEntry.value.amount });
    }

    console.log("originalDate", originalDate, "dbEntry.value.date", dbEntry.value.date, 'isEqual', originalDate === dbEntry.value.date);

    if (originalDate !== dbEntry.value.date) {
        let entityObj: any = {};
        entityObj.entity_id = dbEntry.value.id;
        entityObj.editor_user_id = mainStore.loggedUser.id;
        entityObj.field = 'date';
        entityObj.value = dbEntry.value.date;

        console.log('POSTing entity_debits_credits_changes', entityObj)
        let result = await pgrestapi.post_data("entity_debits_credits_changes", entityObj);

        if (result.error) {
            showError(result.error.code + ' - ' + result.error.message);
            return;
        }

        ids_to_approve.push({ id: result.data[0].id, entity_id: dbEntry.value.id, field: 'date', value: dbEntry.value.date });
    }

    if (originalPerigrafi !== dbEntry.value.perigrafi) {
        let entityObj: any = {};
        entityObj.entity_id = dbEntry.value.id;
        entityObj.editor_user_id = mainStore.loggedUser.id;
        entityObj.field = 'perigrafi';
        entityObj.value = dbEntry.value.perigrafi;

        console.log('POSTing entity_debits_credits_changes', entityObj)
        let result = await pgrestapi.post_data("entity_debits_credits_changes", entityObj);

        if (result.error) {
            showError(result.error.code + ' - ' + result.error.message);
            return;
        }

        ids_to_approve.push({ id: result.data[0].id, entity_id: dbEntry.value.id, field: 'perigrafi', value: dbEntry.value.perigrafi });
    }

    if (originalCreditType !== dbEntry.value.credit_type) {
        let entityObj: any = {};
        entityObj.entity_id = dbEntry.value.id;
        entityObj.editor_user_id = mainStore.loggedUser.id;
        entityObj.field = 'credit_type';
        entityObj.value = dbEntry.value.credit_type;

        console.log('POSTing entity_debits_credits_changes', entityObj)
        let result = await pgrestapi.post_data("entity_debits_credits_changes", entityObj);

        if (result.error) {
            showError(result.error.code + ' - ' + result.error.message);
            return;
        }

        ids_to_approve.push({ id: result.data[0].id, entity_id: dbEntry.value.id, field: 'credit_type', value: dbEntry.value.credit_type });
    }
    if (originalConfigInterestId !== dbEntry.value.config_interest_id) {
        let entityObj: any = {};
        entityObj.entity_id = dbEntry.value.id;
        entityObj.editor_user_id = mainStore.loggedUser.id;
        entityObj.field = 'config_interest_id';
        entityObj.value = dbEntry.value.config_interest_id;

        let result = await pgrestapi.post_data("entity_debits_credits_changes", entityObj);

        if (result.error) {
            showError(result.error.code + ' - ' + result.error.message);
            return;
        }

        ids_to_approve.push({ id: result.data[0].id, entity_id: dbEntry.value.id, field: 'config_interest_id', value: dbEntry.value.config_interest_id });
    }

    if (originalPerithorio !== dbEntry.value.perithorio) {
        let entityObj: any = {};
        entityObj.entity_id = dbEntry.value.id;
        entityObj.editor_user_id = mainStore.loggedUser.id;
        entityObj.field = 'perithorio';
        entityObj.value = dbEntry.value.perithorio;

        console.log('POSTing entity_debits_credits_changes', entityObj)
        let result = await pgrestapi.post_data("entity_debits_credits_changes", entityObj);

        if (result.error) {
            showError(result.error.code + ' - ' + result.error.message);
            return;
        }

        ids_to_approve.push({ id: result.data[0].id, entity_id: dbEntry.value.id, field: 'perithorio', value: dbEntry.value.perithorio });
    }

    if (originalEisfora !== dbEntry.value.eisfora) {
        let entityObj: any = {};
        entityObj.entity_id = dbEntry.value.id;
        entityObj.editor_user_id = mainStore.loggedUser.id;
        entityObj.field = 'eisfora';
        entityObj.value = dbEntry.value.eisfora;

        let result = await pgrestapi.post_data("entity_debits_credits_changes", entityObj);

        if (result.error) {
            showError(result.error.code + ' - ' + result.error.message);
            return;
        }

        ids_to_approve.push({ id: result.data[0].id, entity_id: dbEntry.value.id, field: 'eisfora', value: dbEntry.value.eisfora });
    }

    if (originalPosostoTokwnYperhmerias !== dbEntry.value.pososto_tokwn_yperhmerias) {
        let entityObj: any = {};
        entityObj.entity_id = dbEntry.value.id;
        entityObj.editor_user_id = mainStore.loggedUser.id;
        entityObj.field = 'pososto_tokwn_yperhmerias';
        entityObj.value = dbEntry.value.pososto_tokwn_yperhmerias;

        let result = await pgrestapi.post_data("entity_debits_credits_changes", entityObj);

        if (result.error) {
            showError(result.error.code + ' - ' + result.error.message);
            return;
        }

        ids_to_approve.push({ id: result.data[0].id, entity_id: dbEntry.value.id, field: 'pososto_tokwn_yperhmerias', value: dbEntry.value.pososto_tokwn_yperhmerias });
    }

    if (mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor) {
        emits("save_and_approve", ids_to_approve);
    } else {
        emits("save");
    }

    showSuccess("Credit edited successfully");
};

const close = () => {
    emits("close");
}

const CreditTypeOptions = [
  {
    value: CreditType.DEFAULT,
    label: t("CreditType.DEFAULT"),
  },
  {
    value: CreditType.EXODO,
    label: t("CreditType.EXODO"),
  },
  {
    value: CreditType.KEFALAIO,
    label: t("CreditType.KEFALAIO"),
  },
  {
    value: CreditType.TOKOI_TOKWN,
    label: t("CreditType.TOKOI_TOKWN"),
  },
];

</script>

<template>
  <div v-loading="isLoading">
    <el-form>
      <el-row :gutter="10">
        <el-col :span="24">
            <b>{{ $t('DebitCreditList.date') }}</b>
            <el-date-picker
                v-model="dbEntry.date"
                type="date"
                placeholder="Pick a Date"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
            />
        </el-col>
        <el-col :span="24" v-if="props.type !== DebitCreditType.HmerTokou && props.type !== DebitCreditType.InterestTypeChanges" >
            <p/>
            <b>{{ $t('DebitCreditList.amount') }}</b>
            <el-input v-model="dbEntry.amount" type="number" step="0.01" placeholder="Amount"  />
        </el-col>
        <el-col :span="24">
            <p/>
            <b>{{ $t('DebitCreditList.perigrafi') }}</b>
            <el-input v-model="dbEntry.perigrafi" type="string" />
        </el-col>

        <el-col :span="24" v-if="props.type === DebitCreditType.Credit">
            <p/>
            <b>{{ $t('DebitCreditList.credit_type') }}</b>
            <el-select v-model="dbEntry.credit_type" class="m-2 header-filter" filterable placeholder=" ">
              <el-option v-for="item in CreditTypeOptions" :key="item.value" :label="item.label"
                :value="item.value" />
            </el-select>
        </el-col>

        <el-col :span="24" v-if="props.type === DebitCreditType.InterestTypeChanges">
            <p/>
            <b>{{ $t('DebitCreditList.interest_category') }}</b>
            <el-select v-model="dbEntry.config_interest_id" class="m-2 header-filter" filterable placeholder=" ">
              <el-option v-for="item in interest_rate_categories_list" :key="item.id" :label="item.interest"
                :value="item.id" />
            </el-select>

        </el-col>

        <el-col :span="24" v-if="props.type === DebitCreditType.InterestTypeChanges">
            <p/>
            <b>{{ $t('DebitCreditList.perithorio') }}</b>
            <el-input v-model="dbEntry.perithorio" type="number" step="0.01" placeholder="Amount"  />
        </el-col>

        <el-col :span="24" v-if="props.type === DebitCreditType.InterestTypeChanges">
            <p/>
            <b>{{ $t('DebitCreditList.eisfora') }}</b>
            <el-input v-model="dbEntry.eisfora" type="number" step="0.01" placeholder="Amount"  />
        </el-col>

        <el-col :span="24" v-if="props.type === DebitCreditType.InterestTypeChanges">
            <p/>
            <b>{{ $t('DebitCreditList.pososto_tokwn_yperhmerias') }}</b>
            <el-input v-model="dbEntry.pososto_tokwn_yperhmerias" type="number" step="0.01" placeholder="Amount"  />
        </el-col>
        
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button v-if="!props.view_only" type="primary" @click="onSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped>

</style>
