<script lang="ts" setup>
import { ref, watch, onMounted, nextTick, reactive } from "vue";
import type { FormInstance } from "element-plus";
import { useRouter } from "vue-router";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import { Edit, Delete, Notebook } from "@element-plus/icons-vue";
import KADDetails from "../components/ConfigKADDetails.vue";
import { useSort } from "../composables/sort";
import UserType from "../enums/UserType";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
const { showError } = useGenericMethodsVariables();

const { onSortChange, sortColumn, sortOrder } = useSort();
const klados = ref<any>([]);
const router = useRouter();
const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const formRef = ref<FormInstance>();
const tabPosition = ref("left");
const DeleteDialogVisible = ref(false);
let detailsVisible = ref(false);
let deleteVisible = ref(false);
let selectedRow = ref(0);
let DeleteSelectedRow = ref(0);
let dataList = ref([]);
let isLoading = ref(false);
let lastPage = ref(0);
let dataCount = ref(0);
let filterBusinessActivityCode = ref<string>("");
let filterBusinessActivity = ref<string>("");
let filterDescription = ref<string>("");
let timer = ref<any>(null);

const emits = defineEmits(["save", "close", "fail-save"]);

onMounted(async () => {
  await loadKlados();
  loadPage(1);
});

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeleteKAD(row_id);
};

const handleClick = (row_id: number) => {
  selectedRow.value = row_id;
  detailsVisible.value = true;
};

const DeleteKAD = async (row_id: number) => {
  let result: PGRESTResult = new PGRESTResult();

  let id = JSON.stringify(row_id);

  result = await pgrestapi.delete_data("config_kad?id=eq." + id);

  if (result.error) {
    showError(result.error.message);

    DeleteDialogVisible.value = false;
    return;
  }

  onDelete();
};

const onSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "KAD Code updated Successfully",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onInsert = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "KAD Code saved Successfully",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onDelete = async () => {
  ElMessage({
    showClose: true,
    message: "KAD Code Deleted Successfully",
    type: "success",
    duration: 1000,
  });

  DeleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "KAD Code failed to update",
    type: "error",
    duration: 2000,
  });

  detailsVisible.value = false;
};

const dynamicValidateForm = reactive<{
  kad: AgentItem[];
}>({
  kad: [],
});

interface AgentItem {
  kodikos_kad: string;
  perigrafi: string;
  klados_id: any;
}

const removeKAD = (item: AgentItem) => {
  const index = dynamicValidateForm.kad.indexOf(item);
  if (index !== -1) {
    dynamicValidateForm.kad.splice(index, 1);
  }
};

const addKAD = () => {
  dynamicValidateForm.kad.push({
    kodikos_kad: "",
    perigrafi: "",
    klados_id: null,
  });
};

const submitForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!");
    }
  });
};

const save = async () => {
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.post_data("config_kad", dynamicValidateForm.kad);

  if (result.error) {
    onFailSave();
    return;
  }
  dynamicValidateForm.kad = [];
  onInsert();
  loadPage(1);
};

const loadPage = async (page: number) => {
  isLoading.value = true;
  if (page <= 0) {
    page = 1;
  }
  lastPage.value = page;
  page = page - 1;

  let filterUrl = "";

  if (filterBusinessActivityCode.value !== "") {
    filterUrl += `&kodikos_kad=ilike.*${filterBusinessActivityCode.value}*`;
  }

  if (filterBusinessActivity.value) {
    filterUrl += `&kodikos=ilike.*${filterBusinessActivity.value}*`;
  }

  if (filterDescription.value !== "") {
    filterUrl += `&perigrafi=ilike.*${filterDescription.value}*`;
  }

  if (sortOrder.value !== "") {
    // if (filterUrl === "") {
    //   filterUrl = "?";
    // }
    filterUrl += `&order=${sortColumn.value}.${sortOrder.value}`;
  }

  let result: PGRESTResult = await pgrestapi.getPaginated(
    "view_mosconfigkad_view1?select=*" + filterUrl + "&order=kodikos",
    page
  );

  if (result.error) {
    showError(result.error.message);

    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;

  isLoading.value = false;
};

const loadKlados = async () => {
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get("config_klados?order=kodikos");

  if (result.error) {
    showError(result.error.message);

    return;
  }

  klados.value = result.data;
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 500);
};
</script>

<template>
  <div style="height: 100%" v-loading="isLoading">
    <el-row :gutter="10" style="margin: 20px">
      <el-col :span="8">
        <el-input v-model="filterBusinessActivityCode" class="client-input"
          :placeholder="$t('PlatformSettingsView.kad')" @input="filterChanged" style="width: 100%" />
      </el-col>
      <el-col :span="8">
        <el-input v-model="filterBusinessActivity" class="client-input" :placeholder="$t('PlatformSettingsView.klados')"
          @input="filterChanged" style="width: 100%" />
      </el-col>
      <el-col :span="8">
        <el-input v-model="filterDescription" class="client-input" :placeholder="$t('PlatformSettingsView.description')"
          @input="filterChanged" style="width: 100%" />
      </el-col>
    </el-row>
    <el-row :gutter="10" style="margin: 20px">
      <el-col :span="24">
        <el-table :data="dataList" stripe border table-layout="fixed" size="small">
          <el-table-column fixed sortable prop="kodikos_kad" @sort-change="localSortChange"
            :label="$t('PlatformSettingsView.kad')" type="text" />
          <el-table-column fixed sortable prop="kodikos" :label="$t('PlatformSettingsView.klados')" type="text" />
          <el-table-column fixed sortable prop="perigrafi" :label="$t('PlatformSettingsView.description')"
            type="text" />
          <el-table-column fixed="right" :label="$t('global.operations')">
            <template #default="scope">
              <el-button link type="primary" size="small" class="btn-detail" @click="handleClick(scope.row.id)">
                <el-icon>
                  <Edit />
                </el-icon>
              </el-button>
              <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary"
                size="small" class="btn-delete" @click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;"
                :icon="Delete" />
            </template>
          </el-table-column>
        </el-table>
        <div>
          <el-pagination size="small" layout=" prev, pager, next" :page-size="10" :total="dataCount"
            @current-change="loadPage" />
        </div>

        <el-dialog v-model="DeleteDialogVisible" title="KAD Delete" width="50%">
          <span>{{ $t("PlatformSettingsView.kad_delete_message") }}</span>
          <template #footer #default="scope">
            <span class="dialog-footer">
              <el-button @click="DeleteDialogVisible = false">{{$t('generic.cancel')}}</el-button>
              <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{$t('generic.delete')}}
              </el-button>
            </span>
          </template>
        </el-dialog>

        <el-dialog v-model="detailsVisible" title="KAD Details" width="50%">
          <KADDetails :kadid="selectedRow" @save="onSave" @close="
  detailsVisible = false;
selectedRow = -1;
          " @fail-save="onFailSave" />
        </el-dialog>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form ref="formRef" :model="dynamicValidateForm" label-width="120px">
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-form-item v-for="(kad, index) in dynamicValidateForm.kad" :label="'KAD ' + index"
                    :prop="'Kad.' + index + '.value'"
                    style="border-bottom: 0px; margin-top: 30px; padding-bottom: 30px">
                    <el-col :span="6">
                      <el-input :placeholder="$t('PlatformSettingsView.kad_details')" v-model="kad.kodikos_kad"
                        autocomplete="off" style="border-bottom: 3px solid #e3e3e3" />
                    </el-col>
                    <el-col :span="6">
                      <el-input :placeholder="$t('PlatformSettingsView.description_details')" v-model="kad.perigrafi"
                        autocomplete="off" style="border-bottom: 3px solid #e3e3e3" />
                    </el-col>
                    <el-col :span="6" style="margin-top: 0px">
                      <el-form-item style="margin-top: 0px">
                        <el-select v-model="kad.klados_id" :placeholder="$t('PlatformSettingsView.klados')"
                          style="margin-top: 0px">
                          <el-option v-for="klados_item in klados" :key="klados_item.id" :label="
                            klados_item.kodikos + ' (' + klados_item.perigrafi + ')'
                          " :value="klados_item.id" />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-button style="float: left" @click.prevent="removeKAD(kad)">{{$t('generic.delete')}}
                      </el-button>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-form-item style="border-bottom: 0px; float: right; margin-left: 0px; margin-top: 30px;">
                    <el-button v-if="dynamicValidateForm.kad.length != 0" type="primary" @click="submitForm(formRef)">
                      {{ $t('generic.save') }}
                    </el-button>
                    <el-button @click="addKAD">{{
                        $t("PlatformSettingsView.new_kad_code")
                    }}</el-button>
                    <!-- <el-button @click="resetForm(formRef)">Reset</el-button> -->
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.info {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}
</style>
