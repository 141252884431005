import ITableName from "../../interfaces/ITableName";

class ConfigKlados implements ITableName {
  id?: number = -1;
  kodikos: string = "";
  perigrafi: string = "";

  static getTableNameStatic(): string {
    return "config_klados";
  }

  getTableName(): string {
    return ConfigKlados.getTableNameStatic();
  }
}

export default ConfigKlados;
