import ITableName from "../../interfaces/ITableName";

class EktokismosLoanAgreement implements ITableName {
  id: number = -1;
  loan_agreement_id: number = 0;
  base_rate_value: number = 0;
  margin: number = 0;
  total_due_rate: number = 0;
  overdue_rate: number = 0;
  total_rate: number = 0;
  participating_banks: number = 0;
  due_principal: number = 0;
  interest_on_due_principal: number = 0;
  overdue_principal: number = 0;
  overdue_interest: number = 0;
  interest_on_overdue_amount: number = 0;

  static getTableNameStatic(): string {
    return "ektokismoi_loan_agreement";
  }

  getTableName(): string {
    return EktokismosLoanAgreement.getTableNameStatic();
  }
}

export default EktokismosLoanAgreement;
