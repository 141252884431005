import ITableName from "../../interfaces/ITableName";

class monitoring implements ITableName {
  id?: number = -1;
  loan_agreement_id: number = 0;
  short_desc: string = "";
  description: string = "";
  actions_comments_clarifications: string = "";
  due_date: string = "";
  alarm_date: string = "";
  end_date: string = "";
  mos_officer: string = "";
  account_manager: string = "";
  due_date_financials: string = "";
  obligations: string = "";
  recruit_final_stat: string = "";
  comments: string = "";
  account_manager_financials: string = "";
  insurance_company: string = "";
  insurance_policy: string = "";
  start_date: string = "";
  end_date_as: string = "";
  alarm_date_as: string = "";
  object_of_insurance: string = "";
  insurance_risk: string = "";
  insured_capital: string = "";
  cession_of_insurance_policy: boolean = false;
  actions_comments_clarifications_as: string = "";
  type_of_agency_fee: string = "";
  agency_fee_amount: number = 0;
  agency_fee_percentage_first: number = 0;
  agency_fee_percentage_second: string = "";
  fee_payment_freq: string = "";
  due_date_p: string = "";
  alarm_date_p: string = "";
  payment_date: string = "";
  comments_p: string = "";
  monitoring_type: number = 0;

  static getTableNameStatic(): string {
    return "la_monitoring";
  }

  getTableName(): string {
    return monitoring.getTableNameStatic();
  }
}

export default monitoring;
