import ITableName from "../../interfaces/ITableName";

class ConfigArgia implements ITableName {
  id?: number = -1;
  hmer_argias: string = "";
  description: string = "";

  static getTableNameStatic(): string {
    return "config_argies";
  }

  getTableName(): string {
    return ConfigArgia.getTableNameStatic();
  }
}

export default ConfigArgia;
