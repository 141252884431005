<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import { Back, Edit, Delete, Plus, RefreshLeft } from "@element-plus/icons-vue";
import AddEdit_Playment from "../components/AddEdit_Payment.vue";
import UserType from "../enums/UserType";
import { useMainStore } from "../store/main";
import { useSort } from "../composables/sort";
import { useI18n } from "vue-i18n";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
const { showError } = useGenericMethodsVariables();

const mainStore = useMainStore();
const router = useRouter();
const route = useRoute();
const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });

let dataList = ref([]);
let dataCount = ref(0);
let isLoading = ref(false);
let lastPage = ref(0);
let selectedRow = ref(-1);
let DeleteDialogVisible = ref(false);
let DeleteSelectedRow = ref(0);
let deleteVisible = ref(false);
let addEditVisible = ref(false);
let isNewEntity = ref<boolean>(false);

const { onSortChange, sortColumn, sortOrder } = useSort();

const handleClickEdit = (row_id: number) => {
  selectedRow.value = row_id;
  isNewEntity.value = false;
  addEditVisible.value = true;
};

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeletePliromi(row_id);
};

const handleClickAdd = () => {
  isNewEntity.value = false; /*δε θα εχει approval*/
  selectedRow.value = -1;
  addEditVisible.value = true;
};

const DeletePliromi = async (row_id: number) => {
  let result: PGRESTResult = new PGRESTResult();

  let id = JSON.stringify(row_id);

  result = await pgrestapi.delete_data("sunallages?id=eq." + id);

  if (result.error) {
    showError(result.error.message);

    DeleteDialogVisible.value = false;
    return;
  }

  onDelete();
};

onMounted(async () => {
  sortColumn.value = "id";
  sortOrder.value = "asc";
  loadPage(1);
});

const loadPage = async (page: number) => {
  isLoading.value = true;
  lastPage.value = page;
  page = page - 1;
  let filterURL = `?select=*&loan_account_id=eq.${route.params.loan_account_id}`;
  console.log(sortColumn.value);
  console.log(sortOrder.value);
  if (!(sortOrder.value === null || sortOrder.value === "")) {
    filterURL += `&order=${sortColumn.value}.${sortOrder.value}`;
  }

  let result: PGRESTResult = await pgrestapi.getPaginated("sunallages" + filterURL, page);

  // console.log(JSON.stringify(result.data));
  if (result.error) {
    showError(result.error.message);

    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;
  isLoading.value = false;
};

const onSave = async () => {
  ElMessage({
    showClose: true,
    message: "Payment updated",
    type: "success",
    duration: 1000,
  });

  addEditVisible.value = false;
  selectedRow.value = -1;

  await loadPage(lastPage.value);
};

const onDelete = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Payment Deleted Successfully",
    type: "success",
    duration: 1000,
  });

  DeleteDialogVisible.value = false;
  await loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Payment failed to update",
    type: "error",
    duration: 2000,
  });

  addEditVisible.value = false;
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const onCloseAddEdit = () => {
  addEditVisible.value = false;
  selectedRow.value = -1;
};

const proskomisiFormatter = (row: any, column: any) => {
  if (row.proskomisi_vevaiosis) {
    return t("global.true");
  } else {
    return t("global.false");
  }
};

const onClickBack = () => {
  router.back();
};
</script>

<template>
  <div class="content-wrapper" v-loading="isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">{{ $t("loan_account.pliromes") }} ({{ dataCount }})</h3>
      </el-col>
    </el-row>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="1" class=""><el-button link type="primary" size="small" class="btn-add" @click="onClickBack"
            style="float: right; margin: 2.5px" :icon="Back" /></el-col>
        <el-col :span="11" class=""></el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link type="primary" size="small"
            class="btn-add" style="float: right; margin: 2.5px" @click="handleClickAdd()" :icon="Plus" />
        </el-col>
      </el-row>
    </div>

    <el-table v-loading="isLoading" :data="dataList" stripe border table-layout="fixed" sortable="custom"
      @sort-change="localSortChange" size="small">
      <el-table-column sortable fixed prop="hmer" :label="$t('payments.hmer')" />
      <el-table-column sortable fixed prop="poso" :label="$t('payments.poso')" />
      <el-table-column sortable fixed prop="kefalaio" :label="$t('payments.kefalaio')" />
      <el-table-column sortable fixed prop="tokoi" :label="$t('payments.tokoi')" />
      <el-table-column sortable fixed prop="exoda" :label="$t('payments.exoda')" />
      <el-table-column sortable fixed prop="proskomisi_vevaiosis" :label="$t('payments.proskomisi')"
        :formatter="proskomisiFormatter" />
      <el-table-column sortable fixed prop="hmer_proskomisis" :label="$t('payments.hmer_prosk')" />
      <el-table-column fixed="right" :label="$t('global.operations')">
        <template #default="scope">
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClickEdit(scope.row.id)"
            :icon="Edit" />
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
            class="btn-delete" @click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;" :icon="Delete" />
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
    </div>

    <el-dialog v-if="addEditVisible" v-model="addEditVisible" @update:model-value="onCloseAddEdit"
      :title="isNewEntity ? 'Add Payment' : 'Payment Details'">
      <AddEdit_Playment :loan_account_id="(route.params.loan_account_id as any)" :paymentid="selectedRow"
        :new-entity="isNewEntity" @close="onCloseAddEdit" @save="onSave" />
    </el-dialog>

    <el-dialog v-model="DeleteDialogVisible" title="Payment Delete" width="50%">
      <span>Are you sure you want to delete this Payment?</span>
      <template #footer #default="scope">
        <span class="dialog-footer">
          <el-button @click="DeleteDialogVisible = false">{{$t('generic.cancel')}}</el-button>
          <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{$t('generic.delete')}}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped>

</style>
