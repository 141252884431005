<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import { CircleCheck, CloseBold, Edit, Delete, Plus, Files } from "@element-plus/icons-vue";
import UserDetails from "../components/UserDetails.vue";
import AddUser from "../components/AddUser.vue";
import UserType from "../enums/UserType";
import { useMainStore } from "../store/main";
import { useSort } from "../composables/sort";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import * as ExcelJS from 'exceljs';
import { DateTime } from "luxon";

const { showError } = useGenericMethodsVariables();

const mainStore = useMainStore();
const { onSortChange, sortColumn, sortOrder } = useSort();
const pgrestapi = usePGRESTAPIStore();

let filterUsername = ref<string>("");
let filterName = ref<string>("");
let filterCompany = ref<string>("");
let filterEmail = ref<string>("");
let filterInactive = ref(true);
let timer = ref<any>(null);
let dataList = ref([]);

let dataCount = ref(0);
let isLoading = ref(false);
let lastPage = ref(0);
let detailsVisible = ref(false);
let ΑddUserVisible = ref(false);
let selectedRow = ref(-1);

const handleClick = (userid: number) => {
  selectedRow.value = userid;
  detailsVisible.value = true;
};

const handleClickAdd = () => {
  ΑddUserVisible.value = true;
};

const exportExcel = async () => {

  let result2: PGRESTResult = await pgrestapi.get(
    "view_users?order=username.asc"
  );

  if (result2.error) {
    showError(result2.error.message);

    return;
  }

  let data = result2.data;
  console.log(JSON.stringify(data));

  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Projects');
  let currentRow = 1;

  let row = sheet.getRow(currentRow);

  //HEADERS
  row.getCell(1).value = 'Name';
  row.getCell(2).value = 'Username';
  row.getCell(3).value = 'Status';
  row.getCell(4).value = 'User Type';
  row.getCell(5).value = 'Portfolio';
  row = sheet.getRow(++currentRow);

  for (let i = 0; i < data.length; ++i) {

    row.getCell(1).value = data[i].name;
    row.getCell(2).value = data[i].username;

    if (data[i].active) {
      row.getCell(3).value = 'Active';
    } else {
      row.getCell(3).value = 'Inactive';
    }

    switch (data[i].user_type) {
      case 0: {
        row.getCell(4).value = 'Administrator';
        break;
      }
      case 1: {
        row.getCell(4).value = 'Supervisor';
        break;
      }
      case 2: {
        row.getCell(4).value = 'Agent';
        break;
      }
      case 3: {
        row.getCell(4).value = 'Digitizer';
        break;
      }
      default: {
        row.getCell(4).value = data[i].user_type
      }
    }

    if (data[i].portfolio_ids === null) {
      data[i].portfolio_ids = [];
    }
    row.getCell(5).value = data[i].portfolio_ids.join(',');

    row = sheet.getRow(++currentRow);
  }

  let buffer = await workbook.xlsx.writeBuffer();
  let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  var link = document.createElement("a");
  link.setAttribute("href", window.URL.createObjectURL(blob));
  link.setAttribute(
    "download",
    `users.${DateTime.now().toFormat("yyyy-MM-dd_HHmmss")}.xlsx`
  );

  document.body.appendChild(link); // Required for FF
  link.click(); // Trigger file download
}

onMounted(async () => {
  loadPage(1);
});

const loadPage = async (page: number) => {
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;

  let filterUrl = "";

  if (filterUsername.value !== "") {
    filterUrl += `&username=ilike.*${filterUsername.value}*`;
  }

  if (filterName.value !== "") {
    filterUrl += `&name=ilike.*${filterName.value}*`;
  }

  if (filterCompany.value !== "") {
    filterUrl += `&company=ilike.*${filterCompany.value}*`;
  }

  if (filterEmail.value !== "") {
    filterUrl += `&email=ilike.*${filterEmail.value}*`;
  }

  if (sortOrder.value !== "") {
    filterUrl += `&order=${sortColumn.value}.${sortOrder.value}`;
  }
  else {
    filterUrl += `&order=username`;
  }

  if (filterInactive.value) {
    filterUrl += `&active=eq.true`;
  }

  let result: PGRESTResult = await pgrestapi.getPaginated(
    "view_users?" + filterUrl,
    page
  );
  if (result.error) {
    showError(result.error.message);

    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;

  isLoading.value = false;
};

const onSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "User updated",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onAdd = async () => {
  ElMessage({
    showClose: true,
    message: "User Added Successfully",
    type: "success",
    duration: 1000,
  });

  ΑddUserVisible.value = false;

  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "User failed to update",
    type: "error",
    duration: 2000,
  });

  detailsVisible.value = false;
};

const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 500);
};

const inactiveFilterChange = async () => {
  await loadPage(1);
};

const userTypeFormatter = (row: any, column: any) => {
  switch (row.user_type as UserType) {
    case UserType.Administrator:
      return "Administrator";
    case UserType.Supervisor:
      return "Supervisor";
    case UserType.Agent:
      return "Agent";
    case UserType.Digitizer:
      return "Digitizer";
  }
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const deactivateUser = async (id: number) => {
  if (id === null || id === undefined) {
    showError('Invalid user id for deactivation.');
    return;
  }

  let result = await pgrestapi.patch('users?id=eq.' + id, { active: false })

  if (result.error) {
    showError(result.error.message);
    return;
  }

  await loadPage(lastPage.value);
};
</script>

<template>
  <div class="content-wrapper">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">{{ $t("UsersView.users") }} ({{ dataCount }})</h3>
      </el-col>
    </el-row>

    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input v-model="filterUsername" class="client-input" :placeholder="$t('UsersView.username')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filterName" class="client-input" :placeholder="$t('UsersView.name')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filterCompany" class="client-input" :placeholder="$t('UsersView.company')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filterEmail" class="client-input" placeholder="Email" @input="filterChanged" />
        </el-col>
      </el-row>
    </div>

    <div>
      <el-row :gutter="10">
        <el-col :span="12" class="realestate-add-new-btn">
          <el-switch v-model="filterInactive" size="small" :active-text="$t('global.ignore_inactive_users')"
            inactive-text="" @change="inactiveFilterChange()" style="float: left" />
        </el-col>
        <el-col :span="12" :offset="11" class="user-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="handleClickAdd()"
            style="float: right; margin: 5px">
            <el-icon>
              <Plus />
            </el-icon>
          </el-button>
          <el-button link v-if="mainStore.loggedUser.user_type === UserType.Administrator" type="primary"
						size="small" class="btn-add" style="float: right; margin: 5px" @click="exportExcel()"
						:icon="Files" >Export Users</el-button>
        </el-col>
      </el-row>
    </div>

    <el-table v-loading="isLoading" :data="dataList" stripe border table-layout="fixed" sortable="custom"
      @sort-change="localSortChange" size="small">
      <el-table-column sortable fixed prop="username" :label="$t('UsersView.username')" />
      <el-table-column sortable fixed prop="name" :label="$t('UsersView.name')" />
      <el-table-column sortable fixed prop="company" :label="$t('UsersView.company')" />
      <el-table-column sortable fixed prop="email" label="E-mail" />
      <el-table-column sortable fixed prop="user_type" :label="$t('UsersView.user_type')"
        :formatter="userTypeFormatter" />
      <el-table-column fixed :label="$t('global.active')">
        <template #default="scope">
          <el-icon v-if="scope.row.active === true" color="green" :size="24">
            <CircleCheck />
          </el-icon>
          <el-icon v-else color="red" :size="24">
            <CloseBold />
          </el-icon>

        </template>
      </el-table-column>
      <el-table-column fixed="right" :label="$t('global.operations')">
        <template #default="scope">
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClick(scope.row.id)">
            <el-icon>
              <Edit />
            </el-icon>
          </el-button>
          <el-popconfirm v-if="scope.row.title_name !== '' && mainStore.loggedUser.user_type === UserType.Administrator"
            :title="`${$t('UsersView.deactive_user_question')} (${scope.row.username})`"
            @confirm="deactivateUser(scope.row.id)" width="600">
            <template #reference>
              <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
                class="btn-delete" :icon="Delete" />
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination small layout="prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
    </div>

    <el-dialog v-if="detailsVisible" v-model="detailsVisible" @update:model-value="selectedRow = -1; loadPage(lastPage);" title="User Details">
      <UserDetails :userid="selectedRow" :allow-type-change="true" @save="onSave"
        @close="selectedRow = -1; detailsVisible = false; loadPage(lastPage);" @fail-save="onFailSave" />
    </el-dialog>

    <el-dialog v-if="ΑddUserVisible" v-model="ΑddUserVisible" title="Add User">
      <AddUser @close="ΑddUserVisible = false" @save="onAdd" />
    </el-dialog>
  </div>
</template>

<style scoped></style>
