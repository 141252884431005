<script lang="ts" setup>
import { ref, onMounted, Ref } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useMOSAPIStore } from "../store/mos_api";
import PGRESTResult from "../classes/PGRESTResult";
import UserType from "../enums/UserType";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import Printer from "../classes/DB_Entities/Printer";
const { showError } = useGenericMethodsVariables();

const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const mosapiStore = useMOSAPIStore();

let StoreUsers = ref<any>([]);

const data = ref<any>({
    username: "",
    first_name: "",
    user_type: 2,
    password: "",
    last_name: "",
    company: "",
    email: "",
    privileges: [],
    errors: [],
});
let printersList = ref<any>([]);
let availablePrivileges: Ref<any[]> = ref([]);

onMounted(async () => {
    await loadPrivileges();
    await GetAllUsers();
    await loadPrinters();
});

const GetAllUsers = async () => {
    let result: PGRESTResult = await pgrestapi.get(`users`);
    StoreUsers.value = result.data;
};

const close = async () => {
    emits("close");
};

const resetData = async () => {
    data.value.username = "";
    data.value.first_name = "";
    data.value.user_type = 2;
    data.value.password = "";
    data.value.last_name = "";
    data.value.company = "";
    data.value.email = "";
    data.value.errors = [];
}

const save = async () => {
    data.value.errors = [];
    if (data.value.password != data.value.passwordconfirm) { data.value.errors.push('Passwords do not match.'); }
    if (data.value.first_name == "") { data.value.errors.push('First name cant be empty.'); }
    if (data.value.last_name == "") { data.value.errors.push('Last name cant be empty.'); }
    //if (data.value.user_type == "") { data.value.errors.push('Please select a user type.'); }

    if (data.value.errors.length > 0) {
        for (let error of data.value.errors) {
            showError(error);
        }
        return;
    }
    if (data.value.user_type == 3 || data.value.user_type == 0) {
        data.value.digitization = true;
    } else if (data.value.user_type == 2) {
        data.value.digitization = false;
    }
    let obj = JSON.parse(JSON.stringify(data.value));
    delete obj.errors;
    delete obj.passwordconfirm;
    let result = await mosapiStore.createUser(obj);

    if (result.error) {
        showError(result.error.message); // emits("fail-save");
        return;
    }
    resetData();
    emits("save");
};

const loadPrinters = async () => {
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get("printers?order=printer_name.asc");

  if (result.error) {
    showError(result.error.message);
    return;
  }
  
  printersList.value = [];
  result.data.forEach((element: Printer) => {
    printersList.value.push({
      value: element.printer_name,
      label: `${element.printer_name} (${element.printer_ip})`,
    });
  });
};

const loadPrivileges = async () => {
	let result: PGRESTResult = new PGRESTResult();

	result = await pgrestapi.get("privileges?order=name.asc");

	if (result.error) {
		showError(result.error.message);
		return;
	}

	availablePrivileges.value = [];

	result.data.forEach((element: any) => {
		availablePrivileges.value.push({
			key: element.name,
			label: element.name,
			disabled: false
		});
	});
};

</script>
    
<template>
    <div>
        <div v-if="data.errors.length">
            <ul>
                <li v-for="error in data.errors">{{ error }}</li>
            </ul>
        </div>
        <el-form :model="data">
            <el-row>
                <el-col :span="24">
                    <el-form-item label="Username">
                        <el-input type="text" v-model="data.username" autocomplete="off" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="Password">
                        <el-input v-model="data.password" type="password" placeholder="Please input password"
                            autocomplete="off" show-password />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="24">
                    <el-form-item label="Confirm Password">
                        <el-input v-model="data.passwordconfirm" type="password"
                            placeholder="Please confirm your password" autocomplete="off" show-password />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="10">
                <el-col :span="24">
                    <el-form-item label="First Name">
                        <el-input v-model="data.first_name" autocomplete="off" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="10">
                <el-col :span="24">
                    <el-form-item label="Surname">
                        <el-input v-model="data.last_name" autocomplete="off" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="10">
                <el-col :span="24">
                    <el-form-item label="E-mail">
                        <el-input v-model="data.email" autocomplete="off" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="10">
                <el-col :span="24">
                    <el-form-item label="Company">
                        <el-input v-model="data.company" autocomplete="off" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="10">
                <el-col :span="24">
                    <el-form-item label="User Type">
                        <el-select v-model="data.user_type" placeholder="Please Select User Type">
                            <el-option label="Administrator" :value="UserType.Administrator" />
                            <el-option label="Supervisor" :value="UserType.Supervisor" />
                            <el-option label="Agent" :value="UserType.Agent" />
                            <el-option label="Digitizer" :value="UserType.Digitizer" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :span="12" v-if="data.user_type == UserType.Supervisor">
                    <el-form-item label="Has digitization rights">
                        <el-switch v-model="data.digitization" :active-text="$t('components.switch.yes')" :inactive-text="$t('components.switch.no')" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10" v-if="data.digitization || data.user_type === UserType.Administrator">
                <el-col :span="12">
                    <el-form-item label="Barcode Printer">
                        <el-select v-model="data.assigned_printer" placeholder="Select printer">
                            <el-option v-for="printer in printersList" :label="printer.label" :value="printer.value" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

			<el-row :gutter="10" v-if="mainStore.loggedUser.user_type === UserType.Administrator">
				<el-col :span="24">
					<el-form-item label="Privileges">
						<el-transfer v-model="data.privileges" :data="availablePrivileges" :titles="['Available', 'Assigned']" />
					</el-form-item>
				</el-col>
			</el-row>

            <el-row :gutter="10">
                <el-col :span="24">
                    <el-form-item class="btn-wrapper-components">
                        <el-button type="primary" @click="save()">{{ $t('generic.save') }}</el-button>
                        <el-button @click="close">{{ $t('generic.close') }}</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
    
<style scoped>

</style>
    