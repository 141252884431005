import { ref, Ref } from "vue";
import { defineStore } from "pinia";
import { useRouter } from "vue-router";
import User from "../classes/DB_Entities/User";
import MenuEntry from "../classes/DB_Entities/MenuEntry";

export const useMainStore = defineStore("main", () => {
  const router = useRouter();
  let loggedUser = ref<User>(new User());
  let menu = ref<{ [key: string]: MenuEntry[] }>({});
  let menuOrder = ref<string[]>([]);
  let menuIcon = ref<string[]>([]);
  let iscollapsed = ref<boolean>(false);
  let globalPortfolioID = ref<string>("");
  let globalDebtorID = ref<string>("");
  let globalLoanAgreementID = ref<string>("");

  let moneyConfig = ref({
    masked: false,
    prefix: '',
    suffix: ' €',
    thousands: '.',
    decimal: ',',
    precision: 2,
    disableNegative: true,
    disabled: false,
    min: 0,
    max: 2000000000,
    allowBlank: true,
    minimumNumberOfCharacters: 0,
  });

  let viewOptions = ref<any>({});

  async function setUser(user: User) {
    loggedUser.value = user;
  }

  async function setMenu(menu_: { [key: string]: MenuEntry[] }) {
    menu.value = menu_;
  }

  async function setMenuOrder(menuOrder_: string[]) {
    menuOrder.value = menuOrder_;
  }

  async function setMenuIcons(menuIcon_: string[]) {
    menuIcon.value = menuIcon_;
  }

  async function unsetUser() {
    loggedUser.value = new User();
  }

  return {
    loggedUser,
    menu,
    menuOrder,
    iscollapsed,
    menuIcon,
    moneyConfig,
    globalPortfolioID,
    globalDebtorID,
    globalLoanAgreementID,
    viewOptions,
    setUser,
    setMenu,
    setMenuOrder,
    setMenuIcons,
    unsetUser,
    //setGlobalPortfolio,
    // setGlobalDebtor,
    //setGlobalLoanAgreement,
  };
});
