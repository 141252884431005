import { ref } from "vue";
import { defineStore } from "pinia";
import LoginRequest from "../classes/LoginRequest";
import LoginReply from "../classes/LoginReply";
import CreateUserRequest from "../classes/CreateUserRequest";
import CreateUserReply from "../classes/CreateUserReply";
import UpdateUserRequest from "../classes/UpdateUserRequest";
import UpdateUserReply from "../classes/UpdateUserReply";
import UpdatePasswordReply from "../classes/UpdatePasswordReply";
import APIError from "../classes/APIError";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useI18n } from "vue-i18n";
import { DateTime } from "luxon";


export const useMOSAPIStore = defineStore("mos_api", () => {

  let ip = ref<string>(import.meta.env.VITE_MOSAPI_URL);
  let port = ref<number>(0);
  let token = ref<string>("");

  let apiURL = (): string => {
    if (port.value === 0) return ip.value;

    return `${ip.value}:${port.value}/`;
  };

  let setToken = async (token_: string) => {
    token.value = token_;
  };

  function unsetToken() {
    token.value = "";
  }

  let login = async (username: string, password: string): Promise<LoginReply> => {
    let fv: LoginReply = new LoginReply();

    let body: LoginRequest = {
      username,
      password,
    };

    let result: AxiosResponse | null = null;

    try {
      result = await axios.post(apiURL() + "login/", body);

      fv.screens = result?.data.menu;
      fv.token = result?.data.token;
      fv.user = result?.data.user;
    } catch (err: any | AxiosError) {
      fv.error = new APIError();

      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          fv.error.code = "1";
          fv.error.message = "Wrong credentials";
        } else {
          fv.error.code = "2";
          //fv.error.message = "Possible connectivity issue";
          fv.error.message = err.message;

        }
      } else {
        fv.error.code = "9999";
        fv.error.message = err.message;
      }
    }

    return fv;
  };

  let createUser = async (user_obj: any): Promise<CreateUserReply> => {
    let fv = new CreateUserReply();

    let headers = {
      Authorization: `Bearer ${token.value}`,
    }

    let body: CreateUserRequest = user_obj;

    let result: AxiosResponse | null = null;

    try {
      result = await axios.post(apiURL() + "createUser", body, { headers });
    } catch (err: any | AxiosError) {
      fv.error = new APIError();

      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          fv.error.code = "1";
          fv.error.message = "Username already exists";
        } else if (err.response?.status === 402) {
          fv.error.code = "2";
          fv.error.message = "Unknown error";
        }
      } else {
        fv.error.code = "9999";
        fv.error.message = err.message;
      }
    }

    return fv;
  };

  let updateUser = async (user_obj: any): Promise<UpdateUserReply> => {
    let fv = new UpdateUserReply();

    let headers = {
      Authorization: `Bearer ${token.value}`,
    }

    let body: UpdateUserRequest = user_obj;

    let result: AxiosResponse | null = null;

    try {
      result = await axios.post(apiURL() + "updateUser", body, { headers });
    } catch (err: any | AxiosError) {
      fv.error = new APIError();

      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          fv.error.code = "1";
          fv.error.message = "User does not exist";
        } else if (err.response?.status === 402) {
          fv.error.code = "2";
          fv.error.message = "Unknown error";
        }
      } else {
        fv.error.code = "9999";
        fv.error.message = err.message;
      }
    }

    return fv;
  };

  let updatePassword = async (user_id: number, password: string): Promise<UpdatePasswordReply> => {
    let fv = new UpdatePasswordReply();

    let headers = {
      Authorization: `Bearer ${token.value}`,
    }

    let body: any = {
      id: user_id,
      password: password
    };

    let result: AxiosResponse | null = null;

    try {
      result = await axios.post(apiURL() + "updatePassword", body, { headers });
    } catch (err: any | AxiosError) {
      fv.error = new APIError();

      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          fv.error.code = "1";
          fv.error.message = "User does not exist";
        } else if (err.response?.status === 402) {
          fv.error.code = "2";
          fv.error.message = "No id was provided for /updatePassword";
        } else if (err.response?.status === 403) {
          fv.error.code = "3";
          fv.error.message = "No password was provided for /updatePassword";
        } else if (err.response?.status === 404) {
          fv.error.code = "4";
          fv.error.message = "Unknown API Error";
        }
      } else {
        fv.error.code = "9999";
        fv.error.message = err.message;
      }
    }

    return fv;
  };

  let getSummaryCheckKOD = async (loan_agreement_id: number, ar_sumbashs: string): Promise<string> => {

    let headers = {
      Authorization: `Bearer ${token.value}`,
    }

    let result: AxiosResponse | null = null;

    try {
      result = await axios.post(apiURL() + "export/summary_check_kod/" + loan_agreement_id, {}, { headers, responseType: 'blob' });

      // console.log(JSON.stringify(result?.data))
      if (result?.data.error !== undefined) {
        return result.data.error.message;
      }

      let filename = `summary_check_kod_${ar_sumbashs}_${DateTime.now().toFormat('yyyy-MM-dd_HH_mm_ss')}.xlsx`
      forceDownload(result as AxiosResponse, filename)
    } catch (err: any | AxiosError) {
      return 'Could not find all required data for loan agreement summary.';
    };

    return "";
  }

  let getSummaryCheckAL = async (loan_agreement_id: number, ar_sumbashs: string) => {

    let headers = {
      Authorization: `Bearer ${token.value}`,
    }

    let result: AxiosResponse | null = null;

    try {
      result = await axios.post(apiURL() + "export/summary_check_al/" + loan_agreement_id, {}, { headers, responseType: 'blob' });

      // console.log(JSON.stringify(result))
      if (result?.data.error !== undefined) {
        return result.data.error.message;
      }

      let filename = `summary_check_al_${ar_sumbashs}_${DateTime.now().toFormat('yyyy-MM-dd_HH_mm_ss')}.xlsx`
      forceDownload(result as AxiosResponse, filename)
    } catch (err: any | AxiosError) {
        if (err.response?.status === 405) {
          // console.log(await err.response.data.text());
          return JSON.parse((await err.response.data.text())).error.message;
      } else {
        console.log(err);
      }

      return 'Could not find all required data for loan agreement summary.';
    };

    return "";
  }

  let getexportDT = async (portfolio_id: number): Promise<string> => {
    let headers = {
      Authorization: `Bearer ${token.value}`,
    }

    let result: AxiosResponse | null = null;

    try {
      result = await axios.post(apiURL() + "export_digitized/digitizedDT/"+portfolio_id, {}, { headers, responseType: 'blob' });

      // console.log(JSON.stringify(result?.data))
      if (result?.data.error !== undefined) {
        return result.data.error.message;
      }

      let filename = `datatape_${DateTime.now().toFormat('yyyy-MM-dd_HH_mm_ss')}.xlsx`
      forceDownload(result as AxiosResponse, filename)
    } catch (err: any | AxiosError) {
      return 'Could not find all required data for loan agreement summary.';
    };

    return "";
  }

  let getexportProt = async (portfolio_id: number): Promise<string> => {
    let headers = {
      Authorization: `Bearer ${token.value}`,
    }

    let result: AxiosResponse | null = null;

    try {
      result = await axios.post(apiURL() + "export_digitized/digitizedProt/"+portfolio_id, {}, { headers, responseType: 'blob' });

      // console.log(JSON.stringify(result?.data))
      if (result?.data.error !== undefined) {
        return result.data.error.message;
      }

      let filename = `protocol_${DateTime.now().toFormat('yyyy-MM-dd_HH_mm_ss')}.xlsx`
      forceDownload(result as AxiosResponse, filename)
    } catch (err: any | AxiosError) {
      return 'Could not find all required data for loan agreement summary.';
    };

    return "";
  }

  let getexportProtFrontier = async (portfolio_id: number): Promise<string> => {
    let headers = {
      Authorization: `Bearer ${token.value}`,
    }

    let result: AxiosResponse | null = null;

    try {
      result = await axios.post(apiURL() + "frontierProtocol/frontierProtocol/"+portfolio_id, {}, { headers, responseType: 'blob' });

      // console.log(JSON.stringify(result?.data))
      if (result?.data.error !== undefined) {
        return result.data.error.message;
      }

      let filename = `protocol_FC_${DateTime.now().toFormat('yyyy-MM-dd_HH_mm_ss')}.xlsx`
      forceDownload(result as AxiosResponse, filename)
    } catch (err: any | AxiosError) {
      return 'Could not find all required data for loan agreement summary.';
    };

    return "";
  }

  const forceDownload = (result: AxiosResponse, filename: string) => {
    const url = window.URL.createObjectURL(new Blob([(result as AxiosResponse).data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }

  const updateEntityApproveStatus = async (table_name: string, entity_id: number): Promise<void> => {
    let fv: any = {};

    let headers = {
      Authorization: `Bearer ${token.value}`,
    }

    let body: any = {
      table_name,
      entity_id
    }

    let result: AxiosResponse | null = null;

    try {
      result = await axios.post(apiURL() + "updateEntityApproveStatus", body, { headers });
    } catch (err: any | AxiosError) {
      fv.error = new APIError();

      if (axios.isAxiosError(err)) {
        if (err.response?.status === 402) {
          fv.error.code = "2";
          fv.error.message = "Unknown error";
        }
      } else {
        fv.error.code = "9999";
        fv.error.message = err.message;
      }
    }

    return fv;
  };

  let downloadLog = async (filename: string) => {
    let headers = {
      Authorization: `Bearer ${token.value}`,
    }

    let result: AxiosResponse | null = null;

    try {
      result = await axios.post(apiURL() + "getLog/" + filename, {}, { headers, responseType: 'blob' });

      if (result?.data.error !== undefined) {
        return result.data.error.message;
      }

      forceDownload(result as AxiosResponse, filename + '.log');
    } catch (err: any | AxiosError) {
      console.log(err);
      return 'Could not retrieve import log file';
    };

    return "";
  }

  let getDebtorSummary = async (debtor_id: number) => {

    let headers = {
      Authorization: `Bearer ${token.value}`,
    }

    let result: AxiosResponse | null = null;

    try {
      result = await axios.post(apiURL() + "reports/debtor_group?debtor_id=" + debtor_id, {}, { headers, responseType: 'blob' });

      if (result?.data.error !== undefined) {
        return result.data.error.message;
      }

      let filename = `debtor_summary_${DateTime.now().toFormat('yyyy-MM-dd_HH_mm_ss')}.xlsx`
      forceDownload(result as AxiosResponse, filename)
    } catch (err: any | AxiosError) {
      console.log(err);
      return 'Could not get debtor summary.';
    };

    return "";
  }

  let getGroupSummary = async (group_id: number) => {

    let headers = {
      Authorization: `Bearer ${token.value}`,
    }

    let result: AxiosResponse | null = null;

    try {
      result = await axios.post(apiURL() + "reports/debtor_group?group_id=" + group_id, {}, { headers, responseType: 'blob' });

      if (result?.data.error !== undefined) {
        return result.data.error.message;
      }

      let filename = `group_summary_${DateTime.now().toFormat('yyyy-MM-dd_HH_mm_ss')}.xlsx`
      forceDownload(result as AxiosResponse, filename)
    } catch (err: any | AxiosError) {
      console.log(err);
      return 'Could not get debtor summary.';
    };

    return "";
  }

  let notify = async (obj: any) => {
    let headers = {
      Authorization: `Bearer ${token.value}`,
    }

    let result: AxiosResponse | null = null;

    try {
      result = await axios.post(apiURL() + "notify", obj, { headers, responseType: 'blob' });

      if (result?.data.error !== undefined) {
        return result.data.error.message;
      }

    } catch (err: any | AxiosError) {
      console.log(err);
    };
  }

  return {
    ip,
    port,
    token,
    apiURL,
    downloadLog,
    getDebtorSummary,
    getGroupSummary,
    getSummaryCheckAL,
    getSummaryCheckKOD,
    login,
    notify,
    setToken,
    unsetToken,
    createUser,
    updateEntityApproveStatus,
    updateUser,
    updatePassword,
    getexportDT,
    getexportProt,
    getexportProtFrontier,
  };
});
