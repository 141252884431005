<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import TaskTemplate from "../classes/DB_Entities/TaskTemplates";
import { ElMessage } from "element-plus";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import PGRESTResult from "../classes/PGRESTResult";
import UserType from "../enums/UserType";
import CustomField from "../classes/DB_Entities/CustomField";

const props = defineProps<{
	tasktemplateid: number;
	newEntity?: boolean;
}>();

interface Option {
	key: number
	label: string
	disabled: boolean
	type: string
}

const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });
let data = ref<TaskTemplate>(new TaskTemplate());
let fieldsList = ref<any>([]);

const {
	showError,
	isLoading,
	close,
} = useGenericMethodsVariables();

const getCustomFields = async () => {
	const fv: Option[] = []
	let fields = await pgrestapi.get("custom_fields?status=eq.1");
	let fieldsData = fields.data;

	fieldsData.forEach((fieldRow: CustomField, index: number) => {
		fv.push({
			label: `${fieldRow.name_en} (${fieldRow.type}) (${fieldRow.id})`,
			key: fieldRow.id,
			disabled: false,
			type: fieldRow.type,
		})
	});

	// console.log('zzz', JSON.stringify(fv));

	return fv;
}

onMounted(async () => {
	console.log("tasktemplateid", props.tasktemplateid, "newEntity", props.newEntity);

	fieldsList.value = await getCustomFields();
	// console.log(JSON.stringify(fieldsList.value));

	if (props.newEntity === true) {
		return;
	}

	isLoading.value = true;
	await loadData();
	isLoading.value = false;
});

const onPreSave = async () => {
	let statusListFound = false;
	console.log('data.value.fields_ids', JSON.stringify(data.value.fields_ids));

	for (let field_id of data.value.fields_ids) {
		console.log('checking field_id', field_id)
		let field = fieldsList.value.find((item: any) => item.key === field_id);
		if (field.type === 'status_list') {
			statusListFound = true;
			break;
		}
	}

	if (!statusListFound) {
		showError('You must select at least one Status List field');
		return;
	}
	//   console.log(JSON.stringify(fullObj.value));
	//VALIDATION CHECKS
	await save();
};

const loadData = async () => {
	isLoading.value = true;

	let result: PGRESTResult = new PGRESTResult();

	result = await pgrestapi.get("task_templates?id=eq." + props.tasktemplateid);
	if (result.error) {
		showError(result.error.message);

		return;
	}

	data.value = result.data[0];

	isLoading.value = false;
};

const save = async () => {
	if (props.newEntity) {
		let obj = JSON.parse(JSON.stringify(data.value));
		delete obj.id;
		let result = await pgrestapi.post_data("task_templates", obj);
	} else {
		console.log('patching', JSON.stringify(data.value))
		let result = await pgrestapi.patch("task_templates?id=eq." + props.tasktemplateid, {
			name_el: data.value.name_el,
			name_en: data.value.name_en,
			fields_ids: data.value.fields_ids
		});
	}
	emits("save");
}

const filterMethod = (query: string, item: any) => {
	// console.log(query + ' ' + JSON.stringify(item))
	return item.label.toLowerCase().includes(query.toLowerCase())
}

</script>

<template>
	<div v-loading="isLoading">
		<el-form>
			<el-row :gutter="10">
				<el-col :span="12">
					<el-form-item :label="$t('TaskTemplatesList.name_el')">
						<el-input v-model="data.name_el" autocomplete="off" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item :label="$t('TaskTemplatesList.name_en')">
						<el-input v-model="data.name_en" autocomplete="off" />
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item style="margin-top: 0px">
						<el-transfer v-model="data.fields_ids" filterable :filter-method="filterMethod"
							filter-placeholder="Fields" :data="fieldsList" target-order="push"
							:titles="['Available Fields', 'Selected Fields']" class="myCLASS" />
					</el-form-item>
				</el-col>

				<p></p><br><br><br><br><br>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item class="btn-wrapper-components">
						<el-button type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
						<el-button @click="close">{{ $t('generic.close') }}</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<style scoped>
.myCLASS .el-transfer-panel__filter {
	margin: 0px !important;
}

.el-transfer {
	--el-transfer-panel-width: 400px;
}
</style>
