<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import PGRESTResult from "../classes/PGRESTResult";
import UserType from "../enums/UserType";
import { Money3Component } from 'v-money3'

const props = defineProps<{
	projecttaskid: number;
}>();

const emits = defineEmits(["saveTask", "close", "fail-save"]);

class ProjectTask {
	project_task_id: number = -1;
	allowed_users: any[] = [];
	current_user_id: number = -1;
	hmeromhnia_anathesis: string = "";
}

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });
let project_task_value_entries = ref<any[]>([]);
let originalEntries: any[] = [];
let originalProjectEntry: ProjectTask = new ProjectTask();
let projectTask = ref<ProjectTask>(new ProjectTask());

let moneyConfig = ref({
  masked: false,
  prefix: '',
  suffix: ' €',
  thousands: '.',
  decimal: ',',
  precision: 2,
  disableNegative: true,
  disabled: false,
  min: 0,
  max: 2000000000,
  allowBlank: false,
  minimumNumberOfCharacters: 1,
})

const {
	showError,
	isLoading,
	close,
} = useGenericMethodsVariables();

onMounted(async () => {
	isLoading.value = true;
	console.log("projecttaskid", props.projecttaskid);

	await loadData();
	isLoading.value = false;
});

const onPreSave = async () => {
	//   console.log(JSON.stringify(fullObj.value));
	//VALIDATION CHECKS
	await save();
};

const loadData = async () => {
	isLoading.value = true;

	let result: PGRESTResult = new PGRESTResult();

	result = await pgrestapi.get("view_project_task_data?project_task_id=eq." + props.projecttaskid);

	if (result.error) {
		showError(result.error.message);
		return;
	}

	originalEntries = JSON.parse(JSON.stringify(result.data));

	project_task_value_entries.value = result.data;

	result = await pgrestapi.get("view_project_task?project_task_id=eq." + props.projecttaskid);

	if (result.error) {
		showError(result.error.message);
		return;
	}

	// console.log(JSON.stringify(result));
	originalProjectEntry = JSON.parse(JSON.stringify(result.data[0]));
	projectTask.value = result.data[0];

	isLoading.value = false;
};

const save = async () => {

	isLoading.value = true;

	let projectTaskChanges: any = {};

	if (originalProjectEntry.current_user_id !== projectTask.value.current_user_id) {
		projectTaskChanges.user_id = projectTask.value.current_user_id;
	}

	if (originalProjectEntry.hmeromhnia_anathesis !== projectTask.value.hmeromhnia_anathesis) {
		projectTaskChanges.hmeromhnia_anathesis = projectTask.value.hmeromhnia_anathesis;
	}

	if (Object.keys(projectTaskChanges).length > 0) {
		let result = await pgrestapi.patch("project_tasks?id=eq." + props.projecttaskid, projectTaskChanges);

		if (result.error) {
			showError(result.error.message);
			isLoading.value = false;
			return;
		}

		if (projectTaskChanges.user_id) {
			let newUser = projectTask.value.allowed_users.find((u) => projectTaskChanges.user_id === u.id);
			await pgrestapi.post_data("project_tasks_edit_history", {
				project_task_id: props.projecttaskid,
				editor_user_id: mainStore.loggedUser.id,
				field_en: "Assigned user",
				field_el: "Ανατεθημένος χρήστης",
				value: `${newUser.username} (${newUser.first_name} ${newUser.last_name})`
			});
		}

		if (projectTaskChanges.hmeromhnia_anathesis) {
			await pgrestapi.post_data("project_tasks_edit_history", {
				project_task_id: props.projecttaskid,
				editor_user_id: mainStore.loggedUser.id,
				field_en: "Assign date",
				field_el: "Ημερομηνία ανάθεσης",
				value: projectTaskChanges.hmeromhnia_anathesis,
			});
		}
	}

	for (let project_task_value of project_task_value_entries.value) {
		let original_task_value = originalEntries.find((x) => x.id === project_task_value.id);

		if (!original_task_value) {
			console.log('original_task_value not found', project_task_value.id);
			continue;
		}

		// Convert boolean to string for comparison
		// if (project_task_value.type === 'boolean') {
			// console.log('convert boolean')
			project_task_value.task_variable_value = project_task_value.task_variable_value + '';
		// }

		// Skip if there's no value change
		if (JSON.stringify(original_task_value.task_variable_value) === JSON.stringify(project_task_value.task_variable_value)) {
			continue;
		}

		console.log('patching', JSON.stringify(project_task_value));
		let result = await pgrestapi.patch("project_task_values?id=eq." + project_task_value.id, {
			task_variable_value: project_task_value.task_variable_value
		});

		if (result.error) {
			showError(result.error.message);
			isLoading.value = false;
			return;
		}

		await pgrestapi.post_data("project_tasks_edit_history", {
			project_task_id: project_task_value.project_task_id,
			editor_user_id: mainStore.loggedUser.id,
			field_en: project_task_value.name_en,
			field_el: project_task_value.name_el,
			value: project_task_value.task_variable_value,
		});
	}

	let statusValue = project_task_value_entries.value.find((x) => x.type === 'status_list');

	isLoading.value = false;
	emits("saveTask", statusValue?.task_variable_value);
}

</script>

<template>
  <div v-loading="isLoading">
    <el-form>
		<el-row :gutter="10">
			<el-col :span="24">
				<el-form-item label="Ανατεθειμένος χρήστης">
					<el-select clearable v-model="projectTask.current_user_id" autocomplete="off" class="m-2 header-filter"
						placeholder=" " value-key="id" style="width: 100%" :disabled="mainStore.loggedUser.user_type > UserType.Supervisor">
						<el-option v-for="user in projectTask.allowed_users" :key="user.id" :label="`${user.username} (${user.first_name} ${user.last_name})`" :value="user.id" />
					</el-select>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row :gutter="10">
			<el-col :span="24">
				<el-form-item label="Ημερομηνία ανάθεσης">
					<el-date-picker v-model="projectTask.hmeromhnia_anathesis" autocomplete="off" format="DD-MM-YYYY" value-format="YYYY-MM-DD" :disabled="mainStore.loggedUser.user_type > UserType.Supervisor" />
				</el-form-item>
			</el-col>
		</el-row>
      <el-row v-for="project_task_value of project_task_value_entries" :gutter="10">
        <el-col :span="24">
          <el-form-item :label="project_task_value.name_en">
            <el-input v-if="project_task_value.type === 'text'" v-model="project_task_value.task_variable_value" autocomplete="off" />
            <el-date-picker v-else-if="project_task_value.type === 'date'" v-model="project_task_value.task_variable_value" autocomplete="off" format="DD-MM-YYYY" value-format="YYYY-MM-DD" />
            <el-switch v-else-if="project_task_value.type === 'boolean'" v-model="project_task_value.task_variable_value" :active-text="$t('components.switch.yes')" :inactive-text="$t('components.switch.no')" />
            <el-select v-else-if="project_task_value.type === 'list' || project_task_value.type === 'status_list'"v-model="project_task_value.task_variable_value" class="m-2 header-filter" size="small" clearable filterable placeholder="">
              <el-option v-for="list_item in project_task_value.dropdown_list_values" :key="list_item" :label="list_item" :value="list_item" />
            </el-select>
			<el-input-number v-if="project_task_value.type === 'number'" v-model="project_task_value.task_variable_value" autocomplete="off" :precision="2" :step="0.01" />
			<Money3Component v-if="project_task_value.type === 'money'" class="el-input__inner"
            	v-model.number="project_task_value.task_variable_value" v-bind="moneyConfig"></Money3Component>

          </el-form-item>
        </el-col>
      </el-row>
      <p></p><br><br><br><br><br>
      <el-row :gutter="10">
      <el-col :span="24">
        <el-form-item class="btn-wrapper-components">
          <el-button type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
          <el-button @click="close">{{ $t('generic.close') }}</el-button>
        </el-form-item>
      </el-col>
    </el-row>
    </el-form>
  </div>
</template>

<style scoped>
.myCLASS .el-transfer-panel__filter {margin:0px !important;}
.el-transfer {
    --el-transfer-panel-width: 400px;
}
</style>
