import ITableName from "../../interfaces/ITableName";

class ConfigObjOfIns implements ITableName {
  id?: number = -1;
  obj_of_ins: string = "";

  static getTableNameStatic(): string {
    return "config_obj_of_ins";
  }

  getTableName(): string {
    return ConfigObjOfIns.getTableNameStatic();
  }
}

export default ConfigObjOfIns;
