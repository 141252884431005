<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMOSAPIStore } from "../store/mos_api";

import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { Document } from "@element-plus/icons-vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import UserType from "../enums/UserType";

const { showError } = useGenericMethodsVariables();

const mainStore = useMainStore();
const mosapi = useMOSAPIStore();
const pgrestapi = usePGRESTAPIStore();

let runDebtorSummary = ref(false);
let runGroupSummary = ref(false);
let isLoading = ref(false);
let debtorsList = ref([]);
let debtorForReport = ref("");
let groupsList = ref([]);
let groupForReport = ref("");

const debtorReports = [
    {
        name: 'Summary',
    },
]

const groupReports = [
    {
        name: 'Summary',
    },
]

const loanAgreementReports = [
    {
        name: 'Summary Check',
    },
];

const loadDebtors = async () => {
    isLoading.value = true;
    let addedFilter = "";

    try {
        if (mainStore.loggedUser.user_type == UserType.Agent) {
            addedFilter += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
        } else if (mainStore.loggedUser.user_type == UserType.Supervisor) {
            addedFilter += `&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`;
        }

        let result: PGRESTResult = await pgrestapi.get("view_mosdebtors_view1?select=id,fullname,vat_number,cid,group_id,group_name"
            + addedFilter + "&approve_status=eq.1&order=fullname.asc");

        debtorsList.value = result.data;

        // console.log(JSON.stringify(result.data))
        
        let dict: any = {};
        for (let i = 0; i < result.data.length; ++i) {
            if (result.data[i].group_id === null)
                continue;
            if (!(result.data[i].group_id in dict)) {
                dict[result.data[i].group_id] = result.data[i].group_name;
                console.log(result.data[i].group_id, result.data[i].group_name);
            }
        }

        for (let key in dict) {
            (groupsList.value as any).push({
                id: key,
                groupname: dict[key]
            });
        }
    } catch (error) {
        showError(error as any);
    }
    isLoading.value = false;
};

onMounted(async () => {
    await loadDebtors();
});

const debtorChanged = async () => {
    console.log(debtorForReport.value);
}

const generateDebtorSummary = async () => {
    if (debtorForReport.value == "") {
        showError("Please select debtor");
        return;
    }

    isLoading.value = true;
    let result = await mosapi.getDebtorSummary(parseInt(debtorForReport.value));
    isLoading.value = false;
    runDebtorSummary.value = false;
    debtorForReport.value = "";
}

const groupChanged = () => {
    console.log(groupForReport.value);
}

const generateGroupSummary = async () => {
    if (groupForReport.value == "") {
        showError("Please select group");
        return;
    }

    isLoading.value = true;
    let result = await mosapi.getGroupSummary(parseInt(groupForReport.value));
    isLoading.value = false;
    runGroupSummary.value = false;
    groupForReport.value = "";
}
</script>

<template>
    <div class="content-wrapper">
        <div>
            Debtor reports
        </div>

        <el-table :data="debtorReports" border style="width: 100%">
            <el-table-column prop="name" label="Report name" width="350" />
            <el-table-column fixed="right" :label="$t('global.operations')">
                <template #default="scope">
                    <el-tooltip effect="dark" content="Generate report" placement="top">
                        <el-button size="small" type="primary" class="btn-warn" :icon="Document" circle
                            @click="runDebtorSummary = true"/>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>

        <br />

        <br />

        <div>
            Group reports (for Debtors)
        </div>

        <el-table :data="groupReports" border style="width: 100%">
            <el-table-column prop="name" label="Report name" width="350" />
            <el-table-column fixed="right" :label="$t('global.operations')">
                <template #default="scope">
                    <el-tooltip effect="dark" content="Generate report" placement="top">
                        <el-button size="small" type="primary" class="btn-warn" :icon="Document" circle 
                        @click="runGroupSummary = true"/>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>

        <br />

        <br />

        <!-- <div>
            Loan Agreement reports
        </div>

        <el-table :data="loanAgreementReports" border style="width: 100%">
            <el-table-column prop="name" label="Report name" width="350" />
            <el-table-column fixed="right" :label="$t('global.operations')">
                <template #default="scope">
                    <el-tooltip effect="dark" content="Generate report" placement="top">
                        <el-button size="small" type="primary" class="btn-warn" :icon="Document" circle />
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table> -->

        <el-dialog v-model="runDebtorSummary" title="Debtor Summary Report" @update:model-value="runDebtorSummary = false; debtorForReport = '';">
            <el-form v-loading="isLoading" label-width="120px">
                <el-form-item label="Debtor" prop="debtor">
                    <el-select filterable clearable v-model="debtorForReport" placeholder="Select debtor" @change="debtorChanged">
                        <el-option v-for="debtor in debtorsList" :key="(debtor as any).id" :label="(debtor as any).fullname"
                            :value="(debtor as any).id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button :disabled="isLoading" @click="runDebtorSummary = false">Cancel</el-button>
                <el-button :disabled="isLoading" type="primary" @click="generateDebtorSummary">Run</el-button>
            </span>
        </el-dialog>

        <el-dialog v-model="runGroupSummary" title="Group Summary Report" @update:model-value="runGroupSummary = false; groupForReport = '';">
            <el-form v-loading="isLoading" label-width="120px">
                <el-form-item label="Group">
                    <el-select filterable clearable v-model="groupForReport" placeholder="Select group" @change="groupChanged">
                        <el-option v-for="group in groupsList" :key="(group as any).id" :label="(group as any).groupname"
                            :value="(group as any).id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button :disabled="isLoading" @click="runGroupSummary = false">Cancel</el-button>
                <el-button :disabled="isLoading" type="primary" @click="generateGroupSummary">Run</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<style scoped></style>
