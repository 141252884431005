import ITableName from "../../interfaces/ITableName";
import Client from "./Client";

class Portfolio implements ITableName {
  id: number = -1;
  mosclient_id: string = "";
  portf_name: string = "";
  asset_owner: string = "";
  completion_rate: string = "";
  mosclient: Client = new Client();
  allowed_users: number[] = [];
  digitization: boolean = false;
  digital_code: string = "";

  static getTableNameStatic(): string {
    return "portfolio";
  }

  getTableName(): string {
    return Portfolio.getTableNameStatic();
  }
}

export default Portfolio;
