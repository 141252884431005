import ITableName from "../../interfaces/ITableName";

class Apografi_Frontier implements ITableName {
id: number=-1;
borrower_id?: string;
borrower_name?: string;
asset_class?: string;
contract_id?: string;
loan_id_31_12_2021?: string;
origination_date?: string;
loan_type_31_12_2021?: string;
loan_id_open_date_31_12_2021?: string;
last_restructured_cut_off?: string;
loan_id_30_06_2020?: string;
origination_date_loan_id_30_06_2020?: string;
looking_for_initial?: string;
looking_for_last?: string;
looking_for_collateral?: string;
breach_category?: string;
management_branch_31_12_2021_meta_609?: string;
priority?: number;
activation_branch?: string;
management_branch_30_06_2020?: string;
management_branch_31_12_2021_me_609_pro_antik?: string;
dms_status?: string;
iron_mountain_deliv_status?: string;
dovlaue_delivery_status?: string;
kwd?: string;
katastasi?: string;
sxolio?: string;
anathesi_mo_officer?: number;
hmerominia_enarxis_epexergasias?: string;
imerominia_olokl_epexerg?: string;
mo_supervisor?: number;
imeromhnia_elegxou?: string;
anartimeno_psif_iliko_public_dms?: string;
arxiki_sumvasi_pliris?: string;
teleutaia_pp_pliris?: string;
eggrafa_prosimiosis?: string;
initial_loan_id?: string;
initial_contract_id?: string;
initial_digitization_date?: string;
initial_subfolder?: string;
initial_pages?: string;
last_amend_loan_id?: string;
last_amend_contract_id?: string;
last_amend_digitization_date?: string;
last_amend_subfolder?: string;
last_amend_pages?: string;
re_collateral_loan_id?: string;
re_collateral_contract_id?: string;
re_collateral_digitization_date?: string;
re_collateral_subfolder?: string;
re_collateral_pages?: string;
elegxos_dms?: string;
fakelos_rc?: string;
mitos?: string;
arxiki_sumv_pliris_se_allo_sustima?: string;
teleutaia_pp_pliris_se_allo_sustima?: string;
eggrafa_pros_se_allo_sustima?: string;
entopismos_se_sustima?: string;
remedy?: string;
apothikeusi_psifiakou_ulikou?: string;
portfolio_id?:number;
row_problem?:string;
allowed_users: Array<number> = [];
ektiposi?: boolean=false;
ektiposi_date?: string;
epivevaiosi_kataxorisis?: boolean=false;
epivevaiosi_date?: string;
xristis?: number;
arithmos_kivotiou?: string;
remedy_actions_comment?: string;


  static getTableNameStatic(): string {
    return "apografi_frontier";
  }

  getTableName(): string {
    return Apografi_Frontier.getTableNameStatic();
  }
}

export default Apografi_Frontier;
