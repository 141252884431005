<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import { Edit, Delete, Notebook, User, Plus, RefreshLeft, Lock } from "@element-plus/icons-vue";
import AddEdit_Client from "../components/AddEdit_Client.vue";
import UserType from "../enums/UserType";
import { useMainStore } from "../store/main";
import { useSort } from "../composables/sort";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import DeleteEntityDialog from "../components/DeleteEntityDialog.vue";

const { showError, showSuccess, isLoading } = useGenericMethodsVariables();

const mainStore = useMainStore();
const router = useRouter();
const pgrestapi = usePGRESTAPIStore();

let filterPending = ref(false);

let dataList = ref([]);
let dataCount = ref(0);
let lastPage = ref(0);
let selectedRow = ref(-1);
let deleteDialogVisible = ref(false);
let addEditVisible = ref(false);
let LockedRow = ref(false);
let UnlockAllRowsVisible = ref(false);
let testObject = {};
const { onSortChange, sortColumn, sortOrder } = useSort();

const handleClickEdit = async (row_id: number) => {
  selectedRow.value = row_id;
 /* let ResultLockedRow = await pgrestapi.get("mosclients?select=*&id=eq." + row_id);
  LockedRow = ResultLockedRow.data[0].lockedrow;
  LockedRow= false; //να κρυφτει για να παιξει μαζι με καποια &&false 
  if(LockedRow){
    await loadPage(lastPage.value);
    return
  } else if (LockedRow == false){
    let result = await pgrestapi.patch("mosclients?id=eq." + row_id, { lockedrow: 'true' });
    await loadPage(lastPage.value);*/
    addEditVisible.value = true;
  /*}*/

};

const handleClickToUnlock = async (row_id: number) => {
  let result = await pgrestapi.patch("mosclients?id=eq." + row_id, { lockedrow: 'false' });
  await loadPage(lastPage.value);
}

const UnlockAllLockedRows = async () => {
  let result = await pgrestapi.patch("mosclients?" , { lockedrow: 'false' });
  await loadPage(lastPage.value);
  UnlockAllRowsVisible.value = false;
}



const handleClickAdd = () => {
  selectedRow.value = -1;
  addEditVisible.value = true;
};

const handleClickDelete = (row_id: number) => {
  selectedRow.value = row_id;
  deleteDialogVisible.value = true;
};

const onDelete = async () => {
  showSuccess("MOS Client deleted successfully");
  deleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const handlePortfolioClick = (client_id: number, client_name: string) => {
  router.push({
    name: "Portfolios",
    query: { client_id, client_name },
  });
};

onMounted(async () => {
  const ResultLockedRow = await pgrestapi.get("mosclients?select=*&lockedrow=eq.true");
  if (ResultLockedRow.data.length > 0) {
    UnlockAllRowsVisible.value = true;
    UnlockAllRowsVisible.value = false;
  } else {
    UnlockAllRowsVisible.value = false;
  }
  sortColumn.value = "clientname";
  sortOrder.value = "asc";
  loadPage(1);
});

const loadPage = async (page: number) => {
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;

  let filterURL = "";

  if (filterPending.value) {
    filterURL += "?pending_approvals=gt.0";
  }

  if (sortOrder.value !== "") {
    if (filterURL === "") {
      filterURL = "?";
    }
    filterURL += `&order=${sortColumn.value}.${sortOrder.value}`;
  }

  let result: PGRESTResult = await pgrestapi.getPaginated(
    "view_mosclients_view1" + filterURL,
    page
  );

  if (result.error) {
    showError(result.error.message);

    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;

  let ResultLockedRow = await pgrestapi.get("mosclients?select=*&lockedrow=eq.true");
  if (ResultLockedRow.data.length > 0) {
    UnlockAllRowsVisible.value = true;
  } else {
    UnlockAllRowsVisible.value = false;
  }

  isLoading.value = false;
};

const onSave = async () => {
  ElMessage({
    showClose: true,
    message: "Client updated",
    type: "success",
    duration: 1000,
  });

  addEditVisible.value = false;
  selectedRow.value = -1;

  await loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Client failed to update",
    type: "error",
    duration: 2000,
  });

  addEditVisible.value = false;
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const onCloseAddEdit = async () => {
  let result = await pgrestapi.patch("mosclients?id=eq." + selectedRow.value, { lockedrow: 'false' });
  await loadPage(lastPage.value);
  addEditVisible.value = false;
  selectedRow.value = -1;
};
</script>

<template>
  <div class="content-wrapper" v-loading="isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">{{ $t("ClientsView.clients") }} ({{ dataCount }})</h3>
      </el-col>
    </el-row>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn"> </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link v-if="mainStore.loggedUser.user_type === UserType.Administrator" type="primary" size="small"
            class="btn-add" style="float: right; margin: 2.5px" @click="handleClickAdd()" :icon="Plus" />
            <el-tooltip :content="$t('global.unlockalllockedrow')" v-if="UnlockAllRowsVisible&&false">
              <el-button link type="primary" size="small"
              class="btn-add" style="float: right; margin: 2.5px; background-color: #f56c6c !important;" @click="UnlockAllLockedRows" :icon="Lock" />
            </el-tooltip>
        </el-col>
      </el-row>
    </div>

    <el-table v-loading="isLoading" :data="dataList" stripe border table-layout="fixed" sortable="custom"
      @sort-change="localSortChange" size="small">
      <el-table-column sortable fixed prop="clientname" :label="$t('ClientsView.company_name')" />

      <el-table-column fixed="right" :label="$t('global.operations')">
        <template #default="scope">
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClickEdit(scope.row.id)"
            :icon="Edit" :disabled="scope.row.lockedrow&&false" style="background-color: #d6d6d6 !important;"/>
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
            class="btn-delete" @click="handleClickDelete(scope.row.id)" :icon="Delete" />
          <el-tooltip class="box-item" effect="dark" content="Portfolios" placement="top">
            <el-button link type="primary" class="btn-portfolios" size="small"
              @click="handlePortfolioClick(scope.row.id, scope.row.clientname)" :icon="Notebook" />
          </el-tooltip>
          <el-tooltip :content="$t('global.lockedrow')"  placement="top"  v-if="scope.row.lockedrow == true&&false">
          <el-button type="danger" :icon="Lock" circle @click="handleClickToUnlock(scope.row.id)"/>
         </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
    </div>

    <el-dialog v-if="addEditVisible" v-model="addEditVisible" @update:model-value="onCloseAddEdit" :title="selectedRow === -1 ? 'Add Client' : 'Client Details'">
      <AddEdit_Client :row_id="selectedRow" @close="onCloseAddEdit" @save="onSave" />
    </el-dialog>

    <DeleteEntityDialog v-if="deleteDialogVisible" :row_id="selectedRow" entity_name="Client" table="mosclients"
      title="Delete MOS Client" width="50%" @close="deleteDialogVisible = false" @delete="onDelete()" />
  </div>
</template>

<style scoped>

</style>
