import ITableName from "../../interfaces/ITableName";

class EktokismosLoanAcc_EpomDosi implements ITableName {
  id: number = -1;
  loan_account_id: string = "";
  hmer_epomenis_dosis: string = "";
  kefalaio: string = "";
  tokoi: string = "";

  static getTableNameStatic(): string {
    return "ektokismoi_loan_acc";
  }

  getTableName(): string {
    return EktokismosLoanAcc_EpomDosi.getTableNameStatic();
  }
}

export default EktokismosLoanAcc_EpomDosi;
