<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import { CopyDocument, Edit, Delete, Plus, RefreshLeft, Lock } from "@element-plus/icons-vue";
import AddEdit_TaskTemplate from "../components/AddEdit_TaskTemplate.vue";
import UserType from "../enums/UserType";
import { useMainStore } from "../store/main";
import { useSort } from "../composables/sort";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import DeleteEntityDialog from "../components/DeleteEntityDialog.vue";
import EntityField from "../components/EntityField.vue";

const { showError, showSuccess, isLoading, close } = useGenericMethodsVariables();

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();

let dataList = ref([]);
let dataCount = ref(0);
let lastPage = ref(0);
let selectedRow = ref(-1);
let deleteDialogVisible = ref(false);
let addEditVisible = ref(false);
let addEditVisibleTask = ref(false);
let isNewEntity = ref(false);
let copyName = ref("");

const { onSortChange } = useSort();

const handleClickEdit = async (row_id: number) => {
  selectedRow.value = row_id;
  isNewEntity.value = false;
  addEditVisible.value = true;
};

onMounted(async () => {
  await loadPage(1);
});

const handleClickAdd = () => {
  selectedRow.value = -1;  
  isNewEntity.value = true;
  addEditVisible.value = true;
};

const handleClickDelete = (row_id: number) => {
  selectedRow.value = row_id;
  deleteDialogVisible.value = true;
};

const handleClickCopy = async (row: any) => {
  isLoading.value = true;
  try {
    console.log(JSON.stringify(row))
    let newObj = JSON.parse(JSON.stringify(row));
    newObj.name_en = copyName.value;
    newObj.name_el = newObj.name_en;
    delete newObj.id;
    await pgrestapi.post_data("task_templates", newObj);
    copyName.value = "";
    await loadPage(lastPage.value);
  } catch (ex: any) {
    showError(ex);
  }
  isLoading.value = false;
};


const onDelete = async () => {
  showSuccess("Project deleted successfully");
  deleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const loadPage = async (page: number) => {
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;
  
  let result: PGRESTResult = await pgrestapi.getPaginated(
    "task_templates" ,
    page
  );

  if (result.error) {
    showError(result.error.message);

    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;
  isLoading.value = false;
};

const onSave = async () => {
  ElMessage({
    showClose: true,
    message: "Task template updated",
    type: "success",
    duration: 1000,
  });

  addEditVisible.value = false;
  selectedRow.value = -1;

  await loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Task template failed to update",
    type: "error",
    duration: 2000,
  });

  addEditVisible.value = false;
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const onCloseAddEdit = async () => {
  /*let result = await pgrestapi.patch("mosclients?id=eq." + selectedRow.value, { lockedrow: 'false' });*/
  await loadPage(lastPage.value);
  addEditVisible.value = false;
};
const onCloseAddEditTask = async () => {
  /*let result = await pgrestapi.patch("mosclients?id=eq." + selectedRow.value, { lockedrow: 'false' });*/
  await loadPage(lastPage.value);
  addEditVisibleTask.value = false;
};

const parentBorder = ref(false)
const value = ref([]);

const filterMethod = (query:string, item:any) => {
  console.log(query + ' ' + JSON.stringify(item))
  return item.label.toLowerCase().includes(query.toLowerCase())
}

const onAdd = async () => {
  ElMessage({
    showClose: true,
    message: "Task Template added successfully",
    type: "success",
    duration: 1000,
  });

  addEditVisible.value = false;

  loadPage(lastPage.value);
};

</script>

<template>
  <div class="content-wrapper" v-loading="isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">{{ $t("TaskTemplatesList.name") }} <!--({{ dataCount }})--></h3>
      </el-col>
    </el-row>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn"> </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link v-if="mainStore.loggedUser.user_type === UserType.Administrator" type="primary" size="small"
            class="btn-add" style="float: right; margin: 2.5px" @click="handleClickAdd()" :icon="Plus" />
        </el-col>
      </el-row>
    </div>

    <el-table :data="dataList" :border="parentBorder" style="width: 100%" stripe table-layout="fixed" sortable="custom"
      @sort-change="localSortChange" size="small">
    
      <el-table-column :label="$t('TaskTemplatesList.name_el')" prop="name_el" />
      <el-table-column :label="$t('TaskTemplatesList.name_en')" prop="name_en" />
      
      <el-table-column fixed="right" :label="$t('global.operations')">
        <template #default="scope">
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClickEdit(scope.row.id)"
            :icon="Edit" style="background-color: #d6d6d6 !important;"/>
          <el-popover placement="right" :width="400" trigger="click">
            <template #reference>
              <el-button link type="primary" size="small" class="btn-detail"
                :icon="CopyDocument" style="background-color: #d6d6d6 !important;"/>

            </template>
            <template #default>
              <el-input v-model="copyName" placeholder="English name of new task template" style="width: 300px"/>
              <el-button :disabled="copyName === ''" @click="handleClickCopy(scope.row)">Copy</el-button>
            </template>
            
          </el-popover>
            
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
            class="btn-delete" @click="handleClickDelete(scope.row.id)" :icon="Delete" />
        </template>
      </el-table-column>
    </el-table>
 

    <div><!--
      <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
      -->
    </div>

    <el-dialog width="80%" v-if="addEditVisible" v-model="addEditVisible" @update:model-value="onCloseAddEdit" :title="selectedRow === -1 ? 'Add Task Template' : 'Task Template Details'">
      <AddEdit_TaskTemplate :tasktemplateid="selectedRow" :new-entity="isNewEntity" @close="onCloseAddEdit" @save="onAdd"
        @fail-save="onFailSave"/>
    </el-dialog>

    <el-dialog v-if="addEditVisibleTask" v-model="addEditVisibleTask" @update:model-value="onCloseAddEditTask" :title="selectedRow === -1 ? 'Add Task' : 'Task Details'">
      <el-form>
      </el-form>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button type="primary" @click="close">{{$t('generic.save')}}</el-button>
            <el-button @click="close">{{$t('generic.close')}}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-dialog>

    <DeleteEntityDialog v-if="deleteDialogVisible" :row_id="selectedRow" entity_name="Task" table="tasks"
      title="Delete Task" width="50%" @close="deleteDialogVisible = false"/>
  </div>
</template>

<style scoped>
.myCLASS .el-transfer-panel__filter {margin:0px !important;}
.el-transfer {
    --el-transfer-panel-width: 400px;
}

</style>
