<script lang="ts" setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useMOSAPIStore } from "../store/mos_api";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useDataHelpers } from "../composables/dataHelpers";

const props = defineProps<{
    table: string;
    entity_name: string;
    row_id: number;
    title: string;
    width: string;
}>();

const { emit } = getCurrentInstance() as NonNullable<ReturnType<typeof getCurrentInstance>>;
const emits = defineEmits(["delete", "close"]);

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const mosapiStore = useMOSAPIStore();
const { deleteEntityFromTableWithID } = useDataHelpers();

let show = ref(true);

const {
    isLoading,
    showError,
} = useGenericMethodsVariables();

onMounted(async () => {

});

const onAcceptDelete = async () => {
    let res = await deleteEntityFromTableWithID(props.table, props.row_id);

    if (!res) {
        showError('Failed to delete ' + props.entity_name + ' entry');
        return;
    }

    emit('delete');
}

</script>

<template>
    <el-dialog v-model="show" :title="props.title" :width="props.width" @update:model-value="emit('close')">
        <span>Are you sure you want to delete this {{ props.entity_name }} entry?</span>
        <template #footer #default="scope">
            <span class="dialog-footer">
                <el-button @click="emit('close')">{{ $t('generic.cancel') }}</el-button>
                <el-button type="primary" @click="onAcceptDelete()">
                    {{ $t('generic.delete') }}
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<style scoped></style>
