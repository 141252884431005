import ITableName from "../../interfaces/ITableName";

class ConfigGroup implements ITableName {
  id?: number = -1;
  group_id: string = "";
  group_name: string = "";

  static getTableNameStatic(): string {
    return "config_group";
  }

  getTableName(): string {
    return ConfigGroup.getTableNameStatic();
  }
}

export default ConfigGroup;
