import ITableName from "../../interfaces/ITableName";

class CustomField implements ITableName {
  id: number = -1;
  name_el:string ="";
  name_en: string = "";  
  type: string = "";
  status: number = 1;
  dropdown_list_values: string[] = [];
  
  static getTableNameStatic(): string {
    return "custom_fields";
  }

  getTableName(): string {
    return CustomField.getTableNameStatic();
  }
}

export default CustomField;
