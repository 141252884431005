import ITableName from "../../interfaces/ITableName";

class ROB_Participating_Bank implements ITableName {
  id: number = -1;
  register_of_bond_id: string = "";
  bank_name: string = "";
  participation_percent: string = "";
  contact_details: string = "";
  iban: string = "";
  iban_bank: string = "";
  portfolio_account: boolean = false;

  static getTableNameStatic(): string {
    return "rob_participating_banks";
  }

  getTableName(): string {
    return ROB_Participating_Bank.getTableNameStatic();
  }
}

export default ROB_Participating_Bank;
