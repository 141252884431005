import ITableName from "../../interfaces/ITableName";

class DigitizedGelatin implements ITableName {
    id: number = -1;
    digitized_folder_id: number = 0;
    gelatin_barcode: string = "";
    page_count: number = 0;
    document_type: number = -1;

    static getTableNameStatic(): string {
        return "digitized_gelatin";
    }

    getTableName(): string {
        return DigitizedGelatin.getTableNameStatic();
    }
}

export default DigitizedGelatin;
