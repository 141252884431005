<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import Interest from "../classes/DB_Entities/EktokismosLoanAcc_EpomDosi";
import { ElMessage } from "element-plus";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import User from "../classes/DB_Entities/User";
import PGRESTResult from "../classes/PGRESTResult";
import { useValidation } from "../composables/validation";

const props = defineProps<{
  loan_account_id: string;
  interestid: number;
  newEntity?: boolean;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);
const loanaccoundlist = ref(new Array<any>());
const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });

const usersList = ref(new Array<User>());

const data = ref<any>({
  loan_account_id: null,
  hmer_epomenis_dosis: null,
  kefalaio: null,
  tokoi: null,
});
const resetData = async () => {
  data.value.loan_account_id = props.loan_account_id;
  data.value.hmer_epomenis_dosis = null;
  data.value.kefalaio = null;
  data.value.tokoi = null;
};

const {
  isLoading,
  fullObj,
  table_name,
  loadData,
  close,
  cleanObject,
  resetObjects,
  showError,
} = useGenericMethodsVariables();

const {
  validationErrors,
  onValidationChange,
  checkValidationsForError,
} = useValidation();

const save = async () => {
  let obj = JSON.parse(JSON.stringify(data.value));
  let result: PGRESTResult = new PGRESTResult();

  console.log("obj");

  if (obj.loan_account_id === null) {
    return;
  }

  if (props.interestid < 0) {
    // if (obj.hmer_proskomisis === null) {
    //   delete obj.hmer_proskomisis;
    // }
    // console.log(obj);
    result = await pgrestapi.post_data("ektokismoi_loan_acc", obj);
  } else {
    result = await pgrestapi.patch("ektokismoi_loan_acc?id=eq." + props.interestid, {
      hmer_epomenis_dosis: data.value.hmer,
      kefalaio: data.value.kefalaio,
      tokoi: data.value.tokoi,
    });
  }

  if (result.error) {
    showError(result.error.message); // emits("fail-save");
    return;
  }
  resetData();
  emits("save");
};

const loadInterest = async () => {
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get("ektokismoi_loan_acc?select=*&id=eq." + props.interestid);

  if (result.error) {
    showError(result.error.message);

    return;
  }
  data.value.hmer_epomenis_dosis = result.data[0].hmer_epomenis_dosis;
  data.value.kefalaio = result.data[0].kefalaio;
  data.value.tokoi = result.data[0].tokoi;
};

// const loadLoanAccount = async () => {
//   let result: PGRESTResult = new PGRESTResult();
//   loanaccoundlist.value = [];

//   result = await pgrestapi.get("loan_accounts?select=id,ar_trexontos_logariasmou");

//   if (result.error) {
//     ElMessage({
//       showClose: true,
//       message: result.error.message,
//       type: "error",
//     });

//     return;
//   }

//   for (let i = 0; i < result.data.length; i++) {
//     loanaccoundlist.value.push({
//       value: result.data[i].id,
//       label: result.data[i].ar_trexontos_logariasmou,
//     });
//   }
// };

onMounted(async () => {
  //await loadLoanAccount();

  data.value.loan_account_id = props.loan_account_id;
  if (props.interestid > 0) {
    await loadInterest();
  }
  table_name.value = Interest.getTableNameStatic();
  cleanObject.value = new Interest();

  if (props.newEntity === true) {
    resetObjects();
    return;
  }

  isLoading.value = true;
  await loadData(props.interestid);
  isLoading.value = false;
});

const onPreSave = async () => {
  if (checkValidationsForError()) {
    showError("Please correct shown errors.");
    return;
  }

  await save();
  resetObjects();
};
</script>

<template>
  <div v-loading="isLoading">
    <el-form :model="data" ref="ruleFormRef">
      <!-- {{ $t('payments.loan_account') }}: {{ data.loan_account_id }} -->
      <el-row :gutter="10">
        <el-col :span="24">
          <span v-if="data.poso === null || data.poso === ''">{{
            $t("errors.missing_field_poso")
          }}</span>
          <el-form-item :label="$t('LoanAccountEktokismosView.hmer_epomenis_dosis')">
            <el-date-picker
              value-format="YYYY-MM-DD"
              format="DD-MM-YYYY"
              v-model="data.hmer_epomenis_dosis"
              autocomplete="off"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LoanAccountEktokismosView.kefalaio')">
            <el-input v-model="data.kefalaio" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LoanAccountEktokismosView.tokoi')">
            <el-input v-model="data.tokoi" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form-item class="btn-wrapper-components">
          <el-button type="primary" @click="onPreSave">{{$t('generic.save')}}</el-button>
          <el-button @click="close">{{$t('generic.close')}}</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped></style>
