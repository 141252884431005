import ITableName from "../../interfaces/ITableName";

class TaskTemplates implements ITableName {
  id: number = -1;
  name_el:string ="";
  name_en: string = "";  
  fields_ids: number[]=[];
  status: number = 1;
  
  static getTableNameStatic(): string {
    return "task_templates";
  }

  getTableName(): string {
    return TaskTemplates.getTableNameStatic();
  }
}

export default TaskTemplates;
