enum EntityType {
    Unknown = 0,
    Client = 1,
    Portfolio = 1,
    Debtor = 2,
    LoanAgreement = 3,
    LoanAccount = 4,
    Approval = 5,
    Auction = 6,
    GreekStateGuarantee = 7,
    Guarantee = 8,
    RECollateral = 9,
    RealEstate = 10,
    RegisterOfBonds = 11,
    NRECollateral = 12,
    ROBParticipatingBank = 13,
    ROBTitle = 14,
    ROBBond = 15,
    SummaryCheck = 16,
  }
  
  export default EntityType;
  