import ITableName from "../../interfaces/ITableName";

class ConfigKad implements ITableName {
  id?: number = -1;
  kodikos_kad: string = "";
  perigrafi: string = "";
  klados_id: number = 0;

  static getTableNameStatic(): string {
    return "config_kad";
  }

  getTableName(): string {
    return ConfigKad.getTableNameStatic();
  }
}

export default ConfigKad;
