import { ref, getCurrentInstance } from "vue";
import { ElMessage } from "element-plus";
import ChangeApprovalStatus from "../enums/ChangeApprovalStatus";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useMOSAPIStore } from "../store/mos_api";
import { useDataHelpers } from "../composables/dataHelpers";

import ChangesetHelper from "changeset-helper";

import Decision from "../classes/Decision";
import UserType from "../enums/UserType";
import { Newable } from "../classes/Newable";
import ITableName from "../interfaces/ITableName";

export function useGenericMethodsVariables() {
  const { emit } = getCurrentInstance() as NonNullable<ReturnType<typeof getCurrentInstance>>;

  const mainStore = useMainStore();
  const pgrestapi = usePGRESTAPIStore();
  const mosapi = useMOSAPIStore();
  const dataHelpers = useDataHelpers();

  let isLoading = ref(false);
  let table_name = ref<string>("");
  let fullObj = ref<any>("");
  let data = ref<any>("");
  let cleanObject = ref<any>(null);
  let hasPendingChanges = ref<boolean>(false);

  const updateDecision = async (decision: Decision) => {
    let timestamp: string = await (await pgrestapi.get("rpc/customnew")).data;

    if (decision.accepted) {
      let result = await pgrestapi.patch("entity_" + table_name.value + "_changes?id=eq." + decision.db_change_id, {
        approval_status: ChangeApprovalStatus.Approved,
        approver_user_id: mainStore.loggedUser.id,
        decision_timestamp: timestamp,
      });

      let obj: any = {};
      let field = decision.field as string;
      obj[field] = decision.value;
      result = await pgrestapi.patch(table_name.value + "?id=eq." + data.value.id, obj);
    } else {
      let result = await pgrestapi.patch("entity_" + table_name.value + "_changes?id=eq." + decision.db_change_id, {
        approval_status: ChangeApprovalStatus.Rejected,
        approver_user_id: mainStore.loggedUser.id,
        decision_timestamp: timestamp,
      });
    }

    // set to approve_status = 1 if no more pending changes
    await mosapi.updateEntityApproveStatus(table_name.value, data.value.id);
  };

  const TransformFullObj = () => {
    for (let field of Object.keys(fullObj.value)) {
      (fullObj as any).value[field] = {
        field,
        originalValue: (data as any).value[field],
      };
    }
  };

  const loadPendingChanges = async (entity_id: number) => {
    let result = await pgrestapi.get(
      `entity_${table_name.value}_changes?entity_id=eq.${entity_id}&approval_status=eq.0&select=id,field,value,insert_timestamp,editor:users!fk_user_editor(username)`
    );

    TransformFullObj();

    for (let pending of result.data) {
      pending["originalValue"] = JSON.parse(JSON.stringify((data as any).value[pending.field]));
      (fullObj as any).value[pending.field].pendingValue = pending;
    }

    hasPendingChanges.value = (result.data.length > 0);
  };

  const loadData = async (entity_id: number) => {
    if (entity_id === -1) return;

    let result = await pgrestapi.get(`${table_name.value}?id=eq.${entity_id}`);

    if (result.error) {
      isLoading.value = false;
      showError(result.error.message);
      return;
    }

    data.value = result.data[0];
    fullObj.value = JSON.parse(JSON.stringify(result.data[0]));

    await loadPendingChanges(entity_id);
  };

  const isNewEntity = (): boolean => {
    return data.value.id === -1;
  }

  const save = async (skip_approve: boolean = false) => {
    isLoading.value = true;
    let newFull: any = {};
    let timestamp: string = await (await pgrestapi.get("rpc/customnew")).data;

    for (let field of Object.keys(fullObj.value)) {
      if (data.value[field] === null) {
        data.value[field] = "";
      }
      if ((fullObj as any).value[field].originalValue === null)
        (fullObj as any).value[field].originalValue = "";

      newFull[field] = (fullObj as any).value[field].originalValue;
    }

    if (!isNewEntity()) {
      const report = ChangesetHelper.compare(data.value, newFull);

      let madeChange = false;

      for (let changedField of report.changes) {
        madeChange = true;

        // For agents, either add a new entity change or update an existing one
        if (mainStore.loggedUser.user_type === UserType.Agent) {
          // If the field does not have a pending change already, create one
          if (fullObj.value[changedField].pendingValue === undefined) {
            let result = await pgrestapi.post_data("entity_" + table_name.value + "_changes", {
              entity_id: data.value.id,
              editor_user_id: mainStore.loggedUser.id,
              field: changedField,
              value: newFull[changedField],
            });
          }
          else { // If the field already has a pending change, update it
            let result = await pgrestapi.patch(
              "entity_" + table_name.value + "_changes?id=eq." + fullObj.value[changedField].pendingValue.id,
              {
                entity_id: data.value.id,
                editor_user_id: mainStore.loggedUser.id,
                field: changedField,
                value: newFull[changedField],
                insert_timestamp: timestamp,
              }
            );
          }
        } else { // For admins/supervisors, add a new entity change and set it directly to approved
          let result = await pgrestapi.post_data("entity_" + table_name.value + "_changes", {
            entity_id: data.value.id,
            editor_user_id: mainStore.loggedUser.id,
            field: changedField,
            value: newFull[changedField],
          });

          await updateDecision({ accepted: true, db_change_id: result.data[0].id, field: changedField, value: newFull[changedField] } as Decision);
        }
      }

      //
      // Set to approve status PendingReview if changes were made by an agent
      //
      if (madeChange && data.value.approve_status !== ChangeApprovalStatus.PendingApprove && mainStore.loggedUser.user_type === UserType.Agent) {
        await pgrestapi.patch(`${table_name.value}?id=eq.${data.value.id}`, { approve_status: ChangeApprovalStatus.PendingReview });
      }

      isLoading.value = false;
      emit("save");
      return;
    } // End update existing entity

    delete newFull.id;

    newFull.insert_user = mainStore.loggedUser.id;
    newFull.insert_timestamp = timestamp;

    // Set approve_status to pending (0) when new entity is created by Agent
    if (mainStore.loggedUser.user_type === UserType.Agent) {
      newFull.approve_status = 0;
    } else {
      newFull.approve_status = 1; // Automatically accepted for Admin/Supervisor
      newFull.approve_user = mainStore.loggedUser.id; // Set also the approve_user
      newFull.approve_timestamp = timestamp;
    }

    if (skip_approve)
      delete newFull.approve_status;

    // Insert new entity
    // console.log(JSON.stringify(newFull))

    for (let field of Object.keys(newFull)) {
      if (newFull[field] === "")
        newFull[field] = null;
    }

    let result = await pgrestapi.post_data(table_name.value, newFull);

    isLoading.value = false;
    emit("save");
  };

  const saveDirect = async () => {
    isLoading.value = true;

    let newFull: any = {};

    for (let field of Object.keys(fullObj.value)) {
      if (data.value[field] === null) {
        data.value[field] = "";
      }
      if ((fullObj as any).value[field].originalValue === null)
        (fullObj as any).value[field].originalValue = "";

      newFull[field] = (fullObj as any).value[field].originalValue;
    }

    if (isNewEntity()) {// Do nothing if new entity (shouldn't happen)
      emit("close");
      return;
    }

    const report = ChangesetHelper.compare(data.value, newFull);

    if (report.changes.length === 0) { // Do nothing if no changes
      emit("close");
      return;
    }

    for (let field of Object.keys(newFull)) {
      if (newFull[field] === "")
        newFull[field] = null;
    }

    let result = await pgrestapi.patch(table_name.value + "?id=eq." + data.value.id, newFull);

    if (result.error) {
      emit("fail-save");
      return;
    }

    emit("save");
  }

  const close = async () => {
    emit("close");
  };

  const resetObjects = () => {
    data.value = JSON.parse(JSON.stringify(cleanObject.value));
    fullObj.value = JSON.parse(JSON.stringify(cleanObject.value));
    TransformFullObj();
  };

  const entityApprove = async (entity_table: string, entity_id: number, accepted: boolean): Promise<boolean> => {
    // console.log("entity id", entity_id, "approved", accepted);
    if (entity_id === undefined || entity_id === null) return false;

    let timestamp: string = await (await pgrestapi.get("rpc/customnew")).data;

    let result = await pgrestapi.patch(
      `${entity_table}?id=eq.${entity_id}`,
      {
        approve_status: accepted ? ChangeApprovalStatus.Approved : ChangeApprovalStatus.Rejected,
        approve_user: mainStore.loggedUser.id,
        approve_timestamp: timestamp,
      }
    );

    return result.error === undefined;
  }

  const showSuccess = (message: string, duration: number = 1500) => {
    ElMessage({
      showClose: true,
      message: message,
      type: "success",
      duration: duration,
    });
  }

  const showWarning = (message: string, duration: number = 5000) => {
    ElMessage({
      showClose: true,
      message: message,
      type: "warning",
      duration: duration,
    });
  }

  const showError = (message: string, duration: number = 5000) => {
    ElMessage({
      showClose: true,
      message: message,
      type: "error",
      duration: duration,
    });
  }

  const clickAcceptAll = async (entity_id: number) => {
    isLoading.value = true;

    try {
      for (let key of Object.keys(fullObj.value)) {
        if ((fullObj as any).value[key].pendingValue !== undefined) {
          await updateDecision({ accepted: true, db_change_id: (fullObj as any).value[key].pendingValue.id, field: key, value: (fullObj as any).value[key].pendingValue.value } as Decision);
        }
      }
    } catch (ex) {
      console.log(ex);
    }

    await loadData(entity_id);

    isLoading.value = false;
  }

  const hasPendingChangeForField = (field: string): boolean => {
    if (field in (fullObj as any).value) {
      return (fullObj as any).value[field].pendingValue !== undefined;
    }

    return false;
  };

  //
  // Used when replacing select fields and there's a pending change
  //
  const setDisplayValues = async <T extends ITableName & { id?: number }>(tableType: Newable<T>, field: string, formatFunction: (dbObj: any) => string): Promise<void> => {
    let dbObj = await dataHelpers.getObjectFromTableWithID<T>(tableType, fullObj.value[field].pendingValue.value);

    // Right side (Pending value)
    fullObj.value[field].pendingValue["pendingDisplayValue"] = (dbObj.id !== -1) ? formatFunction(dbObj) : fullObj.value[field].pendingValue.value;

    // Left side (Current DB value)
    if (fullObj.value[field].originalValue !== null) {
      dbObj = await dataHelpers.getObjectFromTableWithID(tableType, fullObj.value[field].originalValue);
      fullObj.value[field].pendingValue["originalDisplayValue"] = (dbObj.id !== -1) ? formatFunction(dbObj) : fullObj.value[field].originalValue;
    }
  }

  const printBarcode = async (barcode: string) => {
    await mosapi.notify({
      channel: "print_barcode",
      data: `~MDEL
^W70
^Q40,2
^H1
^P1
^L  
BP, 20, 115, 2, 10, 70, 0, 1,${barcode.trim()}
E`,
    });
  }
  //BB,20,100,3,3,100,0,1,1234567
  // BP,30,57,2,5,80,0,1,22408785Godex
  // BP, 75, 115, 2, 10, 70, 0, 1, ${barcode}
  return {
    cleanObject,
    data,
    fullObj,
    hasPendingChanges,
    isLoading,
    table_name,
    clickAcceptAll,
    close,
    entityApprove,
    hasPendingChangeForField,
    isNewEntity,
    loadPendingChanges,
    loadData,
    printBarcode,
    resetObjects,
    save,
    saveDirect,
    setDisplayValues,
    showError,
    showSuccess,
    showWarning,
    TransformFullObj,
    updateDecision,
  };
}
