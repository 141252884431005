import ITableName from "../../interfaces/ITableName";

class Client implements ITableName {
  id: number = -1;
  clientname: string = "";
  allowed_users: Array<number> = [];

  static getTableNameStatic(): string {
    return "mosclients";
  }

  getTableName(): string {
    return Client.getTableNameStatic();
  }
}

export default Client;
