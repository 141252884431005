<script lang="ts" setup>
import { ref, watch, onMounted, reactive } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import type { FormInstance } from "element-plus";
import ConfigPrefecture from "../classes/DB_Entities/ConfigPrefecture";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
const { showError } = useGenericMethodsVariables();

const props = defineProps({
  prefectureid: Number,
});
let isLoading = ref(false);
const emits = defineEmits(["save", "close", "fail-save"]);
const pgrestapi = usePGRESTAPIStore();
const data = ref(new ConfigPrefecture());
const activeName = ref("first");
const ruleFormRef = ref<FormInstance>();

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!", fields);
    }
  });
};

watch(
  () => props.prefectureid,
  async (newVal, oldVal) => {
    // console.log("Loading debtor with id " + props.debtorid);
    await loadPrefectures(props.prefectureid as number);
  }
);

onMounted(async () => {
  await loadPrefectures(props.prefectureid as number);
});

const close = async () => {
  emits("close");
};

const save = async () => {
  let result: PGRESTResult = new PGRESTResult();

  let id = JSON.stringify(data.value.id);
  delete data.value.id;

  result = await pgrestapi.patch("config_prefecture?id=eq." + id, data.value);

  if (result.error) {
    showError(result.error.message); // emits("fail-save");
    return;
  }

  emits("save");
};

const loadPrefectures = async (prefecture_id: number) => {
  isLoading.value = true;
  if (prefecture_id === -1) return;

  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get("config_prefecture?id=eq." + prefecture_id);

  if (result.error) {
    showError(result.error.message);

    return;
  }

  data.value = result.data[0];
  isLoading.value = false;
};
</script>

<template>
  <div>
    <el-form :model="data" ref="ruleFormRef" v-loading="isLoading">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item :label="$t('PlatformSettingsView.prefecture_name')">
            <el-input v-model="data.prefecture" autocomplete="off" />
          </el-form-item>
          <el-form-item :label="$t('PlatformSettingsView.min_tk')">
            <el-input v-model="data.min_tk" autocomplete="off" />
          </el-form-item>
          <el-form-item :label="$t('PlatformSettingsView.max_tk')">
            <el-input v-model="data.max_tk" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>

      <div style="display: flex; justify-content: flex-end; width: 100%; padding: 0">
        <el-form-item>
          <el-button type="primary" @click="submitForm(ruleFormRef)">{{$t('generic.save')}}</el-button>
          <el-button @click="close">{{$t('generic.close')}}</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<style scoped></style>
