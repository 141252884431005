import { ref } from "vue";
import { defineStore } from "pinia";
import PGRESTResult from "../classes/PGRESTResult";
import APIError from "../classes/APIError";
import axios, { AxiosError } from "axios";
import IUploadDocument from "../interfaces/IUploadDocument";
import EntityType from "../enums/EntityType";

export const usePGRESTAPIStore = defineStore("pgrest_api", () => {
  let _url = ref<string>(import.meta.env.VITE_PGREST_URL);
  let _port = ref<number>(0);
  let _token = ref<string>("");

  let apiURL = (): string => {
    if (_port.value === 0) return _url.value;

    return `${_url.value}:${_port.value}`;
  };

  let setToken = async (token_: string) => {
    _token.value = token_;
  };

  function unsetToken() {
    _token.value = "";
  }

  let get = async (pgrest_url: string): Promise<PGRESTResult> => {
    let fv: PGRESTResult = new PGRESTResult();

    let headers = {
      Authorization: `Bearer ${_token.value}`,
    };

    let callURL = encodeURI(apiURL() + pgrest_url);

    try {
      let response = await axios.get(callURL, {
        headers,
      });

      fv.headers = response.headers as any;
      fv.data = response.data;
    } catch (err: any | AxiosError) {
      fv.error = new APIError();

      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          fv.error.code = "1";
          fv.error.message = "Wrong credentials";
        } else {
          fv.error.code = "2";
          //fv.error.message = "Possible connectivity issue!!!!!!!!!!!!!";
          fv.error.message = err.message + ": " + err?.response?.data?.message;
        }
      } else {
        fv.error.code = "9999";
        fv.error.message = err.message;
      }
    }

    return fv;
  };

  let getPaginated = async (pgrest_url: string, pageNumber: number, pageSize: number = 10): Promise<PGRESTResult> => {
    let fv: PGRESTResult = new PGRESTResult();

    let headers = {
      Authorization: `Bearer ${_token.value}`,
      "Range-Unit": "items",
      Range: `${pageNumber * pageSize}-${pageNumber * pageSize + (pageSize - 1)}`,
      Prefer: "count=exact",
    };

    let callURL = encodeURI(apiURL() + pgrest_url);

    try {
      let response = await axios.get(callURL, {
        headers,
      });

      fv.headers = response.headers as any;
      fv.data = response.data;
    } catch (err: any | AxiosError) {
      fv.error = new APIError();

      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          fv.error.code = "1";
          fv.error.message = "Wrong credentials";
        } else {
          fv.error.code = "2";
          //fv.error.message = "Possible connectivity issue";
          fv.error.message = err.message;

        }
      } else {
        fv.error.code = "9999";
        fv.error.message = err.message;
      }
    }

    return fv;
  };

  let patch = async (pgrest_url: string, object: any): Promise<PGRESTResult> => {
    let fv: PGRESTResult = new PGRESTResult();

    let headers = {
      Authorization: `Bearer ${_token.value}`,
    };

    let callURL = encodeURI(apiURL() + pgrest_url);

    try {
      let result = await axios.patch(callURL, object, { headers });

      fv.headers = result.headers as any;
      fv.data = result.data;
    } catch (err: any | AxiosError) {
      fv.error = new APIError();

      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          fv.error.code = "1";
          fv.error.message = "Wrong credentials";
        } else {
          fv.error.code = "2";
          //fv.error.message = "Possible connectivity issue";
          fv.error.message = err.message;

        }
      } else {
        fv.error.code = "9999";
        fv.error.message = err.message;
      }
    }

    return fv;
  };

  let delete_data = async (pgrest_url: string): Promise<PGRESTResult> => {
    let fv: PGRESTResult = new PGRESTResult();

    let headers = {
      Authorization: `Bearer ${_token.value}`,
    };

    try {
      let result = await axios.delete(apiURL() + pgrest_url, { headers });

      fv.headers = result.headers as any;
      fv.data = result.data;
    } catch (err: any | AxiosError) {
      fv.error = new APIError();

      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          fv.error.code = "1";
          fv.error.message = "Wrong credentials";
        } else if (err.response?.status === 409) {
          fv.error.code = "2";
          fv.error.message = "A problem occured, unable to delete this row";
        } else {
          fv.error.code = "3";
          //fv.error.message = "Possible connectivity issue";
          fv.error.message = err.message;

        }
      } else {
        fv.error.code = "9999";
        fv.error.message = err.message;
      }
    }

    return fv;
  };

  let post_data = async (pgrest_url: string, object: any): Promise<PGRESTResult> => {
    let fv: PGRESTResult = new PGRESTResult();

    let headers = {
      Authorization: `Bearer ${_token.value}`,
      Prefer: "return=representation",
    };

    let callURL = encodeURI(apiURL() + pgrest_url);

    // console.log(callURL + object);

    try {
      let result = await axios.post(callURL, object, { headers });

      fv.headers = result.headers as any;
      fv.data = result.data;
    } catch (err: any | AxiosError) {
      fv.error = new APIError();

      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          fv.error.code = "1";
          fv.error.message = "Wrong credentials";
        } else {
          fv.error.code = "2";
          //fv.error.message = "Possible connectivity issue";
          fv.error.message = err.message;
        }
      } else {
        fv.error.code = "9999";
        fv.error.message = err.message;
      }
    }

    return fv;
  };

  function b64EncodeUnicode(str: string) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt(p1, 16))
    }))
  }

  //
  // In case it's required in the future
  //
  // const b64DecodeUnicode = (str: string) => {
  //   return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
  //     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  //   }).join(''))
  // }


  let upload_document = async (file: any, params: IUploadDocument): Promise<PGRESTResult> => {
    let fv: PGRESTResult = new PGRESTResult();

    let headers = {
      Authorization: `Bearer ${_token.value}`,
      'Content-Type': 'application/octet-stream',
      entityid: params.entityid,
      filename: b64EncodeUnicode(params.filename),
      foldername: params.foldername,
      uploaderuserid: params.uploaderuserid,
    };

    let rpc_function = '';

    if (params.entitytype === EntityType.RealEstate) {
      rpc_function = 'upload_document_real_estate';
    } else if (params.entitytype === EntityType.Debtor) {
      rpc_function = 'upload_document_debtor';
    } else if (params.entitytype === EntityType.LoanAgreement) {
      rpc_function = 'upload_document_loan_agreement';
    }

    let callURL = encodeURI(apiURL() + 'rpc/' + rpc_function);

    try {
      let result = await axios.post(callURL, file, { headers });

      fv.headers = result.headers as any;
      fv.data = result.data;
    } catch (err: any | AxiosError) {
      fv.error = new APIError();

      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          fv.error.code = "1";
          fv.error.message = "Wrong credentials";
        } else {
          fv.error.code = "2";
          //fv.error.message = "Possible connectivity issue";
          fv.error.message = err.message;
        }
      } else {
        fv.error.code = "9999";
        fv.error.message = err.message;
      }
    }

    return fv;
  };

  let download_document = async (entity_type: string, row_id: number): Promise<PGRESTResult> => {
    let fv: PGRESTResult = new PGRESTResult();

    let headers = {
      Authorization: `Bearer ${_token.value}`,
      Accept: 'application/octet-stream',
    };

    let callURL = encodeURI(apiURL() + `documents_${entity_type}?id=eq.${row_id}&select=filedata`);

    try {
      let result = await axios.get(callURL, { headers, responseType: 'arraybuffer' });

      fv.headers = result.headers as any;
      fv.data = result.data;
    } catch (err: any | AxiosError) {
      fv.error = new APIError();

      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          fv.error.code = "1";
          fv.error.message = "Wrong credentials";
        } else {
          fv.error.code = "2";
          //fv.error.message = "Possible connectivity issue";
          fv.error.message = err.message;
        }
      } else {
        fv.error.code = "9999";
        fv.error.message = err.message;
      }
    }

    return fv;
  };

  return {
    apiURL,
    token: _token,
    get,
    patch,
    setToken,
    getPaginated,
    unsetToken,
    delete_data,
    download_document,
    post_data,
    upload_document,
  };
});
