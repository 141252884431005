import ITableName from "../../interfaces/ITableName";

class SummaryCheck implements ITableName {
  id: number = -1;
  loan_agreement_id: string = "";
  skopos_ekdosis: string = "";
  paratasi_diarkeias: string = "";
  ekxoriseis_elegxos_sumvasis: string = "";
  ekxoriseis_elegxos_epidosis: string = "";
  ekxoriseis_eispraxi_gegen_apait: string = "";
  lixi_ekxorimenon_asf: string = "";
  promitheies_adraneias: string = "";
  promitheies_anadoxis: string = "";
  promitheies_diaxeirisis: string = "";
  promitheies_tropopoihshs: string = "";
  promitheies_waiver_fee_ekpr_omolog: string = "";
  promitheies_waiver_fee_omolog: string = "";
  upoxreoseis_plirof_oikon_stoixeia: string = "";
  gegonota_kataggelias: string = "";
  xronos_paragrafis: string = "";
  paratiriseis: string = "";
  summary_check_date: string = "";
  plhr_sumb_hmerom: string = "";
  plhr_sumb_ekpr_trapezas: string = "";
  plhr_sumb_ypogr_pistouxou: string = "";
  plhr_sumb_ypogr_egg: string = "";
  epipleon_eggrafa: string = "";

  static getTableNameStatic(): string {
    return "summary_check_xtra";
  }

  getTableName(): string {
    return SummaryCheck.getTableNameStatic();
  }
}

export default SummaryCheck;
