import ITableName from "../../interfaces/ITableName";

class Approval implements ITableName {
  id: number = -1;
  loan_agreement_id:string ="";
  hmer_apost_egkrishs: string = "";
  account_manager: number = 0;
  thema_egkrishs: string = "";
  hmer_anatheshs_mos: string = "";
  ektim_hmer_ylop: string = "";
  middle_officer: string = "";
  hmer_olokl: string = "";
  lawyer: string = "";
  comments: string = "";

  approve_status: string = "";
  insert_timestamp: string = "";
  insert_user: string = "";
  approve_user: string = "";

  static getTableNameStatic(): string {
    return "approvals";
  }

  getTableName(): string {
    return Approval.getTableNameStatic();
  }
}

export default Approval;
