<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { Bell, Edit, Plus, RefreshLeft, Delete, View } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import AddEdit_ROB_Bond from "./AddEdit_ROB_Bond.vue";
import AddEdit_ROB_Title from "./AddEdit_ROB_Title.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import UserType from "../enums/UserType";

const props = defineProps({
  rob_id: Number,
  view_only: Boolean
});

let isLoading = ref(false);
const emits = defineEmits(["save", "close", "fail-save"]);
const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
let titlesArray = ref<Array<any>>([]);
let showAddEditBond = ref<boolean>(false);
let showAddEditTitle = ref<boolean>(false);
let editROBBondID = ref<number>(-1);
let editROBTitleID = ref<number>(-1);
let newEntityTitle = ref<boolean>(false);
let newEntityBond = ref<boolean>(false);
let newTitle_ParticipatingBankID = ref<number>(-1);
let newBond_TitleID = ref<number>(-1);

const {
  showError,
} = useGenericMethodsVariables();

watch(
  () => props.rob_id,
  async (oldVal, newVal) => {
    await loadData();
  }
);

onMounted(async () => {
  await loadData();
});

const close = async () => {
  showAddEditBond.value = false;
  showAddEditTitle.value = false;
  editROBBondID.value = -1;
  editROBTitleID.value = -1;
  newEntityBond.value = false;
  newEntityTitle.value = false;
};

const onSave = async () => {
  showAddEditBond.value = false;
  editROBBondID.value = -1;
  showAddEditTitle.value = false;
  editROBTitleID.value = -1;
  newEntityBond.value = false;
  newEntityTitle.value = false;

  ElMessage({
    showClose: true,
    message: "Entry updated",
    type: "success",
    duration: 1000,
  });

  isLoading.value = true;
  await loadData();
  isLoading.value = false;
};

const loadData = async () => {
  if (props.rob_id === -1) return;

  // console.log("Loading ROB_titles " + props.rob_id);
  isLoading.value = true;

  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get(
    "view_mosregisterofbonds_fullbonds?register_of_bond_id=eq." +
    props.rob_id +
    "&order=bondholder,title_name,issue_date"
  );

  if (result.error) {
    showError(result.error.message);

    isLoading.value = false;

    return;
  }

  titlesArray.value = result.data;
  isLoading.value = false;
  console.log(titlesArray.value)
};

interface SpanMethodProps {
  row: any;
  column: any;
  rowIndex: number;
  columnIndex: number;
}

const isFirstBankRow = (rowNumber: number): boolean => {
  // console.log(rowNumber);
  if (rowNumber === 0) return true;

  if (
    titlesArray.value[rowNumber - 1].bondholder !==
    titlesArray.value[rowNumber].bondholder
  )
    return true;

  return false;
};

const isFirstTitleRow = (rowNumber: number): boolean => {
  if (rowNumber === 0) return true;

  if (
    titlesArray.value[rowNumber - 1].title_name !==
    titlesArray.value[rowNumber].title_name ||
    (titlesArray.value[rowNumber].title_name === "" &&
      titlesArray.value[rowNumber - 1].title_name === "")
  )
    return true;

  return false;
};

const getBankRows = (bankName: string): number => {
  let count = 0;

  for (let i = 0; i < titlesArray.value.length; ++i) {
    if (titlesArray.value[i].bondholder === bankName) ++count;
  }

  return count;
};

const getTitleRows = (bankName: string, title: string): number => {
  let count = 0;

  for (let i = 0; i < titlesArray.value.length; ++i) {
    if (
      titlesArray.value[i].bondholder === bankName &&
      titlesArray.value[i].title_name === title
    )
      ++count;
  }

  return count;
};

const objectSpanMethod = ({ row, column, rowIndex, columnIndex }: SpanMethodProps) => {
  if (columnIndex === 0) {
    if (isFirstBankRow(rowIndex)) {
      return {
        rowspan: getBankRows(row.bondholder),
        colspan: 1,
      };
    } else {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
  }

  if (columnIndex === 1) {
    if (isFirstTitleRow(rowIndex)) {
      return {
        rowspan: getTitleRows(row.bondholder, row.title_name),
        colspan: 1,
      };
    } else {
      return {
        rowspan: 0,
        colspan: 0,
      };
    }
  }
};

const getSummaries = (param: any) => {
  const { columns, data } = param;
  const sums: string[] = [];
  columns.forEach((column: any, index: any) => {
    if (index === 0) {
      sums[index] = "Sum";
      return;
    }

    if (index !== 4) {
      sums[index] = "";
      return;
    }

    const values = data.map((item: any) => {
      return Number(item[column.property]);
    });

    if (!values.every((value: any) => Number.isNaN(value))) {
      sums[index] = `€ ${values.reduce((prev: any, curr: any) => {
        const value = Number(curr + "");
        if (!Number.isNaN(value)) {
          return prev + curr;
        } else {
          return prev;
        }
      }, 0)}`;
    } else {
      sums[index] = "";
    }
  });

  return sums;
};

const statusFormatter = (row: any, column: any) => {
  switch (row.status) {
    case "paid":
      return "PAID";
    case "part_paid":
      return "PARTIALLY PAID";
    case "cancel":
      return "CANCELLED";
    case "unpaid":
      return "UNPAID";
  }
  return "";
};

const addEditBond = async (
  rob_title_id: number,
  rob_bond_id: number,
  isNewEntity: boolean
) => {
  showAddEditBond.value = true;
  editROBBondID.value = rob_bond_id;
  console.log("edit rob bond id", editROBBondID.value);
  newEntityBond.value = isNewEntity;
  newBond_TitleID.value = rob_title_id;
};

const addEditTitle = async (
  rob_bank_id: number,
  rob_title_id: number,
  isNewEntity: boolean
) => {
  showAddEditTitle.value = true;
  editROBTitleID.value = rob_title_id;
  newEntityTitle.value = isNewEntity;
  newTitle_ParticipatingBankID.value = rob_bank_id;
};

const deleteTitle = async (titleId: number) => {
  if (!Number.isInteger(titleId)) {
    return;
  }

  try {
    await pgrestapi.delete_data("rob_titles?id=eq." + titleId);
    await loadData();
  } catch (ex: any) {
    showError(ex);
    console.log(ex);
  }
}

const deleteBond = async (bondId: number) => {
  if (!Number.isInteger(bondId)) {
    return;
  }

  try {
    await pgrestapi.delete_data("rob_bonds?id=eq." + bondId);
    await loadData();
  } catch (ex: any) {
    showError(ex);
    console.log(ex);
  }
}
</script>

<template>
  <div v-loading="isLoading" style="padding-top: 50px">
    <el-button link type="primary" size="small" class="btn-add" @click="loadData()" style="float: right; margin: 2.5px">
      <el-icon>
        <RefreshLeft />
      </el-icon>
    </el-button>

    <el-table :data="titlesArray" :border="true" :show-summary="true" :summary-method="getSummaries"
      table-layout="fixed" :span-method="objectSpanMethod" :cell-style="{
        padding: '0',
        height: '15px',
      }" size="small">
      <el-table-column fixed prop="bondholder" :label="$t('RegisterOfBondsView.bondholder')">
        <template #default="scope">
          <span style="padding: 25px; font-size: 14px">{{ scope.row.bondholder }}</span>
          <el-tooltip class="box-item" effect="dark" content="Add Title" placement="top">
            <el-button v-if="!props.view_only" size="small" class="btn-detail" style="width: 1px; padding: 11px"
              @click="addEditTitle(scope.row.rob_bank_id, -1, true)">
              <el-icon :size="12">
                <Plus />
              </el-icon>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column fixed prop="title_name" :label="$t('RegisterOfBondsView.titles')">
        <template #default="scope">
          <div>
            <span style="padding: 15px; font-size: 14px" :class="{ 'red': scope.row.pending_approvals_titles > 0 }">{{
              scope.row.title_name
            }}</span>
            <el-tooltip v-if="scope.row.title_name !== ''" class="box-item" effect="dark" content="Edit Title"
              placement="right">
              <el-icon :size="12" :class="{ 'red': scope.row.pending_approvals_titles > 0 }"
                @click="addEditTitle(scope.row.rob_bank_id, scope.row.rob_title_id, false)">
                <Edit v-if="!props.view_only" />
                <View v-else />
              </el-icon>
            </el-tooltip>

            <el-popconfirm
              v-if="scope.row.title_name !== '' && mainStore.loggedUser.user_type === UserType.Administrator"
              :title="`Are you sure you want to delete Title ${scope.row.title_name}?`"
              @confirm="deleteTitle(scope.row.rob_title_id)" width="350">
              <template #reference>
                <el-icon color="red" :size="12" :class="{ 'red': scope.row.pending_approvals_titles > 0 }">
                  <Delete />
                </el-icon>
              </template>
            </el-popconfirm>

            <el-tooltip v-if="scope.row.title_name !== '' && !props.view_only" class="box-item" effect="dark"
              content="Add Bond" placement="right">
              <el-icon :size="12" @click="addEditBond(scope.row.rob_title_id, -1, true)">
                <Plus v-if="!props.view_only" />
                <View v-else />
              </el-icon>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>

      <el-table-column fixed prop="start_bond_number" :label="$t('RegisterOfBondsView.from')" />
      <el-table-column fixed prop="end_bond_number" :label="$t('RegisterOfBondsView.to')" />

      <el-table-column fixed prop="nominal_value" :label="$t('RegisterOfBondsView.nominal_value')">
        <template #default="scope">{{ scope.row.status !== null ? "€" : "" }} {{ scope.row.nominal_value }}
        </template>
      </el-table-column>
      <el-table-column fixed prop="issue_date" :label="$t('RegisterOfBondsView.issue_date')" />
      <el-table-column fixed prop="end_date" :label="$t('RegisterOfBondsView.end_date')" />
      <el-table-column fixed prop="status" :label="$t('RegisterOfBondsView.status')" :formatter="statusFormatter" />
      <el-table-column fixed prop="pending_approvals_bonds" :label="$t('global.pending_approvals')" />
      <el-table-column fixed="right" :label="$t('global.operations')">
        <template #default="scope">
          <el-button v-if="scope.row.status !== null" link type="primary" size="small" class="btn-detail"
            style="margin: 5px" @click="addEditBond(scope.row.rob_title_id, scope.row.rob_bond_id, false)"
            :icon="!props.view_only ? Edit : View" />
          <el-popconfirm
            v-if="scope.row.rob_bond_id !== null && mainStore.loggedUser.user_type === UserType.Administrator"
            :title="`Are you sure you want to delete this Bond?`" @confirm="deleteBond(scope.row.rob_bond_id)"
            width="350">
            <template #reference>
              <el-icon color="red" :size="12" :class="{ 'red': scope.row.pending_approvals_titles > 0 }">
                <Delete />
              </el-icon>
            </template>
          </el-popconfirm>
          <el-popover v-if="scope.row.pending_approvals_bonds > 0 || scope.row.pending_approvals_titles > 0"
            placement="top-start" title="" :width="155" trigger="hover" :content="$t('global.pending_changes')">
            <template #reference>
              <el-button :icon="Bell" circle size="small" style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-model="showAddEditBond" :title="(newEntityBond ? 'Add' : 'Edit') + ' Bond'" width="55%" @update:model-value="editROBBondID = -1;">
      <AddEdit_ROB_Bond :rob_bond_id="editROBBondID" :rob_title_id="newBond_TitleID" :new-entity="newEntityBond"
        @save="onSave" @close="close" :view_only="props.view_only" />
    </el-dialog>

    <el-dialog v-model="showAddEditTitle" :title="(newEntityTitle ? 'Add' : 'Edit') + ' Title'" width="55%" @update:model-value="editROBTitleID = -1;">
      <AddEdit_ROB_Title :rob_title_id="editROBTitleID" :rob_participating_bank_id="newTitle_ParticipatingBankID"
        :new-entity="newEntityTitle" @save="onSave" @close="close" :view_only="props.view_only"
        :rob_id="$props.rob_id"/>
    </el-dialog>
  </div>
</template>

<style scoped>

</style>
