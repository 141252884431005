enum DocumentFolders {
    Collaterals = "collaterals",
    ConventionalDocuments = "conventional_documents",
    LegalActions = "legal_actions",
    LegalizationDocuments = "legalization_documents",
    MiscDocuments = "misc_documents",
    Rollovers = "rollovers",
    TechnicalEvaluations = "technical_evaluations",
  }
    
  export default DocumentFolders;
  