<script lang="ts" setup>
import { watch, onMounted, ref } from "vue";
import { ElMessage } from "element-plus";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import ROB_Title from "../classes/DB_Entities/ROB_Title";
import Decision from "../classes/Decision";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useRoute } from "vue-router";
import PGRESTResult from "../classes/PGRESTResult";

const route = useRoute();
const emits = defineEmits(["save", "close", "fail-save"]);

const props = defineProps<{
  rob_title_id: number;
  rob_participating_bank_id: number;
  newEntity?: boolean;
  view_only: boolean;
  rob_id?: number;
}>();

let skipApprove = ref<boolean>(false);
let isNewROB = ref<boolean>(false);

const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });

const {
  updateDecision,
  isLoading,
  data,
  fullObj,
  table_name,
  cleanObject,
  loadData,
  save,
  TransformFullObj,
  close,
} = useGenericMethodsVariables();

watch(
  () => props.rob_participating_bank_id,
  async (newVal, oldVal) => {
    resetObjects();
    if (props.newEntity === true) return;
    await loadData(props.rob_title_id);
  }
);

watch(
  () => props.rob_title_id,
  async (newVal, oldVal) => {
    resetObjects();
    if (props.newEntity === true) return;
    await loadData(props.rob_title_id);
  }
);

const resetObjects = () => {
  data.value = new ROB_Title();
  fullObj.value = new ROB_Title();
  data.value.rob_participating_bank_id = props.rob_participating_bank_id;
  fullObj.value.rob_participating_bank_id = props.rob_participating_bank_id;
  TransformFullObj();
};

onMounted(async () => {
  // console.log("rob_id", props.rob_id);
  table_name.value = ROB_Title.getTableNameStatic();
  cleanObject.value = new ROB_Title();

  isLoading.value = true;
  if (!props.newEntity) {
    await loadData(props.rob_title_id as number);
  } else {
    resetObjects();
  }

  isLoading.value = false;
});

const onDecision = async (decision: Decision) => {
  isLoading.value = true;

  await updateDecision(decision);

  await loadData(props.rob_title_id);

  isLoading.value = false;
};

const onPreSave = async () => {
  if (props.newEntity as boolean) {
    if (fullObj.value.title_name.originalValue === "") {
      ElMessage({
        showClose: true,
        message: t("errors.missing_title_name"),
        type: "warning",
        duration: 1000,
      });
      return;
    }
  }
  isNewROB.value = false;
  let pendingresult: PGRESTResult = await pgrestapi.get(
    "view_mosregisterofbonds_view1?&id=eq." + props.rob_id // route.params.id
  );
  // console.log(pendingresult.data[0].approve_status);

  if (pendingresult.data[0].approve_status === 0) {
    isNewROB.value = true;
  } else {
    isNewROB.value = false;
  }

  if (isNewROB) {
    skipApprove.value = true;
  } else {
    skipApprove.value = false;
  }
  await save(skipApprove.value);
  resetObjects();
};
</script>

<template>
  <div v-loading="isLoading">
    <el-form>
      <EntityField type="input" label="Title Name" :object="fullObj.title_name" @decision="onDecision"
        :is-disabled="props.view_only" />

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button v-if="!props.view_only" type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped>

</style>
