// import { ref } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import LoginView from "../views/LoginView.vue";
import MainView from "../views/MainView.vue";
import UsersView from "../views/UsersView.vue";
import ClientsView from "../views/ClientsView.vue";
import DebtorView from "../views/DebtorView.vue";
import DigitizedLAView from "../views/DigitizedLAView.vue";
import PortfoliosView from "../views/PortfoliosView.vue";
import PrivilegesView from "../views/PrivilegesView.vue";
import LoanAgreementsView from "../views/LoanAgreementsView.vue";
import RegisterOfBondsView from "../views/RegisterOfBondsView.vue";
import LoanAccountsView from "../views/LoanAccountsView.vue";
import RealEstatesView from "../views/RealEstatesView.vue";
import RealEstateCollateralsView from "../views/RealEstateCollateralsView.vue";
import GuaranteesView from "../views/GuaranteesView.vue";
import GreekStateGuaranteesView from "../views/GreekStateGuaranteesView.vue";
import DashboardView from "../views/DashboardView.vue";
import SummaryCheckView from "../views/SummaryCheck.vue";
import SummaryCheckKODView from "../views/SummaryCheckKOD.vue";
import SummaryCheckAnotherLoanView from "../views/SummaryCheckAnotherLoan.vue";
// import SummaryCheckODView from "../views/SummaryCheckOD.vue";
import AuctionsView from "../views/AuctionsView.vue";
import PliromesView from "../views/PliromesView.vue";
import LoanAccountEktokismosView from "../views/EktokismosLoanAccountView.vue";
import LoanAgreementEktokismosView from "../views/EktokismosLoanAgreementView.vue";
import AddEdit_Auction from "../components/AddEdit_Auction.vue";
import RestOfCollateralsView from "../views/RestOfCollateralsView2.vue";
import PlatformSettingsView from "../views/PlatformSettingsView.vue";
import ApprovalsView from "../views/ApprovalsView.vue";
import EktokismosView from "../views/EktokismosView.vue";
import ROB_Selected from "../views/ROB_Selected.vue";
import EntityChangesView from "../views/EntityChangesView.vue";
import UploadView from "../views/UploadView.vue";
import AddInterestView from "../components/AddInterest.vue"
import MonitoringView from "../views/MonitoringView.vue";
import LiksiarioView from "../views/LiksiarioView.vue";
import ReportsView from "../views/ReportsView.vue";
import ReportPreview from "../components/ReportPreview.vue";
import MetabaseVue from "../views/Metabase.vue";
import LoanAccountCalculations from "../views/LoanAccountCalculations.vue";

import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "../store/mos_api";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useCookies } from "vue3-cookies";
import UserType from "../enums/UserType";
import ProjectsView from "../views/Workflow_ProjectsView.vue";
import ProjectTemplatesView from "../views/Workflow_ProjectTemplatesView.vue";
import TaskTemplatesView from "../views/Workflow_TaskTemplatesView.vue";
import CustomFieldsView from "../views/Workflow_CustomFieldsView.vue";
import Apografi_Frontier from "../views/Apografi_Frontier.vue";

let client = (import.meta.env.VITE_CLIENT);

const { cookies } = useCookies();

let routes = [
  { path: "/login", name: "Login", component: LoginView },
  {
    path: "/",
    redirect: "/dashboard",
    name: "Root",
    component: MainView,
    children: [
      { path: "/users", name: "Users", component: UsersView, meta: { privileges: [UserType.Administrator] } },
      {
        path: "/privileges",
        name: "Privileges",
        component: PrivilegesView,
        meta: { privileges: [UserType.Administrator] },
      },
      { path: "/clients", name: "Clients", component: ClientsView },
      { path: "/portfolios", name: "Portfolios", component: PortfoliosView },
      { path: "/debtors", name: "Debtors", component: DebtorView },
      { path: "/digitizedla", name: "Digitized Loan Agreements", component: DigitizedLAView },
      { path: "/loanagreements", name: "Loan Agreements", component: LoanAgreementsView },
      { path: "/monitoring", name: "Monitoring", component: MonitoringView },
      { path: "/liksiario", name: "Liksiario", component: LiksiarioView },
      {
        path: "/registerofbonds",
        name: "Register of Bonds",
        component: RegisterOfBondsView,
      },
      {
        path: "/platformsettings/interest/:catg_id",
        name: "Interest Data",
        component: AddInterestView,
      },
      {
        path: "/rob_selected",
        name: "Register of Bonds Selection",
        component: ROB_Selected,
        props: true
      },
      { path: "/loanaccounts", name: "Loan Accounts", component: LoanAccountsView },
      { path: "/pliromes/:loan_account_id", name: "Pliromes", component: PliromesView },
      { path: "/loanAccountInstallments/:loan_account_id", name: "Loan_Account_Ektokismos", component: LoanAccountEktokismosView },
      { path: "/loanAgreementEktokismos/:loan_agreement_id", name: "Loan_Agreement_Ektokismos", component: LoanAgreementEktokismosView },
      { path: "/realestates", name: "Real Estates", component: RealEstatesView },
      { path: "/recollaterals", name: "RE Collaterals", component: RealEstateCollateralsView },
      { path: "/guarantees", name: "Guarantees", component: GuaranteesView },
      { path: "/greekstateguarantees", name: "Greek State Guarantees", component: GreekStateGuaranteesView },
      { path: "/auctions", name: "Auctions", component: AuctionsView },
      { path: "/auctions/:auction_id/:view_only?", name: "Auction Add/Edit", component: AddEdit_Auction },
      { path: "/nrecollaterals", name: "NRE Collaterals", component: RestOfCollateralsView },
      { path: "/dashboard", name: "Dashboard", component: DashboardView },
      { path: "/summary_check", name: "Summary Check", component: SummaryCheckView },
      { path: "/summary_check_kod/:loan_agreement_id", name: "Summary Check KOD", component: SummaryCheckKODView },
      {
        path: "/summary_check_od/:loan_agreement_id",
        name: "Summary Check OD",
        component: SummaryCheckAnotherLoanView,
      },
      {
        path: "/summary_check_al/:loan_agreement_id",
        name: "Summary Check AL",
        component: SummaryCheckAnotherLoanView,
      },
      { path: "/approvals", name: "Approvals", component: ApprovalsView },
      {
        path: "/platformsettings",
        name: "Platform Settings",
        component: PlatformSettingsView,
        meta: { privileges: [UserType.Administrator] },
      },
      { path: "/ektokismos", name: "Ektokismos", component: EktokismosView },
      { path: "/entitychanges", name: "Entity Changes", component: EntityChangesView },
      { path: "/upload", name: "File Upload", component: UploadView },
      { path: "/reports", name: "Report", component: ReportsView },
      { path: "/reportpreview", name: "Report Preview", component: ReportPreview },
      { path: "/metabase/:dashboard_id", name: "Metabase", component: MetabaseVue },
      // { path: "/test1", name: "Test1", component: () => import("../views/TestEktokismos.vue") },
      { path: "/la_calculations/:loan_account_id", name: "LACalculations", component: LoanAccountCalculations },
      { path: "/projects", name: "Projects", component: ProjectsView },
      { path: "/projecttemplates", name: "Project Templates", component: ProjectTemplatesView },
      { path: "/tasktemplates", name: "Task Templates", component: TaskTemplatesView },
      { path: "/customfields", name: "Custom Fields", component: CustomFieldsView },
      { path: "/indexing/frontier", name: "Frontier Physical File Indexing", component: Apografi_Frontier },
      
    ],
  },
];

if (client ==='DOVALUE'){
  routes = [
    { path: "/login", name: "Login", component: LoginView },
    {
      path: "/",
      redirect: "/dashboard",
      name: "Root",
      component: MainView,
      children: [
        { path: "/users", name: "Users", component: UsersView, meta: { privileges: [UserType.Administrator] } },
        {
          path: "/privileges",
          name: "Privileges",
          component: PrivilegesView,
          meta: { privileges: [UserType.Administrator] },
        },
        { path: "/clients", name: "Clients", component: ClientsView },
        { path: "/portfolios", name: "Portfolios", component: PortfoliosView },
        { path: "/debtors", name: "Debtors", component: DebtorView },
       { path: "/loanagreements", name: "Loan Agreements", component: LoanAgreementsView },        
        {
          path: "/platformsettings/interest/:catg_id",
          name: "Interest Data",
          component: AddInterestView,
        },
        {
          path: "/rob_selected",
          name: "Register of Bonds Selection",
          component: ROB_Selected,
          props: true
        },
        { path: "/loanAccountInstallments/:loan_account_id", name: "Loan_Account_Ektokismos", component: LoanAccountEktokismosView },
        { path: "/loanAgreementEktokismos/:loan_agreement_id", name: "Loan_Agreement_Ektokismos", component: LoanAgreementEktokismosView },

        { path: "/dashboard", name: "Dashboard", component: DashboardView },      
        { path: "/approvals", name: "Approvals", component: ApprovalsView },
        {
          path: "/platformsettings",
          name: "Platform Settings",
          component: PlatformSettingsView,
          meta: { privileges: [UserType.Administrator] },
        },     
        { path: "/entitychanges", name: "Entity Changes", component: EntityChangesView },
        { path: "/upload", name: "File Upload", component: UploadView },
        { path: "/reports", name: "Report", component: ReportsView },
        { path: "/reportpreview", name: "Report Preview", component: ReportPreview },
        { path: "/metabase/:dashboard_id", name: "Metabase", component: MetabaseVue },
        // { path: "/test1", name: "Test1", component: () => import("../views/TestEktokismos.vue") },
        { path: "/la_calculations/:loan_account_id", name: "LACalculations", component: LoanAccountCalculations },
      ],
    },
  ];
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  const mainStore = useMainStore();
  const pgrestapi = usePGRESTAPIStore();
  const mosapi = useMOSAPIStore();

  let dataCookie: any = cookies.get("dataCookie");
  let data2Cookie: any = cookies.get("data2Cookie");
  let menuListCookie: any = cookies.get("menuListCookie");
  // let menuCookie: any = cookies.get("menuCookie");
  let userCookie: any = cookies.get("userCookie");
  let menuOrderCookie: any = cookies.get("menuOrderCookie");
  let menuIconsCookie: any = cookies.get("menuIconsCookie");

  // console.log('datacookie', dataCookie);
  // console.log('cookie:', menuListCookie);
  if (
    dataCookie &&
    data2Cookie &&
    menuListCookie &&
    userCookie &&
    menuOrderCookie &&
    menuIconsCookie &&
    mainStore.loggedUser.username === "" &&
    to.path !== "/login"
  ) {
    await mainStore.setUser(userCookie.user);

    let menu: any = {};
    JSON.parse(menuListCookie).forEach((menuItem: any) => {
      // console.log('get cookie', 'menu_'+ menuItem);
      let m: any = cookies.get('menu_' + menuItem);
      // console.log(m)
      menu[menuItem] = m.menu;
    });

    await mainStore.setMenu(menu);
    await mainStore.setMenuOrder(menuOrderCookie.menuOrder);
    await mainStore.setMenuIcons(menuIconsCookie.menuIcons);
    await mosapi.setToken(dataCookie.token);
    await pgrestapi.setToken(dataCookie.token);
  }

  //
  // Go to login route if we don't have a logged in user
  //
  if (to.path !== "/login" && mainStore.loggedUser.username === "") {
    return {
      path: "/login",
    };
  }

  if (to.meta.privileges !== undefined) {
    if (!(to.meta.privileges as any).includes(mainStore.loggedUser.user_type)) {
      return {
        path: "/",
      };
    }
  }
});

export default router;

