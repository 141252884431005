<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { Refresh } from "@element-plus/icons-vue";
// import * as jose from 'jose';
import * as jsr from 'jsrsasign';
import { useMainStore } from "../store/main";
import UserType from "../enums/UserType";
const route = useRoute();
const frame = ref();
const mainStore = useMainStore();
let dashboard_id = ref(0);
let iframeUrl = ref("");

watch(
  () => route.params.dashboard_id,
  async (newVal, oldVal) => {
    dashboard_id.value = parseInt(newVal as string);
    await reloadData(dashboard_id.value);
  }
);

onMounted(async () => {
  if (route.params.dashboard_id !== undefined) {
    dashboard_id.value = parseInt(route.params.dashboard_id as string);
  }
  await reloadData(dashboard_id.value);
  /**************ΠΡΟΣΠΑΘΕΙΑ ΝΑ ΓΙΝΕΙ INJECT ΤΟ CSS ΣΤΟ IFRAME - ΔΕ ΔΟΥΛΕΥΕΙ****************/
  /*let css = document.createElement('style');
  css.type = 'text/css';

  let styles = '.EmbedFrame {' +
      '  border:0px solid black !important' +
      '  box-shadow: none !important;;' +
  '}';
  frame.value.onload = () => {
  css.appendChild(document.createTextNode(styles));
  //frame.value.appendChild(css)
  frame.value.contentDocument.appendChild(css);

  }
  
  console.log('**************')
  
  let d = frame.value.contentWindow.document;
  console.log(frame.value)
  console.log('**************')
  //console.log(JSON.stringify(frame.value));
  //console.log(frame.value);
  */
/**************ΠΡΟΣΠΑΘΕΙΑ ΝΑ ΓΙΝΕΙ INJECT ΤΟ CSS ΣΤΟ IFRAME - ΔΕ ΔΟΥΛΕΥΕΙ****************/
}

);

const reloadData = async (dashboard_id: number) => {
  let METABASE_SITE_URL = import.meta.env.VITE_METABASE_URL;

  let METABASE_SECRET_KEY = import.meta.env.VITE_METABASE_APIKEY;

  let payload = {
    resource: { dashboard: dashboard_id },
    params: {},
    exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
  };

  // const secret = new TextEncoder().encode(METABASE_SECRET_KEY);
  // const alg = 'HS256';

  let oHeader = {alg: 'HS256' };
  // console.log(jsr.KJUR.jws.JWS)
  let token2 = jsr.KJUR.jws.JWS.sign("HS256", JSON.stringify(oHeader), JSON.stringify(payload), {utf8: METABASE_SECRET_KEY});
  // console.log('token2:', token2);

  // const token = await new jose.SignJWT(payload)
  // .setProtectedHeader({ alg })
  // .sign(secret);
  // console.log('token:', token);  

  iframeUrl.value =
    METABASE_SITE_URL +
    "embed/dashboard/" +
    token2 +
    "#bordered=true&titled=false";

    console.log('iframe: ', iframeUrl.value)
}
</script>

<template>
  <div class="content-wrapper" v-if="(
    (mainStore.loggedUser.user_type === UserType.Administrator) ||
    (mainStore.loggedUser.user_type === UserType.Supervisor) && mainStore.loggedUser.digitization===false && dashboard_id!=5 ||
    (mainStore.loggedUser.user_type === UserType.Supervisor && mainStore.loggedUser.digitization===true)
    )">
    <el-row>
      <el-col :span="1" :offset="0">
        <el-button @click="reloadData(dashboard_id)" circle :icon="Refresh"></el-button>
        <p/>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <iframe :src="iframeUrl" frameborder="0" ref="frame" id="metabasestat"></iframe>
      </el-col>

    </el-row>
  </div>
  <div v-else>
    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="6">
          {{ $t("DDebtorDetailsView.permissions") }}
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<style scoped>
iframe {
    display: block;       /* iframes are inline by default */
    background: #FFFFFF;
    border: none;         /* Reset default border */
    height: 100vh;        /* Viewport-relative units */
    width: 100%;
}

.EmbedFrame {
  border:0px solid black !important;
  box-shadow: none !important;
}</style>
