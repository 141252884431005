enum DigitizedDocumentType {
    Folder = 0,
    Conventional = 1,
    Legalization = 2,
    Securities = 3,
    Surety = 4,
    LegalAction = 5,
    Miscellaneous = 6,
  }
    
  export default DigitizedDocumentType;
  