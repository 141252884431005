import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

export function useKeyboardActions() {
  const router = useRouter();
  let removingEscapeListener = ref<boolean>(false);

  const keyDown = (e: KeyboardEvent) => {
    // console.log(e.key)
    if (removingEscapeListener.value) return;

    if (e.key == "Escape") {
        removeEscapeListener();
        router.back();
    }
  }

  const removeEscapeListener = () => {
    if (removingEscapeListener.value) return;
  
    removingEscapeListener.value = true;
    // console.log('forcibly removing listener')
    window.removeEventListener("keydown", keyDown);
  };

  const addEscapeListener = () => {
    window.addEventListener("keydown", keyDown);
  }

  onMounted(() => {
    // console.log('adding escape listener')
    addEscapeListener();
  })
  
  onUnmounted(() => {
    // console.log('removing escape listener')
    removeEscapeListener();
  })

  return {
    addEscapeListener,
    removeEscapeListener,
  };
}
