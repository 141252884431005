<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import Portfolio from "../classes/DB_Entities/Portfolio";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useValidation } from "../composables/validation";
import UserType from "../enums/UserType";

const props = defineProps<{
  row_id: number;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });
const {
  validationErrors,
  onValidationChange,
  checkValidationsForError,
} = useValidation();

const clientsList = ref(new Array<any>());

const {
  cleanObject,
  fullObj,
  isLoading,
  table_name,
  close,
  isNewEntity,
  loadData,
  resetObjects,
  save,
  saveDirect,
  showError,
} = useGenericMethodsVariables();

onMounted(async () => {
  isLoading.value = true;
  table_name.value = Portfolio.getTableNameStatic();
  cleanObject.value = new Portfolio();

  console.log("portfolio_id", props.row_id);

  await loadClients();

  if (props.row_id === -1) {
    resetObjects();
    isLoading.value = false;
    return;
  }

  await loadData(props.row_id);
  isLoading.value = false;
});

const loadClients = async () => {
  clientsList.value = [];

  let result = await pgrestapi.get("mosclients");

  if (result.error) {
    showError(result.error.message);
    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    clientsList.value.push({
      value: result.data[i].id,
      label: result.data[i].clientname,
    });
  }
};

const onPreSave = async () => {
  console.log('presave-1');
  if (checkValidationsForError()) {
    showError("Please correct shown errors.");
    return;
  }
  console.log(JSON.stringify(fullObj.value.digital_code));
  console.log(JSON.stringify(fullObj.value.digital_code.originalValue));
if ((fullObj.value.digital_code.originalValue === null || fullObj.value.digital_code.originalValue === "") ) {
  fullObj.value.digital_code.originalValue =" ";
  console.log('mpike presave -2')
  }
  // Check if portfolio name already exists (if new entity)
  if (isNewEntity()) {
    let result = await pgrestapi.get('portfolio?select=id&portf_name=eq.' + fullObj.value.portf_name.originalValue);

    if (result.data.length > 0) {
      showError(t("validation.portfolio_name_already_exists"));
      return;
    }
  } else { // Check if portfolio name already exists (when updating existing entity)
    let result = await pgrestapi.get(`portfolio?select=id&id=neq.${fullObj.value.id.originalValue}&portf_name=eq.${fullObj.value.portf_name.originalValue}`);

    if (result.data.length > 0) {
      showError(t("validation.portfolio_name_already_exists"));
      return;
    }
  }

  delete fullObj.value.mosclient;
  await save();
};
</script>

<template>
  <div v-loading="isLoading">
    <el-form>
      <EntityField type="input" :label="$t('PortfolioDetails.name')" :object="fullObj.portf_name"
        :is-disabled="mainStore.loggedUser.user_type !== UserType.Administrator"
        :validation-rules="[['^.+$', 'validation.insert_portfolio_name']]" @validation-change="onValidationChange" />
      <EntityField type="input" :label="$t('PortfolioDetails.asset_owner')" :object="fullObj.asset_owner"
        :is-disabled="mainStore.loggedUser.user_type !== UserType.Administrator"
        :validation-rules="[['^.+$', 'validation.insert_asset_owner']]" @validation-change="onValidationChange" />
      <EntityField type="select" :object="fullObj.mosclient_id" label="MOS Client" :options="clientsList"
        :is-disabled="mainStore.loggedUser.user_type !== UserType.Administrator"
        :validation-rules="[['^[0-9]+$', 'validation.select_client']]" @validation-change="onValidationChange" />
      <EntityField type="switch" :label="$t('PortfolioDetails.digitization')" :object="fullObj.digitization" />
       <!-- 
          Να μπει validation
          :validation-rules="[['^[a-z]', 'validation.insert_digital_code']]" @validation-change="onValidationChange"

        -->
    
      <EntityField v-if="fullObj.digitization?.originalValue == true" type="input" :label="$t('PortfolioDetails.digital_code')" :object="fullObj.digital_code"
        :is-disabled="mainStore.loggedUser.user_type !== UserType.Administrator"
        />
      
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped></style>
