<script lang="ts" setup>
import { ref, onMounted, watch } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import DigitizedGelatin from "../classes/DB_Entities/DigitizedGelatin";
import DigitizedDocumentType from "../enums/DigitizedDocumentType";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { forIn } from 'lodash'
import { useI18n } from "vue-i18n";

const { showError } = useGenericMethodsVariables();
const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
    row_id: number; // digitized_gelatin
    digitized_folder_id: number;
    portfolio_code: string;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);

const pgrestapi = usePGRESTAPIStore();

let isLoading = ref(false);
const data = ref(new DigitizedGelatin()); 
const documentTypes = ref<any>([]);

watch(() => data.value.gelatin_barcode, async (newVal, oldVal) => {
    if (newVal.length === 24) {
        data.value.document_type = parseInt(newVal.substring(20, 22));
    }
});

onMounted(async () => {
    isLoading.value = true;

    console.log('loading digitized_gelatin id', props.row_id);

    if (props.row_id !== -1) {
        try {
            await loadData(props.row_id);
        } catch (ex: any) {
            showError(ex.message);
        }
    } else {
        data.value.digitized_folder_id = props.digitized_folder_id;
    }

    forIn(DigitizedDocumentType, (value, key) => {
        if (isNaN(Number(key)) && value !== DigitizedDocumentType.Folder) {
            documentTypes.value.push({ label: key, value: value })
        }
    });
    console.log(JSON.stringify(documentTypes.value));
    isLoading.value = false;
});

const loadData = async (entity_id: number) => {
    if (entity_id === -1) return;

    let result = await pgrestapi.get(`digitized_gelatin?id=eq.${entity_id}`);

    if (result.error) {
        showError(result.error.message);
        return;
    }

    if (result.data.length === 0) {
        showError(`Digitized Gelatin ${entity_id} not found`);
        emits('close');
        return;
    }

    data.value = result.data[0];
}

const close = () => {
    emits("close");
};

const save = async () => {

    console.log('gelatin barcode length:', data.value.gelatin_barcode.length);

    if (data.value.gelatin_barcode.length !== 24) {
        showError('Gelatin Barcode must be 24 characters exactly');
        return;
    }

    if (data.value.gelatin_barcode.substring(20, 22) !== data.value.document_type.toString().padStart(2, '0')) {
        showError('Gelatin Barcode character 21 and 22 must match the selected document type');
        return;
    }

    let res = null;
    if (props.row_id === -1) {
        let obj = JSON.parse(JSON.stringify(data.value));
        delete obj.id;
        res = await pgrestapi.post_data(`digitized_gelatin`, obj);
    } else {
        res = await pgrestapi.patch(`digitized_gelatin?id=eq.${data.value.id}`, data.value);
    }

    if (res.error) {
        console.log(res.error);
        if (res.error.message == 'Request failed with status code 409')
            showError('Gelatin barcode already exists');
        else
            showError(res.error.message);
        
        return;
    }

    emits("save");
}

</script>

<template>
  <div>

    <el-form>
        <el-form-item :label="$t('DDebtorDetailsView.gelatin_barcode')">
            <el-input v-model="data.gelatin_barcode" autocomplete="off" />
        </el-form-item>

        <el-form-item label="Page Count">
            <el-input v-model="data.page_count" autocomplete="off" />
        </el-form-item>

        <el-form-item label="Document Type">
            <el-select clearable v-model="data.document_type" autocomplete="off" class="m-2 header-filter"
              placeholder="" style="width: 100%" disabled>
              <el-option v-for="item in documentTypes" :key="item.value" :label="t('DigitizedGelatin.' + item.label)" :value="item.value" />
            </el-select>
        </el-form-item>
    </el-form>

    <el-row :gutter="10">
      <el-col :span="24">
        <el-form-item class="btn-wrapper-components">
          <el-button type="primary" @click="save">{{$t('generic.save')}}</el-button>
          <el-button @click="close">{{$t('generic.close')}}</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped></style>
