import ITableName from "../../interfaces/ITableName";

class ConfigTypeOfAgencyFee implements ITableName {
  id?: number = -1;
  type_of_agency_fee: string = "";

  static getTableNameStatic(): string {
    return "config_type_of_agency_fee";
  }

  getTableName(): string {
    return ConfigTypeOfAgencyFee.getTableNameStatic();
  }
}

export default ConfigTypeOfAgencyFee;
