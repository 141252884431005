<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { ElTree } from "element-plus";
import { Delete, Download, Upload } from "@element-plus/icons-vue";
import EntityType from "../enums/EntityType";
import RealEstate from "../classes/DB_Entities/RealEstate";
import Debtor from "../classes/DB_Entities/Debtor";
import LoanAgreement from "../classes/DB_Entities/LoanAgreement";
import { ElUpload, UploadFile, UploadFiles } from "element-plus";
import type { UploadInstance } from "element-plus";
import IUploadDocument from "../interfaces/IUploadDocument";
import { DateTime } from "luxon";
import UserType from "../enums/UserType";

interface Tree {
    id: number;
    label: string;
    db_string?: string;
    insert_timestamp?: string;
    username?: string;
    children: Tree[];
}

const props = defineProps<{
    entity_type: EntityType;
    entity_id: number;
    row_id: number;
    allowed_folders: string[];
}>();

const defaultProps = {
    children: 'children',
    label: 'label',
}

const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const { t, locale } = useI18n({ useScope: "global" });

const {
    isLoading,
    showError,
} = useGenericMethodsVariables();

let files = ref([] as any[]);
let tree = ref([] as Tree[]);

let showUploadDialog = ref(false);
const uploadRef = ref<UploadInstance>();
let fileSelected = ref(false);
let selectedUploadFolder = ref('');

const loadData = async () => {
    isLoading.value = true;

    let entity_table = '';

    if (props.entity_type === EntityType.RealEstate) {
        entity_table = RealEstate.getTableNameStatic();
    } else if (props.entity_type === EntityType.Debtor) {
        entity_table = Debtor.getTableNameStatic();
    } else if (props.entity_type === EntityType.LoanAgreement) {
        entity_table = LoanAgreement.getTableNameStatic();
    } else {
        throw new Error('Unknown entity type');
    }

    let res = await pgrestapi.get(`documents_${entity_table}?select=id,entity_id,filename,folder_name,insert_timestamp,uploader_user_id!left(username)&order=folder_name,insert_timestamp.desc,filename&entity_id=eq.${props.entity_id}&delete_timestamp=is.null`);

    files.value = res.data;
    tree.value = [];

    // Add allowed folders first
    for (let folder of props.allowed_folders) {
        tree.value.push({
            id: 0,
            label: t('documents_folders.' + folder),
            db_string: folder,
            children: [],
        });
    }

    // Add files, add also unknown folders
    for (let entry of files.value) {
        let treeItem = tree.value.find((x) => x.db_string === entry.folder_name);
        if (!treeItem) {
            tree.value.push({
                id: 0,
                label: t('documents_folders.' + entry.folder_name),
                db_string: entry.folder_name,
                children: [{ id: entry.id, label: entry.filename, children: [] }],
            });
        } else {
            treeItem.children?.push({ id: entry.id, label: entry.filename, insert_timestamp: entry.insert_timestamp, username: entry.uploader_user_id?.username, children: [] });
        }
    }

    isLoading.value = false;
}

onMounted(async () => {
    await loadData();
});

const onClickDownload = async (row: Tree) => {
    console.log('download', row.id);
    let entityType = '';

    if (props.entity_type === EntityType.RealEstate)
        entityType = RealEstate.getTableNameStatic();
    else if (props.entity_type === EntityType.Debtor)
        entityType = Debtor.getTableNameStatic();
    else if (props.entity_type === EntityType.LoanAgreement)
        entityType = LoanAgreement.getTableNameStatic();

    let filedata = await pgrestapi.download_document(entityType, row.id);

    let blob = new Blob([filedata.data], { type: 'application/octet-stream' });

    var link = document.createElement("a");
    link.setAttribute("href", window.URL.createObjectURL(blob));
    link.setAttribute(
        "download",
        row.label
    );
    document.body.appendChild(link); // Required for FF

    link.click(); // Trigger file download

    document.body.removeChild(link); // Remove link from body
};

const onClickDelete = async (row: Tree) => {
    isLoading.value = true;
    let entityType = '';

    if (props.entity_type === EntityType.RealEstate)
        entityType = RealEstate.getTableNameStatic();
    else if (props.entity_type === EntityType.Debtor)
        entityType = Debtor.getTableNameStatic();
    else if (props.entity_type === EntityType.LoanAgreement)
        entityType = LoanAgreement.getTableNameStatic();

    let timestamp: string = await (await pgrestapi.get("rpc/customnew")).data;
    let res = await pgrestapi.patch(`documents_${entityType}?id=eq.${row.id}`, { delete_timestamp: timestamp, deleted_by_user_id: mainStore.loggedUser.id, filedata: null });
    console.log(res);
    await loadData();
    isLoading.value = false;
};

const addedFile = async (uploadFile: UploadFile, uploadFiles: UploadFiles) => {
    for (let file of uploadFiles) {
        if (file.status === "ready") {
            fileSelected.value = true;
        } else if (file.status === "success") {
            fileSelected.value = false;
            uploadRef.value!.clearFiles();
        }
    }
}

const doUpload = async (test: any) => {
    // console.log('do upload it', test);
}

const submitUpload = async () => {
    isLoading.value = true;
    uploadRef.value!.submit();
};

const uploadComplete = async (response: any, uploadFile: UploadFile, uploadFiles: UploadFiles) => {
    let res = await pgrestapi.upload_document((await uploadFile.raw?.arrayBuffer()), { entitytype: props.entity_type, filename: uploadFile.raw?.name, foldername: selectedUploadFolder.value, uploaderuserid: mainStore.loggedUser.id, entityid: props.entity_id } as IUploadDocument)

    showUploadDialog.value = false;
    await loadData();
    isLoading.value = false;
};

</script>

<template>
    <div>
        <div class="center">
            <el-button :icon="Upload" circle style="background-color: lightblue; " @click="showUploadDialog = true" />
            Upload new file
            <p />
        </div>

        <el-divider />

        <el-tree :data="tree" :props="defaultProps" default-expand-all>
            <template #default="{ node, data }">
                <span class="custom-tree-node">
                    <span style="display:inline-block; width: 250px;">{{ data.label }} {{ data.id === 0 ?
                        `(${data.children.length})` : '' }}</span>

                    <span v-if="data.id !== 0">
                        <span class="vl"></span>

                        {{ DateTime.fromISO(data.insert_timestamp).toFormat("dd/MM/yyyy HH:mm:ss") }}

                        <span class="vl"></span>
                        
                        {{  data.username }}

                        <span class="vl"></span>

                        <el-button :icon="Download" circle size="small"
                            style="background-color: green; border: 0px; color: white; margin-left: 15px"
                            @click="onClickDownload(data)" />
                        <el-popconfirm v-if="mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor" title="Are you sure?" @confirm="onClickDelete(data)">
                            <template #reference>
                                <el-button :icon="Delete" circle size="small"
                                    style="background-color: red; border: 0px; color: white; margin-left: 15px" />
                            </template>
                        </el-popconfirm>
                    </span>
                </span>
            </template>
        </el-tree>

        <el-dialog v-if="showUploadDialog" v-model="showUploadDialog" :title="`Upload new file`">
            <div v-loading="isLoading">
                Upload folder
                <el-select v-model="selectedUploadFolder" class="m-2 docselect" size="large" clearable filterable placeholder=" ">
                    <el-option v-for="item in props.allowed_folders" :key="item" :label="t('documents_folders.' + item)"
                        :value="item" />
                </el-select>
                <p />

                <el-upload ref="uploadRef" :http-request="doUpload" class="upload-demo" :auto-upload="false" :limit="1"
                    :on-success="uploadComplete" @change="addedFile">
                    <template #trigger>
                        <el-button class="topmargin50" type="primary">Select file</el-button>
                    </template>
                    <div class="el-upload el-upload--text leftpadding15" tabindex="0" style="border: 0px;">
                    <el-button v-if="fileSelected && selectedUploadFolder !== ''" class="ml-3" type="success"
                        @click="submitUpload">
                        Upload
                    </el-button>
                </div>
                    <template #tip>
                        <div class="el-upload__tip text-red">
                            Limit 1 file.
                        </div>
                    </template>
                </el-upload>
            </div>
        </el-dialog>
    </div>
</template>

<style scoped>
.center {
    padding-left: 125px;
    margin: auto;
}

.vl {
  border-left: 1px dotted gray;
  padding-left: 1px;
  padding-right: 1px;

}
</style>
