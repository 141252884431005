import { createApp } from "vue";
import VueRouter from "vue-router";
import { createPinia } from "pinia";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./style.css";
import "./assets/custom-theme/index.css";
import App from "./App.vue";
import router from "./router/index";
import { createI18n } from "vue-i18n";

import en from "./locales/en.json";
import el from "./locales/el.json";

const i18n = createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en,
    el,
  },
});

const pinia = createPinia();
const app = createApp(App).use(router).use(pinia).use(ElementPlus).use(i18n);
app.mount("#app");
