<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import Debtor from "../classes/DB_Entities/Debtor";
import Decision from "../classes/Decision";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import UserType from "../enums/UserType";
import { usePendingChanges } from "../composables/pendingChanges";
import { useValidation } from "../composables/validation";
import AcceptAllPending from "./AcceptAllPending.vue";
import { DateTime } from "luxon";
import ConfigKlados from "../classes/DB_Entities/ConfigKlados";
import ConfigKad from "../classes/DB_Entities/ConfigKad";
import Portfolio from "../classes/DB_Entities/Portfolio";

const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });

const agents = ref<any>([]);
const loanAgreements = ref<any>([]);

const props = defineProps<{
  row_id: number;
}>();

const activeName = ref("first");
let portfolios = ref<any>([]);
let groups = ref<any>([]);
let countriesData = ref<any>([]);
let allPrefectures = ref<any>([]);
let allPostCodes = ref<any>([]);
let allKladoi = ref<any>([]);
let allKAD = ref<any>([]);
let filteredPostCodes = ref<any>([]);
let filteredPostalPostCodes = ref<any>([]);
let linkTable = ref<any>([]);

let { multipleFieldOne, multipleFieldTwo, warningMultipleVisible } = usePendingChanges();
const {
  cleanObject,
  fullObj,
  hasPendingChanges,
  isLoading,
  table_name,
  clickAcceptAll,
  hasPendingChangeForField,
  isNewEntity,
  loadData,
  save,
  close,
  resetObjects,
  setDisplayValues,
  showError,
  showSuccess,
  showWarning,
  updateDecision,
} = useGenericMethodsVariables();

const {
  validationErrors,
  onValidationChange,
  checkValidationsForError,
} = useValidation();

const GetPortfolios = async () => {
  let url = `portfolio?select=*,mosclients!inner(clientname)`;

  if (mainStore.loggedUser.user_type === UserType.Supervisor)
    url += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;

  let result = await pgrestapi.get(url);

  portfolios.value = [];

  for (let portfolio of result.data) {
    portfolios.value.push({
      value: portfolio.id,
      label: `${portfolio.portf_name} (${portfolio.mosclients.clientname})`,
    });
  }
};

const GetGroups = async () => {
  let result = await pgrestapi.get(`config_group?select=*`);

  groups.value = [];

  for (let group of result.data) {
    groups.value.push({
      value: group.id,
      label: `${group.group_id} (${group.group_name})`,
    });
  }
};

const GetCountries = async () => {
  let result = await pgrestapi.get(`config_country?select=*`);

  countriesData.value = [];

  for (let countries of result.data) {
    countriesData.value.push({
      value: countries.id,
      label: `${countries.country}`,
    });
  }
}

const getPostalCodes = async () => {
  let result = await pgrestapi.get(`config_postalcode?`);

  if (result.error) {
    showError(result.error.message);
    return;
  }

  allPostCodes.value = [];

  for (let postalcodes of result.data) {
    allPostCodes.value.push({
      value: postalcodes.id,
      label: `${postalcodes.postalcode}`,
      tk: parseInt(postalcodes.postalcode),
    });
  }

  filteredPostCodes.value = JSON.parse(JSON.stringify(allPostCodes.value));
  filteredPostalPostCodes.value = JSON.parse(JSON.stringify(allPostCodes.value));
}

const GetPrefecture = async () => {
  let result = await pgrestapi.get(`config_prefecture?select=*`);

  allPrefectures.value = [];

  for (let prefectures of result.data) {
    allPrefectures.value.push({
      value: prefectures.id,
      label: `${prefectures.prefecture}`,
      min_tk: `${prefectures.min_tk}`,
      max_tk: `${prefectures.max_tk}`,
    });
  }
}

onMounted(async () => {
  console.log('loading debtor id', props.row_id);

  table_name.value = Debtor.getTableNameStatic();
  cleanObject.value = new Debtor();

  isLoading.value = true;

  await GetPortfolios();
  await GetGroups();
  await GetPrefecture();
  await getPostalCodes();
  await GetCountries();
  await loadKlados();
  await loadSelects();
  //await loadLoanAgreements();

  resetObjects();
  loanAgreements.value = [];
  agents.value = [];

  if (props.row_id === -1) {
    isLoading.value = false;
    return;
  }
  await loadLoanAgreements();
  await loadData(props.row_id);
  await loadAgents();
  await loadKAD();
  await fixPendingSelects();
  if(fullObj.value.district.originalValue){
    await filterPostCode(fullObj.value.district.originalValue);
  }
  if(fullObj.value.postal_district.originalValue){
    await filterPostalPostCode(fullObj.value.postal_district.originalValue);
  }
  isLoading.value = false;
});

const getDecisionObjectForField = (field: string): Decision => {
  let decision = new Decision();

  let obj: any = (fullObj as any).value[field];

  decision.db_change_id = obj.pendingValue.id;
  decision.field = field;
  decision.value = obj.pendingValue.value;

  return decision;
};

const getTranslationLegalStatus = (row: any, column: any) => {
  return t("legal_status_list." + row[column.property]);
};

const onDecision = async (decision: Decision) => {
  isLoading.value = true;

  if ((decision.field === "klados" && hasPendingChangeForField("kad")) || (decision.field === "kad" && hasPendingChangeForField("klados"))) {
    multipleFieldOne.value = (fullObj as any).value["klados"];
    multipleFieldTwo.value = fullObj.value["kad"];

    linkTable.value = [
      { field: t('DebtorDetailsView.' + multipleFieldOne.value.field), pendingValue: multipleFieldOne.value.pendingValue.pendingDisplayValue, username: multipleFieldOne.value.pendingValue.editor.username, timestamp: DateTime.fromISO(multipleFieldOne.value.pendingValue.insert_timestamp).toFormat("dd/MM/yyyy HH:mm") },
      { field: t('DebtorDetailsView.' + multipleFieldTwo.value.field), pendingValue: multipleFieldTwo.value.pendingValue.pendingDisplayValue, username: multipleFieldTwo.value.pendingValue.editor.username, timestamp: DateTime.fromISO(multipleFieldTwo.value.pendingValue.insert_timestamp).toFormat("dd/MM/yyyy HH:mm") },
    ];

    isLoading.value = false;
    warningMultipleVisible.value = true;
    return;
  }

  await updateDecision(decision);

  await loadData(props.row_id);

  await loadSelects();
  await loadKAD();
  await fixPendingSelects();
  await loadLoanAgreements();

  isLoading.value = false;
};

const linkAnswer = async (result: number) => {
  warningMultipleVisible.value = false;

  isLoading.value = true;

  let decision1 = getDecisionObjectForField(multipleFieldOne.value.field);
  let decision2 = getDecisionObjectForField(multipleFieldTwo.value.field);

  decision1.accepted = result === 1 ? true : false;
  decision2.accepted = result === 1 ? true : false;

  await updateDecision(decision1);
  await updateDecision(decision2);

  await loadData(props.row_id);

  await loadSelects();
  await loadKAD();
  await fixPendingSelects();
  await loadLoanAgreements();

  isLoading.value = false;
};

const debtorExistsGlobal = async (): Promise<number> => {
  let result = await pgrestapi.get(
    `${Debtor.getTableNameStatic()}?cid=eq.${fullObj.value.cid.originalValue}&vat_number=eq.${fullObj.value.vat_number.originalValue
    }`
  );

  return result.data.length > 0 ? result.data[0].id : 0;
};

const portfolioHasDebtorWithCID = async (): Promise<number> => {
  let result = await pgrestapi.get(
    `${Debtor.getTableNameStatic()}?cid=eq.${fullObj.value.cid.originalValue}&portfolio=eq.${fullObj.value.portfolio.originalValue}`
  );

  return result.data.length > 0 ? result.data[0].id : 0;
};

const portfolioHasDebtorWithVAT = async (): Promise<number> => {
  let result = await pgrestapi.get(
    `${Debtor.getTableNameStatic()}?vat_number=eq.${fullObj.value.vat_number.originalValue}&portfolio=eq.${fullObj.value.portfolio.originalValue}`
  );

  return result.data.length > 0 ? result.data[0].id : 0;
};

const onPreSave = async () => {
  // console.log(JSON.stringify(fullObj.value));
  if (checkValidationsForError()) {
    showError("Please correct shown errors.");
    return;
  }

  let exists_error = "";

  let existingDebtorID = await debtorExistsGlobal();

  if (existingDebtorID > 0) {
    exists_error = t("errors.debtor_already_exists");
  } else {
    existingDebtorID = await portfolioHasDebtorWithCID();

    if (existingDebtorID > 0) {
      exists_error = t("errors.debtor_already_exists_portfolio_cid");
    } else {
      existingDebtorID = await portfolioHasDebtorWithVAT();

      if (existingDebtorID > 0) {
        exists_error = t("errors.debtor_already_exists_portfolio_vat");
      }
    }
  }

  if (isNewEntity()) {
    if (exists_error !== '') {
      showWarning(exists_error);
      return;
    }
  } else {
    if (existingDebtorID !== 0 && existingDebtorID !== fullObj.value.id.originalValue) {
      showWarning(exists_error);
      return;
    }
  }

  if (isNewEntity() && mainStore.loggedUser.user_type === UserType.Agent) {
    fullObj.value.allowed_users.originalValue.push(mainStore.loggedUser.id);
  }

  await save();
};

const IdCardList = ref<any>([]);
const CustomerCategoryList = ref<any>([]);

const loadAgents = async () => {
  agents.value = [];

  let result = await pgrestapi.get(`debtor?select=allowed_users&id=eq.${props.row_id}`);

  if (result.error) {
    showError(result.error.message);
    return;
  }

  if (result.data[0]?.allowed_users.length > 0) {
    for (let i = 0; i < result.data[0].allowed_users.length; i++) {
      if (result.data[0].allowed_users[i] > 0) {
        let result2 = await pgrestapi.get(
          `users?select=username&user_type=eq.2&id=eq.${result.data[0].allowed_users[i]}&order=username`
        );

        if (result2.error) {
          showError(result2.error.message);
          return;
        }

        if (result2.data.length > 0) {
          agents.value.push(result2.data[0].username);
        }
      }
    }
  }
};

const loadLoanAgreements = async () => {
  loanAgreements.value = [];
  let result = await pgrestapi.get(
    `view_legal_status_view1?select=*&debtor_id=eq.${props.row_id}`
  );

  // console.log(`view_legal_status_view1?select=*&debtor_id=eq.${props.row_id}&la_debtor_id=eq.${props.row_id}`);

  if (result.error) {
    showError(result.error.message)
  }

  loanAgreements.value = result.data;

  // console.log(loanAgreements.value);
};

const loadKlados = async () => {
  allKladoi.value = [];

  let result = await pgrestapi.get("config_klados?order=kodikos.asc");

  if (result.error) {
    showError(result.error.message);
    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    allKladoi.value.push({
      value: result.data[i].id,
      label: result.data[i].kodikos + " " + result.data[i].perigrafi,
    });
  }
};

const loadKAD = async () => {
  allKAD.value = [];

  console.log('klados', fullObj.value?.klados?.originalValue);

  if (fullObj.value.klados.originalValue === undefined || fullObj.value.klados.originalValue === null || fullObj.value.klados.originalValue === '') {
    return;
  }

  let result = await pgrestapi.get(`config_kad?order=kodikos_kad.asc&klados_id=eq.${fullObj.value.klados.originalValue}`);

  if (result.error) {
    showError(result.error.message);
    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    allKAD.value.push({
      value: result.data[i].id,
      label: result.data[i].kodikos_kad + " " + result.data[i].perigrafi,
      klados_id: result.data[i].klados_id,
    });
  }
};

//
// Called when Debtor Prefecture changes
//
const filterPostCode = async (val: any) => {
  let selectedPrefecture = allPrefectures.value.find((x: any) => x.value === val);

  if (selectedPrefecture === undefined) {
    filteredPostCodes.value = JSON.parse(JSON.stringify(allPostCodes.value));
  } else {
    filteredPostCodes.value = allPostCodes.value.filter((x: any) => x.tk >= selectedPrefecture?.min_tk && x.tk <= selectedPrefecture?.max_tk);
  }
};

//
// Called when Postal Prefecture changes
//
const filterPostalPostCode = async (val: any) => {
  let selectedPostalPrefecture = allPrefectures.value.find((x: any) => x.value === val);

  if (selectedPostalPrefecture === undefined) {
    filteredPostalPostCodes.value = JSON.parse(JSON.stringify(allPostCodes.value));
  } else {
    filteredPostalPostCodes.value = allPostCodes.value.filter((x: any) => x.tk >= selectedPostalPrefecture.min_tk && x.tk <= selectedPostalPrefecture.max_tk);
  }
};

const GenerateGroupName = async (val: any) => {
  let result = await pgrestapi.get(`config_group?select=group_name&id=eq.` + val);
  fullObj.value.group_name.originalValue = result.data[0].group_name;
};

const fixPendingSelects = async () => {
  for (let field of Object.keys(fullObj.value)) {
    if (fullObj.value[field].pendingValue === undefined) continue;

    if (field === "portfolio") {
        setDisplayValues(Portfolio, field, (dbObj: any) => { return `${dbObj.portf_name}`; });
    } else if (field === "klados") {
        setDisplayValues(ConfigKlados, field, (dbObj: any) => { return `${dbObj.kodikos} ${dbObj.perigrafi}`; });
    } else if (field === "kad") {
        setDisplayValues(ConfigKad, field, (dbObj: any) => { return `${dbObj.kodikos_kad} ${dbObj.perigrafi}`; });
    }

    if (field === "customer_type") {
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = CustomerCategoryList.value.find(
        (e: any) => e.value === fullObj.value[field].originalValue
      )?.label;
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = CustomerCategoryList.value.find(
        (e: any) => e.value === fullObj.value[field].pendingValue.value
      )?.label;
    }
    if (field === "id_card_type") {
      fullObj.value[field].pendingValue["originalDisplayValue"] = IdCardList.value.find(
        (e: any) => e.value === fullObj.value[field].originalValue
      )?.label;
      fullObj.value[field].pendingValue["pendingDisplayValue"] = IdCardList.value.find(
        (e: any) => e.value === fullObj.value[field].pendingValue.value
      )?.label;
    }
  }
};

const loadSelects = async () => {
  CustomerCategoryList.value = [
    {
      value: "individual",
      label: t('DebtorDetailsView.individual'),
    },
    {
      value: "legal_entity", // Ιδιωτικό δίκαιο
      label: t('DebtorDetailsView.legal_entity'),
    },
    {
      value: "legal_entity_public", // Δημόσιο Δίκαιο
      label: t('DebtorDetailsView.legal_entity_public'),
    },
  ];

  IdCardList.value = [
    {
      value: "id_card",
      label: "ID card",
    },
    {
      value: "passport",
      label: "Passport",
    },
  ];
};

const clickAcceptAllInternal = async () => {
  await clickAcceptAll(props.row_id);

  isLoading.value = true;

  await loadAgents();
  await loadLoanAgreements();

  isLoading.value = false;
}

</script>

<template>
  <div v-loading="isLoading">
    <el-form>
      <AcceptAllPending v-show="hasPendingChanges" @accept="clickAcceptAllInternal" />
      <p />
      <div style="text-align: right; padding-bottom: 10px" v-if="loanAgreements[0]?.katast_sumbashs">
        <el-tag class="ml-2" style="margin: 12px">
          {{ $t("DebtorDetailsView.legal_status") }} of
          {{ loanAgreements[0]?.ar_sumbashs }}: <span v-if="loanAgreements[0]?.katast_sumbashs == 'denounced'">Denounced</span>
          <span v-if="loanAgreements[0]?.katast_sumbashs == 'non_performing'">Non performing</span>
          <span v-if="loanAgreements[0]?.katast_sumbashs == 'performing'">Performing</span>
        </el-tag>
      </div>

      <el-tabs v-model="activeName" type="card" class="demo-tabs">
        <el-tab-pane :label="$t('DebtorDetailsView.details')" name="first">
          <el-row :gutter="10">
            <el-col :span="24">
              <EntityField type="input" :label="$t('DebtorDetailsView.cid')" :object="fullObj.cid" @decision="onDecision"
                :validation-rules="[['.+', 'validation.insert_cid']]" @validation-change="onValidationChange" />
            </el-col>

            <el-col :span="24">
              <EntityField type="select" :label="$t('PlatformSettingsView.group_id')" :object="fullObj.group_id"
                @decision="onDecision" :options="groups" @select-change="GenerateGroupName" />
            </el-col>

            <el-col :span="24">
              <EntityField type="input" :label="$t('PlatformSettingsView.group_name')" :object="fullObj.group_name"
                @decision="onDecision" :is-disabled="true" />
            </el-col>

            <el-col :span="24">
              <EntityField type="select" :label="$t('DebtorDetailsView.portfolio')" :object="fullObj.portfolio"
                :options="portfolios" @decision="onDecision" :validation-rules="[['.+', 'validation.insert_portfolio']]"
                @validation-change="onValidationChange" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('DebtorDetailsView.former_asset_owner_fao')" :object="fullObj.fao"
                @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('DebtorDetailsView.cid_fao')" :object="fullObj.cid_fao"
                @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :object="fullObj.customer_type"
                :label="$t('DebtorDetailsView.customer_category')" :options="CustomerCategoryList"
                @decision="onDecision" />
            </el-col>

            <el-col :span="24">
              <el-table :data="loanAgreements"  stripe border table-layout="fixed" size="small"
                style="margin-top: 20px; margin-bottom: 30px">
                <el-table-column fixed sortable prop="ar_sumbashs" :label="$t('LoanAgreementView.loan_agreement')"
                  type="text" />
                <el-table-column fixed sortable :formatter="getTranslationLegalStatus" prop="katast_sumbashs" :label="$t('LoanAgreementView.legal_status')"
                  type="text" />
                <el-table-column fixed sortable prop="re_kalipsi"
                  :label="$t('real_estate_collateral.prenotation_amount')" type="text" />
                  <el-table-column fixed sortable prop="greatest"
                  :label="$t('real_estate_collateral.kalimaNRE')" type="text" />
                  <el-table-column fixed sortable prop="synol_poso_sumbashs"
                  :label="$t('real_estate_collateral.synol_poso_sumbashs')" type="text" />
              </el-table>
            </el-col>

            <el-col :span="24">
              <EntityField type="input" :label="$t('DebtorDetailsView.company_fullname')" :object="fullObj.fullname"
                @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :label="$t('DebtorDetailsView.id_card_type')" :object="fullObj.id_card_type"
                :options="IdCardList" @decision="onDecision" />
            </el-col>
            <el-col :span="24" v-if="fullObj.id_card_type?.originalValue">
              <EntityField type="input" label="ID Card Number" :object="fullObj.id_card" @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('DebtorDetailsView.tax_registration_nbr')" :object="fullObj.vat_number"
                @decision="onDecision" :validation-rules="[['.+', 'validation.tax_registration_nbr']]"
                @validation-change="onValidationChange" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('DebtorDetailsView.gemi')" :object="fullObj.gemh"
                @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :object="fullObj.klados" :label="$t('DebtorDetailsView.klados')"
                :options="allKladoi" @decision="onDecision" @select-change="loadKAD" />
              <!-- <EntityField type="input" :label="$t('DebtorDetailsView.klados')" :object="fullObj.klados"
                                        @decision="onDecision" /> -->
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :object="fullObj.kad" :label="$t('DebtorDetailsView.kad')" :options="allKAD"
                @decision="onDecision" />
              <!-- <EntityField type="input" :label="$t('DebtorDetailsView.kad')" :object="fullObj.kad"
                                        @decision="onDecision" /> -->
            </el-col>

            <el-col :span="24">
              <EntityField type="input" :label="$t('DebtorDetailsView.street')" :object="fullObj.address"
                @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('DebtorDetailsView.street_number')" :object="fullObj.address_number"
                @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('DebtorDetailsView.region')" :object="fullObj.region"
                @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('DebtorDetailsView.city')" :object="fullObj.city"
                @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :label="$t('DebtorDetailsView.prefecture')" :object="fullObj.district"
                :options="allPrefectures" @decision="onDecision" @select-change="filterPostCode"
                @clear="filterPostCode" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :label="$t('DebtorDetailsView.zip_code')" :object="fullObj.postcode"
                :options="filteredPostCodes" @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :label="$t('DebtorDetailsView.country')" :object="fullObj.country"
                :options="countriesData" @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('DebtorDetailsView.phone')" :object="fullObj.phone_number"
                @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" label="Email" :object="fullObj.email" @decision="onDecision" />
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane :label="$t('DebtorDetailsView.postal_address')" name="third">
          <el-row :gutter="10">
            <el-col :span="24">
              <EntityField type="input" :label="$t('DebtorDetailsView.postal_street')" :object="fullObj.postal_address"
                @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('DebtorDetailsView.postal_nbr')"
                :object="fullObj.postal_address_number" @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('DebtorDetailsView.postal_region')" :object="fullObj.postal_region"
                @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('DebtorDetailsView.postal_city')" :object="fullObj.postal_city"
                @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :label="$t('DebtorDetailsView.postal_prefecture')"
                :object="fullObj.postal_district" :options="allPrefectures" @decision="onDecision"
                @select-change="filterPostalPostCode" @clear="filterPostalPostCode" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :label="$t('DebtorDetailsView.postal_zip_code')"
                :object="fullObj.postal_postcode" :options="filteredPostalPostCodes" @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :label="$t('DebtorDetailsView.postal_country')" :object="fullObj.postal_country"
                :options="countriesData" @decision="onDecision" />
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane :label="$t('DebtorDetailsView.rest_of_legal_details')" name="fourth">
          <el-row :gutter="10">
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('DebtorDetailsView.legal_start_date')"
                :object="fullObj.hmer_enar_isx_nomim" @decision="onDecision" />
              <EntityField type="datepicker" :label="$t('DebtorDetailsView.legal_end_date')"
                :object="fullObj.hmer_liks_isx_nomim" @decision="onDecision" />
            </el-col>
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('DebtorDetailsView.social_security_clearance_issue_date')"
                :object="fullObj.hmer_ekd_asfa_enim" @decision="onDecision" />
              <EntityField type="datepicker" :label="$t('DebtorDetailsView.social_security_clearance_end_date')"
                :object="fullObj.hmer_liks_asfa_enim" @decision="onDecision" />
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="MOS" name="fifth">

          <div style="border-radius:10px; border: 1px transparent solid; overflow: hidden">
            <div style="background-color:#EEEEEE; padding-top: 10px; padding-bottom: 10px;">
              <el-row style="padding-left: 10px;">
                <el-col :span="24">
                  <EntityField type="input" :label="$t('DebtorDetailsView.mos_client_account_officer')"
                    :object="fullObj.mos_clients_acc_off" @decision="onDecision" />
                </el-col>
              </el-row>
            </div>
          </div>
          <div style="border-radius:10px; border: 1px transparent solid; overflow: hidden; margin-top: 5px;">
            <div style="background-color:#EEEEEE; padding-top: 10px; padding-bottom: 10px;">
              <el-row>
                <el-col :span="12" style="padding-left: 10px">
                  {{ t("DebtorDetailsView.mos_officer") }}
                </el-col>
                <el-col :span="12">
                  <el-tag class="ml-2" type="success" style="margin: 2px;" v-for="item in agents" effect="light">
                    {{ item }}
                  </el-tag>
                  <span v-if="agents.length == 0">
                    No Data
                  </span>
                </el-col>
              </el-row>
            </div>
          </div>

        </el-tab-pane>
      </el-tabs>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog v-if="warningMultipleVisible" v-model="warningMultipleVisible" title="Linked fields" width="80%">
      Both changes must be accepted or rejected at the same time.
      <p />
      <el-table :data="linkTable" style="width: 100%">
        <el-table-column prop="field" label="Field" width="180" />
        <el-table-column prop="pendingValue" label="Pending Value" />
        <el-table-column prop="username" label="Editor" width="140" />
        <el-table-column prop="timestamp" label="Edit date/time" width="150" />
      </el-table>
      <p />
      <el-button @click="linkAnswer(1)">Accept</el-button>
      <el-button @click="linkAnswer(2)">Reject</el-button>
      <el-button @click="warningMultipleVisible = false">Back</el-button>
    </el-dialog>
  </div>
</template>

<style scoped>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
</style>
