import { DateTime } from "luxon";

// Enums

import AmountEntryType from "../enums/LoanAccountEventType";
import Frequency from "../enums/Frequency";

class AmountEntryExtra {
    public type: AmountEntryType = AmountEntryType.Dosh;
    public startDate: string = DateTime.now().toISODate() as string;
    public amount: number = 0;
    public endDate: string | undefined = undefined; //  '1970-01-01'; //DateTime.now().toISODate() as string;
    public original_endDate: string | undefined =  '1970-01-01'; //DateTime.now().toISODate() as string;
    public hiddenDate: string = '';
    public dedouleymenoi_tokoi: number = 0;
    public tokoi_periodou: number = 0;
    public periodos_index: number = 0;
    public dummytext: string = '';
    public ignore: boolean = false;
    public ignoreDedoul: boolean = false;
    public recalculatePMTEntry: boolean = false;
    public doshAmount: number = 0;
    public tokoxreolusio: number = 0;
    public usedPistwsh: number = 0;
    public ofeilhIndex: number = 0;
    public interest: number = 0;
    public perithorio: number = 0;
    public eisfora: number = 0;
    public bash_ektokismou: number = 360;
    public pososto_tokwn_yperhmerias: number = 2.5;
    public valeurDate: string | undefined = undefined; //  '1970-01-01'; //DateTime.now().toISODate() as string;
    public origin: string = "";
    public calculatedDays: number = 0;

    public constructor(perithorio: number, eisfora: number, bash_ektokismou: number, pososto_tokwn_yperhmerias: number) {
        this.perithorio = perithorio;
        this.eisfora = eisfora;
        this.bash_ektokismou = bash_ektokismou;
        this.pososto_tokwn_yperhmerias = pososto_tokwn_yperhmerias;
    }

    public CalculateDedouleymenousTokous(useYperhmeria: boolean) {
        let days = DateTime.fromISO(this.endDate as string).diff(DateTime.fromISO(this.startDate), 'days').days;
        /*
        If tokoxreolutiko kai if different month this.endDate με this.startDate add to days diff apo 30-endDateDAY 
        (πχ 30-31 = -1 αφαιρει μια μερα
            30-28 = 2 προσθετει 2 μερες
            30-30 = 0)
        */
        this.dedouleymenoi_tokoi = this.amount * days * (this.interest / 100 + this.GetPerithorio() + this.GetEisfora() + (useYperhmeria ? this.pososto_tokwn_yperhmerias / 100 : 0)) / this.GetDivisibleDays();
    }

    public CalculateDedouleymenousTokousTokoxreolytiko(useYperhmeria: boolean) {
        let month_difference = DateTime.fromISO(this.endDate as string).month - DateTime.fromISO(this.startDate).month;

        if (month_difference < 0)
            month_difference += 12;

        let days = DateTime.fromISO(this.endDate as string).diff(DateTime.fromISO(this.startDate), 'days').days;    

        if (month_difference > 0) {
            let dt = DateTime.fromISO(this.startDate as string);

            for (let i = 0; i < month_difference; ++i) {
                days += 30 - (dt.daysInMonth as number);
                dt = dt.plus({ month: 1 });
            }
            this.calculatedDays = days;
        }
        
        /*
        If tokoxreolutiko kai if different month this.endDate με this.startDate add to days diff apo 30-endDateDAY 
        (πχ 30-31 = -1 αφαιρει μια μερα
            30-28 = 2 προσθετει 2 μερες
            30-30 = 0)
        */
        // console.log('zzz days:', days, 'startDate', this.startDate, 'endDate', this.endDate, 'monthdiff', month_difference);
        this.dedouleymenoi_tokoi = this.amount * days * (this.interest / 100 + this.GetPerithorio() + this.GetEisfora() + (useYperhmeria ? this.pososto_tokwn_yperhmerias / 100 : 0)) / this.GetDivisibleDays();
    }

    public GetPerithorio(): number {
        return this.perithorio / 100;
    }

    public GetEisfora(): number {
        return this.eisfora / 100;
    }

    public GetDivisibleDays(): number {
        return this.bash_ektokismou;
    }

    public static getNextFrequencyDate = (date: DateTime,
        frequency: Frequency,
        epeteiako: boolean): DateTime => {

        if (!epeteiako) {
            switch (frequency) {
                case Frequency.Monthly:
                    if (date.diff(date.endOf('month'), 'days').days <= -1) {
                        return date.endOf('month').startOf('day');
                    }
                    return date.plus({ days: 1 }).endOf('month').startOf('day');

                case Frequency.Quarterly:
                    if (date.month % 3 === 0 && date.diff(date.endOf('month'), 'days').days <= -1) {
                        return date.endOf('month').startOf('day');
                    }
                    return date.plus({ months: 3 - (date.month % 3) }).endOf('month').startOf('day');

                case Frequency.SemiAnnually:
                    if (date.month % 6 === 0 && date.diff(date.endOf('month'), 'days').days <= -1) {
                        return date.endOf('month').startOf('day');
                    }
                    return date.plus({ months: 6 - (date.month % 6) }).endOf('month').startOf('day');

                case Frequency.Annually:
                    if (date.diff(date.endOf('year'), 'days').days <= -1) {
                        return date.endOf('year').startOf('day');
                    }
                    return date.plus({ years: 1 }).endOf('year').startOf('day');
            }

            return DateTime.fromISO('1970-01-01').startOf('day'); // Default return value
        }

        // Epeteiako

        switch (frequency) {
            case Frequency.Monthly:
                return date.plus({ months: 1 }).startOf('day');

            case Frequency.Quarterly:
                return date.plus({ months: 3 }).startOf('day');

            case Frequency.SemiAnnually:
                return date.plus({ months: 6 }).startOf('day');

            case Frequency.Annually:
                return date.plus({ year: 1 }).startOf('day');
        }

        return DateTime.fromISO('1970-01-01').startOf('day'); // Default return value
    }

    public static getTokosPlus6Months = (date: DateTime,
        frequency: Frequency,
        epeteiako: boolean): DateTime => {

        if (!epeteiako) {
            switch (frequency) {
                case Frequency.Monthly:
                    if (date.diff(date.endOf('month'), 'days').days <= -1) {
                        return date.endOf('month').startOf('day');
                    }
                    return date.plus({ days: 1 }).endOf('month').startOf('day');

                case Frequency.Quarterly:
                    if (date.month % 3 === 0 && date.diff(date.endOf('month'), 'days').days <= -1) {
                        return date.endOf('month').startOf('day');
                    }
                    return date.plus({ months: 3 - (date.month % 3) }).endOf('month').startOf('day');

                case Frequency.SemiAnnually:
                    if (date.month % 6 === 0 && date.diff(date.endOf('month'), 'days').days <= -1) {
                        return date.endOf('month').startOf('day');
                    }
                    return date.plus({ months: 6 - (date.month % 6) }).endOf('month').startOf('day');

                case Frequency.Annually:
                    if (date.diff(date.endOf('year'), 'days').days <= -1) {
                        return date.endOf('year').startOf('day');
                    }
                    return date.plus({ years: 1 }).endOf('year').startOf('day');
            }

            return DateTime.fromISO('1970-01-01').startOf('day'); // Default return value
        }

        // Epeteiako

        switch (frequency) {
            case Frequency.Monthly:
                return date.plus({ months: 1 }).startOf('day');

            case Frequency.Quarterly:
                return date.plus({ months: 3 }).startOf('day');

            case Frequency.SemiAnnually:
                return date.plus({ months: 6 }).startOf('day');

            case Frequency.Annually:
                return date.plus({ year: 1 }).startOf('day');
        }

        return DateTime.fromISO('1970-01-01').startOf('day'); // Default return value
    }

    public static getPreviousFrequencyDate = (date: DateTime,
        frequency: Frequency,
        epeteiako: boolean): DateTime => {

        if (!epeteiako) {
            switch (frequency) {
                case Frequency.Monthly:
                    return date.minus({ months: 1 }).endOf('month').startOf('day');

                case Frequency.Quarterly:
                    if (date.month === 1 || date.month === 2 || date.month === 3) {
                        return date.minus({ months: date.month }).endOf('month').startOf('day');
                    }
                    if (date.month === 4 || date.month === 5 || date.month === 6) {
                        return date.minus({ months: date.month - 3 }).endOf('month').startOf('day');
                    }
                    if (date.month === 7 || date.month === 8 || date.month === 9) {
                        return date.minus({ months: date.month - 6 }).endOf('month').startOf('day');
                    }
                    if (date.month === 10 || date.month === 11 || date.month === 12) {
                        return date.minus({ months: date.month - 9 }).endOf('month').startOf('day');
                    }

                case Frequency.SemiAnnually:
                    if (date.month % 6 === 0) {
                        return date.minus({ months: 6 }).endOf('month').startOf('day');
                    }
                    return date.minus({ months: (date.month % 6) }).endOf('month').startOf('day');

                case Frequency.Annually:
                    return date.minus({ years: 1 }).endOf('year').startOf('day');
            }

            return DateTime.fromISO('1970-01-01').startOf('day'); // Default return value
        }

        // Epeteiako

        switch (frequency) {
            case Frequency.Monthly:
                return date.minus({ months: 1 }).startOf('day');

            case Frequency.Quarterly:
                return date.minus({ months: 3 }).startOf('day');

            case Frequency.SemiAnnually:
                return date.minus({ months: 6 }).startOf('day');

            case Frequency.Annually:
                return date.minus({ year: 1 }).startOf('day');
        }

        return DateTime.fromISO('1970-01-01').startOf('day'); // Default return value
    }
}

export default AmountEntryExtra;