<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import Decision from "../classes/Decision";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import PGRESTResult from "../classes/PGRESTResult";
import Approval from "../classes/DB_Entities/Approval";
import AcceptAllPending from "./AcceptAllPending.vue";
import UserType from "../enums/UserType";

const props = defineProps<{
  row_id: number;
  view_only: boolean;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });

const loanAgreementsList = ref(new Array<any>());

const {
  updateDecision,
  hasPendingChanges,
  isLoading,
  fullObj,
  table_name,
  clickAcceptAll,
  isNewEntity,
  loadData,
  save,
  close,
  cleanObject,
  resetObjects,
  showError
} = useGenericMethodsVariables();

onMounted(async () => {
  isLoading.value = true;

  table_name.value = Approval.getTableNameStatic();
  cleanObject.value = new Approval();

  console.log("approval_id", props.row_id);

  await loadLoanAgreements();

  if (props.row_id === -1) {
    resetObjects();
    isLoading.value = false;
    
    return;
  }
  
  await loadData(props.row_id);
  await fixPendingSelects();
  isLoading.value = false;
});

const loadLoanAgreements = async () => {
  let result: PGRESTResult = new PGRESTResult();
  loanAgreementsList.value = [];

  let query = `view_mosloanagreement_view1?approve_status=eq.1&debtor_approve_status=eq.1`;

  if (mainStore.loggedUser.user_type === UserType.Agent) {
    query += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
  } else if (mainStore.loggedUser.user_type === UserType.Supervisor) {
    query += `&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`;
  }

  result = await pgrestapi.get(query);

  if (result.error) {
    showError(result.error.message);

    return;
  }

  if (result.data.length === 0) {
    showError(t("errors.no_loan_agreements"), 1500);
  } else {  
    for (let i = 0; i < result.data.length; i++) {
      loanAgreementsList.value.push({
        value: result.data[i].id,
        label: result.data[i].ar_sumbashs + "(" + result.data[i].fullname + ")",
      });
    }
  }
};

const onDecision = async (decision: Decision) => {
  isLoading.value = true;

  await updateDecision(decision);

  await loadData(props.row_id);

  isLoading.value = false;
};

const onPreSave = async () => {
  if (isNewEntity()) {
    if (fullObj.value.loan_agreement_id.originalValue === 0) {
      showError(t("errors.missing_loan_agreement"), 1500);
      return;
    }
  }

  if (fullObj.value.hmer_apost_egkrishs.originalValue === "") {
    showError(t("errors.missing_hmer_apost_egkrishs"), 1500);
    return;
  }

  if (fullObj.value.hmer_anatheshs_mos.originalValue === "") {
    showError(t("errors.missing_hmer_anatheshs_mos"), 1500);
    return;
  }

  if (fullObj.value.ektim_hmer_ylop.originalValue === "") {
    showError(t("errors.missing_ektim_hmer_ylop"), 1500);
    return;
  }

  if (fullObj.value.hmer_olokl.originalValue === "") {
    showError(t("errors.missing_hmer_olokl"), 1500);
    return;
  }

  delete fullObj.value.loan_agreement;

  await save();
  resetObjects();
};

const fixPendingSelects = async () => {
  for (let field of Object.keys(fullObj.value)) {
    if (fullObj.value[field].pendingValue === undefined) continue; // Skip field if no pending value

    // Loan Agreement
    if (field === "loan_agreement_id") {
      let loan1 = await pgrestapi.get(
        `loan_agreement?select=ar_sumbashs,debtor!inner(fullname)&id=eq.` +
        fullObj.value[field].pendingValue.value
      );
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = `${loan1.data[0].ar_sumbashs} (${loan1.data[0].fullname})`;

      let loan2 = await pgrestapi.get(
        `loan_agreement?select=ar_sumbashs,debtor!inner(fullname)&id=eq.` +
        fullObj.value[field].originalValue
      );
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = `${loan2.data[0].ar_sumbashs} (${loan2.data[0].fullname})`;
    }
  }
};
</script>

<template>
  <div v-loading="isLoading">
    <el-form>
      <AcceptAllPending v-show="hasPendingChanges && !props.view_only" @accept="clickAcceptAll(props.row_id)" />
      <p />
      <el-row :gutter="10">
        <el-col :span="24">
          <EntityField type="select" :label="$t('ApprovalDetailsView.loan_agreement')"
            :object="fullObj.loan_agreement_id" :options="loanAgreementsList" @decision="onDecision"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="datepicker" :label="$t('ApprovalDetailsView.approval_decision_send_date')"
            :object="fullObj.hmer_apost_egkrishs" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('ApprovalDetailsView.account_manager')" :object="fullObj.account_manager"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('ApprovalDetailsView.approval_decision_subject')"
            :object="fullObj.thema_egkrishs" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="datepicker" :label="$t('ApprovalDetailsView.mos_assignment_date')"
            :object="fullObj.hmer_anatheshs_mos" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="datepicker" :label="$t('ApprovalDetailsView.mos_estimated_imlementation_date')"
            :object="fullObj.ektim_hmer_ylop" @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('ApprovalDetailsView.middle_officer')" :object="fullObj.middle_officer"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="datepicker" :label="$t('ApprovalDetailsView.end_date')" :object="fullObj.hmer_olokl"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('ApprovalDetailsView.lawyer')" :object="fullObj.lawyer"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="textarea" :label="$t('ApprovalDetailsView.comments')" :object="fullObj.comments"
            @decision="onDecision" :is-disabled="props.view_only" />
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button v-if="!props.view_only" type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped>

</style>
